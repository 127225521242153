import * as React from 'react';
import Draggable from 'react-draggable';

interface IScaleBarProps {
    activeFrame: number;
    frames: number;
    offset: number;
    scale: number;
    width: number;
    minScale: number;
    onUpdate(s, p): any;
}

class ScaleBar extends React.PureComponent<IScaleBarProps> {
    constructor(props) {
        super(props);

        this.state = {};
    }

    public getBarWidth(): number {
        return this.props.width * this.props.scale;
    }

    public getLeftHandleX(): number {
        return this.props.width * this.props.offset;
    }

    public getRightHandleX(): number {
        const w = this.getBarWidth();
        return this.props.width * this.props.offset + w;
    }

    public moveBar(e, ui) {
        const dX = ui.deltaX;
        const x = this.getLeftHandleX();
        const newX = x + dX;
        const offset = newX / this.props.width;

        this.props.onUpdate(this.props.scale, offset);
    }

    public render() {
        const { width, activeFrame, frames } = this.props;

        const minWidth = this.props.minScale * width;
        const barW = this.getBarWidth();
        const lPos = { x: this.getLeftHandleX(), y: 0 };
        const rPos = { x: this.getRightHandleX(), y: 0 };

        const barStyle = {
            width: `${barW}px`
        };

        const indicatorX = (activeFrame / frames) * width;
        const indicatorStyle = {
            left: `${indicatorX}px`
        };

        const lBounds = { top: 0, left: 0, right: rPos.x - minWidth, bottom: 0 };
        const rBounds = { top: 0, left: lPos.x + minWidth, right: width, bottom: 0 };
        const handleBounds = { top: 0, left: 0, right: width - barW, bottom: 0 };

        return (
            <div className='scale-bar'>
                <div className='bg'></div>
                <Draggable
                    axis='x'
                    position={lPos}
                    onDrag={(e, u) => this.moveBar(e, u)}
                    bounds={handleBounds}>
                    <div
                        className='bar'
                        style={barStyle}></div>
                </Draggable>
                <div
                    className='indicator'
                    style={indicatorStyle}
                />
                <Draggable
                    axis='x'
                    position={lPos}
                    onDrag={(e, u) => this.updateLeft(e, u)}
                    bounds={lBounds}>
                    <div className={`handle left`}>
                        <div className='handle-inner' />
                    </div>
                </Draggable>
                <Draggable
                    axis='x'
                    position={rPos}
                    onDrag={(e, u) => this.updateRight(e, u)}
                    bounds={rBounds}>
                    <div className={`handle right`}>
                        <div className='handle-inner' />
                    </div>
                </Draggable>
            </div>
        );
    }

    public updateLeft(e, ui) {
        const dX = ui.deltaX;
        const x = this.getLeftHandleX() + dX;
        const newW = this.getRightHandleX() - x;
        const newScale = newW / this.props.width;
        const offset = x / this.props.width;

        this.props.onUpdate(newScale, offset);
    }

    public updateRight(e, ui) {
        const dX = ui.deltaX;
        const x = this.getRightHandleX() + dX;
        const newW = x - this.getLeftHandleX();
        const newScale = newW / this.props.width;

        this.props.onUpdate(newScale, this.props.offset);
    }
}

export default ScaleBar;
