import { ASSET_PENDING, ASSET_SUCCESS, ASSET_ERROR, JOB_PENDING } from '../../constants/asset';
import { api } from '../../constants/app';

const assetPending = () => {
    return {
        type: ASSET_PENDING
    };
};

const jobPending = (copyResp) => {
    return {
        type: JOB_PENDING,
        copyResp
    };
};

const assetSuccess = (jobResp) => {
    return {
        type: ASSET_SUCCESS,
        jobResp
    };
};

const assetError = (error) => {
    return {
        type: ASSET_ERROR,
        error
    };
};

export const createAsset = (asset, data) => {
    return (dispatch) => {
        dispatch(assetPending());
        return api
            .copyAsset(asset.id, data)
            .then((copyResp) => {
                dispatch(jobPending(copyResp));
                return api.pollJob(copyResp.job_id);
            })
            .then((jobResp) => dispatch(assetSuccess(jobResp)))
            .catch((error) => assetError(error));
    };
};
