import * as React from 'react';
import Variable from './Variable';
import { Button, ButtonMenu, ButtonMenuItem } from '@imposium-hub/components';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { updateInventory, changeInventoryId, deleteInventory } from '../redux/actions/story';
import { newVariable } from '../util/story';
import { VARIABLE_TYPE_OPTIONS } from '../constants/story';
import type { IProject } from '../redux/reducers/project';
import { generateUUID } from '../util/story';
import { fields as copy } from '../constants/copy';
import { IVariable } from '../constants/snippets';
import { logError } from '../util/notifications';
import { ICON_PLUS, getVariableIcon } from '../constants/icons';
import _ from 'lodash';
import { IEditor } from '../redux/reducers/editor';

interface IVariableListProps {
    editor: IEditor;
    project: IProject;
    variables: any;
    changeInventoryId(config): void;
    editInventory(s): void;
    updateInventory(config): void;
    deleteInventory(config): void;
}

interface IVariableListState {
    newVar: IVariable;
}

class VariableList extends React.PureComponent<IVariableListProps, IVariableListState> {
    private btnAdd: JSX.Element = (<Button size='large'>{ICON_PLUS}</Button>);

    private newVariableItems: JSX.Element[] = [];

    constructor(props) {
        super(props);

        this.state = {
            newVar: null
        };

        for (const option of VARIABLE_TYPE_OPTIONS) {
            const handler = () => this.addVariable(option.value);

            this.newVariableItems.push(
                <ButtonMenuItem
                    key={option.value}
                    label={
                        <span>
                            {getVariableIcon(option.value)}&nbsp;&nbsp;{option.label}
                        </span>
                    }
                    onClick={handler}
                />
            );
        }
    }

    private deleteVariable(id) {
        const {
            project: { actId }
        } = this.props;

        this.props.deleteInventory({
            actId,
            inventoryId: id
        });
    }

    private duplicateVariable(id) {
        const { variables } = this.props;
        const newVar = { ...variables[id] };

        newVar.id = '';
        newVar.name = `Copy of ${newVar.name}`;

        // loop through the versions and assign new IDs for the new variable
        if (newVar.versions) {
            const newVersions = [];
            for (const v of newVar.versions) {
                const newVersion = { ...v };
                newVersion.id = generateUUID();
                newVersions.push(newVersion);
            }
            newVar.versions = newVersions;
        }

        this.setState({
            newVar
        });
    }

    public updateVariable(id, config, oldId?) {
        const {
            project: { actId }
        } = this.props;
        const action = {
            actId,
            inventoryId: id,
            inventoryItem: config
        };

        if (oldId !== undefined) {
            action['oldInventoryId'] = oldId;
            this.props.changeInventoryId(action);
        } else {
            this.props.updateInventory(action);
        }
    }

    public addVariable(type) {
        const variable = newVariable(type);

        this.setState({
            newVar: variable
        });
    }

    private cancelNewVariable() {
        this.setState({
            newVar: null
        });
    }

    private updateNewVariable(id, variable, oldId) {
        this.setState({
            newVar: variable
        });
    }

    private saveNewVariable() {
        const {
            project: { actId },
            variables
        } = this.props;
        const {
            newVar,
            newVar: { id }
        } = this.state;

        let variableKeys = variables ? Object.keys(variables) : [];
        variableKeys = variableKeys.map((k) => k.toLowerCase());
        if (variableKeys.includes(id.toLowerCase())) {
            logError(copy.variable.uniqIdError.replace('[id]', id));
            return;
        }

        if (id === '' || !id) {
            logError(copy.variable.emptyIdError);
            return;
        }

        this.setState(
            {
                newVar: null
            },
            () => {
                this.props.updateInventory({
                    actId,
                    inventoryId: newVar.id,
                    inventoryItem: newVar
                });
            }
        );
    }

    public renderVariables() {
        const {
            variables,
            editor: { sortVars }
        } = this.props;
        const { newVar } = this.state;
        const vars = [];

        if (variables) {
            for (const key in variables) {
                if (variables.hasOwnProperty(key)) {
                    const variable = variables[key];
                    vars.push(
                        <Variable
                            config={variable}
                            key={variable.id}
                            variables={variables}
                            onDelete={(i) => this.deleteVariable(i)}
                            onDuplicate={(i) => this.duplicateVariable(i)}
                            onUpdate={(id, config, oldId) => this.updateVariable(id, config, oldId)}
                        />
                    );
                }
            }
        }

        if (newVar) {
            vars.push(
                <Variable
                    config={newVar}
                    key={'new-variable'}
                    variables={variables}
                    addingNew={true}
                    onCancelNew={() => this.cancelNewVariable()}
                    onSaveNew={() => this.saveNewVariable()}
                    onUpdate={(id, config, oldId) => this.updateNewVariable(id, config, oldId)}
                />
            );
        }

        if (sortVars) {
            const grouped = _.groupBy(vars, 'props.config.type');
            const varsSorted = [];

            for (const group in grouped) {
                if (group) {
                    const groups = _.orderBy(
                        grouped[group],
                        [
                            (g) =>
                                g.props.config.name !== null
                                    ? g.props.config.name.toLowerCase()
                                    : ''
                        ],
                        ['asc']
                    );

                    varsSorted.push(...groups);
                }
            }

            const newVarIndex = varsSorted.findIndex((v) => v.key === 'new-variable');
            varsSorted.push(...varsSorted.splice(newVarIndex, 1));
            return varsSorted;
        } else {
            return vars;
        }
    }

    public render() {
        const { newVar } = this.state;

        const renderBtnAdd = () => {
            if (!newVar) {
                return (
                    <ButtonMenu
                        position='right'
                        items={this.newVariableItems}
                        button={this.btnAdd}
                    />
                );
            } else {
                return null;
            }
        };

        return (
            <div className='variable-list'>
                {this.renderVariables()}
                {renderBtnAdd()}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ updateInventory, changeInventoryId, deleteInventory }, dispatch);
};

const mapStateToProps = (state): any => {
    return {
        editor: state.editor,
        project: state.project
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VariableList);
