import * as React from 'react';
import { ROW_HEIGHT } from '../../constants/timeline';
import Overlay from './Overlay';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IImageSceneData } from '../../constants/snippets';
import { Button } from '@imposium-hub/components';
import { newOverlay } from '../../util/story';
import { IOverlay } from '../../constants/snippets';
import { IProject } from '../../redux/reducers/project';
import { arrayMove } from '../../util/general';
import { timeline as copy } from '../../constants/copy';
import { ICON_PLUS } from '../../constants/icons';
import { updateTimelineState } from '../../redux/actions/timeline';

interface ICutProps {
    project: IProject;
    config: IImageSceneData;
    height: number;
    activeOverlay?: string;
    select(cutId: string, overlayId?: string): void;
    update(config): void;
    updateTimelineState(s): void;
}

class Cut extends React.PureComponent<ICutProps, undefined> {
    private evtHandlers: any;

    constructor(props) {
        super(props);

        this.evtHandlers = {
            buttons: {
                add: (e) => this.addOverlay(e)
            }
        };
    }

    public overlayClicked(id) {
        this.props.select(id);
    }

    private shiftOverlay(index: number, offset: number) {
        const newIndex = index + offset;
        const newConfig = { ...this.props.config };
        let newOverlays = [...newConfig.overlays];

        newOverlays = arrayMove(newOverlays, index, newIndex);

        newConfig.overlays = newOverlays;

        this.props.update(newConfig);
    }

    private addOverlay(e) {
        e.preventDefault();
        e.stopPropagation();

        const {
            config: { width, height }
        } = this.props;

        const overlay: IOverlay = newOverlay();
        const newConfig = { ...this.props.config };
        overlay.width = width;
        overlay.height = height;

        const newOverlays = [...newConfig.overlays];
        newOverlays.push(overlay);
        newConfig.overlays = newOverlays;

        this.props.update(newConfig);
        this.props.updateTimelineState({ activeTimelineOverlay: overlay.id });
    }

    public render() {
        const {
            activeOverlay,
            config: { overlays }
        } = this.props;

        const btnAddOverlay = (
            <Button
                style='subtle'
                tooltip={copy.tooltipAddOverlay}
                customStyles={{
                    position: 'absolute',
                    left: '50%',
                    bottom: `${ROW_HEIGHT * overlays.length}px`,
                    marginLeft: '-9px'
                }}
                onClick={this.evtHandlers.buttons.add}>
                {ICON_PLUS}
            </Button>
        );

        const h = ROW_HEIGHT * (overlays.length + 1);
        const layersStyle = {
            height: `${h}px`
        };

        return (
            <div
                className={`image-layers`}
                style={layersStyle}>
                {overlays.map((overlay, i) => {
                    const selected = overlay.id === activeOverlay;
                    return (
                        <Overlay
                            selected={selected}
                            config={overlay}
                            key={i}
                            height={h + ROW_HEIGHT}
                            index={i}
                            shift={(e, o) => this.shiftOverlay(e, o)}
                            select={(e) => this.overlayClicked(e)}
                        />
                    );
                })}

                {btnAddOverlay}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ updateTimelineState }, dispatch);
};

const mapStateToProps = (state): any => {
    return { project: state.project };
};

export default connect(mapStateToProps, mapDispatchToProps)(Cut);
