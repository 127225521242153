import * as React from 'react';
import { FieldWrapper, Button, SelectField } from '@imposium-hub/components';
import { ILayerAnchor, NEW_ANCHOR } from '../constants/snippets';
import { ATTACHMENT_POINT_OPTIONS } from '../constants/story';
import { ICON_PLUS, ICON_TIMES } from '../constants/icons';

interface ILayerAnchorConfigProps {
    config: ILayerAnchor;
    onDelete(): void;
    layerOptions: any;
    onUpdate(config: ILayerAnchor): void;
    label: string;
}

class LayerAnchorConfig extends React.PureComponent<ILayerAnchorConfigProps, undefined> {
    private evtHandlers: any = {
        attachmentPoint: (e) => this.updateConfig('attachment_point', e),
        addAnchor: (e) => this.addAnchor(),
        layerId: (e) => this.updateConfig('id', e),
        deleteAnchor: () => this.deleteAnchor()
    };

    constructor(props) {
        super(props);
    }

    private addAnchor() {
        this.props.onUpdate({ ...NEW_ANCHOR });
    }

    private deleteAnchor() {
        this.props.onDelete();
    }

    private updateConfig(key, val) {
        const { config } = this.props;
        const newConfig = config ? { ...config } : { ...NEW_ANCHOR };

        if (config[key] !== val) {
            this.props.onDelete();
        }

        newConfig[key] = val;
        this.props.onUpdate(newConfig);
    }

    public render() {
        const { label } = this.props;
        const { config } = this.props;

        const btnDelete = (
            <Button
                style='subtle'
                key='btn-delete'
                onClick={this.evtHandlers.deleteAnchor}>
                {ICON_TIMES}
            </Button>
        );

        const btnAdd = (
            <Button
                style='subtle'
                color='primary'
                onClick={this.evtHandlers.addAnchor}>
                {ICON_PLUS}
            </Button>
        );

        const options = this.props.layerOptions ? this.props.layerOptions : [];

        const contents = config ? (
            <>
                <SelectField
                    onChange={this.evtHandlers.layerId}
                    width={'70%'}
                    value={config.id}
                    options={options}
                />
                <SelectField
                    width={'calc( 30% - 30px)'}
                    value={config.attachment_point}
                    onChange={this.evtHandlers.attachmentPoint}
                    options={ATTACHMENT_POINT_OPTIONS}
                />
            </>
        ) : (
            btnAdd
        );

        return (
            <FieldWrapper
                buttons={config ? btnDelete : null}
                width='100%'
                label={label}>
                {contents}
            </FieldWrapper>
        );
    }
}

export default LayerAnchorConfig;
