import React from 'react';
import loader from '@monaco-editor/loader';
import { FORCE_UPDATE_HTML_EDITOR } from '../../../constants/editor';

interface ICodePanelProps {
    type: any;
    code: any;
    onChange(code: any);
}

export class CodePanel extends React.PureComponent<ICodePanelProps> {
    private monacoEl: any;

    private editor: any;

    private updateEditorHandler = () => this.updatePanel();

    constructor(props) {
        super(props);

        this.monacoEl = React.createRef();
    }

    private updatePanel() {
        if (this.editor) {
            this.editor.setValue(this.props.code);
        }
    }

    public componentWillUnmount(): void {
        document.removeEventListener(FORCE_UPDATE_HTML_EDITOR, this.updateEditorHandler);
    }

    public componentDidMount(): void {
        document.addEventListener(FORCE_UPDATE_HTML_EDITOR, this.updateEditorHandler);
        if (this.monacoEl) {
            const { code, type } = this.props;

            void loader.init().then((monaco) => {
                this.editor = monaco.editor.create(this.monacoEl.current, {
                    language: type,
                    value: code,
                    minimap: {
                        enabled: false
                    },
                    automaticLayout: true,
                    theme: 'vs-dark',
                    wordWrap: 'on'
                });
            });
        }
    }

    private onKeyUpHandler() {
        this.props.onChange(this.editor.getValue());
    }

    public render() {
        const { type } = this.props;
        return (
            <>
                <div className={`code-title ${type}`}>{type.toUpperCase()}</div>
                <div
                    className='code-panel'
                    ref={this.monacoEl}
                    onKeyUp={() => this.onKeyUpHandler()}></div>
            </>
        );
    }
}
