import * as React from 'react';
import {
    TextField,
    FieldWrapper,
    Tag,
    HRule,
    NumberField,
    SMPTEField,
    formatDateDefault
} from '@imposium-hub/components';
import { connect } from 'react-redux';
import { fields as copy } from '../../constants/copy';
import { ASSET_TYPES } from '../../constants/story';
import { getDuration } from '../../util/ui';

interface IAssetDetailsProps {
    asset: any;
    assetList: any;
    columns: number;
    newAsset: boolean;
    onNameChange(n: any): void;
    onAddTag(t: any): void;
    onRemoveTag(t: any): void;
    assetTags: string[];
    headerButtons?: JSX.Element[];
}

interface IAssetDetailsState {
    newTag: string;
    tags: any;
}

class AssetDetails extends React.Component<IAssetDetailsProps, IAssetDetailsState> {
    constructor(p: IAssetDetailsProps) {
        super(p);

        this.state = {
            newTag: '',
            tags: null
        };
    }

    componentDidMount(): void {
        this.setState({ tags: this.getTagsHandler() });
    }

    componentDidUpdate(): void {
        const tags = this.getTagsHandler();
        if (tags !== this.state.tags) {
            this.setState({ tags });
        }
    }

    private getTagsHandler() {
        const {
            assetList: { assets },
            asset,
            asset: { id },
            newAsset
        } = this.props;

        const assetFromList = assets.find((i) => i.id === id);
        let tags;
        if (newAsset) {
            tags = asset.tags;
        } else if (assetFromList) {
            tags = assetFromList.tags;
        } else {
            tags = asset.tags;
        }
        return tags;
    }

    private renderTags() {
        const { tags } = this.state;
        const tagsArray = [...new Set(tags)];
        const tagsMarkup: JSX.Element[] = tagsArray.map((tag: string) => (
            <Tag
                key={tag}
                copy={tag}
                removeHandler={() => this.props.onRemoveTag(tag)}
                colorize={true}
            />
        ));

        return tagsMarkup;
    }

    public render = (): JSX.Element => {
        const {
            asset,
            asset: { type, rate, frame_count, width, height },
            columns,
            newAsset,
            assetTags
        } = this.props;

        if (!asset) {
            return null;
        }

        const duration = type === ASSET_TYPES.VIDEO ? getDuration(frame_count, rate) : null;
        const title = newAsset ? copy.asset.newDetails : copy.asset.details;
        const formattedDate = formatDateDefault(asset.date_created);
        const isTwoCol = columns === 2;

        const nameSubmit = !newAsset ? (e) => this.props.onNameChange(e) : undefined;
        const nameChange = newAsset ? (e) => this.props.onNameChange(e) : undefined;

        const assetIdField = (
            <TextField
                readOnly={true}
                width='25%'
                showCopy={true}
                value={asset.id}
            />
        );

        const nameField = (
            <TextField
                label={copy.global.name}
                controlled={!newAsset}
                width={isTwoCol ? '50%' : '100%'}
                onSubmit={nameSubmit}
                onChange={nameChange}
                value={asset.name}
            />
        );

        const dateField = (
            <TextField
                readOnly={true}
                label={copy.global.dateCreated}
                width={isTwoCol ? '50%' : '100%'}
                value={formattedDate}
            />
        );

        const newTag = (
            <TextField
                submittable={true}
                submittableType='add'
                label={copy.asset.addTag}
                suggestions={assetTags}
                doSubmit={(t) => this.props.onAddTag(t)}
                width={isTwoCol ? '50%' : '100%'}
                buttons={[]}
                value={this.state.newTag}
            />
        );

        const widthField = (
            <TextField
                label={copy.global.width}
                width={isTwoCol ? '25%' : '50%'}
                value={width}
            />
        );

        const heightField = (
            <TextField
                label={copy.global.height}
                width={isTwoCol ? '25%' : '50%'}
                value={height}
            />
        );

        const tags = (
            <FieldWrapper
                label={copy.asset.tags}
                width={isTwoCol ? '50%' : '100%'}>
                {this.renderTags()}
            </FieldWrapper>
        );

        const frameRateField = (
            <NumberField
                width='25%'
                label={copy.global.rate}
                value={rate}
                readOnly
            />
        );

        const durationField = (
            <SMPTEField
                label={copy.global.duration}
                width='25%'
                value={duration}
                frameRate={rate}
                readOnly
            />
        );

        return (
            <div className='asset-details'>
                <h2>{title}</h2>
                {assetIdField}
                <div className='header-buttons'>{this.props.headerButtons}</div>
                <HRule />
                {nameField}
                {dateField}
                {newTag}
                {tags}
                {width ? widthField : null}
                {height ? heightField : null}
                {type !== ASSET_TYPES.VIDEO ? null : frameRateField}
                {type !== ASSET_TYPES.VIDEO ? null : durationField}
            </div>
        );
    };
}

const mapStateToProps = (state): any => {
    return {
        assetTags: state.assetTags,
        assetList: state.assetList
    };
};

export default connect(mapStateToProps, null)(AssetDetails);
