import * as React from 'react';
import { IVariables, NEW_TTS_CONFIG, colorPresets } from '../constants/snippets';
import {
    ButtonGroupField,
    Card,
    ColorField,
    CheckboxField,
    NumberField,
    AdvancedNumberField,
    Spinner,
    FontPicker,
    FieldWrapper,
    CopyPropIdButton
} from '@imposium-hub/components';
import { fields as copy } from '../constants/copy';
import { ASSET_TYPES, DEFAULT_FONT, FONT_TYPES, VARIABLE_TYPES } from '../constants/story';
import { connect } from 'react-redux';
import { api } from '../constants/app';
import { DEFAULT_FONTS } from '../constants/fonts';
import {
    ICON_ALIGN_CENTER,
    ICON_ALIGN_LEFT,
    ICON_ALIGN_RIGHT,
    ICON_ARROW_CENTER_V,
    ICON_ARROW_DOWN,
    ICON_ARROW_UP
} from '../constants/icons';
import ReactTextareaAutocomplete from '@imposium-hub/react-textarea-autocomplete';
import { TextToSpeechOptions } from './TextToSpeechOptions';
import { reactAutoCompleteSettings } from '../util/fields';
import { logError, logNotification } from '../util/notifications';
import moize from 'moize';
import { PROP_KEY_PRFIX } from '../constants/editor';

interface ITextLayerOptionsProps {
    storyId: string;
    compositionId: string;
    showCopyPropIds: boolean;
    options: any;
    layerId: string;
    type: any;
    onChange(options: any): void;
    assetList: any;
    variables: IVariables;
}

interface ITextLayerOptionsState {
    availableFonts: any[];
}

export const H_ALIGN_OPTIONS = [
    {
        icon: ICON_ALIGN_LEFT,
        value: 'left'
    },
    {
        icon: ICON_ALIGN_CENTER,
        value: 'center'
    },
    {
        icon: ICON_ALIGN_RIGHT,
        value: 'right'
    }
];

export const V_ALIGN_OPTIONS = [
    {
        icon: ICON_ARROW_UP,
        value: 'flex-start'
    },
    {
        icon: ICON_ARROW_CENTER_V,
        value: 'center'
    },
    {
        icon: ICON_ARROW_DOWN,
        value: 'flex-end'
    }
];

class TextLayerOptions extends React.PureComponent<ITextLayerOptionsProps, ITextLayerOptionsState> {
    private eventHandlers;

    constructor(props) {
        super(props);

        this.state = {
            availableFonts: []
        };

        this.eventHandlers = {
            inputs: {
                name: (v) => this.optionValueChanged('name', v),
                width: (v) => this.optionValueChanged('width', v),
                height: (v) => this.optionValueChanged('height', v),
                x: (v) => this.optionValueChanged('x', v),
                y: (v) => this.optionValueChanged('y', v),
                rotation: (v) => this.optionValueChanged('rotation', v),
                content: (v) => this.optionValueChanged('content', v),
                hAlign: (v) => this.optionValueChanged('horizontal_alignment', v),
                vAlign: (v) => this.optionValueChanged('vertical_alignment', v),
                textWrap: (v) => this.optionValueChanged('text_wrap', v),
                textFit: (v) => this.optionValueChanged('text_fit', v),
                fontSize: (v) => this.optionValueChanged('font_size', v),
                lineHeight: (v) => this.optionValueChanged('line_height', v),
                letterSpacing: (v) => this.optionValueChanged('letter_spacing', v),
                strokeWeight: (v) => this.optionValueChanged('stroke_weight', v),
                font: (v) => this.optionValueChanged('font', v),
                customFont: (v) => this.customFontUpdated(v),
                wordSpacing: (v) => this.optionValueChanged('word_spacing', v),
                color: (v) => this.optionValueChanged('color', this.formatColor(v)),
                highlight_color: (v) =>
                    this.optionValueChanged('highlight_color', this.formatColor(v)),
                strokeColor: (v) => this.optionValueChanged('stroke_color', this.formatColor(v)),
                backgroundColor: (v) =>
                    this.optionValueChanged('background_color', this.formatColor(v)),
                italic: () => this.optionValueChanged('font_style', 'italic'),
                caps: (v) => this.optionValueChanged('upper_case', v),
                textToSpeech: () => this.addTextToSpeech(),
                ttsConfig: (v) => this.ttsConfigHandler(v)
            },
            onNotification: (e) => logNotification(e),
            onError: (e) => logError(e)
        };
    }

    public componentDidMount() {
        this.getFontsAssets();
    }

    public componentDidUpdate(prevProps) {
        if (prevProps.assetList !== this.props.assetList) {
            this.getFontsAssets();
        }
    }

    private ttsConfigHandler(options) {
        const merged = { ...this.props.options, ...options };
        this.props.onChange(merged);
    }

    private addTextToSpeech() {
        const newOpts = { ...this.props.options };
        newOpts['source'] = {
            from: VARIABLE_TYPES.TEXT,
            text: newOpts['content'],
            text_to_speech: { ...NEW_TTS_CONFIG }
        };

        const merged = { ...this.props.options, ...newOpts };
        this.props.onChange(merged);
    }

    private customFontUpdated(asset) {
        const newOpts = { ...this.props.options };

        if (asset) {
            const { name, url, id } = asset;
            newOpts['font'] = name;
            newOpts['custom_font_url'] = url;
            newOpts['custom_font_id'] = id;
        } else {
            newOpts['font'] = DEFAULT_FONT;
            newOpts['custom_font_url'] = null;
            newOpts['custom_font_id'] = null;
        }

        this.props.onChange(newOpts);
    }

    private getFontsAssets() {
        const { storyId } = this.props;

        api.getAssets({ type: ASSET_TYPES.FONT }, storyId)
            .then((assetList) => {
                if (assetList.assets) {
                    this.setState({
                        availableFonts: assetList.assets
                    });
                }
            })
            .catch((e) => console.error(e));
    }

    private formatColor(color) {
        const { r, g, b, a } = color.rgb;
        const alpha = color.source === 'hsv' ? 1 : a;
        return `rgba(${r},${g},${b},${alpha})`;
    }

    private optionValueChanged(key, value) {
        const newOpts = { ...this.props.options };

        if (key === 'font') {
            if (value === copy.overlayConfig.fontNotFound) {
                return;
            }

            const { availableFonts } = this.state;
            const standardFonts = DEFAULT_FONTS.find((font) => font.name === value);
            const customFonts = availableFonts.filter((asset) => asset.name === value);

            if (newOpts['font'] !== value) {
                if (standardFonts) {
                    newOpts['font_type'] = FONT_TYPES.STANDARD;
                    newOpts['font'] = value;
                    newOpts['font_weight'] = standardFonts.weight;
                    this.props.onChange(newOpts);
                }

                if (customFonts.length > 0) {
                    newOpts['font_type'] = FONT_TYPES.CUSTOM;
                    newOpts['font_weight'] = 'normal';
                    newOpts['font'] = value;
                    newOpts['custom_font_id'] = customFonts[0].id;
                    newOpts['custom_font_url'] = customFonts[0].url;
                    this.props.onChange(newOpts);
                }
            }
        } else {
            newOpts[key] = value;
            this.props.onChange(newOpts);
        }
    }

    private renderFontField() {
        const { availableFonts } = this.state;
        const {
            options: { font },
            showCopyPropIds,
            compositionId,
            layerId
        } = this.props;

        const { onNotification, onError } = this.eventHandlers;
        const customAvailableFonts = availableFonts.map((asset) => asset.name);
        const standardFonts = DEFAULT_FONTS.map((f) => f.name);
        const availableAssets = [...standardFonts, ...customAvailableFonts].sort();
        const fontCheck = availableAssets.includes(this.props.options.font);
        const fontValue = fontCheck ? font : copy.overlayConfig.fontNotFound;

        return (
            <FieldWrapper
                customClass='select-field'
                label={[
                    copy.textConfig.font,
                    getCopyPropIdButton(
                        showCopyPropIds,
                        compositionId,
                        layerId,
                        'options.font',
                        onNotification,
                        onError
                    )
                ]}
                labelPosition='top'
                width='50%'>
                <FontPicker
                    apiKey='AIzaSyApdITAFBEQA0j5YJbcfTIP6ZBMJTJmo9E'
                    activeFontFamily={fontValue}
                    families={availableAssets}
                    defaultFonts={DEFAULT_FONTS}
                    customFonts={availableFonts}
                    onChange={this.eventHandlers.inputs.font}
                />
            </FieldWrapper>
        );
    }

    private renderTextArea() {
        const { options, variables, compositionId, layerId, showCopyPropIds } = this.props;
        const { varsData, variableItem } = reactAutoCompleteSettings(variables);
        const { onNotification, onError } = this.eventHandlers;

        return (
            <FieldWrapper
                label={[
                    copy.textConfig.textLayerContent,
                    getCopyPropIdButton(
                        showCopyPropIds,
                        compositionId,
                        layerId,
                        'options.content',
                        onNotification,
                        onError
                    )
                ]}
                labelPosition='top'
                customClass='text-area-field autocomplete'>
                <ReactTextareaAutocomplete
                    key='text-autocomplete'
                    onChange={(e) => this.eventHandlers.inputs.content(e.target.value)}
                    value={options['content']}
                    loadingComponent={() => <Spinner />}
                    movePopupAsYouType
                    minChar={0}
                    tabOrEnter={true}
                    trigger={{
                        '{': {
                            dataProvider: (d) => {
                                const filtered = varsData.filter((f) =>
                                    f.name.toLowerCase().startsWith(d)
                                );
                                return filtered;
                            },
                            component: variableItem,
                            output: (value) => `{{${value.name}}}`
                        }
                    }}
                />
            </FieldWrapper>
        );
    }

    private renderTextToSpeech() {
        const { options } = this.props;

        if (options?.source?.text_to_speech) {
            const {
                source: { text_to_speech }
            } = options;
            return (
                <TextToSpeechOptions
                    key='inventory-tts-config'
                    config={text_to_speech}
                    onChange={this.eventHandlers.inputs.ttsConfig}
                />
            );
        } else {
            return null;
        }
    }

    public render() {
        const { options, layerId, compositionId, showCopyPropIds } = this.props;
        const { onNotification, onError } = this.eventHandlers;
        const strokeColor = options['stroke_color'] ? options['stroke_color'] : 'transparent';
        const highlightColor = options['highlight_color']
            ? options['highlight_color']
            : 'transparent';

        return (
            <Card
                collapsable={true}
                open={true}
                title={copy.layerConfig.options}
                style={'text-options'}>
                {this.renderTextArea()}
                <ButtonGroupField
                    label={
                        <span>
                            {getCopyPropIdButton(
                                showCopyPropIds,
                                compositionId,
                                layerId,
                                'options.horizontal_alignment',
                                onNotification,
                                onError
                            )}
                        </span>
                    }
                    labelPosition='top'
                    options={H_ALIGN_OPTIONS}
                    value={options['horizontal_alignment']}
                    width='50%'
                    onChange={this.eventHandlers.inputs.hAlign}
                />
                <ButtonGroupField
                    label={
                        <span>
                            {getCopyPropIdButton(
                                showCopyPropIds,
                                compositionId,
                                layerId,
                                'options.vertical_alignment',
                                onNotification,
                                onError
                            )}
                        </span>
                    }
                    labelPosition='top'
                    options={V_ALIGN_OPTIONS}
                    value={options['vertical_alignment']}
                    width='50%'
                    onChange={this.eventHandlers.inputs.vAlign}
                />
                {this.renderFontField()}
                <CheckboxField
                    label={
                        <span>
                            {copy.textConfig.wrap}
                            {getCopyPropIdButton(
                                showCopyPropIds,
                                compositionId,
                                layerId,
                                'options.text_wrap',
                                onNotification,
                                onError
                            )}
                        </span>
                    }
                    value={options['text_wrap']}
                    width='10%'
                    labelPosition='top'
                    onChange={this.eventHandlers.inputs.textWrap}
                />
                <CheckboxField
                    label={
                        <span>
                            {copy.textConfig.fit}
                            {getCopyPropIdButton(
                                showCopyPropIds,
                                compositionId,
                                layerId,
                                'options.text_fit',
                                onNotification,
                                onError
                            )}
                        </span>
                    }
                    value={options['text_fit']}
                    width='10%'
                    labelPosition='top'
                    onChange={this.eventHandlers.inputs.textFit}
                />
                <CheckboxField
                    label={
                        <span>
                            {copy.textConfig.caps}
                            {getCopyPropIdButton(
                                showCopyPropIds,
                                compositionId,
                                layerId,
                                'options.upper_case',
                                onNotification,
                                onError
                            )}
                        </span>
                    }
                    value={options['upper_case']}
                    width='10%'
                    labelPosition='top'
                    onChange={this.eventHandlers.inputs.caps}
                />
                <AdvancedNumberField
                    label={
                        <span>
                            {copy.textConfig.fontSize}
                            {getCopyPropIdButton(
                                showCopyPropIds,
                                compositionId,
                                layerId,
                                'options.font_size',
                                onNotification,
                                onError
                            )}
                        </span>
                    }
                    value={options['font_size']}
                    width='25%'
                    max={250}
                    min={1}
                    labelPosition='top'
                    onChange={this.eventHandlers.inputs.fontSize}
                />
                <NumberField
                    label={
                        <span>
                            {copy.textConfig.lineHeight}
                            {getCopyPropIdButton(
                                showCopyPropIds,
                                compositionId,
                                layerId,
                                'options.line_height',
                                onNotification,
                                onError
                            )}
                        </span>
                    }
                    value={options['line_height']}
                    width='25%'
                    min={0}
                    labelPosition='top'
                    onChange={this.eventHandlers.inputs.lineHeight}
                />
                <NumberField
                    label={
                        <span>
                            {copy.textConfig.letterSpacing}
                            {getCopyPropIdButton(
                                showCopyPropIds,
                                compositionId,
                                layerId,
                                'options.letter_spacing',
                                onNotification,
                                onError
                            )}
                        </span>
                    }
                    value={options['letter_spacing']}
                    width='25%'
                    min={0}
                    labelPosition='top'
                    onChange={this.eventHandlers.inputs.letterSpacing}
                />
                <NumberField
                    label={
                        <span>
                            {copy.textConfig.strokeWeight}
                            {getCopyPropIdButton(
                                showCopyPropIds,
                                compositionId,
                                layerId,
                                'options.stroke_weight',
                                onNotification,
                                onError
                            )}
                        </span>
                    }
                    value={options['stroke_weight']}
                    width='25%'
                    min={0}
                    labelPosition='top'
                    onChange={this.eventHandlers.inputs.strokeWeight}
                />
                <ColorField
                    label={
                        <span>
                            {copy.textConfig.color}
                            {getCopyPropIdButton(
                                showCopyPropIds,
                                compositionId,
                                layerId,
                                'options.color',
                                onNotification,
                                onError
                            )}
                        </span>
                    }
                    value={options['color']}
                    presetColors={colorPresets}
                    enableAlpha={true}
                    width='25%'
                    labelPosition='top'
                    pickerPosition='left'
                    onChange={this.eventHandlers.inputs.color}
                />
                <ColorField
                    label={
                        <span>
                            {copy.textConfig.highlight}
                            {getCopyPropIdButton(
                                showCopyPropIds,
                                compositionId,
                                layerId,
                                'options.highlight_color',
                                onNotification,
                                onError
                            )}
                        </span>
                    }
                    value={highlightColor}
                    presetColors={colorPresets}
                    enableAlpha={true}
                    width='25%'
                    labelPosition='top'
                    pickerPosition='left'
                    onChange={this.eventHandlers.inputs.highlight_color}
                />
                <ColorField
                    label={
                        <span>
                            {copy.textConfig.backgroundColor}
                            {getCopyPropIdButton(
                                showCopyPropIds,
                                compositionId,
                                layerId,
                                'options.background_color',
                                onNotification,
                                onError
                            )}
                        </span>
                    }
                    value={options['background_color']}
                    presetColors={colorPresets}
                    enableAlpha={true}
                    width='25%'
                    labelPosition='top'
                    pickerPosition='right'
                    onChange={this.eventHandlers.inputs.backgroundColor}
                />
                <ColorField
                    label={
                        <span>
                            {copy.textConfig.strokeColor}
                            {getCopyPropIdButton(
                                showCopyPropIds,
                                compositionId,
                                layerId,
                                'options.stroke_color',
                                onNotification,
                                onError
                            )}
                        </span>
                    }
                    value={strokeColor}
                    presetColors={colorPresets}
                    enableAlpha={true}
                    width='25%'
                    labelPosition='top'
                    pickerPosition='right'
                    onChange={this.eventHandlers.inputs.strokeColor}
                />
            </Card>
        );
    }
}

const mapStateToProps = (state): any => {
    return {
        assetList: state.assetList,
        showCopyPropIds: state.editor.showCopyPropIds,
        story: state.story
    };
};

export const mGetCopyPropIdButton = (
    enabled,
    compId,
    layerId,
    propKey,
    onNotification,
    onError
) => {
    if (enabled) {
        return (
            <CopyPropIdButton
                key={propKey}
                onNotification={onNotification}
                onError={onError}
                copyPropId={`${PROP_KEY_PRFIX}-${compId}-${layerId}.${propKey}`}
            />
        );
    } else {
        return null;
    }
};

export const getCopyPropIdButton = moize(mGetCopyPropIdButton);

export default connect(mapStateToProps, {})(TextLayerOptions);
