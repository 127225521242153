import * as React from 'react';
import { connect } from 'react-redux';
import { INotification } from '../redux/reducers/notifications';
import { clearNotifications } from '../util/notifications';
import Notification from './Notification';
interface INotificationConsoleProps {
    notifications: INotification[];
}

class NotificationConsole extends React.PureComponent<INotificationConsoleProps> {
    constructor(props) {
        super(props);
    }

    public renderNotification(notification) {
        if (notification) {
            return (
                <Notification
                    {...notification}
                    onClose={() => clearNotifications()}
                />
            );
        } else {
            return null;
        }
    }

    public render() {
        const { notifications } = this.props;
        return (
            <div
                key={Math.random()}
                className='notification-console'>
                {this.renderNotification(notifications[0])}
            </div>
        );
    }
}

const mapStateToProps = (state): any => {
    return { notifications: state.notifications };
};

export default connect(mapStateToProps, {})(NotificationConsole);
