import * as React from 'react';
import {
    FRAME_WIDTH,
    ROW_HEIGHT,
    CUT_HANDLE_WIDTH,
    CUT_HANDLE_BREAKPOINT
} from '../../constants/timeline';
import Overlay from './Overlay';
import { getFrameFromXPos, getXPosFromFrame } from '../../util/timeline';
import Draggable from 'react-draggable';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { addViewer } from '../../redux/actions/story';
import { VIEWER_TYPES } from '../../constants/viewer';
import { ICut, NEW_RECT_POSITION } from '../../constants/snippets';
import { Button } from '@imposium-hub/components';
import { newOverlay } from '../../util/story';
import { timeline as copy } from '../../constants/copy';
import { IOverlay } from '../../constants/snippets';
import { IProject } from '../../redux/reducers/project';
import { arrayMove } from '../../util/general';
import { ICON_PLUS } from '../../constants/icons';
import ErrorLayer from './ErrorLayer';
import { OVERLAY_POSITION_TYPES } from '../../constants/story';
import { updateTimelineState } from '../../redux/actions/timeline';

const iconOut = (
    <svg
        version='1.1'
        id='Layer_1'
        x='0px'
        y='0px'
        viewBox='0 0 100 100'>
        <polygon points='100,0 0,100 0,0 ' />
    </svg>
);

const iconIn = (
    <svg
        version='1.1'
        id='Layer_1'
        x='0px'
        y='0px'
        viewBox='0 0 100 100'>
        <polygon points='100,100 0,0 100,0 ' />
    </svg>
);

interface ICutState {
    leftDragFrameOffset: number;
    rightDragFrameOffset: number;
}

interface ICutProps {
    project: IProject;
    active: boolean;
    activeFrame: number;
    config: ICut;
    nextCut: ICut;
    previousCut: ICut;
    scale: number;
    videoHeight: number;
    videoWidth: number;
    height: number;
    timelineWidth: number;
    activeOverlay?: string;
    move(config): void;
    select(cutId: string, overlayId?: string): void;
    setActiveFrame(frame: number): void;
    addViewer(c: any): void;
    update(config): void;
    updateTimelineState(s): void;
    errors: any;
}

class Cut extends React.Component<ICutProps, ICutState> {
    private evtHandlers: any;

    constructor(props) {
        super(props);

        this.state = {
            rightDragFrameOffset: 0,
            leftDragFrameOffset: 0
        };

        this.evtHandlers = {
            addOverlay: (e) => this.addOverlay(e)
        };
    }

    public cutClicked(e) {
        e.preventDefault();
        e.stopPropagation();

        this.props.select(this.props.config.id);
    }

    public cutDoubleClicked(e) {
        e.preventDefault();
        e.stopPropagation();

        const { config } = this.props;

        this.props.addViewer({
            type: VIEWER_TYPES.CUT_PREVIEW,
            id: config.id,
            label: `Cut: ${config.name}`
        });
    }

    private getStartFrame() {
        const s: any = this.props.config.startFrame;
        return parseInt(s, 10);
    }

    private getEndFrame() {
        const e: any = this.props.config.endFrame;
        return parseInt(e, 10);
    }

    public overlayClicked(id) {
        this.props.select(this.props.config.id, id);
    }

    public getCutWidth(): number {
        const { scale } = this.props;
        const { rightDragFrameOffset, leftDragFrameOffset } = this.state;
        const frames =
            this.getEndFrame() +
            rightDragFrameOffset -
            (this.getStartFrame() + leftDragFrameOffset);

        return FRAME_WIDTH * frames * scale;
    }

    public getCutX(): number {
        const { scale } = this.props;
        const { leftDragFrameOffset } = this.state;

        return getXPosFromFrame(this.getStartFrame() + leftDragFrameOffset, scale);
    }

    public onBaseDrag(e, ui) {
        const { setActiveFrame, scale } = this.props;
        const newX = ui.x + this.getCutX();
        const frame = getFrameFromXPos(newX, scale);
        const startFrame = this.getStartFrame();

        if (frame !== startFrame) {
            const offset = frame - startFrame;
            this.setState(
                {
                    leftDragFrameOffset: offset,
                    rightDragFrameOffset: offset
                },
                () => {
                    setActiveFrame(frame);
                }
            );
        }
    }

    public onBaseDragStart(e, ui) {
        const {
            select,
            config: { id }
        } = this.props;
        select(id);
    }

    public onBaseDragStop(e, ui) {
        const { scale, move } = this.props;
        const newX = ui.x + this.getCutX();
        const start = getFrameFromXPos(newX, scale);
        const end = getFrameFromXPos(newX + this.getCutWidth(), scale);
        const startFrame = this.getStartFrame();
        const endFrame = this.getEndFrame();

        if (start !== startFrame || end !== endFrame) {
            this.setState(
                {
                    leftDragFrameOffset: 0,
                    rightDragFrameOffset: 0
                },
                () => {
                    move({
                        startFrame: start,
                        endFrame: end
                    });
                }
            );
        }
    }

    public onLeftHandleDrag(e, ui) {
        const { setActiveFrame, scale } = this.props;
        const startFrame = this.getStartFrame();
        const newX = ui.x + CUT_HANDLE_WIDTH + this.getCutX();
        const frame = getFrameFromXPos(newX, scale);
        if (frame !== startFrame) {
            const leftDragFrameOffset = frame - startFrame;
            this.setState({ leftDragFrameOffset }, () => {
                setActiveFrame(frame);
            });
        }
    }

    public onLeftHandleDragStart(e, ui) {
        const {
            setActiveFrame,
            select,
            config: { id }
        } = this.props;
        select(id);
        setActiveFrame(this.getStartFrame());
    }

    public onLeftHandleDragStop(e, ui) {
        const { update, scale } = this.props;
        const newX = ui.x + CUT_HANDLE_WIDTH + this.getCutX();
        const frame = getFrameFromXPos(newX, scale);
        const startFrame = this.getStartFrame();

        if (frame !== startFrame) {
            this.setState(
                {
                    leftDragFrameOffset: 0
                },
                () => {
                    update({
                        startFrame: frame
                    });
                }
            );
        }
    }

    public onRightHandleDrag(e, ui) {
        const { setActiveFrame, scale } = this.props;
        const newX = ui.x + this.getCutX();
        const frame = getFrameFromXPos(newX, scale);
        const endFrame = this.getEndFrame();

        if (frame !== endFrame) {
            const rightDragFrameOffset = frame - endFrame;
            this.setState({ rightDragFrameOffset }, () => {
                setActiveFrame(frame);
            });
        }
    }

    public onRightHandleDragStart(e, ui) {
        const {
            setActiveFrame,
            select,
            config: { id }
        } = this.props;
        select(id);
        setActiveFrame(this.getEndFrame());
    }

    public onRightHandleDragStop(e, ui) {
        const { update, scale } = this.props;
        const newX = ui.x + this.getCutX();
        const frame = getFrameFromXPos(newX, scale);
        const endFrame = this.getEndFrame();

        if (frame !== endFrame) {
            this.setState(
                {
                    rightDragFrameOffset: 0
                },
                () => {
                    update({
                        endFrame: frame
                    });
                }
            );
        }
    }

    private shiftOverlay(index: number, offset: number) {
        const newIndex = index + offset;
        const newConfig = { ...this.props.config };
        let newOverlays = [...newConfig.overlays];

        newOverlays = arrayMove(newOverlays, index, newIndex);

        newConfig.overlays = newOverlays;

        this.props.update(newConfig);
    }

    private addOverlay(e) {
        e.preventDefault();
        e.stopPropagation();

        const { videoWidth, videoHeight } = this.props;

        const overlay: IOverlay = newOverlay();
        const newConfig = { ...this.props.config };
        overlay.width = videoWidth;
        overlay.height = videoHeight;

        // default to FS rect
        const pos = { ...NEW_RECT_POSITION };
        pos.x = 0;
        pos.y = 0;
        pos.width = videoWidth;
        pos.height = videoHeight;
        overlay.position = OVERLAY_POSITION_TYPES.RECT;
        overlay.position_inputs = pos;

        const newOverlays = [...newConfig.overlays];
        newOverlays.push(overlay);
        newConfig.overlays = newOverlays;

        this.props.update(newConfig);
        this.props.updateTimelineState({ activeTimelineOverlay: overlay.id });
    }

    public renderCutHandles(w, x): any[] {
        const { active } = this.props;

        if (active) {
            const { timelineWidth, scale, previousCut, nextCut } = this.props;
            const startFrame = this.getStartFrame();
            const endFrame = this.getEndFrame();

            const { leftDragFrameOffset, rightDragFrameOffset } = this.state;

            const handleStyle = {
                width: `${CUT_HANDLE_WIDTH}px`
            };

            const lPos = {
                x: -CUT_HANDLE_WIDTH,
                y: 0
            };

            // left boundry are either the previous cut's endFrame, or 0
            // right boundry is either this cut's end frame - 1, or the next cut's start frame
            const left = previousCut
                ? -getXPosFromFrame(startFrame + leftDragFrameOffset - previousCut.endFrame, scale)
                : -x;
            const lBounds = {
                left: left - CUT_HANDLE_WIDTH,
                right: getXPosFromFrame(
                    endFrame + rightDragFrameOffset - (startFrame + leftDragFrameOffset) - 1,
                    scale
                ),
                top: 0,
                bottom: 0
            };

            // left boundry is startFrame + 1
            // right boundry is the next cut's end frame, or the last frame
            const right = nextCut
                ? w +
                  getXPosFromFrame(nextCut.startFrame - (endFrame + rightDragFrameOffset), scale)
                : timelineWidth - x;

            const rBounds = {
                left: getXPosFromFrame(1, scale),
                right,
                top: 0,
                bottom: 0
            };

            const rPos = {
                x: w,
                y: 0
            };

            return [
                <Draggable
                    key='left-handle'
                    axis='x'
                    bounds={lBounds}
                    position={lPos}
                    onStart={(e, u) => this.onLeftHandleDragStart(e, u)}
                    onStop={(e, u) => this.onLeftHandleDragStop(e, u)}
                    onDrag={(e, u) => this.onLeftHandleDrag(e, u)}>
                    <div
                        className='cut-handle left'
                        style={handleStyle}>
                        {iconIn}
                    </div>
                </Draggable>,
                <Draggable
                    key='right-handle'
                    axis='x'
                    bounds={rBounds}
                    position={rPos}
                    onStart={(e, u) => this.onRightHandleDragStart(e, u)}
                    onStop={(e, u) => this.onRightHandleDragStop(e, u)}
                    onDrag={(e, u) => this.onRightHandleDrag(e, u)}>
                    <div
                        className='cut-handle right'
                        style={handleStyle}>
                        {iconOut}
                    </div>
                </Draggable>
            ];
        }
    }

    public renderDragHandle(w, x): any {
        const { active } = this.props;

        if (w >= CUT_HANDLE_BREAKPOINT && active) {
            const { timelineWidth } = this.props;

            const handleStyle = {
                height: `${ROW_HEIGHT}px`
            };

            const dragBounds = {
                left: -x,
                right: timelineWidth - x - w,
                top: 0,
                bottom: 0
            };

            return (
                <Draggable
                    axis='x'
                    bounds={dragBounds}
                    onStart={(e, u) => this.onBaseDragStart(e, u)}
                    onStop={(e, u) => this.onBaseDragStop(e, u)}
                    onDrag={(e, u) => this.onBaseDrag(e, u)}>
                    <div
                        className='cut-drag-handle'
                        style={handleStyle}
                    />
                </Draggable>
            );
        }
    }

    private renderErrorLayer(scale) {
        const {
            errors: { cuts },
            config: { id }
        } = this.props;
        for (const cut of cuts) {
            if (cut.cut_id === id) {
                return <ErrorLayer scale={scale} />;
            }
        }
        return false;
    }

    public render() {
        const {
            activeOverlay,
            active,
            height,
            config: { clipSwap, overlays }
        } = this.props;
        const activeClass = active ? 'active' : '';
        const swapClass = clipSwap ? 'swap' : '';
        const w = this.getCutWidth();
        const x = this.getCutX();
        const baseSelected = !activeOverlay && active ? 'selected' : '';

        const cutStyle = {
            left: `${x}px`,
            height: `${height}px`,
            width: `${w}px`
        };

        const baseStyle = {
            height: `${ROW_HEIGHT}px`
        };

        const btnAddOverlay = active ? (
            <Button
                style='subtle'
                tooltip={copy.tooltipAddOverlay}
                customStyles={{
                    position: 'absolute',
                    left: '50%',
                    bottom: `${ROW_HEIGHT * (1 + overlays.length)}px`,
                    marginLeft: '-9px'
                }}
                onClick={this.evtHandlers.addOverlay}>
                {ICON_PLUS}
            </Button>
        ) : null;

        return (
            <div
                className={`cut ${activeClass}`}
                style={cutStyle}
                onMouseUp={(e) => this.cutClicked(e)}
                onDoubleClick={(e) => this.cutDoubleClicked(e)}>
                {overlays.map((overlay, i) => {
                    const selected = overlay.id === activeOverlay;
                    return (
                        <Overlay
                            selected={selected}
                            config={overlay}
                            key={i}
                            height={height}
                            index={i}
                            shift={(e, o) => this.shiftOverlay(e, o)}
                            select={(e) => this.overlayClicked(e)}
                        />
                    );
                })}

                {btnAddOverlay}

                <div
                    className={`cut-base ${swapClass} ${baseSelected}`}
                    style={baseStyle}
                    onMouseUp={(e) => this.cutClicked(e)}
                    onDoubleClick={(e) => this.cutDoubleClicked(e)}>
                    <div className='border-top' />
                    <div className='border-left' />
                    {this.renderErrorLayer(height)}
                </div>

                <div className='cut-border border-top' />
                <div className='cut-border border-right' />
                <div className='cut-border border-bottom' />
                <div className='cut-border border-left' />

                {this.renderDragHandle(w, x)}

                {this.renderCutHandles(w, x)}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ addViewer, updateTimelineState }, dispatch);
};

const mapStateToProps = (state): any => {
    return {
        project: state.project,
        errors: state.errors
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Cut);
