import * as React from 'react';
import SplitPane from 'react-split-pane';
import LeftHeader from './LeftHeader';
import AssetsSection from './assets/AssetsSection';
import LayerConfig from './LayerConfig';
import RightHeader from './RightHeader';
import Viewer from './viewer/Viewer';
import Timeline from './timeline/Timeline';
import { IStory } from '../constants/snippets';
import type { IEditor } from '../redux/reducers/editor';
import type { IProject } from '../redux/reducers/project';
import { sections as sectionCopy } from '../constants/copy';
import { Button } from '@imposium-hub/components';
import { updateEditorConfig } from '../redux/actions/editor';
import { updateScene } from '../redux/actions/story';
import { connect } from 'react-redux';
import {
    HEADER_HEIGHT,
    LEFT_PANEL_MIN,
    LEFT_PANEL_MAX,
    LEFT_PANEL_DEFAULT,
    VAR_PANEL_DEFAULT,
    TIMELINE_DEFAULT,
    TIMELINE_MAX,
    TIMELINE_MIN
} from '../constants/editor';
import PaneErrorBoundry from './errors/PaneErrorBoundry';
import {
    updateCut,
    reorderCuts,
    addCut,
    addAudioOverlay,
    deleteAudioOverlay,
    deleteCut,
    duplicateAudioOverlay,
    updateAudioOverlay
} from '../redux/actions/story';
import { getFirstAct, getFirstScene, getStoryType } from '../util/story';
import { bindActionCreators } from 'redux';
import VariablePanel from './VariablePanel';

interface IVideoStoryInterfaceProps {
    story: IStory;
    editor: IEditor;
    project: IProject;
    updateCut(c): void;
    reorderCuts(c): void;
    addCut(c): void;
    addAudioOverlay(c): void;
    deleteCut(c): void;
    deleteAudioOverlay(c): void;
    duplicateAudioOverlay(c): void;
    updateAudioOverlay(c): void;
    updateEditorConfig(c): void;
    updateScene(s): void;
    onStoryChange?: (story: any) => any;
}

class VideoStoryInterface extends React.PureComponent<IVideoStoryInterfaceProps, undefined> {
    private evtHandlers: any;

    private btnToggleStyle = {
        position: 'absolute',
        top: '2px',
        right: '2px'
    };

    constructor(props) {
        super(props);

        this.evtHandlers = {
            toggleVars: () => this.toggleVars()
        };
    }

    private toggleVars() {
        this.props.updateEditorConfig({
            varsOpen: !this.props.editor.varsOpen
        });
    }

    private baseVideoUploaded(v) {
        const {
            story,
            project: { actId, sceneId }
        } = this.props;
        const scene = { ...story.acts[actId].scenes[sceneId] };
        const sceneData = { ...scene.sceneData };
        sceneData.videoFile = v;
        scene.sceneData = sceneData;

        this.props.updateScene({
            actId,
            sceneId,
            scene
        });
    }

    public render() {
        const {
            story,
            editor: { varsOpen },
            project: { actId, sceneId }
        } = this.props;
        const type = getStoryType(story);

        const act = actId !== '' ? story.acts[actId] : getFirstAct(story);
        const scene = sceneId !== '' ? act.scenes[sceneId] : getFirstScene(act, type);
        const baseVideo: any = scene.sceneData.videoFile || {};

        const btnOpenVars = (
            <Button
                style='subtle'
                onClick={this.evtHandlers.toggleVars}
                customStyles={this.btnToggleStyle}>
                {sectionCopy.variables}
            </Button>
        );

        return (
            <SplitPane
                split='horizontal'
                minSize={TIMELINE_MIN}
                maxSize={TIMELINE_MAX}
                defaultSize={TIMELINE_DEFAULT}
                primary='second'>
                <SplitPane
                    split='vertical'
                    minSize={LEFT_PANEL_MIN}
                    maxSize={LEFT_PANEL_MAX}
                    defaultSize={LEFT_PANEL_DEFAULT}
                    primary='first'>
                    <SplitPane
                        split='horizontal'
                        minSize={HEADER_HEIGHT}
                        maxSize={HEADER_HEIGHT}
                        defaultSize={HEADER_HEIGHT}
                        allowResize={false}
                        primary='first'>
                        <PaneErrorBoundry>
                            <LeftHeader onStoryChange={this.props.onStoryChange} />
                        </PaneErrorBoundry>

                        <SplitPane
                            split='horizontal'
                            defaultSize='50%'
                            primary='first'>
                            <div className='panel'>
                                <PaneErrorBoundry>
                                    <AssetsSection />
                                </PaneErrorBoundry>
                            </div>

                            <div className='panel'>
                                <PaneErrorBoundry>
                                    <LayerConfig
                                        rate={baseVideo.rate}
                                        width={baseVideo.width}
                                        height={baseVideo.height}
                                        updateCut={this.props.updateCut}
                                        deleteCut={this.props.deleteCut}
                                        deleteAudioOverlay={this.props.deleteAudioOverlay}
                                        updateAudioOverlay={this.props.updateAudioOverlay}
                                        duplicateAudioOverlay={this.props.duplicateAudioOverlay}
                                        cuts={scene.sceneData.cuts}
                                        audioOverlays={scene.sceneData.audioOverlays || []}
                                        variables={act.inventory}
                                    />
                                </PaneErrorBoundry>
                            </div>
                        </SplitPane>
                    </SplitPane>

                    <SplitPane
                        split='horizontal'
                        minSize={HEADER_HEIGHT}
                        maxSize={HEADER_HEIGHT}
                        defaultSize={HEADER_HEIGHT}
                        allowResize={false}
                        primary='first'>
                        <PaneErrorBoundry>
                            <RightHeader />
                        </PaneErrorBoundry>

                        <SplitPane
                            split='vertical'
                            allowResize={false}
                            defaultSize={varsOpen ? VAR_PANEL_DEFAULT : 0}
                            primary='second'>
                            <div className='panel'>
                                <PaneErrorBoundry>
                                    <Viewer videoFile={scene.sceneData.videoFile} />
                                </PaneErrorBoundry>
                                {!varsOpen ? btnOpenVars : null}
                            </div>

                            <div className='panel'>
                                <PaneErrorBoundry>
                                    <VariablePanel />
                                </PaneErrorBoundry>
                            </div>
                        </SplitPane>
                    </SplitPane>
                </SplitPane>

                <PaneErrorBoundry>
                    <Timeline
                        updateCut={this.props.updateCut}
                        reorderCuts={this.props.reorderCuts}
                        addCut={this.props.addCut}
                        addAudioOverlay={this.props.addAudioOverlay}
                        deleteCut={this.props.deleteCut}
                        deleteAudioOverlay={this.props.deleteAudioOverlay}
                        audioOverlays={scene.sceneData.audioOverlays}
                        cuts={scene.sceneData.cuts}
                        totalFrames={baseVideo.totalFrames}
                        baseVideoUrl={baseVideo.url}
                        baseVideoUploaded={(v) => this.baseVideoUploaded(v)}
                        rate={baseVideo.rate}
                        width={baseVideo.width}
                        height={baseVideo.height}
                    />
                </PaneErrorBoundry>
            </SplitPane>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            updateEditorConfig,
            updateCut,
            reorderCuts,
            addCut,
            addAudioOverlay,
            updateScene,
            deleteCut,
            deleteAudioOverlay,
            updateAudioOverlay,
            duplicateAudioOverlay
        },
        dispatch
    );
};

const mapStateToProps = (state): any => {
    return {
        story: state.story,
        editor: state.editor,
        project: state.project
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoStoryInterface);
