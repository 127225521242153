import * as Sentry from '@sentry/browser';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { Router, Route, browserHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import store from './redux/store';
import App from './components/App';
import Auth from './components/Auth';
import './less/styles.less';

Sentry.init({
    environment: import.meta.env.VITE_ENV,
    dsn: 'https://969fe58c19bf4743acdec6ecdbead4a7@sentry.io/1529850'
});

const container = document.getElementById('react-root');
const root = createRoot(container);
root.render(
    <Provider store={store}>
        <Router history={syncHistoryWithStore(browserHistory, store)}>
            <Route
                path='/auth'
                component={Auth}
            />
            <Route
                path='/(:story_id)'
                component={App}
            />
        </Router>
    </Provider>
);
