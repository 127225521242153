import * as React from 'react';
import {
    ENCODING_SETTING_RESIZE_OPTIONS,
    IMAGE_ENCODING_SETTING_EXTENSIONS,
    ENCODING_SETTING_QUALITY_OPTIONS,
    SCENE_TYPES,
    RESIZE_TYPES
} from '../constants/story';
import {
    TextField,
    SelectField,
    NumberField,
    Card,
    HRule,
    Button,
    CheckboxField
} from '@imposium-hub/components';
import SuperUserOnly from './SuperUserOnly';
import { IVideoStoryImageOutputSetting } from '../constants/snippets';
import { fields as copy } from '../constants/copy';
import { ICON_COPY, ICON_TRASH, ICON_CHECK, ICON_TIMES } from '../constants/icons';
import { MAX_OUTPUT_HEIGHT, MAX_OUTPUT_WIDTH, MIN_OUTPUT_DIMENSION } from '../constants/editor';
import { responsiveCompWidthHeightField } from '../util/fields';

interface IVideoStoryImageOutputSettingProps {
    cuts: any;
    config: IVideoStoryImageOutputSetting;
    settings?: IVideoStoryImageOutputSetting[];
    showPoster: boolean;
    isNew?: boolean;
    type: string;
    onUpdate?(c): void;
    onDelete?(): void;
    onDuplicate?(): void;
    onConfirm?(c): void;
    onCancel?(): void;
    onCreate?(): any;
}

interface IVideoStoryImageOutputSettingState {
    isNameDup: boolean;
}

class VideoStoryImageOutputSetting extends React.PureComponent<
    IVideoStoryImageOutputSettingProps,
    IVideoStoryImageOutputSettingState
> {
    private nameField: any;

    constructor(props) {
        super(props);

        this.state = {
            isNameDup: false
        };

        this.nameField = React.createRef();
    }

    public inputChanged(key, value) {
        const newConfig = { ...this.props.config };

        if (key === 'width' || key === 'height') {
            newConfig[key] = parseInt(value, 10);
        } else {
            newConfig[key] = value;
        }

        this.props.onUpdate(newConfig);
    }

    private nameCheckHandler(v) {
        const { settings, isNew } = this.props;
        if (isNew) {
            const isDup = settings.findIndex((s) => s.name === v);
            if (isDup >= 0) {
                this.setState({ isNameDup: true });
            } else {
                this.setState({ isNameDup: false });
            }
        } else {
            const dupSetting = [];
            settings.map((s) => {
                if (s.name === v) {
                    dupSetting.push(s);
                }
            });

            if (dupSetting.length >= 1) {
                this.setState({ isNameDup: true });
            } else {
                this.setState({ isNameDup: false });
            }
        }
    }

    private confirmDelete() {
        const { config } = this.props;

        const conf = confirm(copy.global.deletePrompt.replace('[name]', config.name));
        if (conf) {
            this.props.onDelete();
        }
    }

    private renderCutId() {
        const { config, cuts, type } = this.props;

        if (type === SCENE_TYPES.VIDEO) {
            return (
                <SelectField
                    label={copy.encoding.cut}
                    tooltip={copy.encoding.tooltipCut}
                    options={cuts}
                    value={config['cut_id']}
                    width='100%'
                    onChange={(v) => this.inputChanged('cut_id', v)}
                />
            );
        }
    }

    private renderButtons() {
        const { config, isNew } = this.props;
        const { isNameDup } = this.state;
        if (isNew) {
            return (
                <div style={{ textAlign: 'right' }}>
                    <Button
                        size='small'
                        style='subtle'
                        color='primary'
                        disabled={isNameDup}
                        onClick={() => this.props.onConfirm(config)}>
                        {ICON_CHECK}
                    </Button>
                    <Button
                        size='small'
                        style='subtle'
                        onClick={() => this.props.onCancel()}>
                        {ICON_TIMES}
                    </Button>
                </div>
            );
        } else {
            return (
                <div style={{ textAlign: 'right' }}>
                    <Button
                        size='small'
                        style='subtle'
                        onClick={() => this.props.onDuplicate()}
                        tooltip={copy.global.duplicate}>
                        {ICON_COPY}
                    </Button>
                    <Button
                        size='small'
                        style='subtle'
                        onClick={() => this.confirmDelete()}
                        tooltip={copy.global.delete}>
                        {ICON_TRASH}
                    </Button>
                </div>
            );
        }
    }

    public componentDidMount() {
        const { isNew } = this.props;
        const field = this.nameField.current;

        if (isNew) {
            if (field) {
                field.setEditing(true);
            }
        }
    }

    public render() {
        const { config, isNew, showPoster } = this.props;

        const { isNameDup } = this.state;

        const title = isNew ? (
            <span className='text-primary'>{copy.encoding.newImageOutput}</span>
        ) : (
            config['name']
        );

        let widthAndHeight = (
            <>
                <NumberField
                    label={copy.global.width}
                    tooltip={copy.encoding.tooltipWidth}
                    value={config['width']}
                    width='50%'
                    onChange={(v) => this.inputChanged('width', v)}
                    max={MAX_OUTPUT_WIDTH}
                    min={MIN_OUTPUT_DIMENSION}
                />

                <NumberField
                    label={copy.global.height}
                    tooltip={copy.encoding.tooltipHeight}
                    value={config['height']}
                    width='50%'
                    onChange={(v) => this.inputChanged('height', v)}
                    max={MAX_OUTPUT_HEIGHT}
                    min={MIN_OUTPUT_DIMENSION}
                />
            </>
        );

        if (config['resize'] === RESIZE_TYPES.RESPONSIVE) {
            widthAndHeight = responsiveCompWidthHeightField();
        }

        return (
            <Card
                title={title}
                collapsable={!isNew}
                open={true}>
                <div className='encoding-setting-config'>
                    <TextField
                        className={isNameDup ? 'warning' : ''}
                        ref={this.nameField}
                        label={copy.global.id}
                        tooltip={copy.encoding.tooltipID}
                        info={isNameDup ? copy.encoding.nameWarning : ''}
                        value={config['name']}
                        width='100%'
                        onChange={(v) => {
                            this.inputChanged('name', v);
                            this.nameCheckHandler(v.trim());
                        }}
                    />

                    {this.renderCutId()}

                    {widthAndHeight}

                    <NumberField
                        label={copy.encoding.frame}
                        tooltip={copy.encoding.tooltipFrameNumber}
                        value={config['frame']}
                        width='50%'
                        onChange={(v) => this.inputChanged('frame', v)}
                    />

                    <SelectField
                        label={copy.encoding.format}
                        tooltip={copy.encoding.tooltipImageFormat}
                        options={IMAGE_ENCODING_SETTING_EXTENSIONS}
                        value={config['extension']}
                        width='50%'
                        onChange={(v) => this.inputChanged('extension', v)}
                    />

                    <SelectField
                        label={copy.encoding.quality}
                        tooltip={copy.encoding.tooltipQuality}
                        options={ENCODING_SETTING_QUALITY_OPTIONS}
                        value={config['quality']}
                        width='50%'
                        onChange={(v) => this.inputChanged('quality', v)}
                    />

                    <SelectField
                        label={copy.encoding.resize}
                        tooltip={copy.encoding.tooltipResize}
                        options={ENCODING_SETTING_RESIZE_OPTIONS}
                        value={config['resize']}
                        width='50%'
                        onChange={(v) => this.inputChanged('resize', v)}
                    />

                    <CheckboxField
                        width='50%'
                        value={config['play_icon']}
                        info={copy.image.playIconInfo}
                        label={copy.image.playIcon}
                        onChange={(v) => this.inputChanged('play_icon', v)}
                    />

                    {!showPoster ? (
                        <CheckboxField
                            width='50%'
                            value={config['poster']}
                            label={copy.image.poster}
                            onChange={(v) => this.inputChanged('poster', v)}
                        />
                    ) : null}

                    <SuperUserOnly>
                        <TextField
                            label={copy.encoding.script}
                            tooltip={copy.encoding.tooltipScript}
                            value={config['script']}
                            width='100%'
                            onChange={(v) => this.inputChanged('script', v)}
                        />
                    </SuperUserOnly>

                    <HRule style='subtle' />

                    {this.renderButtons()}
                </div>
            </Card>
        );
    }
}

export default VideoStoryImageOutputSetting;
