import * as React from 'react';
import { useDrop } from 'react-dnd';
import { ASSET_DND_TYPES } from '../../constants/timeline';
import { ASSET_TYPES } from '../../constants/story';

interface IAssetDropTargetProps {
    children: any;
    onAssetDrop(asset);
    onExternalCollect?(monitor);
}

export const AssetDropTarget: React.FC<IAssetDropTargetProps> = (p: IAssetDropTargetProps) => {
    const { onExternalCollect, onAssetDrop } = p;

    const [, drop] = useDrop({
        accept: [ASSET_DND_TYPES.ASSET_NAME],
        canDrop: (item: any) => {
            switch (item.rowData.type) {
                case ASSET_TYPES.VIDEO:
                case ASSET_TYPES.IMAGE:
                    return item.rowData.type;
                default:
                    return false;
            }
        },
        drop: (item: any) => {
            onAssetDrop(item.rowData);
        },
        collect: (monitor) => {
            onExternalCollect(monitor);
        }
    });

    return (
        <div
            ref={drop}
            className='asset-drop-target'>
            {p.children}
        </div>
    );
};
