import * as React from 'react';
import { useDrop } from 'react-dnd';
import { LAYER_DND_TYPES } from '../../constants/timeline';
import { AnchorDropTarget } from './AnchorDropTarget';

interface IAnchorOverTargetProps {
    onAnchorDrop(layerId: string, targetLayerId: string, start: boolean, targetStart: boolean);
    removeButtons: any;
    layerId: string;
}

export const AnchorOverTarget: React.FC<IAnchorOverTargetProps> = (p: IAnchorOverTargetProps) => {
    const { onAnchorDrop, layerId, removeButtons } = p;

    const [{ isOver }, drop] = useDrop({
        accept: [LAYER_DND_TYPES.ANCHOR],
        collect: (monitor) => ({
            isOver: monitor.isOver()
        })
    });

    const dropTargets = isOver
        ? [
              <AnchorDropTarget
                  key='start'
                  start={true}
                  onAnchorDrop={onAnchorDrop}
                  layerId={layerId}
              />,
              <AnchorDropTarget
                  key='end'
                  start={false}
                  onAnchorDrop={onAnchorDrop}
                  layerId={layerId}
              />
          ]
        : removeButtons;

    return (
        <div
            ref={drop}
            className='anchor-over-target'>
            {dropTargets}
        </div>
    );
};
