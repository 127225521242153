import { logNotification, logError } from '../util/notifications';
import { fields as copy } from '../constants/copy';
import { api } from '../constants/app';
import store from '../redux/store';
import { addViewer } from '../redux/actions/story';
import { VIEWER_TYPES } from '../constants/viewer';

interface IBaseVideoUploaderProps {
    onProgress?(progress: number): void;
    onUploadStart?(): void;
    onProcessStart?(): void;
    onComplete?(vid: any): void;
    onError?(e): void;
}

export default class BaseVideoUploader {
    private props: IBaseVideoUploaderProps;

    constructor(props?) {
        this.props = props;
    }

    public uploadBaseVideo(file) {
        const state: any = store.getState();
        const {
            project: { storyId }
        } = state;

        if (file) {
            logNotification(copy.base.preparingUpload);
            api.getSignedUrl(storyId, file)
                .then((resSigned) => {
                    logNotification(copy.base.uploadingBase);

                    if (this.props.onUploadStart) {
                        this.props.onUploadStart();
                    }

                    api.uploadToSignedUrl(resSigned.signed_url, file, (resUploadProgress) => {
                        const progress = (resUploadProgress.loaded / resUploadProgress.total) * 100;
                        if (this.props.onProgress) {
                            this.props.onProgress(progress);
                        }
                    })
                        .then((resUploadComplete) => {
                            logNotification(copy.base.uploadComplete);

                            if (this.props.onProcessStart) {
                                this.props.onProcessStart();
                            }

                            api.runProcessBaseVideo(storyId, resSigned.file_key)
                                .then((resJobCreated) => {
                                    logNotification(
                                        copy.base.processBase.replace('[jobId]', resJobCreated.id)
                                    );
                                    api.pollJob(resJobCreated.id)
                                        .then((resJobComplete: any) => {
                                            logNotification(copy.base.processComplete);
                                            if (this.props.onComplete) {
                                                this.props.onComplete(resJobComplete.output);
                                            }
                                        })
                                        .catch((e) => {
                                            logError(copy.base.processJobError);

                                            const viewerConfig = {
                                                id: resJobCreated.id,
                                                label: `Job: ${resJobCreated.id}`,
                                                jobId: resJobCreated.id,
                                                type: VIEWER_TYPES.JOB_LOG
                                            };

                                            store.dispatch(addViewer(viewerConfig));

                                            if (this.props.onError) {
                                                this.props.onError(e);
                                            }
                                        });
                                })
                                .catch((e) => {
                                    logError(copy.base.processJobCreateError);
                                    if (this.props.onError) {
                                        this.props.onError(e);
                                    }
                                });
                        })
                        .catch((e) => {
                            logError(copy.base.uploadingError);
                            if (this.props.onError) {
                                this.props.onError(e);
                            }
                        });
                })
                .catch((e) => {
                    logError(copy.base.preparingError);
                    if (this.props.onError) {
                        this.props.onError(e);
                    }
                });
        }
    }
}
