import { FRAME_WIDTH, KEYFRAME_SIZE } from '../../constants/timeline';
import Draggable from 'react-draggable';
import { useState, useEffect, useRef } from 'react';
import { ICON_ELLIPSIS_H } from '../../constants/icons';
import { Button } from '@imposium-hub/components';
import { KeyframeContextMenu } from './KeyframeContextMenu';

export const Keyframe = ({
    config,
    scale,
    selected,
    onSelect,
    onDeselect,
    onChange,
    onMove,
    onDelete,
    onJump,
    disableEase
}) => {
    const [dragging, setDragging] = useState(false);
    const [contextMenu, setContextMenu] = useState(false);
    const [offsetX, setOffsetX] = useState(0);

    const scaledFrameWidth = scale * FRAME_WIDTH;

    const getKeyframeX = () => {
        return scaledFrameWidth * config.relativeFrame - KEYFRAME_SIZE / 2;
    };

    const containerRef = useRef(null);

    useEffect(() => {
        const container = containerRef.current;
        const onClick = (e) => {
            const insideContainer = container.contains(e.target);
            if (selected && !insideContainer) {
                onDeselect();
            }
        };

        document.addEventListener('click', onClick);

        if (!selected) {
            setContextMenu(false);
        }
        return () => {
            document.removeEventListener('click', onClick);
        };
    }, [selected]);

    const style = {
        left: `${getKeyframeX()}px`
    };

    const sClass = selected ? 'selected' : '';
    const menuTrigger = (
        <div className='btn-keyframe-context-menu'>
            <Button
                onClick={(e) => setContextMenu(true)}
                style='subtle'>
                {ICON_ELLIPSIS_H}
            </Button>
        </div>
    );

    const keyframeContent = (
        <div
            ref={containerRef}
            className={`keyframe ${sClass}`}
            style={style}>
            <div className='keyframe-icon'></div>
            {selected && menuTrigger}
            {!dragging && contextMenu && selected && (
                <KeyframeContextMenu
                    disableEase={disableEase}
                    config={config}
                    onDelete={onDelete}
                    onJump={onJump}
                    onChange={onChange}
                />
            )}
        </div>
    );

    const onDragStart = (e) => {
        if (!selected) {
            onSelect(config);
        }
    };
    const onDrag = (e, ui) => {
        setDragging(true);
        setOffsetX(ui.x);
    };

    const onDragStop = (e, ui) => {
        const frames = Math.round(offsetX / scaledFrameWidth);
        setOffsetX(0);
        setDragging(false);
        onMove(frames);
    };

    return (
        <Draggable
            axis='x'
            position={{ x: offsetX, y: 0 }}
            grid={[scaledFrameWidth, scaledFrameWidth]}
            onStop={onDragStop}
            onStart={onDragStart}
            onDrag={onDrag}>
            {keyframeContent}
        </Draggable>
    );
};
