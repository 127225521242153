import * as React from 'react';
import { FieldWrapper, Button } from '@imposium-hub/components';
import { connect } from 'react-redux';
import { IImageStoryEncodingSetting, NEW_IMAGE_ENCODING_SETTING } from '../constants/snippets';
import { ICON_PLUS } from '../constants/icons';
import { fields as copy } from '../constants/copy';
import { IStory } from '../constants/snippets';
import type { IProject } from '../redux/reducers/project';
import { logError } from '../util/notifications';
import ImageOutputSetting from './ImageOutputSetting';

interface IImageOutputSettingsProps {
    story: IStory;
    project: IProject;
    config: IImageStoryEncodingSetting[];
    onChange(s): void;
}

interface IImageOutputSettingstate {
    addingAdditional: boolean;
    additional: IImageStoryEncodingSetting;
}

class ImageOutputSettings extends React.PureComponent<
    IImageOutputSettingsProps,
    IImageOutputSettingstate
> {
    constructor(props) {
        super(props);

        this.state = {
            addingAdditional: false,
            additional: null
        };
    }

    public updateSetting(index, config) {
        const settings = [...this.props.config];
        settings[index] = config;
        this.props.onChange(settings);
    }

    public updateAdditionalSetting(config) {
        this.setState({
            additional: config
        });
    }

    private deleteSetting(index) {
        const settings = [...this.props.config];
        settings.splice(index, 1);
        this.props.onChange(settings);
    }

    private duplicateSetting(index) {
        const { config } = this.props;

        const setting = { ...config[index] };
        setting.name = '';

        this.setState({
            addingAdditional: true,
            additional: setting
        });
    }

    private newAdditionalImage() {
        const setting = { ...NEW_IMAGE_ENCODING_SETTING };
        const { width, height } = this.getDefaultDimensions();
        setting.width = width;
        setting.height = height;

        this.setState({
            addingAdditional: true,
            additional: setting
        });
    }

    private getDefaultDimensions() {
        const scene = this.getSceneData();
        if (scene) {
            if (scene.videoFile) {
                return {
                    width: scene.videoFile.width,
                    height: scene.videoFile.height
                };
            }
        }
        return {
            width: null,
            height: null
        };
    }

    private getSceneData() {
        const { story, project } = this.props;
        const act = story.acts[project.actId];
        const scene = act.scenes[project.sceneId];
        return scene.sceneData;
    }

    private idExists(id) {
        const { config } = this.props;
        if (config) {
            for (const setting of config) {
                if (setting.name === id) {
                    return true;
                }
            }
        }
        return false;
    }

    private confirmAdditional(config) {
        const setting = { ...config };

        if (!config.name) {
            logError(copy.encoding.errorNoId);
            return;
        }

        if (this.idExists(config.name)) {
            logError(copy.encoding.errorIdExists.replace('[ID]', config.name));
            return;
        }

        this.setState(
            {
                addingAdditional: false,
                additional: null
            },
            () => {
                const settings = this.props.config ? [...this.props.config] : [];
                settings.push(setting);
                this.props.onChange(settings);
            }
        );
    }

    private renderAdditional() {
        const { additional, addingAdditional } = this.state;

        if (addingAdditional) {
            return (
                <ImageOutputSetting
                    isNew={true}
                    config={additional}
                    onUpdate={(c) => this.updateAdditionalSetting(c)}
                    onConfirm={(i) => this.confirmAdditional(i)}
                    onCancel={() => this.cancelAdditional()}
                    key={'new'}
                />
            );
        } else {
            return null;
        }
    }

    private cancelAdditional() {
        this.setState({
            addingAdditional: false,
            additional: null
        });
    }

    public render() {
        const { config } = this.props;
        const settings = config || [];

        return (
            <div>
                <FieldWrapper
                    label={copy.encoding.addNewOutput}
                    info={copy.encoding.infoNewImage}
                    width='100%'>
                    <Button
                        style='subtle'
                        onClick={() => this.newAdditionalImage()}
                        color='primary'>
                        {ICON_PLUS}
                    </Button>
                </FieldWrapper>

                {settings.map((setting: IImageStoryEncodingSetting, i) => {
                    return (
                        <ImageOutputSetting
                            config={setting}
                            onUpdate={(c) => this.updateSetting(i, c)}
                            onDelete={() => this.deleteSetting(i)}
                            onDuplicate={() => this.duplicateSetting(i)}
                            key={i}
                        />
                    );
                })}

                {this.renderAdditional()}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {};
};

const mapStateToProps = (state): any => {
    return { story: state.story, project: state.project };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageOutputSettings);
