import { Button } from '@imposium-hub/components';
import { ICON_TABLE } from '../../constants/icons';
import { useEffect, useState } from 'react';
import { DatasetNameModal } from './DatasetNameModal';
import { DatasetSelector } from './DatasetSelector';
import {
    getDatasetErrors,
    getRequiredAssetsFromComp,
    generateNewCSV,
    generateSampleDataHandler
} from '../../util/dataset';
import { DatasetCSVEditor } from './DatasetCSVEditor';
import { DatasetContextMenu } from './DatasetContextMenu';
import { useResizeDetector } from 'react-resize-detector';
import { IComposition } from '../../constants/snippets';
import { DatasetErrorModal } from './DatasetErrorModal';
import { DatasetAssetsModal } from './DatasetAssetsModal';
import { DatasetAsyncIndicator } from './DatasetAsyncIndicator';
import { dataEditor as copy } from '../../constants/copy';
import store from '../../redux/store';
import { updateEditorConfig } from '../../redux/actions/editor';

export interface IDatasetListItem {
    id: string;
    name: string;
    date_created: string;
    data_modified: string;
    last_modified_by: string;
    data?: any;
    column_widths?: any;
}

interface IDatasetEditorProps {
    datasetList: IDatasetListItem[];
    activeDataset: any;
    loading: boolean;
    activeDatasetId: string;
    variables: any;
    activeComposition: IComposition;
    activeStoryId: string;
    onSelect(id: string): void;
    onDelete(id: string): void;
    onChangeName(id, name): void;
    onChangeData(id, data, name): void;
    onAddRow(id, index, rows): void;
    onDuplicateRow(id, index): void;
    onSetSampleData(id, data): void;
    onAddColumn(id, header): void;
    onCreate(csv: any, name: string): void;
    onDuplicate(id): void;
    onRemoveRows(id, range);
    onRemoveColumns(id, range);
    onMoveColumns(id, data): void;
    onColumnResize(id, data): void;
    onMoveRows(id, data): void;
    onImport(id, file);
    onPreview(items): void;
}

export const DatasetEditor = (props: IDatasetEditorProps) => {
    useEffect(() => {
        checkForMissingColumns();
    }, [props.variables, props.activeDataset]);

    const { width, height, ref } = useResizeDetector();

    const [showCreateModal, setShowCreateModal] = useState(false);
    const [renamingDataset, setRenamingDataset] = useState(false);
    const [duplicatingDataset, setDuplicatingDataset] = useState(false);
    const [datasetName, setDatasetName] = useState('');
    const [checkingForErrors, setCheckingForErrors] = useState(false);
    const [errors, setErrors] = useState([]);
    const [errorRowIndex, setErrorRowIndex] = useState(null);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [gettingAssets, setGettingAssets] = useState(false);
    const [requiredAssets, setRequiredAssets] = useState([]);
    const [showRequiredAssets, setShowRequiredAssets] = useState(false);

    const {
        activeDatasetId,
        activeDataset,
        onCreate,
        onChangeName,
        onAddColumn,
        onDuplicate,
        activeComposition,
        onSetSampleData,
        variables,
        activeStoryId
    } = props;

    const onCreateDataset = () => {
        setDatasetName('');
        setRenamingDataset(false);
        setDuplicatingDataset(false);
        setShowCreateModal(true);
    };

    const onContinue = () => {
        if (renamingDataset) {
            onChangeName(activeDatasetId, datasetName);
        } else if (duplicatingDataset) {
            onDuplicate(datasetName);
        } else {
            const csv = generateNewCSV(datasetName, variables);
            onCreate(csv, datasetName);
        }
        setDatasetName('');
        setRenamingDataset(false);
        setDuplicatingDataset(false);
        setShowCreateModal(false);
    };

    const promptRename = () => {
        setDatasetName(activeDataset.name);
        setRenamingDataset(true);
        setShowCreateModal(true);
    };

    const promptDuplicate = () => {
        setDatasetName(`Copy of ${activeDataset.name}`);
        setDuplicatingDataset(true);
        setShowCreateModal(true);
    };

    const checkForMissingColumns = () => {
        if (!activeDataset) {
            return;
        }
        const missing = [];
        for (const variable in variables) {
            if (variables.hasOwnProperty(variable)) {
                if (activeDataset.headers.indexOf(variable) === -1) {
                    missing.push(variable);
                    break;
                }
            }
        }

        for (const missingColumn of missing) {
            onAddColumn(activeDataset.id, missingColumn);
        }
    };

    const getWrapperStyle = (): any => {
        if (width !== undefined && height !== undefined) {
            return {
                position: 'absolute',
                left: '0px',
                top: '50px',
                width: `${width}px`,
                height: `${height - 50}px`
            };
        } else {
            return {};
        }
    };

    const generateSampleData = () => {
        const { id } = activeDataset;
        const sampleData = generateSampleDataHandler(variables);
        if (sampleData) {
            const len = sampleData.data.length;
            const prompt = copy.generatePrompt.replace(
                '[rows]',
                len === 1 ? `1 row` : `${len} rows`
            );
            const c = confirm(prompt);
            if (c) {
                setErrors([]);
                onSetSampleData(id, sampleData);
            }
        }
    };

    const onCheckForErrors = async () => {
        if (checkingForErrors) {
            return;
        }
        console.debug('Dataset Error Check - Parse Dataset');
        // Set checking to true and clear out existing errors
        setCheckingForErrors(true);
        setErrors([]);
        const { data, headers } = activeDataset;
        let newErrors;
        try {
            newErrors = await getDatasetErrors(
                data,
                activeComposition,
                headers,
                variables,
                activeStoryId
            );
        } catch (e) {
            console.error(e);
        }

        setErrors(newErrors);
        setCheckingForErrors(false);
    };

    const getAssetList = async () => {
        if (gettingAssets) {
            return;
        }
        setGettingAssets(true);
        setRequiredAssets([]);
        const { data, headers } = activeDataset;

        const removeDupesAndSortAssets = (a) => {
            return Array.from(new Set(a)).sort();
        };

        let assets = [];
        for (const row of data) {
            const rowAssets = await getRequiredAssetsFromComp(
                activeComposition,
                row,
                headers,
                activeStoryId
            );

            assets = [...assets, ...rowAssets];
        }

        assets = removeDupesAndSortAssets(assets);
        if (assets.length === 0) {
            assets.push(copy.noSwapError);
        }

        setGettingAssets(false);
        setRequiredAssets(assets);
        setShowRequiredAssets(true);
    };

    const onShowErrors = (rowIndex) => {
        setErrorRowIndex(rowIndex);
        setShowErrorModal(true);
    };

    const onPublish = async () => {
        const proceed = () => {
            store.dispatch(
                updateEditorConfig({
                    publishWizardOpen: true,
                    activeDatasetId: activeDataset.id,
                    publishDataset: true
                })
            );
        };
        await onCheckForErrors();

        if (errors.length > 0) {
            const c = confirm(copy.publishError);
            if (c) {
                proceed();
            }
        } else {
            proceed();
        }
    };

    return (
        <div
            id='dataset-editor'
            ref={ref}>
            <div className='dataset-editor-header'>
                <Button
                    color='primary'
                    onClick={onCreateDataset}
                    style='bold'>
                    New Dataset {ICON_TABLE}
                </Button>
                <DatasetSelector
                    {...props}
                    activeId={activeDatasetId}
                />
                <DatasetContextMenu
                    {...props}
                    onImport={(id, file) => {
                        props.onImport(id, file);
                        setErrors([]);
                    }}
                    onPublish={onPublish}
                    onGetList={getAssetList}
                    onDuplicate={promptDuplicate}
                    onGenerate={generateSampleData}
                    onCheckForErrors={onCheckForErrors}
                    onRename={promptRename}
                />
                <DatasetAsyncIndicator
                    checkingForErrors={checkingForErrors}
                    gettingAssets={gettingAssets}
                />
                {showCreateModal && (
                    <DatasetNameModal
                        show={showCreateModal}
                        datasetName={datasetName}
                        onChange={setDatasetName}
                        renaming={renamingDataset}
                        onClose={() => setShowCreateModal(false)}
                        onContinue={() => onContinue()}
                    />
                )}
                {showErrorModal && (
                    <DatasetErrorModal
                        rowIndex={errorRowIndex}
                        errors={errors[errorRowIndex]}
                        onClose={() => setShowErrorModal(false)}
                    />
                )}
                <DatasetAssetsModal
                    assets={requiredAssets}
                    show={showRequiredAssets}
                    onClose={() => setShowRequiredAssets(false)}
                />
            </div>
            <div
                className='dataset-editor-content'
                style={getWrapperStyle()}>
                <DatasetCSVEditor
                    {...props}
                    height={height - 80}
                    width={width}
                    checkingForErrors={checkingForErrors}
                    onShowErrors={onShowErrors}
                    errors={errors}
                />
            </div>
        </div>
    );
};
