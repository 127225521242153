import * as React from 'react';
import { FRAME_WIDTH } from '../../constants/timeline';
import { Draggable } from './Draggable';

interface IPlayheadProps {
    activeFrame: number;
    frames: number;
    positionOffset: number;
    scale: number;
    width: number;
    height: number;
    onMove(frame: number): void;
    setDragState?(state: any): void;
}

interface IPlayheadState {
    startActiveFrame: number;
}

class Playhead extends React.PureComponent<IPlayheadProps, IPlayheadState> {
    private evtHandlers = {
        onStart: (e, u) => this.onPlayheadDragStart(e, u),
        onStop: (e, u) => this.onPlayheadDragStop(e, u),
        onDrag: (e, u) => this.onPlayheadDrag(e, u)
    };

    constructor(props) {
        super(props);

        this.state = {
            startActiveFrame: null
        };
    }

    public getPlayheadPosition() {
        const { activeFrame, scale } = this.props;
        const { startActiveFrame } = this.state;

        if (startActiveFrame !== null) {
            return FRAME_WIDTH * startActiveFrame * scale;
        } else {
            return FRAME_WIDTH * activeFrame * scale;
        }
    }

    public onPlayheadDrag(e, ui) {
        e.preventDefault();
        e.stopPropagation();

        const { activeFrame, scale } = this.props;
        const offsetFrames = Math.round(ui.offsetX / (FRAME_WIDTH * scale));
        const frame = this.state.startActiveFrame + offsetFrames;
        if (frame !== activeFrame) {
            this.props.onMove(frame);
        }
    }

    public onPlayheadDragStart(e, ui) {
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            startActiveFrame: this.props.activeFrame
        });

        if (this.props.setDragState) {
            this.props.setDragState({
                draggingHandle: false,
                draggingPlayhead: true,
                draggingLayer: false
            });
        }
    }

    public onPlayheadDragStop(e, ui) {
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            startActiveFrame: null
        });

        if (this.props.setDragState) {
            this.props.setDragState({
                draggingHandle: false,
                draggingPlayhead: false,
                draggingLayer: false
            });
        }
    }

    public render() {
        const { scale, height, frames } = this.props;
        const barW = FRAME_WIDTH * scale;
        const position = {
            x: this.getPlayheadPosition(),
            y: 0
        };

        const barStyle = {
            width: `${barW}px`
        };

        const snapGrid = {
            x: FRAME_WIDTH * scale
        };

        const innerStyle = {
            height: `${height}px`
        };

        const bounds = {
            left: 0,
            right: (frames - 1) * FRAME_WIDTH * scale,
            top: 0,
            bottom: 0
        };

        return (
            <Draggable
                axis='x'
                position={position}
                grid={snapGrid}
                onStart={this.evtHandlers.onStart}
                onStop={this.evtHandlers.onStop}
                onDrag={this.evtHandlers.onDrag}
                bounds={bounds}>
                <div
                    className='playhead'
                    style={innerStyle}>
                    <div className='handle'></div>
                    <div
                        className='bar'
                        style={barStyle}></div>
                </div>
            </Draggable>
        );
    }
}

export default Playhead;
