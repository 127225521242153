import * as React from 'react';
import VideoStoryImageOutputSetting from './VideoStoryImageOutputSetting';
import { SelectField } from '@imposium-hub/components';
import { connect } from 'react-redux';
import { getFirstAct, getFirstScene, getStoryType } from '../util/story';
import {
    IVideoStoryImageOutputSetting,
    NEW_VIDEO_IMAGE_ENCODING_SETTING
} from '../constants/snippets';
import { fields as copy } from '../constants/copy';
import { IStory } from '../constants/snippets';
import type { IProject } from '../redux/reducers/project';
import {
    ENCODING_SETTING_PRESET_IMAGE,
    ENCODING_SETTING_PRESET_IMAGE_OPTIONS,
    SCENE_TYPES
} from '../constants/story';
import { logError } from '../util/notifications';

interface IVideoStoryImageOutputSettingsProps {
    story: IStory;
    project: IProject;
    compositions: any;
    config: IVideoStoryImageOutputSetting[];
    onChange(s): void;
    isNameDup?(b): void;
}

interface IVideoStoryImageOutputSettingState {
    addingAdditional: boolean;
    additional: IVideoStoryImageOutputSetting;
    activePreset: string;
}

class VideoStoryImageOutputSettings extends React.PureComponent<
    IVideoStoryImageOutputSettingsProps,
    IVideoStoryImageOutputSettingState
> {
    constructor(props) {
        super(props);

        this.state = {
            addingAdditional: false,
            additional: null,
            activePreset: ENCODING_SETTING_PRESET_IMAGE_OPTIONS[0].value
        };
    }

    public updateSetting(index, config) {
        const settings = [...this.props.config];
        let isNameDup = false;
        for (let i = 0; i < settings.length; i++) {
            if (i !== index) {
                if (settings[i].name === config.name) {
                    isNameDup = true;
                }
            }
        }
        this.props.isNameDup(isNameDup);
        settings[index] = config;
        this.props.onChange(settings);
    }

    private checkNameHandler(setting, settings, num = 0) {
        let newName = `${setting.name}`;
        if (num > 0) {
            newName = `${setting.name} ${num}`;
        }
        const checkNewName = settings.findIndex((s) => s.name === newName);
        if (checkNewName === -1) {
            setting.name = newName;
            settings.push(setting);
            this.props.onChange(settings);
        } else {
            const numOfRep = num + 1;
            this.checkNameHandler(setting, settings, numOfRep);
        }
    }

    private presetChanged(v) {
        if (v === 'New') {
            this.newAdditionalImage();
        } else {
            const setting = { ...ENCODING_SETTING_PRESET_IMAGE[v] };
            const settings = [...this.props.config];
            this.checkNameHandler(setting, settings);
        }
    }

    private newAdditionalImage() {
        const setting = { ...NEW_VIDEO_IMAGE_ENCODING_SETTING };
        const { width, height } = this.getDefaultDimensions();
        setting.cut_id = this.getFirstCut();
        setting.width = width;
        setting.height = height;

        this.setState({
            addingAdditional: true,
            additional: setting
        });
    }

    private getDefaultDimensions() {
        const {
            story,
            compositions,
            project: { compositionId }
        } = this.props;

        const type = getStoryType(story);

        if (type === SCENE_TYPES.VIDEO) {
            const scene = this.getSceneData();
            if (scene && scene.videoFile) {
                const {
                    videoFile: { width, height }
                } = scene;
                return {
                    width,
                    height
                };
            }
        } else if (type === SCENE_TYPES.COMPOSITION) {
            // get the composition dimensions

            const composition = compositions ? compositions[compositionId] : null;
            if (composition) {
                return {
                    width: composition.width,
                    height: composition.height
                };
            }
        }

        return {
            width: null,
            height: null
        };
    }

    private getFirstCut() {
        const { cuts } = this.getSceneData();
        if (cuts) {
            if (cuts[0]) {
                return cuts[0].id;
            }
        }
        return '';
    }

    private deleteSetting(index) {
        const settings = [...this.props.config];
        settings.splice(index, 1);
        this.props.onChange(settings);
    }

    private duplicateSetting(index) {
        const { config } = this.props;

        const setting = { ...config[index] };
        setting.name = '';

        this.setState({
            addingAdditional: true,
            additional: setting
        });
    }

    private getSceneData() {
        const { story } = this.props;
        const act = getFirstAct(story);
        const scene = getFirstScene(act, SCENE_TYPES.COMPOSITION);
        return scene.sceneData;
    }

    private compileCutList() {
        const list = [
            {
                label: '',
                value: ''
            }
        ];

        const { cuts } = this.getSceneData();
        if (cuts) {
            for (const cut of cuts) {
                list.push({
                    value: cut.id,
                    label: cut.name
                });
            }
        }

        return list;
    }

    private idExists(id) {
        const { config } = this.props;
        if (config) {
            for (const setting of config) {
                if (setting.name === id) {
                    return true;
                }
            }
        }
        return false;
    }

    private confirmAdditional(config) {
        const setting = { ...config };

        if (!config.name) {
            logError(copy.encoding.errorNoId);
            return;
        }

        if (config.name === 'poster') {
            logError(copy.encoding.errorIdPoster);
            return;
        }

        if (this.idExists(config.name)) {
            logError(copy.encoding.errorIdExists.replace('[ID]', config.name));
            return;
        }

        this.setState(
            {
                addingAdditional: false,
                additional: null
            },
            () => {
                const settings = this.props.config ? [...this.props.config] : [];
                settings.push(setting);
                this.props.onChange(settings);
            }
        );
    }

    public updateAdditionalSetting(config) {
        this.setState({
            additional: config
        });
    }

    private cancelAdditional() {
        this.setState({
            addingAdditional: false,
            additional: null
        });
    }

    public render() {
        const { config, story } = this.props;
        const { additional, addingAdditional } = this.state;
        const settings = config || [];
        const type = getStoryType(story);
        const hasPoster = settings.filter((x) => x.poster === true).length === 1;

        let additionalPoster;

        if (addingAdditional) {
            additionalPoster = !additional.poster && hasPoster;
        }

        return (
            <div>
                <SelectField
                    label={copy.encoding.addImageOutput}
                    tooltip={copy.encoding.tooltipAddImageOutput}
                    width='100%'
                    disableFirst={true}
                    onChange={(v) => this.presetChanged(v)}
                    value={this.state.activePreset}
                    options={ENCODING_SETTING_PRESET_IMAGE_OPTIONS}
                />

                {settings.map((setting: IVideoStoryImageOutputSetting, i) => {
                    let showPoster = !setting.poster && hasPoster;

                    if (addingAdditional && additional.poster) {
                        showPoster = !setting.poster && additional.poster;
                    }

                    return (
                        <VideoStoryImageOutputSetting
                            showPoster={showPoster}
                            type={type}
                            cuts={this.compileCutList()}
                            config={setting}
                            settings={config}
                            onUpdate={(c) => this.updateSetting(i, c)}
                            onDelete={() => this.deleteSetting(i)}
                            onDuplicate={() => this.duplicateSetting(i)}
                            key={i}
                        />
                    );
                })}

                {addingAdditional && (
                    <VideoStoryImageOutputSetting
                        showPoster={additionalPoster}
                        type={type}
                        isNew={true}
                        config={additional}
                        settings={config}
                        cuts={this.compileCutList()}
                        onUpdate={(c) => this.updateAdditionalSetting(c)}
                        onConfirm={(i) => this.confirmAdditional(i)}
                        onCancel={() => this.cancelAdditional()}
                        key={'new'}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state): any => {
    return { compositions: state.compositions.present };
};

export default connect(mapStateToProps, null)(VideoStoryImageOutputSettings);
