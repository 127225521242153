import * as React from 'react';
import NotificationConsole from './NotificationConsole';
import { Button, ImposiumDropdown, ShortcutMenu } from '@imposium-hub/components';
import { fields } from '../constants/copy';
import { connect } from 'react-redux';
import { ICON_HOTKEYS } from '../constants/icons';
import { UAParser } from 'ua-parser-js';
interface IRightHeaderState {
    showShortcutsMenu: boolean;
}

class RightHeader extends React.PureComponent<undefined, IRightHeaderState> {
    private readonly shortcutsToggleRef: any;

    constructor(props) {
        super(props);

        this.shortcutsToggleRef = React.createRef();

        this.state = {
            showShortcutsMenu: false
        };
    }

    public render() {
        const parser = new UAParser();
        const OS = parser.getOS().name;
        const globalShortcuts =
            OS !== 'Windows' ? fields.mac.globalShortcuts : fields.win.globalShortcuts;
        const timelineShortcuts =
            OS !== 'Windows' ? fields.mac.timelineShortcuts : fields.win.timelineShortcuts;
        const layerShortcuts =
            OS !== 'Windows' ? fields.mac.layerShortcuts : fields.win.layerShortcuts;
        const videoLayerShortCuts =
            OS !== 'Windows' ? fields.mac.videoLayers : fields.win.videoLayers;
        const previewShortcuts =
            OS !== 'Windows' ? fields.mac.previewShortcuts : fields.win.previewShortcuts;
        return (
            <div className='header'>
                <NotificationConsole />
                <span className='header-shortcuts'>
                    <Button
                        key='experiences-shortcuts'
                        style='subtle'
                        size='small'
                        tooltip={fields.global.tooltipHotKeys}
                        buttonRef={this.shortcutsToggleRef}
                        onClick={() =>
                            this.setState({ showShortcutsMenu: !this.state.showShortcutsMenu })
                        }>
                        {ICON_HOTKEYS}
                    </Button>
                    <ImposiumDropdown
                        position='bottomright'
                        show={this.state.showShortcutsMenu}
                        toggleRef={this.shortcutsToggleRef}
                        onOutsideClick={() => this.setState({ showShortcutsMenu: false })}>
                        <ShortcutMenu
                            shortcutCopyMap={globalShortcuts}
                            heading={fields.globalShortcutsHeading}
                        />
                        <ShortcutMenu
                            shortcutCopyMap={previewShortcuts}
                            heading={fields.previewShortcutsHeading}
                        />
                        <ShortcutMenu
                            shortcutCopyMap={timelineShortcuts}
                            heading={fields.timelineShortcutsHeading}
                        />
                        <ShortcutMenu
                            shortcutCopyMap={layerShortcuts}
                            heading={fields.layerShortcutHeading}
                        />
                        <ShortcutMenu
                            shortcutCopyMap={videoLayerShortCuts}
                            heading={fields.VideolayerShortcutHeading}
                        />
                    </ImposiumDropdown>
                </span>
            </div>
        );
    }
}

const mapStateToProps = (state): any => {
    return {};
};

export default connect(mapStateToProps)(RightHeader);
