import * as React from 'react';
import { logNotification, logError } from '../../util/notifications';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { api } from '../../constants/app';
import { viewer as copy } from '../../constants/copy';
import { getCut, getCutIndex } from '../../util/story';
import { IStory } from '../../constants/snippets';
import { IProject } from '../../redux/reducers/project';
import { saveStory } from '../../redux/actions/story';
import {
    Button,
    Spinner,
    ButtonGroupField,
    VideoPlayer,
    formattedTime,
    Timer,
    LogViewer
} from '@imposium-hub/components';
import { updateCut } from '../../redux/actions/story';
import { ICON_VIDEO, ICON_STICKY_NOTE } from '../../constants/icons';
import { IVideoFile } from '../../constants/snippets';

interface ICutPreviewPlayerProps {
    cutId: string;
    project: IProject;
    story: IStory;
    width: number;
    height: number;
    active?: boolean;
    videoFile: IVideoFile;
    saveStory(i: string, s: IStory): any;
    updateCut(c: any): void;
}

interface ICutPreviewPlayerState {
    runningCut: boolean;
    timeElapsed: number;
    jobId: string;
    showLog: boolean;
}

class CutPreviewPlayer extends React.PureComponent<ICutPreviewPlayerProps, ICutPreviewPlayerState> {
    private timer: Timer;

    constructor(props) {
        super(props);

        this.state = {
            runningCut: false,
            timeElapsed: 0,
            jobId: '',
            showLog: false
        };

        this.timer = new Timer({
            interval: 1000,
            onUpdate: (t) => {
                this.setState({ timeElapsed: t });
            }
        });
    }

    public componentWillUnmount() {
        this.timer.stop();
    }

    public runCut() {
        const {
            cutId,
            project: { actId, sceneId, storyId },
            story
        } = this.props;
        const scene = story.acts[actId].scenes[sceneId];
        const cut = getCut(scene.sceneData.cuts, cutId);
        const cutIndex = getCutIndex(scene.sceneData.cuts, cutId);

        this.setState(
            {
                runningCut: true,
                timeElapsed: 0,
                jobId: '',
                showLog: false
            },
            () => {
                this.props.saveStory(storyId, story).then(() => {
                    api.runCut(storyId, actId, sceneId, cutId)
                        .then()
                        .then((jobData: any) => {
                            this.timer.start();

                            logNotification(copy.runningCut.replace('[jobId]', jobData.id));

                            // poll on the job ID
                            api.pollJob(jobData.id)
                                .then((jobOutput: any) => {
                                    const newCut = { ...cut, ...jobOutput.output };

                                    const updateConfig = {
                                        actId,
                                        sceneId,
                                        cutIndex,
                                        cut: newCut
                                    };

                                    this.props.updateCut(updateConfig);

                                    this.setState({
                                        jobId: jobData.id,
                                        showLog: false,
                                        runningCut: false,
                                        timeElapsed: 0
                                    });

                                    this.timer.stop();
                                })
                                .catch((e: Error) => {
                                    logError(copy.runningCutError);
                                    this.setState({
                                        jobId: jobData.id,
                                        showLog: true,
                                        runningCut: false,
                                        timeElapsed: 0
                                    });
                                    this.timer.stop();
                                });
                        })
                        .catch((e: Error) => {
                            logError(copy.runningCutError);
                            this.setState({
                                jobId: null,
                                showLog: false,
                                runningCut: false,
                                timeElapsed: 0
                            });
                            this.timer.stop();
                            this.toggleLog(true);
                        });
                });
            }
        );
    }

    private toggleLog(e) {
        this.setState({
            showLog: e
        });
    }

    public renderPlayer(url) {
        const { width, height, videoFile } = this.props;
        const { runningCut, timeElapsed, showLog } = this.state;

        if (showLog) {
            return (
                <div className='log'>
                    <LogViewer
                        api={api}
                        onError={(e) => logError(e)}
                        jobId={this.state.jobId}
                    />
                </div>
            );
        } else if (runningCut) {
            return (
                <div className='running-prompt'>
                    <Spinner />
                    <br />
                    <h1>{copy.cutRunning}</h1>
                    <p>
                        {copy.time}&nbsp;{formattedTime(timeElapsed)}
                    </p>
                </div>
            );
        } else if (url) {
            return (
                <VideoPlayer
                    active={true}
                    url={url}
                    width={width}
                    height={height}
                    maxWidth={videoFile.width}
                    allowManualScale={true}
                    maxHeight={videoFile.height}
                />
            );
        }

        return null;
    }

    public render() {
        const { runningCut, jobId } = this.state;
        const {
            cutId,
            project: { actId, sceneId },
            story,
            active
        } = this.props;
        const scene = story.acts[actId].scenes[sceneId];
        const cut = getCut(scene.sceneData.cuts, cutId);

        const btnRender = !runningCut ? (
            <Button
                color='primary'
                size='large'
                onClick={() => this.runCut()}>
                {copy.btnRenderCut}
            </Button>
        ) : null;

        const btnOptions = [
            {
                value: false,
                label: (
                    <span>
                        {ICON_VIDEO}&nbsp;{copy.btnVideo}
                    </span>
                )
            },
            {
                value: true,
                label: (
                    <span>
                        {ICON_STICKY_NOTE}&nbsp;{copy.btnLog}
                    </span>
                )
            }
        ];

        const btnGroup = jobId ? (
            <ButtonGroupField
                width={'130px'}
                options={btnOptions}
                onChange={(e) => this.toggleLog(e)}
                value={this.state.showLog}
            />
        ) : null;

        if (active) {
            return (
                <div className='cut-viewer viewer'>
                    {this.renderPlayer(cut.previewURL)}
                    <div className='buttons'>
                        {btnGroup}
                        {btnRender}
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }
}

const mapDispatchToProps = (dispatch): any => {
    return bindActionCreators({ updateCut, saveStory }, dispatch);
};

const mapStateToProps = (state): any => {
    return { project: state.project, story: state.story };
};

export default connect(mapStateToProps, mapDispatchToProps)(CutPreviewPlayer);
