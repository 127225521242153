import * as React from 'react';
import { logNotification, logError } from '../../util/notifications';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { api } from '../../constants/app';
import { IStory } from '../../constants/snippets';
import { IProject } from '../../redux/reducers/project';
import { StoryPreviewer } from '@imposium-hub/components';
import { updateEditorConfig } from '../../redux/actions/editor';

interface IStoryPreviewPlayerProps {
    project: IProject;
    story: IStory;
    width: number;
    height: number;
    active?: boolean;
    updateEditorConfig(c): void;
}

interface IStoryPreviewPlayerState {
    experienceId: string;
    jobId: string;
}

class StoryPreviewPlayer extends React.PureComponent<
    IStoryPreviewPlayerProps,
    IStoryPreviewPlayerState
> {
    private evtHandlers: any;

    constructor(props) {
        super(props);

        this.evtHandlers = {
            onNotification: (e) => logNotification(e),
            onError: (e) => logError(e),
            setExpId: (e, j) => this.setExpId(e, j)
        };

        this.state = {
            experienceId: null,
            jobId: null
        };
    }

    private setExpId(expId, jobId) {
        this.setState({
            experienceId: expId,
            jobId
        });
    }

    public render() {
        const {
            active,
            story,
            project: { actId, storyId }
        } = this.props;
        const variables = story.acts[actId].inventory;

        if (active) {
            return (
                <StoryPreviewer
                    api={api}
                    storyId={storyId}
                    variables={variables}
                    experienceId={this.state.experienceId}
                    jobId={this.state.jobId}
                    onNotification={this.evtHandlers.onNotification}
                    onJobCreated={this.evtHandlers.setExpId}
                    onError={this.evtHandlers.onError}
                />
            );
        } else {
            return null;
        }
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            updateEditorConfig
        },
        dispatch
    );
};

const mapStateToProps = (state): any => {
    return { project: state.project, story: state.story };
};

export default connect(mapStateToProps, mapDispatchToProps)(StoryPreviewPlayer);
