import * as React from 'react';
import { Button, toFixed, padStart } from '@imposium-hub/components';
import { video as copy } from '../../constants/copy';
import Timecode from 'smpte-timecode';
import hotkeys from 'hotkeys-js';
import ReactResizeDetector from 'react-resize-detector';
import PlaybackBar from './PlaybackBar';
import VolumeControl from './VolumeControl';
import {
    ICON_PLAY,
    ICON_PAUSE,
    ICON_CUT,
    ICON_STEP_BACKWARD,
    ICON_STEP_FORWARD
} from '../../constants/icons';

interface IVideoControlsProps {
    frameRate: number;
    videoNode?: any;
    trimMode?: boolean;
    onInPointChange?(p: number): void;
    onOutPointChange?(p: number): void;
}

interface IVideoControlsState {
    paused: boolean;
    muted: boolean;
    duration: number;
    totalFrames: number;
    currentTime: number;
    currentFrame: number;
    seeking: boolean;
    volume: number;
    playbackRate: number;
    playbackDisplayType: string;
    playingOnDragStart: boolean;
    inFrame: number;
    outFrame: number;
    keyCount: number;
}

class VideoControls extends React.Component<IVideoControlsProps, IVideoControlsState> {
    private playbackDisplayTypes = ['time', 'frame', 'smpte'];

    private maxPlaybackRate = 3;

    private nextHandler: any;

    private prevHandler: any;

    private tenNextHandler: any;

    private tenPrevHandler: any;

    private playHandler: any;

    private inHandler: any;

    private outHandler: any;

    private jumpInHandler: any;

    private jumpOutHandler: any;

    constructor(props: IVideoControlsProps) {
        super(props);

        this.state = {
            paused: true,
            muted: false,
            seeking: false,
            duration: 0,
            totalFrames: 0,
            volume: 1,
            playbackRate: 1,
            playbackDisplayType: 'smpte',
            currentTime: 0,
            currentFrame: 0,
            inFrame: undefined,
            outFrame: undefined,
            playingOnDragStart: false,
            keyCount: 0
        };

        this.nextHandler = this.debounceHotkeys(() => this.nextFrame());
        this.prevHandler = this.debounceHotkeys(() => this.previousFrame());
        this.tenNextHandler = () => this.nextFrame(true);
        this.tenPrevHandler = () => this.previousFrame(true);
        this.playHandler = () => this.togglePlay();
        this.inHandler = () => this.setIn();
        this.outHandler = () => this.setOut();
        this.jumpInHandler = () => this.jumpToIn();
        this.jumpOutHandler = () => this.jumpToOut();
    }

    public componentDidUpdate(prevProps) {
        if (this.props.trimMode && !prevProps.trimMode) {
            this.bindTrimHotKeys();
        } else if (!this.props.trimMode && prevProps.trimMode) {
            this.unbindTrimHotkeys();
        }
    }

    public componentWillUnmount() {
        this.unbindHotkeys();
        this.unbindTrimHotkeys();
    }

    private bindHotkeys() {
        hotkeys('right', this.nextHandler);
        hotkeys('shift+right', this.tenNextHandler);
        hotkeys('left', this.prevHandler);
        hotkeys('shift+left', this.tenPrevHandler);
        hotkeys('space', this.playHandler);
    }

    private bindTrimHotKeys() {
        hotkeys('i', this.inHandler);
        hotkeys('o', this.outHandler);
        hotkeys('b', this.jumpInHandler);
        hotkeys('n', this.jumpOutHandler);
    }

    private unbindHotkeys() {
        hotkeys.unbind('right', this.nextHandler);
        hotkeys.unbind('shift+right', this.tenNextHandler);
        hotkeys.unbind('left', this.prevHandler);
        hotkeys.unbind('shift+left', this.tenPrevHandler);
        hotkeys.unbind('space', this.playHandler);
    }

    private unbindTrimHotkeys() {
        hotkeys.unbind('i', this.inHandler);
        hotkeys.unbind('o', this.outHandler);
        hotkeys.unbind('b', this.jumpInHandler);
        hotkeys.unbind('n', this.jumpOutHandler);
    }

    private setIn() {
        const { currentFrame, outFrame, totalFrames } = this.state;
        let setOutFrame = outFrame;
        if (outFrame !== totalFrames) {
            setOutFrame = currentFrame + 1;
        }

        this.setState(
            {
                inFrame: currentFrame,
                outFrame: setOutFrame
            },
            () => {
                this.props.onInPointChange(currentFrame / this.props.frameRate);
                this.props.onOutPointChange(setOutFrame / this.props.frameRate);
            }
        );
    }

    private setOut() {
        const { currentFrame, inFrame } = this.state;
        if (currentFrame > inFrame) {
            this.setState(
                {
                    outFrame: currentFrame
                },
                () => {
                    this.props.onOutPointChange(currentFrame / this.props.frameRate);
                }
            );
        }
    }

    private jumpToIn() {
        const { inFrame } = this.state;
        this.seekToFrame(inFrame);
    }

    private jumpToOut() {
        const { outFrame } = this.state;
        this.seekToFrame(outFrame);
    }

    private seekToFrame(frame) {
        const video = this.props.videoNode.current;
        const { frameRate } = this.props;

        if (video) {
            // The 0.001 forces the browser video player to show the proper frame, and not the previous frame. Not pretty, but seems to work.
            // const time = (frame / frameRate) + 0.001;

            // The 0.001 trick stopped working, so now we're going with the 0.99 trick. Still not pretty. Still seems to work.
            const time = (frame + 0.99) / frameRate;
            video.currentTime = time;
            this.setState({ keyCount: 0 });
        }
    }

    private nextFrame(skipTen: boolean = false) {
        const { currentFrame, totalFrames, paused, keyCount } = this.state;
        if (!paused) {
            this.pauseVideoNode();
        } else {
            let newFrame = skipTen
                ? currentFrame + 10
                : currentFrame + (keyCount !== 0 ? keyCount : 1);

            if (newFrame >= totalFrames) {
                newFrame = totalFrames;
            }

            this.seekToFrame(newFrame);
        }
    }

    private debounceHotkeys(func) {
        let timer;
        return (...args) => {
            this.setState((prev) => {
                return {
                    keyCount: prev.keyCount + 1
                };
            });
            clearTimeout(timer);
            timer = setTimeout(() => {
                func.apply(this, args);
            }, 200);
        };
    }

    private previousFrame(skipTen: boolean = false) {
        const { currentFrame, paused, keyCount } = this.state;
        if (!paused) {
            this.pauseVideoNode();
        } else {
            let newFrame = skipTen
                ? currentFrame - 10
                : currentFrame - (keyCount !== 0 ? keyCount : 1);

            if (newFrame < 0) {
                newFrame = 0;
            }
            this.seekToFrame(newFrame);
        }
    }

    private pauseVideoNode() {
        const video = this.props.videoNode.current;
        if (video) {
            video.pause();
        }
    }

    public componentDidMount() {
        const video = this.props.videoNode.current;

        if (video) {
            video.addEventListener('loadedmetadata', (e) => {
                const totalFrames = video.duration * this.props.frameRate;

                this.setState({
                    duration: video.duration,
                    totalFrames,
                    inFrame: 0,
                    outFrame: totalFrames,
                    currentTime: video.currentTime,
                    paused: video.paused,
                    seeking: video.seeking
                });

                this.props.onInPointChange(0);
                this.props.onOutPointChange(video.duration);

                video.volume = this.state.volume;
            });

            video.addEventListener('timeupdate', (e) => this.onProgressChange(e));

            video.addEventListener('play', (e) => {
                this.setState({
                    paused: false
                });
            });

            video.addEventListener('pause', (e) => {
                this.setVideoPlayback(e.target.currentTime);
                this.setState({
                    paused: true
                });
            });
        }

        this.bindHotkeys();
    }

    private calculateTotalFrames() {
        const { frameRate } = this.props;
        const { duration } = this.state;

        if (duration) {
            return duration * frameRate;
        } else {
            return 0;
        }
    }

    private togglePlaybackDisplayType() {
        const { playbackDisplayType } = this.state;
        const idx = this.playbackDisplayTypes.indexOf(playbackDisplayType);
        let nextType = this.playbackDisplayTypes[idx + 1];
        if (!nextType) {
            nextType = this.playbackDisplayTypes[0];
        }

        this.setState({
            playbackDisplayType: nextType
        });
    }

    private togglePlay() {
        const video = this.props.videoNode.current;
        const { paused } = this.state;

        if (video) {
            if (paused) {
                video.play();
            } else {
                video.pause();
            }
        }
    }

    private play() {
        const video = this.props.videoNode.current;
        video.play();
    }

    private pause() {
        const video = this.props.videoNode.current;
        video.pause();
    }

    private setVideoPlayback(currentTime) {
        const { frameRate } = this.props;
        const frame = Math.floor(currentTime * frameRate);

        this.setState({
            currentTime,
            currentFrame: frame
        });
    }

    private onProgressChange(e) {
        this.setVideoPlayback(e.target.currentTime);
    }

    private updateVolume(v) {
        const video = this.props.videoNode.current;
        if (video) {
            this.setState(
                {
                    volume: v
                },
                () => {
                    video.volume = v;
                }
            );
        }
    }

    private changePlaybackRate() {
        const { playbackRate } = this.state;
        let newRate = playbackRate + 1;

        if (newRate > this.maxPlaybackRate) {
            newRate = 1;
        }

        this.setState(
            {
                playbackRate: newRate
            },
            () => {
                const video = this.props.videoNode.current;
                if (video) {
                    video.playbackRate = newRate;
                }
            }
        );
    }

    private renderPlayPauseButton = () => {
        const { paused } = this.state;
        const icon = paused ? ICON_PLAY : ICON_PAUSE;

        return (
            <Button
                style='subtle'
                onClick={() => this.togglePlay()}>
                {icon}
            </Button>
        );
    };

    private renderTimecode() {
        const { frameRate } = this.props;
        const { currentTime, currentFrame, playbackDisplayType } = this.state;

        if (playbackDisplayType === 'time') {
            return toFixed(currentTime, 2);
        } else if (playbackDisplayType === 'frame') {
            return padStart(currentFrame, 5);
        } else if (playbackDisplayType === 'smpte') {
            const rate = parseFloat(toFixed(frameRate, 3));
            const timecode = new Timecode(currentFrame, rate);
            return timecode.toString();
        }
    }

    private dragStart() {
        if (!this.state.paused) {
            this.pause();
            this.setState({
                playingOnDragStart: true
            });
        }
    }

    private dragEnd() {
        if (this.state.playingOnDragStart) {
            this.play();
            this.setState({
                playingOnDragStart: false
            });
        }
    }

    private renderTrimInButtons() {
        const { trimMode } = this.props;
        if (trimMode) {
            return (
                <div className='trim-buttons in'>
                    <Button
                        onClick={() => this.setIn()}
                        style='subtle'
                        tooltip={copy.tooltipSetIn}>
                        {ICON_CUT}
                    </Button>
                    <Button
                        onClick={() => this.jumpToIn()}
                        style='subtle'
                        tooltip={copy.tooltipJumpIn}>
                        {ICON_STEP_BACKWARD}
                    </Button>
                </div>
            );
        }
    }

    private renderTrimOutButtons() {
        const { trimMode } = this.props;
        if (trimMode) {
            return (
                <div className='trim-buttons out'>
                    <Button
                        onClick={() => this.setOut()}
                        style='subtle'
                        tooltip={copy.tooltipSetOut}>
                        {ICON_CUT}
                    </Button>
                    <Button
                        onClick={() => this.jumpToOut()}
                        style='subtle'
                        tooltip={copy.tooltipJumpOut}>
                        {ICON_STEP_FORWARD}
                    </Button>
                </div>
            );
        }
    }

    public render = (): JSX.Element => {
        const { currentFrame, inFrame, outFrame, playbackRate } = this.state;
        const { trimMode } = this.props;

        const totalFrames: number = this.calculateTotalFrames();
        const playbackClass = trimMode ? 'trim-mode' : '';

        const btnTogglePlaybackRate = (
            <Button
                style='subtle'
                onClick={() => this.changePlaybackRate()}>
                {`${playbackRate}x`}
            </Button>
        );
        return (
            <div className='imposium-video-controls'>
                <div className='left-buttons'>
                    {this.renderPlayPauseButton()}
                    <div
                        className='playback'
                        onClick={() => this.togglePlaybackDisplayType()}>
                        {this.renderTimecode()}
                    </div>
                </div>
                {this.renderTrimInButtons()}
                <div className={`playback-wrapper ${playbackClass}`}>
                    <ReactResizeDetector handleWidth>
                        <PlaybackBar
                            onPlayheadDragStart={() => this.dragStart()}
                            onPlayheadDragEnd={() => this.dragEnd()}
                            onPlayheadDrag={(f) => this.seekToFrame(f)}
                            trimMode={trimMode}
                            totalFrames={totalFrames}
                            currentFrame={currentFrame}
                            inFrame={inFrame}
                            outFrame={outFrame}
                        />
                    </ReactResizeDetector>
                </div>
                {this.renderTrimOutButtons()}
                <div className='right-buttons'>
                    <VolumeControl
                        onChange={(v) => this.updateVolume(v)}
                        volume={this.state.volume}
                    />
                    {btnTogglePlaybackRate}
                </div>
            </div>
        );
    };
}

export default VideoControls;
