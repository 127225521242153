import * as React from 'react';
import store from '../redux/store';
import { Card, scrapeEmail } from '@imposium-hub/components';
import hotkeys from 'hotkeys-js';
import { ICON_ASTERISK } from '../constants/icons';

interface ISuperUserOnlyProps {
    children: any;
}

interface ISuperUserOnlyState {
    show: boolean;
    open: boolean;
}
export const isSuperUser = (): boolean => {
    const state = store.getState();
    const email = scrapeEmail(state.auth);
    return email.indexOf('imposium') !== -1;
};

export class SuperUserOnly extends React.PureComponent<ISuperUserOnlyProps, ISuperUserOnlyState> {
    private showHandler = () => this.toggleShow();

    constructor(props) {
        super(props);

        this.state = {
            show: true,
            open: false
        };
    }

    private toggleShow() {
        this.setState({ show: !this.state.show });
    }

    componentDidMount(): void {
        hotkeys('option+s, alt+s', this.showHandler);
    }

    componentWillUnmount(): void {
        hotkeys.unbind('option+s, alt+s', this.showHandler);
    }

    public render() {
        const { children } = this.props;
        const { show, open } = this.state;
        const asterisk = (
            <div
                onClick={() => this.setState({ open: !open })}
                className='superuser asterisk'>
                {ICON_ASTERISK}
            </div>
        );
        const superuser = (
            <Card
                title='Super User Only'
                open={true}
                collapsable={true}
                onClose={() => this.setState({ open: !open })}
                style='superuser'>
                {children}
            </Card>
        );
        if (isSuperUser() && show) {
            return this.state.open ? superuser : asterisk;
        } else {
            return null;
        }
    }
}
export default SuperUserOnly;
