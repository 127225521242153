import * as React from 'react';
import { ROW_HEIGHT } from '../../constants/timeline';
import { IOverlay } from '../../constants/snippets';
import { connect } from 'react-redux';
import ErrorLayer from './ErrorLayer';
import { ASSET_TYPES } from '../../constants/story';
import Draggable from 'react-draggable';

interface IOverlayProps {
    config: IOverlay;
    index: number;
    selected: boolean;
    height: number;
    select(id: string): void;
    shift(index: number, offset: number): void;
    errors: any;
}

class Overlay extends React.PureComponent<IOverlayProps> {
    constructor(props) {
        super(props);

        this.state = {};
    }

    private overlayClicked(e) {
        e.preventDefault();
        e.stopPropagation();

        this.props.select(this.props.config.id);
    }

    private getTypeClass() {
        const { config } = this.props;
        const source = config.source;

        if (config.generating_script !== undefined && source === null) {
            return 'type-script';
        } else if (source) {
            if (source.asset_type === ASSET_TYPES.TEMPLATE) {
                return 'type-template';
            } else if (source.asset_type === ASSET_TYPES.VIDEO) {
                return 'type-video';
            } else if (source.asset_type === ASSET_TYPES.IMAGE) {
                return 'type-image';
            } else if (source.asset_type === ASSET_TYPES.IMAGE_SEQUENCE) {
                return 'type-image-sequence';
            }
        }

        return '';
    }

    private onOverlayDragStart(e, u) {
        this.props.select(this.props.config.id);
    }

    private onOverlayDragEnd(e, u) {
        e.preventDefault();
        e.stopPropagation();

        const { index } = this.props;

        const startY = this.calculateYPos();
        const offset = u.y - startY + ROW_HEIGHT / 2;
        const indexShift = -Math.floor(offset / ROW_HEIGHT);

        if (indexShift !== 0) {
            this.props.shift(index, indexShift);
        }
    }

    private calculateYPos() {
        const { height, index } = this.props;
        return height - (index + 2) * ROW_HEIGHT;
    }

    private renderErrorHandler(scale, overlay) {
        const {
            errors: { overlays }
        } = this.props;
        for (const error of overlays) {
            if (error.overlay_id === overlay) {
                return <ErrorLayer scale={scale} />;
            }
        }
        return false;
    }

    public render() {
        const {
            selected,
            height,
            config: { id, enabled }
        } = this.props;

        const y = this.calculateYPos();
        const top = ROW_HEIGHT;
        const bottom = height - ROW_HEIGHT * 2;

        const style = {
            height: `${ROW_HEIGHT}px`
        };

        const selectedClass = selected ? 'selected' : '';
        const disabledClass = !enabled ? 'disabled' : '';

        return (
            <>
                <Draggable
                    axis='y'
                    bounds={{ left: 0, right: 0, bottom, top }}
                    position={{ y, x: 0 }}
                    onStart={(e, u) => this.onOverlayDragStart(e, u)}
                    onStop={(e, u) => this.onOverlayDragEnd(e, u)}>
                    <div
                        className={`timeline-overlay ${selectedClass} ${disabledClass} ${this.getTypeClass()}`}
                        style={style}
                        onMouseDown={(e) => this.overlayClicked(e)}>
                        <div className='border-top' />
                        <div className='border-right' />
                        <div className='border-left' />
                        <div className='border-bottom' />
                        {this.renderErrorHandler(top, id)}
                    </div>
                </Draggable>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    errors: state.errors
});

export default connect(mapStateToProps, {})(Overlay);
