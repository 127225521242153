import * as React from 'react';
import { NumberField, HRule, Section, ColorField } from '@imposium-hub/components';
import { fields as copy } from '../constants/copy';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { SCENE_TYPES } from '../constants/story';
import { updateEditorConfig } from '../redux/actions/editor';
import type { IProject } from '../redux/reducers/project';
import { colorPresets, IImageSceneData, IVariables } from '../constants/snippets';
import OverlayConfig from './OverlayConfig';
import ImageLayers from './timeline/ImageLayers';
import { generateUUID } from '../util/story';
import { updateTimelineState } from '../redux/actions/timeline';

interface IImageStoryConfigProps {
    config: IImageSceneData;
    project: IProject;
    variables: IVariables;
    activeOverlay: string;
    updateEditorConfig(c): void;
    updateTimelineState(s): void;
    onUpdate(c): void;
}

class ImageStoryConfig extends React.PureComponent<IImageStoryConfigProps> {
    private evtHandlers: any;

    constructor(props) {
        super(props);

        this.evtHandlers = {
            inputs: {
                bgColor: (v) => this.inputChanged('backgroundColor', v.hex),
                width: (v) => this.inputChanged('width', v),
                height: (v) => this.inputChanged('height', v)
            },
            selectOverlay: (o) => this.selectOverlay(o),
            updateOverlay: (e) => this.props.onUpdate(e)
        };
    }

    private selectOverlay(overlayId) {
        this.props.updateTimelineState({
            activeTimelineOverlay: overlayId
        });
    }

    public inputChanged(key, value) {
        const newConfig = { ...this.props.config };
        if (key === 'width' || key === 'height') {
            newConfig[key] = parseInt(value, 10);
        } else {
            newConfig[key] = value;
        }

        this.props.onUpdate(newConfig);
    }

    private updateOverlay(index, config) {
        const newConfig = { ...this.props.config };
        const overlays = [...newConfig.overlays];
        overlays[index] = config;
        newConfig.overlays = overlays;

        this.props.onUpdate(newConfig);
    }

    private duplicateOverlay = (config) => {
        const newOverlayConfig = { ...config };
        newOverlayConfig.id = generateUUID();
        newOverlayConfig.name = `Copy of ${newOverlayConfig.name}`;

        const newConfig = { ...this.props.config };
        const overlays = [...newConfig.overlays];
        overlays.push(newOverlayConfig);
        newConfig.overlays = overlays;

        this.props.onUpdate(newConfig);
    };

    private deleteOverlay(index) {
        const newConfig = { ...this.props.config };
        const newOverlays = [...newConfig.overlays];
        newOverlays.splice(index, 1);
        newConfig.overlays = newOverlays;

        this.props.updateTimelineState({ activeTimelineOverlay: null });
        this.props.onUpdate(newConfig);
    }

    private previewOverlay(e) {
        e.preventDefault();
    }

    private renderCutConfig() {
        const {
            config,
            config: { width, height },
            activeOverlay,
            variables,
            project: { storyId }
        } = this.props;

        let overlay;

        if (activeOverlay) {
            for (let i = 0; i < config.overlays.length; i++) {
                const o = config.overlays[i];
                if (o.id === activeOverlay) {
                    overlay = (
                        <OverlayConfig
                            storyId={storyId}
                            key={o.id}
                            config={o}
                            width={width}
                            height={height}
                            type={SCENE_TYPES.IMAGE}
                            fps={null}
                            variables={variables}
                            onChange={(c) => this.updateOverlay(i, c)}
                            onDuplicate={(c) => this.duplicateOverlay(c)}
                            onPreview={(e) => this.previewOverlay(e)}
                            onDelete={() => this.deleteOverlay(i)}
                        />
                    );
                }
            }
        }

        return (
            <div>
                <HRule style='subtle' />
                <h2>{copy.imgStoryConfig.baseConfig}</h2>
                <br />
                <ColorField
                    label={copy.imgStoryConfig.bgColor}
                    tooltip={copy.imgStoryConfig.tooltipBgColor}
                    presetColors={colorPresets}
                    width='33%'
                    value={config['backgroundColor']}
                    onChange={this.evtHandlers.inputs.bgColor}
                />
                <NumberField
                    label={copy.global.width}
                    tooltip={copy.imgStoryConfig.tooltipWidth}
                    width='33%'
                    value={config['width']}
                    onChange={this.evtHandlers.inputs.width}
                />
                <NumberField
                    label={copy.global.height}
                    tooltip={copy.imgStoryConfig.tooltipHeight}
                    width='33%'
                    value={config['height']}
                    onChange={this.evtHandlers.inputs.height}
                />
                <br />
                <h2>{copy.imgStoryConfig.layers}</h2>
                <HRule style='subtle' />
                <ImageLayers
                    config={config}
                    select={this.evtHandlers.selectOverlay}
                    update={this.evtHandlers.updateOverlay}
                    activeOverlay={activeOverlay}
                />
                <HRule style='subtle' />
                <div style={{ position: 'relative', height: '420px' }}>{overlay}</div>
            </div>
        );
    }

    public render() {
        return <Section title={copy.imgStoryConfig.title}>{this.renderCutConfig()}</Section>;
    }
}
const mapDispatchToProps = (dispatch): any => {
    return bindActionCreators({ updateEditorConfig, updateTimelineState }, dispatch);
};

const mapStateToProps = (state): any => {
    return { project: state.project };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageStoryConfig);
