import * as React from 'react';
import { connect } from 'react-redux';
import { ICON_CIRCLE, ICON_TIMES_CIRCLE } from '../constants/icons';
import { NOTIFICATION_BUTTONS } from '../constants/editor';
import { storyErrors } from '../constants/copy';
import { Button } from '@imposium-hub/components';
import { VIEWER_TYPES, VIEWER_LABELS } from '../constants/viewer';
import { ICON_ERROR } from '../constants/icons';
import { addViewer } from '../redux/actions/story';

interface INotificationProps {
    onClose(): void;
    time: string;
    text: string;
    type: string;
    addViewer(c: any): void;
    button?: any;
}

interface INotificationState {
    hover: boolean;
    animation: string;
}

class Notification extends React.PureComponent<INotificationProps, INotificationState> {
    private animationTimer;

    constructor(props) {
        super(props);

        this.state = {
            hover: false,
            animation: 'slide-in'
        };
    }

    private onMouseEnter() {
        this.setState({
            hover: true
        });
    }

    private onMouseLeave() {
        this.setState({
            hover: false
        });
    }

    private renderButton() {
        const { button } = this.props;

        switch (button) {
            case NOTIFICATION_BUTTONS.ERROR_VIEWER:
                return (
                    <Button
                        onClick={() =>
                            this.props.addViewer({
                                type: VIEWER_TYPES.ERROR_VIEWER,
                                label: VIEWER_LABELS[VIEWER_TYPES.ERROR_VIEWER],
                                id: VIEWER_TYPES.ERROR_VIEWER
                            })
                        }
                        style='subtle'
                        tooltip={storyErrors.errorDetails}
                        color='warning'
                        customStyles={{ margin: '0px' }}>
                        {ICON_ERROR}
                    </Button>
                );
            default:
                break;
        }
    }

    private handleClose() {
        this.setState({
            animation: 'slide-out'
        });
        this.animationTimer = setTimeout(() => {
            this.props.onClose();
        }, 200);
    }

    public componentWillUnmount() {
        clearTimeout(this.animationTimer);
    }

    public render() {
        const { time, text, type } = this.props;

        let msg = text;
        if (type === 'error') {
            msg = text.split('(')[0];
        }

        const { hover, animation } = this.state;
        const icon = hover ? ICON_TIMES_CIRCLE : ICON_CIRCLE;
        return (
            <div
                id='slide'
                className={`notification ${animation}`}
                onMouseEnter={() => this.onMouseEnter()}
                onMouseLeave={() => this.onMouseLeave()}>
                <span
                    onClick={() => this.handleClose()}
                    className={`icon ${type}`}>
                    {icon}
                </span>
                <span className='time'>{`[${time}]`}</span>&nbsp;-&nbsp;
                <span className='text'>
                    {msg} {this.renderButton()}
                </span>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addViewer: (i) => dispatch(addViewer(i))
    };
};

export default connect(null, mapDispatchToProps)(Notification);
