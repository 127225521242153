import * as React from 'react';
import { useDrop } from 'react-dnd';
import { LAYER_DND_TYPES } from '../../constants/timeline';

interface IAnchorDropTargetProps {
    onAnchorDrop(layerId: string, targetLayerId: string, start: boolean, targetStart: boolean);
    start: boolean;
    layerId: string;
}

export const AnchorDropTarget: React.FC<IAnchorDropTargetProps> = (p: IAnchorDropTargetProps) => {
    const { start, onAnchorDrop, layerId } = p;

    const [{ isOver }, drop] = useDrop({
        accept: [LAYER_DND_TYPES.ANCHOR],
        drop: (item: any) => {
            onAnchorDrop(item.id, layerId, item.start, start);
        },
        collect: (monitor) => ({
            isOver: monitor.isOver()
        })
    });

    const modifier = start ? 'start' : 'end';
    const over = isOver ? 'over' : '';

    return (
        <div
            ref={drop}
            className={`anchor-drop-target ${modifier} ${over}`}>
            <div className='icon' />
        </div>
    );
};
