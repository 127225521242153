import * as React from 'react';
import { NEW_KEY_VALUE_PAIR, IKeyValuePair } from '../constants/snippets';
import {
    Button,
    TextField,
    SelectField,
    ControlledList,
    FieldWrapper
} from '@imposium-hub/components';
import { ICON_PLUS } from '../constants/icons';
import { logError } from '../util/notifications';
import { fields as copy } from '../constants/copy';
import { CustomVar } from './CustomVarFields';

interface IKeyValuePairFieldsProps {
    entries?: IKeyValuePair[];
    label: string;
    btnAddLabel: string;
    tooltip?: any;
    tooltipAdd?: any;
    options?: any[];
    optionGroups?: any[];
    onChange(e): void;
    keyPlaceholder?: string;
    valuePlaceholder?: string;
    lockValue?: boolean;
    lockKey?: boolean;
}

interface IKeyValuePairFieldsState {
    varName: string;
    varValue: string;
}

class KeyValuePairFields extends React.PureComponent<
    IKeyValuePairFieldsProps,
    IKeyValuePairFieldsState
> {
    constructor(props) {
        super(props);

        this.state = {
            varName: '',
            varValue: ''
        };
    }

    public addVar(e) {
        const { entries } = this.props;
        const { varName, varValue } = this.state;

        if (varName === '') {
            logError(copy.keyValue.nameEmptyError);
            return;
        } else if (varValue === '') {
            logError(copy.keyValue.valueEmptyError);
            return;
        } else {
            const v: IKeyValuePair = { ...NEW_KEY_VALUE_PAIR };

            v.key = varName;
            v.value = varValue;

            const newVars = [...entries];
            newVars.push(v);

            this.props.onChange(newVars);
            this.setState({
                varName: '',
                varValue: ''
            });
        }
    }

    private removeVar(index) {
        const newVars = [...this.props.entries];
        newVars.splice(index, 1);

        this.props.onChange(newVars);
    }

    public updateVarState(key, value) {
        const state: any = {
            [key]: value
        };

        this.setState(state);
    }

    private renderVars() {
        const { entries, lockValue, lockKey } = this.props;
        const tagArray = [];

        for (let i = 0; i < entries.length; i++) {
            const v = entries[i];
            tagArray.push(
                <CustomVar
                    name={v.key}
                    lockValue={lockValue}
                    lockKey={lockKey}
                    onKeyChange={(key) => this.onKeyChange(i, key)}
                    onValueChange={(val) => this.onValueChange(i, val)}
                    value={v.value}
                />
            );
        }

        return tagArray;
    }

    private onKeyChange(index, newKey) {
        const newVars = [...this.props.entries];
        const newVar = { ...newVars[index] };
        newVar.key = newKey;
        newVars[index] = newVar;
        this.props.onChange(newVars);
    }

    private onValueChange(index, newValue) {
        const newVars = [...this.props.entries];
        const newVar = { ...newVars[index] };
        newVar.value = newValue;
        newVars[index] = newVar;
        this.props.onChange(newVars);
    }

    private renderValueInput() {
        const { varValue } = this.state;
        const { options, tooltipAdd, valuePlaceholder } = this.props;

        const btnAdd = (
            <Button
                color='primary'
                key='btn-add'
                tooltip={tooltipAdd}
                style='subtle'
                onClick={(e) => this.addVar(e)}>
                {ICON_PLUS}
            </Button>
        );

        if (options) {
            return (
                <SelectField
                    value={varValue}
                    width={'calc(100% - 240px)'}
                    buttons={[btnAdd]}
                    options={options}
                    placeholder={valuePlaceholder}
                    onChange={(v) => this.updateVarState('varValue', v)}
                />
            );
        } else {
            return (
                <TextField
                    value={varValue}
                    width={'calc(100% - 240px)'}
                    buttons={[btnAdd]}
                    placeholder={valuePlaceholder}
                    onChange={(v) => this.updateVarState('varValue', v)}
                />
            );
        }
    }

    public render() {
        const { varName } = this.state;
        const { label, btnAddLabel, tooltipAdd, tooltip, keyPlaceholder } = this.props;
        const listComponents = this.renderVars();

        return (
            <>
                <TextField
                    label={btnAddLabel}
                    tooltip={tooltipAdd}
                    value={varName}
                    width={240}
                    placeholder={keyPlaceholder}
                    onChange={(v) => this.updateVarState('varName', v)}
                />

                {this.renderValueInput()}

                <FieldWrapper
                    label={label}
                    tooltip={tooltip}>
                    <ControlledList
                        items={listComponents}
                        removable={true}
                        onRemove={(t) => this.removeVar(t)}
                    />
                </FieldWrapper>
            </>
        );
    }
}

export default KeyValuePairFields;
