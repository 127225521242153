import { useCallback, useEffect, useState } from 'react';

export const Draggable = ({ children, onDrag, onStart, onStop, axis, bounds, position, grid }) => {
    const [initialPos, setInitialPos] = useState(null);
    const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
    const [dragging, setDragging] = useState(false);

    const drag = useCallback(
        (e: MouseEvent) => {
            // If the axis is set, only apply the drag offset on the correct axis
            let x = axis === 'y' ? 0 : e.clientX - initialPos.x;
            let y = axis === 'x' ? 0 : e.clientY - initialPos.y;

            // If the grid is set, snap to the grid
            if (grid) {
                if (grid.x) {
                    x = Math.round(x / grid.x) * grid.x;
                }
                if (grid.y) {
                    y = Math.round(y / grid.y) * grid.y;
                }
            }

            // If bounds are set, constrain to the bounds
            if (bounds) {
                const minX = bounds.left - position.x;
                const maxX = bounds.right - position.x;
                const minY = bounds.top - position.y;
                const maxY = bounds.bottom - position.y;

                if (axis !== 'y') {
                    if (x < minX) x = minX;
                    if (x > maxX) x = maxX;
                }
                if (axis !== 'x') {
                    if (y < minY) y = minY;
                    if (y > maxY) y = maxY;
                }
            }

            const newOffset = {
                x,
                y
            };

            const dragInfo = {
                offsetX: newOffset.x,
                offsetY: newOffset.y
            };

            setDragOffset(newOffset);
            onDrag(e, dragInfo);
        },
        [dragging]
    );

    const endDrag = useCallback(
        (e: MouseEvent) => {
            setDragOffset({ x: 0, y: 0 });
            setDragging(false);
            setInitialPos(null);
            onStop(e, { offsetX: 0, offsetY: 0 });
        },
        [dragging]
    );

    useEffect(() => {
        if (dragging) {
            window.addEventListener('mousemove', drag);
            window.addEventListener('mouseup', endDrag);
        } else {
            window.removeEventListener('mousemove', drag);
            window.removeEventListener('mouseup', endDrag);
        }
        return () => {
            window.removeEventListener('mousemove', drag);
            window.removeEventListener('mouseup', endDrag);
        };
    }, [dragging, drag, endDrag]);

    const startDrag = (e: React.MouseEvent) => {
        setInitialPos({ x: e.clientX, y: e.clientY });
        setDragging(true);
        const dragEvt = {};
        onStart(e, dragEvt);
    };

    const absoluteStyle: any = {
        position: 'absolute',
        top: `${position.y}px`,
        left: `${position.x}px`,
        zIndex: 8000,
        transform: `translate(${dragOffset.x}px, ${dragOffset.y}px)`
    };

    return (
        <div
            style={absoluteStyle}
            className='draggable'
            onMouseDown={startDrag}>
            {children}
        </div>
    );
};
