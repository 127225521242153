import * as React from 'react';
import { connect } from 'react-redux';
import { ICON_EXCLIMATION_TRIANGLE } from '../../constants/icons';

interface IErrorIndicatorProps {
    errors: any;
    cutId?: string;
    overlayId?: string;
    show: string[];
}

class ErrorIndicator extends React.PureComponent<IErrorIndicatorProps> {
    private renderErrors() {
        const { errors, cutId, overlayId, show } = this.props;
        const allErrors = [...errors.cuts.concat(errors.overlays, errors.audios)];
        const displayErrors = [];

        for (const error of allErrors) {
            if (show.indexOf(error.error_id) !== -1) {
                if (
                    overlayId &&
                    cutId &&
                    overlayId === error.overlay_id &&
                    cutId === error.cut_id
                ) {
                    displayErrors.push(error);
                } else if (overlayId && overlayId === error.overlay_id) {
                    displayErrors.push(error);
                } else if (cutId && cutId === error.cut_id) {
                    displayErrors.push(error);
                }
            }
        }

        return (
            <>
                {displayErrors.map((e) => {
                    return (
                        <p
                            key={e.error_id}
                            className='error-indicator'>
                            {ICON_EXCLIMATION_TRIANGLE}&nbsp;&nbsp;{e.error}
                        </p>
                    );
                })}
            </>
        );
    }

    public render() {
        return <div>{this.renderErrors()}</div>;
    }
}

const mapStateToProps = (state): any => {
    return { errors: state.errors };
};

export default connect(mapStateToProps, {})(ErrorIndicator);
