import { TextField } from '@imposium-hub/components';
import { fields as copy } from '../constants/copy';
import { VARIABLE_TYPES } from '../constants/story';

export const responsiveCompWidthHeightField = () => {
    return (
        <>
            <TextField
                label={copy.global.width}
                tooltip={copy.encoding.tooltipWidth}
                value='Use composition Width'
                disabled
                width='50%'
            />

            <TextField
                label={copy.global.height}
                tooltip={copy.encoding.tooltipHeight}
                value='Use composition Height'
                disabled
                width='50%'
            />
        </>
    );
};

export const reactAutoCompleteSettings = (variables) => {
    const varsData = [];

    for (const key in variables) {
        if (variables.hasOwnProperty(key)) {
            const variable = variables[key];
            // Only allow TEXT, ENUM (list), or NUMBER variables to be used in the text field
            if (
                variable.type === VARIABLE_TYPES.TEXT ||
                variable.type === VARIABLE_TYPES.ENUM ||
                variable.type === VARIABLE_TYPES.NUMBER
            ) {
                const obj = {
                    name: `${key}`
                };
                varsData.push(obj);
            }
        }
    }

    const variableItem = ({ entity: { name } }) => <div className='variable'>{`${name}`}</div>;

    return { varsData, variableItem };
};
