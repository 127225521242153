import { useRef, useState } from 'react';
import { ICON_CLOCK } from '../../constants/icons';
import { ICompositionLayer } from '../../constants/snippets';
import {
    COMPOSITION_LAYER_TYPES,
    LAYER_KEYFRAME_INTERPOLATION_OPTIONS,
    LAYOUT_UNITS,
    OVERLAY_POSITION_TYPES
} from '../../constants/story';
import { KEYFRAME_CONTROLLER_TYPES } from '../../constants/timeline';
import {
    addKeyframe,
    changeKeyframeValue,
    clearKeyframes,
    removeKeyframe
} from '../../util/timeline';
import { timeline } from '../../constants/copy';
import { Button, SelectField, ImposiumDropdown } from '@imposium-hub/components';
import { QuadPositionConfig } from '../QuadPositionConfig';
import { MTQuadPositionConfig } from '../MTQuadPositionConfig';
import { KeyframeController } from './KeyframeController';
import { getCopyPropIdButton } from '../TextLayerOptions';
import { isAudioLayer } from '../../util/story';

interface ILayerKeyframeControlsProps {
    showCopyPropIds: boolean;
    config: ICompositionLayer;
    activeFrame: number;
    activeKeyframe: string;
    compWidth: number;
    compHeight: number;
    composition: any;
    compositionId: string;
    onSelectKeyframe(id, number): void;
    onChange(field: string, value: any): void;
    onOverwrite(config: any): void;
    onNotification(e): void;
    onError(e): void;
}

export const LayerKeyframeControls = ({
    config,
    compWidth,
    compHeight,
    onChange,
    onOverwrite,
    onSelectKeyframe,
    activeKeyframe,
    activeFrame,
    composition,
    showCopyPropIds,
    onNotification,
    onError,
    compositionId
}: ILayerKeyframeControlsProps) => {
    const { type, position_inputs, start_frame, keyframes } = config;
    const disablePositionFields =
        config.position === OVERLAY_POSITION_TYPES.MT_QUAD ||
        config.position === OVERLAY_POSITION_TYPES.QUAD
            ? true
            : false;
    const relativeFrame = activeFrame - start_frame;
    const [showTimingDropdown, setShowTimingDropdown] = useState(false);
    const timingToggleRef = useRef();

    let hasKeyframes = false;

    for (const key in keyframes) {
        if (config.keyframes.hasOwnProperty(key) && key !== 'opacity') {
            const effectKeyframes = keyframes[key];
            if (effectKeyframes.length > 0) {
                hasKeyframes = true;
                break;
            }
        }
    }

    const onAddKeyframe = (field, keyframe) => {
        const updated = addKeyframe(config.keyframes, relativeFrame, field, keyframe);
        onChange('keyframes', updated);
    };

    const onUpdateInterpolation = (v) => {
        onChange('keyframe_interpolation', v);
    };

    const onValueChange = (field, index, value) => {
        const updated = changeKeyframeValue(config.keyframes, field, index, value);
        onChange('keyframes', updated);
    };

    const onClearKeyframes = (field) => {
        const updated = clearKeyframes(config.keyframes, field);
        onChange('keyframes', updated);
    };

    const jumpToKeyframe = (keyframe) => {
        const frameNumber = keyframe.relativeFrame + config.start_frame;
        onSelectKeyframe(keyframe.id, frameNumber);
    };

    const xUnit = config.position_inputs['xUnit'] || LAYOUT_UNITS.PIXELS;
    const yUnit = config.position_inputs['yUnit'] || LAYOUT_UNITS.PIXELS;
    const widthUnit = config.position_inputs['widthUnit'] || LAYOUT_UNITS.PIXELS;
    const heightUnit = config.position_inputs['heightUnit'] || LAYOUT_UNITS.PIXELS;
    const anchorXUnit = config.position_inputs['anchorXUnit'] || LAYOUT_UNITS.PIXELS;
    const anchorYUnit = config.position_inputs['anchorYUnit'] || LAYOUT_UNITS.PIXELS;

    const deleteKeyframe = (field, index) => {
        const kId = config.keyframes[field][index].id;
        if (kId === activeKeyframe) {
            onSelectKeyframe(null, null);
        }
        const updated = removeKeyframe(config.keyframes, field, index);
        onChange('keyframes', updated);
    };

    const hideInterpolation = true;
    const interpolation = (
        <>
            <Button
                buttonRef={timingToggleRef}
                customStyles={{
                    position: 'absolute',
                    top: `0px`,
                    right: `0px`
                }}
                onClick={() => setShowTimingDropdown(!showTimingDropdown)}
                style='subtle'>
                {ICON_CLOCK}
            </Button>
            <ImposiumDropdown
                position='topright'
                onOutsideClick={() => setShowTimingDropdown(false)}
                show={showTimingDropdown}
                toggleRef={timingToggleRef}>
                <div className='keyframe-interpolation'>
                    <h1>{timeline.interpolationTitle}</h1>
                    <p>{timeline.interpolationBody}</p>
                    <SelectField
                        labelPosition='top'
                        onChange={onUpdateInterpolation}
                        label={null}
                        options={LAYER_KEYFRAME_INTERPOLATION_OPTIONS}
                        value={config.keyframe_interpolation}></SelectField>
                    <p>{timeline.interpolationContext[config.keyframe_interpolation]}</p>
                </div>
            </ImposiumDropdown>
        </>
    );

    const onDefaultValueChange = (key, value) => {
        const newObj = { ...config[key], ...value };
        onChange(key, newObj);
    };

    const onUnitChange = (posInputs, k) => {
        const newConfig = { ...config };
        const newInputs = { ...config['position_inputs'], ...posInputs };
        newConfig.position_inputs = newInputs;
        newConfig.keyframes = k;
        onOverwrite(newConfig);
    };

    const getCopyPropIdButtons = (prefix, keys: string | string[]) => {
        if (Array.isArray(keys)) {
            const overrideObj: any = {};
            for (const key of keys) {
                overrideObj[key] = getCopyPropIdButton(
                    true,
                    compositionId,
                    config.id,
                    `${prefix}.${key}`,
                    onNotification,
                    onError
                );
            }
            return overrideObj;
        } else {
            return getCopyPropIdButton(
                true,
                compositionId,
                config.id,
                `${prefix}.${keys}`,
                onNotification,
                onError
            );
        }
    };

    let positionControls;
    const positionKeyframes = config?.keyframes?.position || [];
    const scaleKeyframes = config?.keyframes?.scale || [];
    const anchorKeyframes = config?.keyframes?.anchor || [];
    const opacityKeyframes = config?.keyframes?.opacity || [];
    const volumeKeyframes = config?.keyframes?.volume || [];

    if (type !== COMPOSITION_LAYER_TYPES.AUDIO) {
        switch (config.position) {
            case OVERLAY_POSITION_TYPES.RECT:
                positionControls = (
                    <>
                        <KeyframeController
                            disableKeyframes={true}
                            config={config}
                            compWidth={compWidth}
                            compHeight={compHeight}
                            field={'dimensions'}
                            label={'Dimensions'}
                            labelOverrides={
                                showCopyPropIds
                                    ? getCopyPropIdButtons('position_inputs', [`width`, `height`])
                                    : null
                            }
                            relativeFrame={relativeFrame}
                            type={KEYFRAME_CONTROLLER_TYPES.COMPOUND}
                            keys={['width', 'height']}
                            units={[widthUnit, heightUnit]}
                            onDefaultValueChange={onDefaultValueChange}
                            onUnitChange={onUnitChange}
                            onSelectKeyframe={jumpToKeyframe}
                            defaultValue={{
                                width: position_inputs.width,
                                height: position_inputs.height
                            }}
                            keyframes={[]}
                            showFitToComp={hasKeyframes}
                            composition={composition}
                        />
                        <KeyframeController
                            config={config}
                            compWidth={compWidth}
                            compHeight={compHeight}
                            disabled={disablePositionFields}
                            field={'position'}
                            label={'Position'}
                            labelOverrides={
                                showCopyPropIds && positionKeyframes.length === 0
                                    ? getCopyPropIdButtons('position_inputs', [`x`, `y`])
                                    : null
                            }
                            relativeFrame={relativeFrame}
                            type={KEYFRAME_CONTROLLER_TYPES.COMPOUND}
                            keys={['x', 'y']}
                            units={[xUnit, yUnit]}
                            onAddKeyframe={(k) => onAddKeyframe('position', k)}
                            onClearKeyframes={() => onClearKeyframes('position')}
                            onDeleteKeyframe={(i) => deleteKeyframe('position', i)}
                            onDefaultValueChange={onDefaultValueChange}
                            onUnitChange={onUnitChange}
                            onSelectKeyframe={jumpToKeyframe}
                            defaultValue={{
                                x: position_inputs.x,
                                y: position_inputs.y
                            }}
                            keyframes={positionKeyframes}
                            onKeyframeValueChange={(index, value) =>
                                onValueChange('position', index, value)
                            }
                        />
                        <KeyframeController
                            config={config}
                            compWidth={compWidth}
                            compHeight={compHeight}
                            disabled={disablePositionFields}
                            field={'anchor'}
                            label={'Anchor Point'}
                            labelOverrides={
                                showCopyPropIds && anchorKeyframes.length === 0
                                    ? getCopyPropIdButtons('position_inputs', [
                                          `anchorX`,
                                          `anchorY`
                                      ])
                                    : null
                            }
                            relativeFrame={relativeFrame}
                            type={KEYFRAME_CONTROLLER_TYPES.COMPOUND}
                            keys={['anchorX', 'anchorY']}
                            units={[anchorXUnit, anchorYUnit]}
                            onAddKeyframe={(k) => onAddKeyframe('anchor', k)}
                            onClearKeyframes={() => onClearKeyframes('anchor')}
                            onDeleteKeyframe={(i) => deleteKeyframe('anchor', i)}
                            onDefaultValueChange={onDefaultValueChange}
                            onUnitChange={onUnitChange}
                            onSelectKeyframe={jumpToKeyframe}
                            defaultValue={{
                                anchorX:
                                    position_inputs.anchorX === undefined
                                        ? 0
                                        : position_inputs.anchorX,
                                anchorY:
                                    position_inputs.anchorY === undefined
                                        ? 0
                                        : position_inputs.anchorY
                            }}
                            keyframes={anchorKeyframes}
                            onKeyframeValueChange={(index, value) =>
                                onValueChange('anchor', index, value)
                            }
                        />
                        <KeyframeController
                            config={config}
                            compWidth={compWidth}
                            compHeight={compHeight}
                            disabled={disablePositionFields}
                            field={'scale'}
                            label={'Scale'}
                            labelOverrides={
                                showCopyPropIds && scaleKeyframes.length === 0
                                    ? getCopyPropIdButtons('position_inputs', [`scaleX`, `scaleY`])
                                    : null
                            }
                            relativeFrame={relativeFrame}
                            type={KEYFRAME_CONTROLLER_TYPES.COMPOUND}
                            keys={['scaleX', 'scaleY']}
                            units={[LAYOUT_UNITS.PERCENT, LAYOUT_UNITS.PERCENT]}
                            onAddKeyframe={(k) => onAddKeyframe('scale', k)}
                            onClearKeyframes={() => onClearKeyframes('scale')}
                            onDeleteKeyframe={(i) => deleteKeyframe('scale', i)}
                            onUnitChange={onUnitChange}
                            onDefaultValueChange={onDefaultValueChange}
                            onSelectKeyframe={jumpToKeyframe}
                            defaultValue={{
                                scaleX:
                                    position_inputs.scaleX === undefined
                                        ? 0
                                        : position_inputs.scaleX,
                                scaleY:
                                    position_inputs.scaleY === undefined
                                        ? 0
                                        : position_inputs.scaleY
                            }}
                            keyframes={scaleKeyframes}
                            onKeyframeValueChange={(index, value) =>
                                onValueChange('scale', index, value)
                            }
                        />
                    </>
                );
                break;
            case OVERLAY_POSITION_TYPES.QUAD:
                positionControls = (
                    <QuadPositionConfig
                        config={config}
                        onChange={onChange}
                    />
                );
                break;
            case OVERLAY_POSITION_TYPES.MT_QUAD:
                positionControls = (
                    <MTQuadPositionConfig
                        config={config}
                        onChange={onChange}
                    />
                );
                break;
            default:
                positionControls = null;
        }
    }

    const volumeControls = isAudioLayer(type) ? (
        <KeyframeController
            min={0}
            max={5}
            config={config}
            compWidth={compWidth}
            compHeight={compHeight}
            disabled={false}
            field={'volume'}
            label={'Volume'}
            labelOverrides={showCopyPropIds ? getCopyPropIdButtons('effects', 'volume') : null}
            relativeFrame={relativeFrame}
            type={KEYFRAME_CONTROLLER_TYPES.NUMBER}
            onAddKeyframe={(k) => onAddKeyframe('volume', k)}
            onClearKeyframes={() => onClearKeyframes('volume')}
            onDeleteKeyframe={(i) => deleteKeyframe('volume', i)}
            onDefaultValueChange={(key, value) =>
                onDefaultValueChange('effects', { volume: value })
            }
            onSelectKeyframe={jumpToKeyframe}
            defaultValue={config.effects?.volume || 1}
            keyframes={volumeKeyframes}
            onKeyframeValueChange={(index, value) => onValueChange('volume', index, value)}
        />
    ) : null;

    const opacityControls =
        type !== COMPOSITION_LAYER_TYPES.AUDIO ? (
            <KeyframeController
                config={config}
                compWidth={compWidth}
                compHeight={compHeight}
                disabled={false}
                field={'opacity'}
                label={'Opacity'}
                labelOverrides={showCopyPropIds ? getCopyPropIdButtons('effects', 'opacity') : null}
                relativeFrame={relativeFrame}
                type={KEYFRAME_CONTROLLER_TYPES.NUMBER}
                onAddKeyframe={(k) => onAddKeyframe('opacity', k)}
                onClearKeyframes={() => onClearKeyframes('opacity')}
                onDeleteKeyframe={(i) => deleteKeyframe('opacity', i)}
                onDefaultValueChange={(key, value) =>
                    onDefaultValueChange('effects', { opacity: value })
                }
                onSelectKeyframe={jumpToKeyframe}
                defaultValue={config.effects?.opacity || 1}
                keyframes={opacityKeyframes}
                onKeyframeValueChange={(index, value) => onValueChange('opacity', index, value)}
            />
        ) : null;

    return (
        <div className='layer-keyframe-controls'>
            {positionControls}
            {opacityControls}
            {volumeControls}
            {!hideInterpolation && interpolation}
        </div>
    );
};
