import { NumberField } from '@imposium-hub/components';

export const QuadPositionConfig = ({ config, onChange }) => {
    const { position_inputs } = config;

    const update = (key, subKey, value) => {
        const newConfig = { ...position_inputs };
        const newSubConfig = { ...newConfig[key] };
        newSubConfig[subKey] = value;
        newConfig[key] = newSubConfig;
        onChange('position_inputs', newConfig);
    };

    return (
        <>
            <div className='keyframe-controller'>
                <div className='col-left'></div>
                <div className='col-right'>
                    <div className='keyframe-controller-label'>Top Left</div>
                </div>
                <div className={`keyframe-controls quad`}>
                    <NumberField
                        width='135px'
                        label='X'
                        value={position_inputs['top_left']['x']}
                        onChange={(v) => update('top_left', 'x', v)}
                    />
                    <NumberField
                        width='135px'
                        label='Y'
                        value={position_inputs['top_left']['y']}
                        onChange={(v) => update('top_left', 'y', v)}
                    />
                </div>
            </div>
            <div className='keyframe-controller'>
                <div className='col-left'></div>
                <div className='col-right'>
                    <div className='keyframe-controller-label'>Top Right</div>
                </div>
                <div className={`keyframe-controls quad`}>
                    <NumberField
                        width='135px'
                        label='X'
                        value={position_inputs['top_right']['x']}
                        onChange={(v) => update('top_right', 'x', v)}
                    />
                    <NumberField
                        width='135px'
                        label='Y'
                        value={position_inputs['top_right']['y']}
                        onChange={(v) => update('top_right', 'y', v)}
                    />
                </div>
            </div>
            <div className='keyframe-controller'>
                <div className='col-left'></div>
                <div className='col-right'>
                    <div className='keyframe-controller-label'>Bottom Right</div>
                </div>
                <div className={`keyframe-controls quad`}>
                    <NumberField
                        width='135px'
                        label='X'
                        value={position_inputs['bottom_right']['x']}
                        onChange={(v) => update('bottom_right', 'x', v)}
                    />
                    <NumberField
                        width='135px'
                        label='Y'
                        value={position_inputs['bottom_right']['y']}
                        onChange={(v) => update('bottom_right', 'y', v)}
                    />
                </div>
            </div>
            <div className='keyframe-controller'>
                <div className='col-left'></div>
                <div className='col-right'>
                    <div className='keyframe-controller-label'>Bottom Left</div>
                </div>
                <div className={`keyframe-controls quad`}>
                    <NumberField
                        width='135px'
                        label='X'
                        value={position_inputs['bottom_left']['x']}
                        onChange={(v) => update('bottom_left', 'x', v)}
                    />
                    <NumberField
                        width='135px'
                        label='Y'
                        value={position_inputs['bottom_left']['y']}
                        onChange={(v) => update('bottom_left', 'y', v)}
                    />
                </div>
            </div>
        </>
    );
};
