import * as React from 'react';
import BaseVideoUploader from '../../services/BaseVideoUploader';
import { Spinner, DeterminateLoader, Button } from '@imposium-hub/components';
import { timeline as copy } from '../../constants/copy';

interface IChangeBaseVideoProps {
    onComplete(v: any): void;
    icon: any;
}

interface IChangeBaseVideoState {
    uploading: boolean;
    processing: boolean;
    uploadProgress: number;
    inputKey: number;
}

class ChangeBaseVideo extends React.PureComponent<IChangeBaseVideoProps, IChangeBaseVideoState> {
    private input: any;

    private uploader: BaseVideoUploader;

    constructor(props) {
        super(props);

        this.state = {
            uploading: false,
            processing: false,
            inputKey: Date.now(),
            uploadProgress: 0
        };

        this.uploader = new BaseVideoUploader({
            onUploadStart: () => {
                this.setState({ uploading: true, uploadProgress: 0 });
            },
            onProgress: (p) => {
                this.setState({ uploadProgress: p });
            },
            onProcessStart: () => {
                this.setState({ uploading: false, processing: true });
            },
            onComplete: (v) => {
                this.setState({ processing: false, uploading: false });
                this.props.onComplete(v);
            },
            onError: () => {
                this.setState({ processing: false, uploading: false, uploadProgress: 0 });
            }
        });

        this.input = React.createRef();
    }

    private fileUploaded(e) {
        const files = e.target.files;

        this.setState(
            {
                inputKey: Date.now()
            },
            () => {
                this.uploader.uploadBaseVideo(files[0]);
            }
        );
    }

    public onClick(e) {
        const input = this.input.current;
        const { uploading, processing } = this.state;

        if (input && !uploading && !processing) {
            input.click();
        }
    }

    public renderInterface() {
        const { processing, uploading, uploadProgress } = this.state;

        if (processing) {
            return (
                <>
                    <div className='spinner'>
                        <Spinner />
                    </div>
                </>
            );
        } else if (uploading) {
            return (
                <>
                    <div className='loader'>
                        <DeterminateLoader progress={uploadProgress} />
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <div className='icon'>
                        <Button
                            onClick={(e) => this.onClick(e)}
                            tooltip={copy.tooltipEditBase}
                            style='subtle'>
                            {this.props.icon}
                        </Button>
                    </div>
                </>
            );
        }
    }

    public render() {
        return (
            <div className='change-base-video'>
                {this.renderInterface()}
                <input
                    key={this.state.inputKey}
                    style={{ display: 'none' }}
                    type='file'
                    accept='video/*'
                    ref={this.input}
                    onChange={(e) => this.fileUploaded(e)}
                />
            </div>
        );
    }
}

export default ChangeBaseVideo;
