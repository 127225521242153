import React from 'react';
import SplitPane from 'react-split-pane';
import { MIN_CODE_PANEL_WIDTH } from '../../../constants/editor';
import { CodePanel } from './CodePanel';

interface ICodePanelsProps {
    asset: any;
    paneWidth: any;
    codeChange(field: any, value: any);
}

export class CodePanels extends React.PureComponent<ICodePanelsProps> {
    private evtHandlers: any;

    private SECOND_PANE_MIN_WIDTH = '90px';

    constructor(props) {
        super(props);

        this.state = {
            allowResize: true
        };
        this.evtHandlers = {
            html: (v) => this.updateCode('html', v),
            js: (v) => this.updateCode('javascript', v),
            css: (v) => this.updateCode('css', v)
        };
    }

    private updateCode(field, value) {
        this.props.codeChange(field, value);
    }

    public render() {
        const {
            asset: {
                data: { html, css, javascript }
            },
            paneWidth
        } = this.props;
        return (
            <SplitPane
                defaultSize={paneWidth}
                minSize={MIN_CODE_PANEL_WIDTH}
                split='vertical'
                allowResize={true}
                primary='first'
                className='code-panels'>
                <CodePanel
                    type='html'
                    code={html}
                    onChange={this.evtHandlers.html}
                />
                <SplitPane
                    defaultSize={paneWidth}
                    minSize={this.SECOND_PANE_MIN_WIDTH}
                    split='vertical'
                    pane2Style={{ minWidth: this.SECOND_PANE_MIN_WIDTH }}
                    allowResize={true}>
                    <CodePanel
                        type='css'
                        code={css}
                        onChange={this.evtHandlers.css}
                    />
                    <CodePanel
                        type='javascript'
                        code={javascript}
                        onChange={this.evtHandlers.js}
                    />
                </SplitPane>
            </SplitPane>
        );
    }
}
