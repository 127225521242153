import * as React from 'react';
import { XYCoord, useDragLayer } from 'react-dnd';
import { ICON_TIMES } from '../../constants/icons';
import { LAYER_DND_TYPES } from '../../constants/timeline';

const layerStyles: React.CSSProperties = {
    position: 'fixed',
    pointerEvents: 'none',
    zIndex: 100,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%'
};

const getItemStyles = (initialOffset: XYCoord | null, currentOffset: XYCoord | null) => {
    if (!initialOffset || !currentOffset) {
        return {
            display: 'none'
        };
    }

    const { x, y } = currentOffset;

    const transform = `translate(${x}px, ${y}px)`;
    return {
        transform,
        WebkitTransform: transform
    };
};

export const CustomDragLayer: React.FC = (props) => {
    const { itemType, isDragging, initialOffset, currentOffset } = useDragLayer((monitor) => ({
        item: monitor.getItem(),
        itemType: monitor.getItemType(),
        initialOffset: monitor.getInitialSourceClientOffset(),
        currentOffset: monitor.getSourceClientOffset(),
        isDragging: monitor.isDragging()
    }));

    const renderItem = () => {
        switch (itemType) {
            case LAYER_DND_TYPES.ANCHOR:
                return <div className='layer-anchor-drag-handle'>{ICON_TIMES}</div>;
            default:
                return null;
        }
    };

    if (!isDragging) {
        return null;
    }
    return (
        <div style={layerStyles}>
            <div style={getItemStyles(initialOffset, currentOffset)}>{renderItem()}</div>
        </div>
    );
};
