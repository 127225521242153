import * as React from 'react';
import { Spinner } from '@imposium-hub/components';

const GlobalLoading = () => (
    <div className='global-loading'>
        <Spinner />
    </div>
);

export default GlobalLoading;
