import * as React from 'react';
import { logError } from '../../util/notifications';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { api } from '../../constants/app';
import { LogViewer } from '@imposium-hub/components';

interface IJobLogProps {
    width: number;
    height: number;
    active?: boolean;
    jobId: string;
}

class JobLog extends React.PureComponent<IJobLogProps, undefined> {
    private evtHandlers: any;

    constructor(props) {
        super(props);

        this.evtHandlers = {
            onError: (e) => logError(e)
        };
    }

    public render() {
        const { active, jobId } = this.props;

        if (active) {
            return (
                <div className='job-log'>
                    <LogViewer
                        api={api}
                        onError={(e) => logError(e)}
                        jobId={jobId}
                    />
                </div>
            );
        } else {
            return null;
        }
    }
}

const mapDispatchToProps = (dispatch): any => {
    return bindActionCreators({}, dispatch);
};

const mapStateToProps = (state): any => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(JobLog);
