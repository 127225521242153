import { Button, SelectField } from '@imposium-hub/components';
import { useEffect, useRef, useState } from 'react';
import { fitToContainer } from '../../../util/ui';
import { MAX_ZOOM, MIN_ZOOM, ZOOM_LEVELS } from '../../../constants/editor';
import { ICON_CENTER } from '../../../constants/icons';

export const PanAndZoom = ({
    panEnabled,
    frameWidth,
    frameHeight,
    contentWidth,
    contentHeight,
    children,
    onBgClicked
}) => {
    const getCenterOffset = (z) => {
        return {
            x: (frameWidth - contentWidth * z) / 2,
            y: (frameHeight - contentHeight * z) / 2
        };
    };

    const fitDimensions = fitToContainer(
        {
            width: frameWidth,
            height: frameHeight
        },
        {
            width: contentWidth,
            height: contentHeight
        },
        {
            scaleMode: 'proportionalInside',
            allowUpscale: true,
            padding: 50
        }
    );

    const fitScale = fitDimensions.width / contentWidth;
    const [panStarted, setPanStarted] = useState(false);
    const [startPanPosition, setStartPanPosition] = useState({ x: 0, y: 0 });
    const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(fitScale);
    const [offset, setOffset] = useState(getCenterOffset(fitScale));
    const [snappedZoom, setSnappedZoom] = useState(fitScale);

    const contentRef = useRef(null);

    const selectRef = useRef(null);

    const startPan = (e) => {
        setStartPanPosition({ x: e.clientX, y: e.clientY });
        setPanStarted(true);
    };

    const endPan = (e?) => {
        // If the pan and zoom moved, it means it was a valid pan, and we should prevent on the event from bubbling
        if (dragOffset && e) {
            e.preventDefault();
            e.stopPropagation();
        }
        clearPan();
    };

    const clearPan = () => {
        setStartPanPosition(null);
        setOffset({ x: offset.x + getDragOffset().x, y: offset.y + getDragOffset().y });
        setDragOffset(null);
        setPanStarted(false);
    };

    const mouseMove = (e) => {
        // If it's panning move the dragOffset
        if (panStarted) {
            const x = e.clientX - startPanPosition.x;
            const y = e.clientY - startPanPosition.y;
            setDragOffset({ x, y });
        }

        // set the mouse position offset
    };

    const onWheel = (e) => {
        // Compute the x and y of the mouse relative to the position of the container

        const contentRect = contentRef.current.getBoundingClientRect();

        const relativeX = e.pageX - contentRect.x;
        const relativeY = e.pageY - contentRect.y;

        // Figure out where the cursor is relative to the content
        const xAnchor = relativeX / contentRect.width;
        const yAnchor = relativeY / contentRect.height;

        const delta = e.deltaY < 0 ? 0.1 : -0.1;

        // Compute the new zoom level
        let newZoom = zoom + delta;
        if (newZoom < MIN_ZOOM) newZoom = MIN_ZOOM;
        if (newZoom > MAX_ZOOM) newZoom = MAX_ZOOM;

        setZoom(newZoom);

        // Figure out which snapped zoom level we're closest to
        const newSnappedZoom = ZOOM_LEVELS.reduce((prev, curr) => {
            return Math.abs(curr - newZoom) < Math.abs(prev - newZoom) ? curr : prev;
        });

        if (newSnappedZoom !== snappedZoom) {
            // Figure out the delta between the current anchor, and the new anchor
            const newXAnchor = contentWidth * newSnappedZoom * xAnchor;
            const newYAnchor = contentHeight * newSnappedZoom * yAnchor;
            const deltaX = newXAnchor - relativeX;
            const deltaY = newYAnchor - relativeY;

            const newOffsetX = offset.x - deltaX;
            const newOffsetY = offset.y - deltaY;

            setSnappedZoom(newSnappedZoom);
            setOffset({ x: newOffsetX, y: newOffsetY });
        }
    };

    useEffect(() => {
        if (panEnabled) {
            window.addEventListener('mousedown', startPan);
            window.addEventListener('mousemove', mouseMove);
            window.addEventListener('mouseup', endPan);
        } else {
            window.removeEventListener('mousedown', startPan);
            window.removeEventListener('mousemove', mouseMove);
            window.removeEventListener('mouseup', endPan);
            if (panStarted) {
                clearPan();
            }
        }
        return () => {
            window.removeEventListener('mousedown', startPan);
            window.removeEventListener('mousemove', mouseMove);
            window.removeEventListener('mouseup', endPan);
        };
    }, [panEnabled, startPan, endPan]);

    const wrapperStyle: any = {
        cursor: panEnabled ? 'grab' : 'default',
        width: `${frameWidth}px`,
        height: `${frameHeight}px`,
        overflow: 'hidden',
        position: 'absolute'
    };

    const getDragOffset = () => {
        return dragOffset || { x: 0, y: 0 };
    };

    const top = getDragOffset().y + offset.y;
    const left = getDragOffset().x + offset.x;

    const contentStyle: any = {
        width: `${contentWidth * snappedZoom}px`,
        height: `${contentHeight * snappedZoom}px`,
        top: `${top}px`,
        left: `${left}px`
    };

    const innerScaleStyle: any = {
        transform: `scale(${snappedZoom})`,
        width: `${contentWidth}px`,
        height: `${contentHeight}px`
    };

    const getZoomOptions = () => {
        const zoomOptions = [
            {
                label: 'Fit',
                value: fitScale
            }
        ];

        for (const opt of ZOOM_LEVELS) {
            zoomOptions.push({
                label: `${Math.round(opt * 100)}%`,
                value: opt
            });
        }

        return zoomOptions;
    };

    const setZoomAndCenter = (value) => {
        setSnappedZoom(value);
        setOffset(getCenterOffset(value));
    };

    return (
        <div
            className='pan-and-zoom'
            onWheel={onWheel}
            style={wrapperStyle}>
            <div
                className='bg-click-handler'
                onClick={onBgClicked}
            />
            <div
                ref={contentRef}
                className='pan-and-zoom-content'
                style={contentStyle}>
                <div
                    className='pan-and-zoom-scale'
                    style={innerScaleStyle}>
                    {children}
                </div>
            </div>
            <div className='pan-and-zoom-controls'>
                <SelectField
                    width='50px'
                    value={snappedZoom}
                    onChange={setZoomAndCenter}
                    options={getZoomOptions()}
                    selectRef={selectRef}
                />
                <Button
                    style=''
                    size='small'
                    onClick={() => setZoomAndCenter(fitScale)}>
                    {ICON_CENTER}
                </Button>
            </div>
        </div>
    );
};
