import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IVideoFile } from '../../constants/snippets';
import { VideoPlayer } from '@imposium-hub/components';
import BaseVideoPrompt from '../BaseVideoPrompt';
import { SCENE_TYPES } from '../../constants/story';
import { ITimelineState } from '../../redux/reducers/timeline';
import { IProject } from '../../redux/reducers/project';

interface ITimelineVideoPlayerProps {
    videoFile: IVideoFile;
    storyType: string;
    timeline: ITimelineState;
    height: number;
    active: boolean;
    width: number;
    project: IProject;
}

class TimelineVideoPlayer extends React.PureComponent<ITimelineVideoPlayerProps> {
    private frameTimeout: any;

    private videoPlayer: any;

    constructor(props) {
        super(props);

        this.state = {};

        this.videoPlayer = React.createRef();
    }

    public componentDidMount() {
        this.setFrame();
    }

    public componentWillUnmount() {
        clearTimeout(this.frameTimeout);
    }

    public componentDidUpdate() {
        this.setFrame();
    }

    public getTimecode() {
        const {
            timeline: { activeFrame },
            videoFile: { rate },
            project: { compositionId }
        } = this.props;
        const actFrame = activeFrame[compositionId] ? activeFrame[compositionId] : 0;
        const time = Math.max(0, (actFrame - 0.1) / rate);
        return time;
    }

    public getPlayer() {
        const { videoFile, width, height, storyType } = this.props;

        if (videoFile) {
            return (
                <VideoPlayer
                    ref={this.videoPlayer}
                    active={true}
                    url={videoFile.url}
                    width={width}
                    height={height}
                    maxWidth={videoFile.width}
                    hideDefaultControls={true}
                    allowManualScale={true}
                    maxHeight={videoFile.height}
                />
            );
        } else if (storyType === SCENE_TYPES.VIDEO) {
            return <BaseVideoPrompt />;
        } else {
            return null;
        }
    }

    public render() {
        const { active } = this.props;

        if (active) {
            return <div className='media-player'>{this.getPlayer()}</div>;
        } else {
            return null;
        }
    }

    public setFrame() {
        clearTimeout(this.frameTimeout);
        this.frameTimeout = setTimeout(() => {
            const player = this.videoPlayer.current;
            if (player) {
                const time = this.getTimecode() || 0;
                player.setTime(time);
            }
        }, 50);
    }
}

const mapDispatchToProps = (dispatch): any => {
    return bindActionCreators({}, dispatch);
};

const mapStateToProps = (state): any => {
    return { timeline: state.timeline, project: state.project };
};

export default connect(mapStateToProps, mapDispatchToProps)(TimelineVideoPlayer);
