import * as React from 'react';
import Draggable from 'react-draggable';

interface IPlaybackBarProps {
    onPlayheadDrag(frame: number): void;
    onPlayheadDragStart(): void;
    onPlayheadDragEnd(): void;
    totalFrames: number;
    currentFrame: number;
    inFrame?: number;
    outFrame?: number;
    trimMode?: boolean;
    width?: number;
}

interface IPlaybackBarState {
    barMouseDown: boolean;
}

const iconOut = (
    <svg
        version='1.1'
        id='Layer_1'
        x='0px'
        y='0px'
        viewBox='0 0 100 100'>
        <polygon points='100,0 0,100 0,0 ' />
    </svg>
);

const iconIn = (
    <svg
        version='1.1'
        id='Layer_1'
        x='0px'
        y='0px'
        viewBox='0 0 100 100'>
        <polygon points='100,100 0,0 100,0 ' />
    </svg>
);

export class PlaybackBar extends React.Component<IPlaybackBarProps, IPlaybackBarState> {
    private frameWidth: number = 5;

    constructor(p) {
        super(p);

        this.state = {
            barMouseDown: false
        };
    }

    private getScale(): number {
        const { width, totalFrames } = this.props;
        const scale =
            width !== undefined && totalFrames !== 0 ? width / (this.frameWidth * totalFrames) : 0;

        return scale;
    }

    private getInMarker() {
        const { inFrame, trimMode } = this.props;

        if (inFrame !== undefined && trimMode === true) {
            const scale = this.getScale();
            const markerStyle = {
                left: this.frameWidth * inFrame * scale
            };

            return (
                <div
                    className='cut-marker in'
                    style={markerStyle}>
                    <div className='handle'>{iconIn}</div>
                </div>
            );
        }

        return null;
    }

    private getOutMarker() {
        const { outFrame, trimMode } = this.props;

        if (outFrame !== undefined && trimMode === true) {
            const scale = this.getScale();
            const markerStyle = {
                left: this.frameWidth * outFrame * scale
            };

            return (
                <div
                    className='cut-marker out'
                    style={markerStyle}>
                    <div className='handle'>{iconOut}</div>
                </div>
            );
        }
        return null;
    }

    public onPlayheadDrag(e, ui) {
        const { currentFrame } = this.props;
        const scale = this.getScale();
        const x = ui.x;
        const trueFrameWidth = this.frameWidth * scale;
        const frame = Math.round(x / trueFrameWidth);

        if (frame !== currentFrame) {
            this.props.onPlayheadDrag(frame);
        }
    }

    public onMouseMove(e) {
        if (this.state.barMouseDown) {
            const rect = e.target.getBoundingClientRect();
            this.setFrameFromDrag(rect, e.clientX);
        }
    }

    public onMouseUp(e) {
        this.setState({
            barMouseDown: false
        });
    }

    public onMouseDown(e) {
        this.setState({
            barMouseDown: true
        });
        const rect = e.target.getBoundingClientRect();
        this.setFrameFromDrag(rect, e.clientX);
    }

    private setFrameFromDrag(rect, x) {
        const divX = rect.x;
        const offset = x - divX;
        const scale = this.getScale();
        const trueFrameWidth = this.frameWidth * scale;
        const frame = Math.round(offset / trueFrameWidth);
        this.props.onPlayheadDrag(frame);
    }

    public render() {
        const { currentFrame } = this.props;
        const scale = this.getScale();
        const playheadX = this.frameWidth * currentFrame * scale;
        const position = {
            x: playheadX,
            y: 0
        };

        return (
            <div className='playback-bar'>
                <div
                    className='bar'
                    onMouseUp={(e) => this.onMouseUp(e)}
                    onMouseOut={(e) => this.onMouseUp(e)}
                    onMouseDown={(e) => this.onMouseDown(e)}
                    onMouseMove={(e) => this.onMouseMove(e)}
                />

                <Draggable
                    axis='x'
                    position={position}
                    onDrag={(e, u) => this.onPlayheadDrag(e, u)}
                    onStart={this.props.onPlayheadDragStart}
                    onStop={this.props.onPlayheadDragEnd}
                    bounds='parent'>
                    <div className='preview-playhead'>
                        <div className='handle' />
                    </div>
                </Draggable>
                {this.getInMarker()}
                {this.getOutMarker()}
            </div>
        );
    }
}

export default PlaybackBar;
