import { LAYER_SOURCE_STATUSES } from '../../../constants/editor';
import MediaController from '../../../services/MediaController';
import MediaLayerSource from './MediaLayerSource';

export default class ImageLayerSource extends MediaLayerSource {
    public async loadLayerSource(): Promise<void | any> {
        const timer = Date.now();

        const { source } = this.props;
        const url = source.url;
        let imageNode;

        this.logInfo(`Load Layer Source`);
        try {
            if (await MediaController.preloadImage(url)) {
                imageNode = await MediaController.preloadImage(url);
            }
        } catch (e) {
            return Promise.reject(e);
        }

        const sourceTimer = Date.now() - timer;
        this.logInfo(`Layer source loaded in ${sourceTimer}ms`);

        return new Promise<void>((resolve) => {
            this.logInfo(`Create Media Sprite`);
            this.clear();

            const { naturalWidth, naturalHeight } = imageNode;

            this.createMediaSprite(imageNode);

            this.setState({
                sourceWidth: naturalWidth,
                sourceHeight: naturalHeight,
                status: LAYER_SOURCE_STATUSES.READY
            });
            resolve();
        });
    }
}
