import * as React from 'react';
import { SliderField } from '@imposium-hub/components';
import { ICutEffects } from '../constants/snippets';
import { fields as copy } from '../constants/copy';
import { ASSET_TYPES } from '../constants/story';
import { logError } from '../util/notifications';
import { api } from '../constants/app';
import AssetField from './assets/AssetField';

interface ICutEffectConfigProps {
    storyId: string;
    config: ICutEffects;
    onChange(e: any): void;
}

class CutEffectConfig extends React.PureComponent<ICutEffectConfigProps, undefined> {
    private evtHandlers: any;

    constructor(props) {
        super(props);

        this.evtHandlers = {
            inputs: {
                saturation: (v) => this.update('saturation', v),
                hue: (v) => this.update('hue', v),
                brightness: (v) => this.update('brightness', v),
                lut: (v) => this.update('lut_asset_id', v ? v.id : null),
                assetError: (m) => {
                    logError(m);
                }
            }
        };
    }

    private update(key, value) {
        const newConfig = { ...this.props.config };
        newConfig[key] = value;

        if (value === '' || value === null) {
            delete newConfig[key];
        }

        this.props.onChange(newConfig);
    }

    public render() {
        const { config, storyId } = this.props;

        return (
            <>
                <SliderField
                    label={copy.effectConfig.saturation}
                    width='50%'
                    min={0}
                    max={1}
                    step={0.01}
                    value={config['saturation']}
                    defaultValue={1}
                    onChange={this.evtHandlers.inputs.saturation}
                />
                <SliderField
                    label={copy.effectConfig.hue}
                    width='50%'
                    min={0}
                    max={360}
                    step={1}
                    value={config['hue']}
                    defaultValue={0}
                    onChange={this.evtHandlers.inputs.hue}
                />
                <SliderField
                    label={copy.effectConfig.brightness}
                    width='50%'
                    min={-10}
                    max={10}
                    step={0.05}
                    value={config['brightness']}
                    defaultValue={0}
                    onChange={this.evtHandlers.inputs.brightness}
                />
                <AssetField
                    api={api}
                    storyId={storyId}
                    label={copy.overlayConfig.lut}
                    width='50%'
                    tooltip={copy.overlayConfig.tooltipLut}
                    onChange={this.evtHandlers.inputs.lut}
                    onError={this.evtHandlers.inputs.assetError}
                    accepts={ASSET_TYPES.LUT}
                    assetId={config['lut_asset_id']}
                />
            </>
        );
    }
}

export default CutEffectConfig;
