import { Modal, Section } from '@imposium-hub/components';
import { DATASET_ERRORS } from '../../constants/dataset';

export const DatasetErrorModal = ({ onClose, errors, rowIndex }) => {
    const getErrorCopy = (error) => {
        const { type, data } = error;
        switch (type) {
            case DATASET_ERRORS.ASSET_TOO_SHORT:
                return `Asset "${data.assetName}" pulled in for layer "${data.layerName}" with the tags "${data.tags}" is ${data.difference} frames too short.`;
            case DATASET_ERRORS.WRONG_TYPE:
                return `The file at "${data.url}" pulled in for variable "${data.varName}" has the type of "${data.type}", which is incompatible with the variable type "${data.varType}"`;
            case DATASET_ERRORS.INCORRECT_VALUE_ENUM:
                return `Invalid value "${data.value}" passed into list variable "${data.variableId}".`;
            case DATASET_ERRORS.MISSING_REQUIRED_VALUE:
                return `Missing value for required variable "${data.variableId}".`;
            case DATASET_ERRORS.NO_ASSET_FOUND:
                return `No ${data.layerType} asset found for layer "${data.layerName}" when using the tags "${data.tags}".`;
            case DATASET_ERRORS.BROKEN_URL:
                return `The URL "${data.url}" passed into the "${data.header}" column is broken, and responded with "${data.status} ${data.statusText}"`;
        }
    };

    return (
        <Modal
            onRequestClose={onClose}
            style={{
                width: '500px',
                height: '70%',
                top: '10%',
                left: 'calc((100% - 600px) / 2)'
            }}
            isOpen={true}>
            <Section title={`Row ${rowIndex + 1} Errors`}>
                <ul>
                    {errors &&
                        errors.map((e, i) => {
                            return (
                                <li
                                    key={i}
                                    className='dataset-error'>
                                    {getErrorCopy(e)}
                                </li>
                            );
                        })}
                </ul>
            </Section>
        </Modal>
    );
};
