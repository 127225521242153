import * as React from 'react';
import { ROW_HEIGHT, FRAME_WIDTH } from '../../constants/timeline';
import { IAudioOverlay } from '../../constants/snippets';
import { getXPosFromFrame } from '../../util/timeline';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ErrorLayer from './ErrorLayer';
import { updateTimelineState } from '../../redux/actions/timeline';

interface IAudioOverlayProps {
    active: boolean;
    config: IAudioOverlay;
    rate: number;
    total: number;
    frames: number;
    index: number;
    scale: number;
    updateTimelineState(state: any): any;
    errors: any;
}

class AudioOverlay extends React.PureComponent<IAudioOverlayProps> {
    constructor(props) {
        super(props);

        this.state = {};
    }

    public getOverlayX(): number {
        const {
            scale,
            config: { offset },
            rate
        } = this.props;
        const offsetFrames = Math.ceil(offset * rate);

        return getXPosFromFrame(offsetFrames, scale);
    }

    public getOverlayWidth(): number {
        const {
            scale,
            config: { offset },
            frames
        } = this.props;
        return FRAME_WIDTH * (frames - offset) * scale;
    }

    private getOverlayY() {
        const { index, total } = this.props;
        return ROW_HEIGHT * (total - index - 1);
    }

    private selectOverlay() {
        const {
            config: { id }
        } = this.props;

        this.props.updateTimelineState({
            activeTimelineOverlay: id,
            activeTimelineCut: null
        });
    }

    private renderErrorLayer(scale, overlay) {
        const {
            errors: { audios }
        } = this.props;
        for (const error of audios) {
            if (error.overlay_id === overlay) {
                return <ErrorLayer scale={scale} />;
            }
        }
        return false;
    }

    public render() {
        const {
            active,
            config: { id }
        } = this.props;
        const activeClass = active ? 'selected' : '';
        const x = this.getOverlayX();
        const w = this.getOverlayWidth();
        const y = this.getOverlayY();

        const overlayStyle = {
            left: `${x}px`,
            top: `${y}px`,
            height: `${ROW_HEIGHT}px`,
            width: `${w}px`
        };

        return (
            <>
                <div
                    className={`audio-overlay ${activeClass}`}
                    style={overlayStyle}
                    onClick={() => this.selectOverlay()}>
                    <div className='cut-border border-top' />
                    <div className='cut-border border-right' />
                    <div className='cut-border border-bottom' />
                    <div className='cut-border border-left' />
                    {this.renderErrorLayer(ROW_HEIGHT, id)}
                </div>
            </>
        );
    }
}

const mapDispatchToProps = (dispatch): any => {
    return bindActionCreators({ updateTimelineState }, dispatch);
};

const mapStateToProps = (state): any => {
    return {
        errors: state.errors
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AudioOverlay);
