import * as React from 'react';
import { logNotification, logError } from '../../util/notifications';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { api } from '../../constants/app';
import { IStory } from '../../constants/snippets';
import { IProject } from '../../redux/reducers/project';
import { StoryPreviewer } from '@imposium-hub/components';
import { updateEditorConfig } from '../../redux/actions/editor';

interface ICompositionPreviewPlayerProps {
    assets: any;
    project: IProject;
    story: IStory;
    width: number;
    height: number;
    active?: boolean;
    updateEditorConfig(c): void;
}

interface ICompositionPreviewPlayerState {
    compAssets: any[];
    aeAssets: any[];
}

class CompositionPreviewPlayer extends React.PureComponent<
    ICompositionPreviewPlayerProps,
    ICompositionPreviewPlayerState
> {
    private evtHandlers: any;

    constructor(props) {
        super(props);

        this.evtHandlers = {
            onNotification: (e) => logNotification(e),
            onError: (e) => logError(e)
        };

        this.state = {
            compAssets: [],
            aeAssets: []
        };
    }

    public componentDidMount(): void {
        this.checkForCompDropdown();
    }

    public componentDidUpdate(prevProps): void {
        const { assets } = this.props;
        if (assets !== prevProps.assets) {
            this.checkForCompDropdown();
        }
    }

    private checkForCompDropdown() {
        const {
            story: { id }
        } = this.props;

        api.getAssets({ type: 'video_composition' }, id)
            .then((res) => this.setState({ compAssets: res.assets }))
            .catch((e) => {
                console.error('Error pulling comp IDs for composition dropdown');
            });

        api.getAssets({ type: 'after_effects' }, id)
            .then((res) => this.setState({ aeAssets: res.assets }))
            .catch((e) => {
                console.error('Error pulling After Effects IDs for composition dropdown');
            });
    }

    private getCompOptions() {
        const { compAssets, aeAssets } = this.state;
        const newOpts = [];
        for (const comp of compAssets) {
            newOpts.push({ label: comp.name, value: comp.id });
        }
        for (const ae of aeAssets) {
            newOpts.push({ label: ae.name, value: ae.id });
        }

        return newOpts;
    }

    public render() {
        const {
            active,
            story,
            project: { actId, storyId, compositionId }
        } = this.props;
        const variables = story.acts[actId].inventory;
        const display = active ? 'block' : 'none';
        const style = {
            display
        };

        return (
            <div style={style}>
                <StoryPreviewer
                    compositionOptions={this.getCompOptions()}
                    compositionId={compositionId}
                    allowCompOverride={true}
                    api={api}
                    useWorkingCopy={true}
                    storyId={storyId}
                    variables={variables}
                    onNotification={this.evtHandlers.onNotification}
                    onError={this.evtHandlers.onError}
                    updateEditorConfig={(i) => this.props.updateEditorConfig(i)}
                />
                ;
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            updateEditorConfig
        },
        dispatch
    );
};

const mapStateToProps = (state): any => {
    return { project: state.project, story: state.story, assets: state.assetList };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompositionPreviewPlayer);
