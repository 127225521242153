import * as React from 'react';
import Playhead from './Playhead';
import ScaleBar from './ScaleBar';
import Labels from './Labels';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createNewHTMLAsset, updateEditorConfig } from '../../redux/actions/editor';
import { FRAME_WIDTH, LAYER_EXPANDED_ROW_HEIGHT, LAYER_ROW_HEIGHT } from '../../constants/timeline';
import { VIEWER_TYPES } from '../../constants/viewer';
import { generateUUID, getLayer, getLayerFromAsset, newCompositionLayer } from '../../util/story';
import hotkeys from 'hotkeys-js';
import { logError, logNotification } from '../../util/notifications';
import { timeline as copy, header, fields } from '../../constants/copy';
import { IEditor } from '../../redux/reducers/editor';
import { IProject } from '../../redux/reducers/project';
import PositionDisplay from './PositionDisplay';
import { setActiveViewer, addViewer, saveStory, replaceViewer } from '../../redux/actions/story';
import { updateTimelineState } from '../../redux/actions/timeline';
import {
    Button,
    IImposiumAPI,
    SliderField,
    doAssetTableHydration,
    filterHotkeys
} from '@imposium-hub/components';
import AddLayerButton from './AddLayerButton';
import { ICompositionLayer, ILayeredComposition, IStory } from '../../constants/snippets';
import CompositionLayer from './CompositionLayer';
import LayerInfoPanel from './LayerInfoPanel';
import { LayerDropTarget } from './LayerDropTarget';
import { onDropLoc } from '../../constants/story';
import {
    ICON_ARROW_TO_LEFT,
    ICON_ARROW_TO_RIGHT,
    ICON_COPY,
    ICON_LAYER_GROUP,
    ICON_MUTED,
    ICON_PAUSE,
    ICON_PLAY,
    ICON_RABBIT_RUNNING,
    ICON_RABBIT_SOLID_RUNNING,
    ICON_TRASH,
    ICON_VOLUME_UP
} from '../../constants/icons';
import { CustomDragLayer } from './CustomDragLayer';
import { LayerInfoPanelHeader } from './LayerInfoPanelHeader';
import {
    circularCompCheck,
    getAnchorsAnchoredTo,
    getKeyframeFieldAndIndex,
    getRowY,
    getTimelineHeight,
    removeKeyframe
} from '../../util/timeline';
import { LayerAnchorDisplay } from './LayerAnchorDisplay';
import { IChanges } from '../../redux/reducers/changes';
import { previewStoryClicked } from '../../util/ui';
import { copyLayer } from '../../redux/actions/layerCopy';
import { addLayer, setLayerMatte, createComposition } from '../../redux/actions/compositions';
import _ from 'lodash';
import { CompNameModal } from './CompNameModal';
import { ContextMenu, ContextMenuItem, ContextMenuTrigger } from '@imposium-hub/components';
import { createCompFromLayers } from '../../util/composition';
import { ITimelineState } from '../../redux/reducers/timeline';
import { getSourceKey } from '../viewer/composition-preview/LayeredCompositionPreview';

interface ILayeredTimelineState {
    width: number;
    anchorMode: boolean;
    playbackTimer: any;
    playbackInitialFrame: any;
    compName: string;
    showCreateCompModal: boolean;
    draggingHandle: boolean;
    draggingLayer: boolean;
    draggingPlayhead: boolean;
    mouseOver: boolean;
}

interface ILayeredTimelineProps {
    compositionId: string;
    layers: any;
    totalFrames: any;
    rate: number;
    width: number;
    height: number;
    editor: IEditor;
    timeline: ITimelineState;
    project: IProject;
    viewerHidden: boolean;
    updateLayer(layer: ICompositionLayer): any;
    updateAnchor(layerId: string, targetLayerId: string, start: boolean, targetStart: boolean);
    moveLayer(index: number, shift: number): any;
    deleteLayer(layerId: string): any;
    shiftLayers(layerIds: string[], frames: number);
    deleteLayers(layerIds: string[]): any;
    duplicateLayer(layerId: string): any;
    duplicateLayers(layerIds: string[]): any;
    addLayer(compId: string, layer: ICompositionLayer, index?: number): any;
    deleteAnchor(layerId: string, anchorLayerId: string, start: boolean): void;
    updateEditorConfig(conf: any): any;
    setActiveViewer(id: any): any;
    addViewer(config: any): void;
    setLayerMatte(cId: string, lId: string, mId: string): void;
    saveStory(): any;
    updateTimelineState(s: any): void;
    layerDragOffset: number;
    rightDragFrameOffset: number;
    leftDragFrameOffset: number;
    tempOffsetFrames: number;
    createNewHTMLAsset(): void;
    story: IStory;
    copyLayer(i): any;
    changes: IChanges;
    compositions: ILayeredComposition[];
    layerCopy: any;
    createComposition(id, comp, name?): any;
    replaceViewer(config): void;
    doAssetTableHydration(apiInstance: IImposiumAPI, sId: string, callback?: any): any;
}

class LayeredTimeline extends React.PureComponent<ILayeredTimelineProps, ILayeredTimelineState> {
    private timelineWrapper: any;

    private scaledTimeline: any;

    private evtHandlers: any;

    private playbackInterval: any;

    private fromIndictor: boolean = false;

    constructor(props) {
        super(props);

        this.state = {
            playbackTimer: null,
            playbackInitialFrame: null,
            width: window.innerWidth,
            anchorMode: false,
            compName: '',
            showCreateCompModal: false,
            draggingHandle: false,
            draggingPlayhead: false,
            draggingLayer: false,
            mouseOver: false
        };

        this.timelineWrapper = React.createRef();
        this.scaledTimeline = React.createRef();

        this.evtHandlers = {
            resize: () => this.resize(),
            jumpToFrame: (e) => this.jumpToFrame(e),
            layer: {
                deleteAnchor: (l, a, s) => this.props.deleteAnchor(l, a, s),
                update: (c) => this.updateLayer(c),
                onDrag: (f, o) => this.onLayerDrag(f, o),
                clearOffset: () => this.clearOffset(),
                select: (l) => this.selectLayer(l),
                copy: () => this.copyLayer(),
                paste: () => this.pasteLayer(),
                multiSelect: (l) => this.multiSelectLayer(l),
                selectKeyframe: (i, f) => this.selectKeyframe(i, f),
                expand: (l) => this.expandLayer(l),
                shift: (f) => this.shiftActiveLayers(f),
                leftDragOffset: (o) => this.props.updateTimelineState({ leftDragFrameOffset: o }),
                rightDragOffset: (o) => this.props.updateTimelineState({ rightDragFrameOffset: o }),
                layerOffsetFrames: (o) => this.props.updateTimelineState({ tempOffsetFrames: o }),
                setDragState: (s) => this.setState(s),
                updateAnchor: (layerId, targetId, layerStart, targetStart) => {
                    this.props.updateAnchor(layerId, targetId, layerStart, targetStart);
                    this.setState({ anchorMode: false });
                }
            },
            hotkeys: {
                anchorMode: (e) => this.toggleAnchorMode(e),
                nextFrame: (e) => this.nextFrame(e),
                prevFrame: (e) => this.previousFrame(e),
                checkForFrameNav: (e) => this.checkForFrameNav(e),
                delete: (e) => this.deleteActiveLayer(e),
                setIn: (e) => this.setLayerIn(e),
                setOut: (e) => this.setLayerOut(e),
                jumpIn: (e) => this.jumpToLayerIn(e),
                jumpOut: (e) => this.jumpToLayerOut(e),
                moveInTo: (e) => this.moveLayerTo(e, true),
                moveOutTo: (e) => this.moveLayerTo(e, false),
                duplicateLayer: (e) => this.duplicateLayer(e),
                toggleTimelinePlay: () => this.toggleTimelinePlay(),
                toggleMute: () => this.toggleMute(),
                toggleVolume: (v) => this.toggleVolume(v),
                toStart: () => this.toStart(),
                toEnd: () => this.toEnd(),
                nextLayer: () => this.nextLayer(),
                previousLayer: () => this.previousLayer()
            },
            buttons: {
                preview: (e) => previewStoryClicked(e)
            },
            addEmptyLayer: (c) => this.addEmptyLayer(c),
            updateTimelineScale: (s, p) => this.updateTimeline(s, p),
            clearSelected: (e) => this.clearSelected(e),
            playheadMove: (f) => this.playheadMoved(f),
            addLayerFromAsset: (asset, index, from) => this.addLayerFromAsset(asset, index, from),
            showViewer: () => this.showViewer(),
            createCompFromLayers: () => this.createCompFromLayers(),
            onNotification: (e) => logNotification(e),
            onError: (e) => logError(e)
        };
        hotkeys.filter = (e) => filterHotkeys(e);
    }

    public bindHotkeys() {
        hotkeys('i', this.evtHandlers.hotkeys.jumpIn);
        hotkeys('o', this.evtHandlers.hotkeys.jumpOut);
        hotkeys('option+[, alt+[', this.evtHandlers.hotkeys.setIn);
        hotkeys('option+], alt+]', this.evtHandlers.hotkeys.setOut);
        hotkeys('[', this.evtHandlers.hotkeys.moveInTo);
        hotkeys(']', this.evtHandlers.hotkeys.moveOutTo);
        hotkeys('backspace', this.evtHandlers.hotkeys.delete);
        hotkeys('delete', this.evtHandlers.hotkeys.delete);
        hotkeys('pagedown, shift+pagedown', this.evtHandlers.hotkeys.prevFrame);
        hotkeys('pageup, shift+pageup', this.evtHandlers.hotkeys.nextFrame);
        hotkeys('*', this.evtHandlers.hotkeys.checkForFrameNav);
        hotkeys('command+d, control+d', this.evtHandlers.hotkeys.duplicateLayer);
        hotkeys('space', this.evtHandlers.hotkeys.toggleTimelinePlay);
        hotkeys('b', this.evtHandlers.hotkeys.toStart);
        hotkeys('n', this.evtHandlers.hotkeys.toEnd);
        hotkeys('a', { keyup: true }, this.evtHandlers.hotkeys.anchorMode);
        hotkeys('command+up, alt+up', this.evtHandlers.hotkeys.previousLayer);
        hotkeys('command+down, alt+down', this.evtHandlers.hotkeys.nextLayer);
        hotkeys('command+c, control+c', () => this.copyLayer());
        hotkeys('command+v, control+v', () => this.pasteLayer());
    }

    public unbindHotkeys() {
        hotkeys.unbind('i', this.evtHandlers.hotkeys.jumpIn);
        hotkeys.unbind('o', this.evtHandlers.hotkeys.jumpOut);
        hotkeys.unbind('option+[, alt+[', this.evtHandlers.hotkeys.setIn);
        hotkeys.unbind('option+], alt+]', this.evtHandlers.hotkeys.setOut);
        hotkeys.unbind('alt+[', this.evtHandlers.hotkeys.setIn);
        hotkeys.unbind('alt+]', this.evtHandlers.hotkeys.setOut);
        hotkeys.unbind('[', this.evtHandlers.hotkeys.moveInTo);
        hotkeys.unbind(']', this.evtHandlers.hotkeys.moveOutTo);
        hotkeys.unbind('backspace', this.evtHandlers.hotkeys.delete);
        hotkeys.unbind('delete', this.evtHandlers.hotkeys.delete);
        hotkeys.unbind('pagedown, shift+pagedown', this.evtHandlers.hotkeys.prevFrame);
        hotkeys.unbind('pageup, shift+pageup', this.evtHandlers.hotkeys.nextFrame);
        hotkeys.unbind('*', this.evtHandlers.hotkeys.checkForFrameNav);
        hotkeys.unbind('command+d, control+d', this.evtHandlers.hotkeys.duplicateLayer);
        hotkeys.unbind('space', this.evtHandlers.hotkeys.toggleTimelinePlay);
        hotkeys.unbind('b', this.evtHandlers.hotkeys.toStart);
        hotkeys.unbind('n', this.evtHandlers.hotkeys.toEnd);
        hotkeys.unbind('a', this.evtHandlers.hotkeys.anchorMode);
        hotkeys.unbind('command+up, alt+up', this.evtHandlers.hotkeys.previousLayer);
        hotkeys.unbind('command+down, alt+down', this.evtHandlers.hotkeys.nextLayer);
        hotkeys.unbind('command+c, control+c', () => this.copyLayer());
        hotkeys.unbind('command+v, control+v', () => this.pasteLayer());
    }

    private createCompFromLayers() {
        const { compName } = this.state;

        this.setState({ showCreateCompModal: false, compName: '' }, () =>
            createCompFromLayers(compName)
        );
    }

    private copyLayer() {
        const activeTag = document.activeElement.tagName;
        const {
            timeline: { activeTimelineLayer, activeMultiSelectLayers },
            compositionId,
            compositions
        } = this.props;
        let msg = copy.copyLayer;
        if (activeTag !== 'INPUT' && activeTag !== 'TEXTAREA') {
            const compLayers = compositions[compositionId].layers;
            let layer2Copy;
            if (activeTimelineLayer !== null) {
                compLayers.map((layer) => {
                    if (activeTimelineLayer && layer.id === activeTimelineLayer) {
                        layer2Copy = layer;
                    }
                });
            }

            if (activeMultiSelectLayers.length > 0) {
                msg = copy.copyLayers;
                const copyArray = [];
                activeMultiSelectLayers.forEach((s) => {
                    const index = compLayers.findIndex((i) => i.id === s);
                    copyArray[index] = compLayers[index];
                });

                layer2Copy = copyArray.filter((n) => n).reverse();
            }
            logNotification(msg);
            this.props.copyLayer(layer2Copy);
        }
    }

    private pasteLayer() {
        const activeTag = document.activeElement.tagName;
        const {
            compositionId,
            layerCopy: { copied }
        } = this.props;

        if (!_.isEmpty(copied) && activeTag !== 'INPUT' && activeTag !== 'TEXTAREA') {
            const isArray = Array.isArray(copied);
            if (!isArray) {
                const id = generateUUID();
                const layer = { ...copied, id };
                this.props.addLayer(compositionId, layer);
                logNotification(copy.pasteLayer);
                this.props.updateTimelineState({ activeTimelineLayer: layer.id });
            } else {
                const selected = [];
                copied
                    .slice()
                    .reverse()
                    .forEach((c) => {
                        const id = generateUUID();
                        selected.push(id);
                        const layer = { ...c, id };
                        logNotification(copy.pasteLayers);
                        this.props.addLayer(compositionId, layer);
                    });

                this.props.updateTimelineState({ activeMultiSelectLayers: selected });
            }
        }
    }

    private checkForFrameNav(e) {
        // CMD + right
        if (e.keyCode === 39 && e.metaKey) {
            this.nextFrame(e);

            // CMD + left
        } else if (e.keyCode === 37 && e.metaKey) {
            this.previousFrame(e);
        }
    }

    public componentWillUnmount() {
        this.unbindHotkeys();
    }

    public componentDidMount() {
        this.bindHotkeys();

        window.addEventListener('resize', this.evtHandlers.resize, true);
        this.resize();
    }

    public componentDidUpdate(prevProps: Readonly<ILayeredTimelineProps>): void {
        const {
            story: {
                viewer: { activeViewer }
            }
        } = this.props;

        if (activeViewer !== prevProps.story.viewer.activeViewer) {
            this.stopPlayback();
        }
    }

    private toggleTimelinePlay() {
        const {
            timeline: { playing }
        } = this.props;

        const { mouseOver } = this.state;

        if (!playing && mouseOver) {
            this.startPlayback();
        } else {
            this.stopPlayback();
        }
    }

    private toggleMute() {
        this.props.updateTimelineState({ muted: !this.props.timeline.muted });
    }

    private toggleVolume(volume) {
        this.props.updateTimelineState({ volume });
    }

    private stopPlayback() {
        this.setState({
            playbackTimer: null,
            playbackInitialFrame: null
        });
        this.props.updateTimelineState({ playing: false });
        cancelAnimationFrame(this.playbackInterval);
    }

    private startPlayback() {
        const {
            timeline: { activeFrame },
            project: { compositionId },
            totalFrames
        } = this.props;

        const actFrame = activeFrame[compositionId] ? activeFrame[compositionId] : 0;
        const startFrame = actFrame === totalFrames - 1 ? 0 : actFrame;

        this.props.updateTimelineState({ playing: true, activeFrame: startFrame });

        this.setState(
            {
                playbackTimer: Date.now(),
                playbackInitialFrame: startFrame
            },
            () => {
                this.playbackInterval = requestAnimationFrame(() => this.playbackIntervalHandler());
            }
        );
    }

    private playbackIntervalHandler() {
        const {
            rate,
            timeline: { activeFrame },
            project: { compositionId },
            totalFrames
        } = this.props;

        const elapsed = Date.now() - this.state.playbackTimer;
        const frameTime = 1 / rate;
        const elapsedFrames = Math.floor(elapsed / 1000 / frameTime);

        const newFrame = Math.min(elapsedFrames + this.state.playbackInitialFrame, totalFrames - 1);
        if (newFrame !== activeFrame[compositionId]) {
            if (newFrame === totalFrames - 1) {
                this.toStart();
                return;
            } else {
                this.props.updateTimelineState({ activeFrame: newFrame });
            }
        }

        this.playbackInterval = requestAnimationFrame(() => this.playbackIntervalHandler());
    }

    private nextLayer() {
        const {
            layers,
            timeline: { activeTimelineLayer }
        } = this.props;
        const activeIndex = layers.findIndex((layer) => layer.id === activeTimelineLayer);
        if (layers[activeIndex - 1]) {
            this.selectLayer(layers[activeIndex - 1].id);
        }
    }

    private previousLayer() {
        const {
            layers,
            timeline: { activeTimelineLayer }
        } = this.props;
        const activeIndex = layers.findIndex((layer) => layer.id === activeTimelineLayer);
        if (layers[activeIndex + 1]) {
            this.selectLayer(layers[activeIndex + 1].id);
        }
    }

    private toStart() {
        this.stopPlayback();
        this.props.updateTimelineState({
            activeFrame: 0
        });
        clearInterval(this.playbackInterval);
        cancelAnimationFrame(this.playbackInterval);
    }

    private toEnd() {
        const { totalFrames } = this.props;
        this.props.updateTimelineState({
            playing: false,
            activeFrame: totalFrames - 1
        });
        this.setState({
            playbackInitialFrame: totalFrames - 1
        });
        clearInterval(this.playbackInterval);
        cancelAnimationFrame(this.playbackInterval);
    }

    private showViewer() {
        this.props.setActiveViewer(this.props.compositionId);
    }

    private addEmptyLayer(type) {
        const { width, height, totalFrames, compositionId } = this.props;
        const layer = newCompositionLayer(type, width, height, width, height, totalFrames);
        this.props.addLayer(compositionId, layer);
        this.props.updateTimelineState({ activeTimelineLayer: layer.id });
    }

    private addLayerFromAsset(asset, index, from) {
        const { width, height, totalFrames, compositionId, compositions } = this.props;

        if (circularCompCheck(asset)) return null;

        const { rate } = compositions[compositionId];
        const layer = getLayerFromAsset(asset, width, height, totalFrames, rate);

        if (from === onDropLoc.INDICATOR) {
            this.fromIndictor = true;
            this.props.addLayer(compositionId, layer, index);
            this.props.updateTimelineState({ activeTimelineLayer: layer.id });
        } else if (from === onDropLoc.TARGET && this.fromIndictor === false) {
            this.props.addLayer(compositionId, layer, index);
            this.props.updateTimelineState({ activeTimelineLayer: layer.id });
        }
    }

    private deleteActiveLayer(e) {
        const {
            layers,
            timeline: { activeTimelineLayer, activeMultiSelectLayers, activeKeyframe }
        } = this.props;

        if (activeKeyframe) {
            const layer = layers.find((l) => {
                return l.id === activeTimelineLayer;
            });
            const { field, index } = getKeyframeFieldAndIndex(layer.keyframes, activeKeyframe);
            const updatedKeyframes = removeKeyframe(layer.keyframes, field, index);
            const newLayer = { ...layer };
            newLayer.keyframes = updatedKeyframes;
            this.props.updateLayer(newLayer);
            this.props.updateTimelineState({ activeKeyframe: null });
        } else if (activeTimelineLayer) {
            const c = confirm(copy.confirmLayerDelete);
            if (c) {
                this.props.deleteLayer(activeTimelineLayer);
            }
        } else if (activeMultiSelectLayers) {
            const c = confirm(copy.confirmLayersDelete);
            if (c) {
                this.props.deleteLayers(activeMultiSelectLayers);
            }
        }
    }

    public getSingleFrameOffset(): number {
        const { totalFrames } = this.props;
        return 1 / totalFrames;
    }

    public getTimelineScale(): number {
        const { width } = this.state;
        const {
            timeline: { scaleModifier },
            compositionId,
            totalFrames
        } = this.props;
        const scale = scaleModifier[compositionId] ? scaleModifier[compositionId] : 1;
        const timelineW = FRAME_WIDTH * totalFrames;
        const screenScale = width / timelineW;
        const timelineScale = screenScale / scale;

        return timelineScale;
    }

    public getTimelineWidth(timelineScale): number {
        const { totalFrames } = this.props;
        return FRAME_WIDTH * totalFrames * timelineScale;
    }

    public getTimelineX(w): number {
        const positionOffset = this.props.timeline.positionOffset[this.props.compositionId] || 0;
        return w * -positionOffset;
    }

    public jumpToLayerIn(e) {
        const {
            layers,
            timeline: { activeTimelineLayer }
        } = this.props;
        const layer = getLayer(layers, activeTimelineLayer);
        if (layer) {
            const activeFrame = layer.start_frame;
            this.props.updateTimelineState({
                activeFrame
            });
        }
    }

    public jumpToLayerOut(e) {
        const {
            layers,
            timeline: { activeTimelineLayer }
        } = this.props;
        const layer = getLayer(layers, activeTimelineLayer);
        if (layer) {
            const activeFrame = layer.end_frame - 1;
            this.props.updateTimelineState({
                activeFrame
            });
        }
    }

    public jumpToFrame(newFrame) {
        const {
            timeline: { activeFrame, playing },
            project: { compositionId }
        } = this.props;

        const actFrame = activeFrame[compositionId] ? activeFrame[compositionId] : 0;
        if (actFrame !== newFrame) {
            if (playing) {
                this.stopPlayback();
            }

            this.props.updateTimelineState({ playing: false, activeFrame: newFrame });
            this.props.setActiveViewer(VIEWER_TYPES.TIMELINE_PREVIEW);
        }
    }

    private clearSelected(e) {
        e.preventDefault();

        if (e.target === this.scaledTimeline.current) {
            this.props.updateTimelineState({
                activeTimelineLayer: null,
                activeKeyframe: null,
                activeMultiSelectLayers: []
            });
        }
    }

    public nextFrame(e) {
        if (e && e.target.tagName !== 'INPUT') {
            e.preventDefault();
        }

        if ((e && e.target.tagName !== 'INPUT') || !e) {
            const {
                timeline: { activeFrame },
                project: { compositionId },
                totalFrames
            } = this.props;
            const increment = e && e.shiftKey ? 10 : 1;
            const actFrame = activeFrame[compositionId] ? activeFrame[compositionId] : 0;
            const next = actFrame + increment;

            if (next < totalFrames) {
                this.props.updateTimelineState({ activeFrame: next });
            } else {
                this.props.updateTimelineState({ playing: false });
                clearInterval(this.playbackInterval);
            }
        }
    }

    public previousFrame(e) {
        const {
            timeline: { activeFrame },
            project: { compositionId }
        } = this.props;

        if (e && e.target.tagName !== 'INPUT') {
            e.preventDefault();
        }

        if ((e && e.target.tagName !== 'INPUT') || !e) {
            const increment = e && e.shiftKey ? 10 : 1;
            const actFrame = activeFrame[compositionId] ? activeFrame[compositionId] : 0;
            const prev = actFrame - increment;
            if (prev >= 0) {
                this.props.updateTimelineState({ activeFrame: prev });
            }
        }
    }

    public playheadMoved(activeFrame) {
        this.props.updateTimelineState({ activeFrame });
    }

    public resize() {
        const container = this.timelineWrapper.current;

        if (container) {
            const containerW = container.getBoundingClientRect().width;
            this.setState({
                width: containerW
            });
        }
    }

    public selectLayer(layerId: string) {
        this.props.updateTimelineState({
            activeTimelineLayer: layerId,
            activeMultiSelectLayers: []
        });
    }

    public multiSelectLayer(layerId: string) {
        const layers = [...this.props.timeline.activeMultiSelectLayers];

        if (this.props.timeline.activeTimelineLayer && layers.length === 0) {
            layers.push(this.props.timeline.activeTimelineLayer);
        }

        const index = layers.indexOf(layerId);
        if (index > -1) {
            layers.splice(index, 1);
        } else {
            layers.push(layerId);
        }

        this.props.updateTimelineState({
            activeTimelineLayer: null,
            activeMultiSelectLayers: layers
        });
    }

    public expandLayer(layerId: string) {
        const layers = [...this.props.timeline.expandedLayers];
        const index = layers.indexOf(layerId);
        if (index > -1) {
            layers.splice(index, 1);
        } else {
            layers.push(layerId);
        }

        this.props.updateTimelineState({
            expandedLayers: layers
        });
    }

    private shiftActiveLayers(frames: number) {
        const {
            timeline: { activeTimelineLayer, activeMultiSelectLayers }
        } = this.props;
        const layers = activeTimelineLayer ? [activeTimelineLayer] : activeMultiSelectLayers;
        this.props.shiftLayers(layers, frames);
    }

    public onLayerDrag(frame, tempOffset) {
        if (tempOffset !== null && tempOffset !== undefined) {
            this.props.updateTimelineState({
                layerDragOffset: tempOffset
            });
        }

        const f = Math.max(0, Math.min(frame, this.props.totalFrames));
        this.props.updateTimelineState({
            activeFrame: f
        });
    }

    private clearOffset() {
        this.props.updateTimelineState({
            layerDragOffset: 0
        });
    }

    public setLayerIn(e) {
        e.preventDefault();

        const {
            timeline: { activeTimelineLayer, activeFrame },
            project: { compositionId },
            layers
        } = this.props;

        if (activeTimelineLayer) {
            const newLayer = { ...getLayer(layers, activeTimelineLayer) };
            const actFrame = activeFrame[compositionId] ? activeFrame[compositionId] : 0;
            if (actFrame < newLayer.end_frame) {
                newLayer.start_frame = actFrame;
                this.updateLayer(newLayer);
            } else {
                logError(copy.startEndError);
            }
        }
    }

    public setLayerOut(e) {
        e.preventDefault();

        const {
            timeline: { activeTimelineLayer, activeFrame },
            project: { compositionId },
            layers
        } = this.props;

        if (activeTimelineLayer) {
            const newLayer = { ...getLayer(layers, activeTimelineLayer) };
            const actFrame = activeFrame[compositionId] ? activeFrame[compositionId] : 0;
            if (actFrame + 1 > newLayer.start_frame) {
                newLayer.end_frame = actFrame + 1;
                this.updateLayer(newLayer);
            } else {
                logError(copy.startEndError);
            }
        }
    }

    private moveLayerTo(e, fromStart: boolean) {
        e.preventDefault();

        const {
            timeline: { activeTimelineLayer, activeFrame },
            project: { compositionId },
            layers
        } = this.props;

        if (activeTimelineLayer) {
            const newLayer = { ...getLayer(layers, activeTimelineLayer) };
            const dur = newLayer.end_frame - newLayer.start_frame;
            const actFrame = activeFrame[compositionId] ? activeFrame[compositionId] : 0;
            if (fromStart) {
                newLayer.start_frame = actFrame;
                newLayer.end_frame = actFrame + dur;
                this.updateLayer(newLayer);
            } else {
                newLayer.start_frame = actFrame + 1 - dur;
                newLayer.end_frame = actFrame + 1;
                this.updateLayer(newLayer);
            }
        }
    }

    private duplicateLayer(e) {
        e.preventDefault();
        e.stopPropagation();

        const {
            timeline: { activeTimelineLayer, activeMultiSelectLayers }
        } = this.props;
        if (activeTimelineLayer) {
            this.props.duplicateLayer(activeTimelineLayer);
        }
        if (activeMultiSelectLayers) {
            this.props.duplicateLayers(activeMultiSelectLayers.reverse());
        }
    }

    public updateLayer(c) {
        this.props.updateLayer(c);
    }

    public updateTimeline(newScale, newOffset) {
        const {
            compositionId,
            timeline: { scaleModifier, positionOffset }
        } = this.props;

        const updateScale = { [compositionId]: newScale };
        const updatePosition = { [compositionId]: newOffset };
        const updateModifier = { ...scaleModifier, ...updateScale };
        const updateOffset = { ...positionOffset, ...updatePosition };
        this.props.updateTimelineState({
            scaleModifier: updateModifier,
            positionOffset: updateOffset
        });
    }

    private toggleAnchorMode(e) {
        this.setState({
            anchorMode: e.type === 'keyup' ? false : true
        });
    }

    private selectKeyframe(id: string, frameNumber: number) {
        if (id === null) {
            this.props.updateTimelineState({
                activeKeyframe: null
            });
        } else {
            this.props.updateTimelineState({
                activeKeyframe: id,
                activeFrame: frameNumber
            });
        }
    }

    public render() {
        const {
            compositionId,
            layers,
            totalFrames,
            rate,
            width,
            viewerHidden,
            timeline: {
                activeFrame,
                expandedLayers,
                activeTimelineLayer,
                activeMultiSelectLayers,
                playing,
                positionOffset,
                scaleModifier,
                muted,
                volume
            },
            editor: { fastRender },
            layerDragOffset,
            leftDragFrameOffset,
            rightDragFrameOffset,
            tempOffsetFrames
        } = this.props;

        const { compName, showCreateCompModal, draggingLayer, draggingHandle, draggingPlayhead } =
            this.state;

        if (totalFrames !== undefined) {
            const timelineScale = this.getTimelineScale();
            const w = this.getTimelineWidth(timelineScale);
            const h = getTimelineHeight();
            const x = this.getTimelineX(w);
            const timelineHeight = h + 25 + LAYER_ROW_HEIGHT;
            const timelineStyle = {
                height: `${timelineHeight}px`
            };

            const innerStyle = {
                width: `${w}px`,
                height: `${h}px`,
                left: `${x}px`
            };

            const playIcon = playing ? ICON_PAUSE : <div className='icon-play'>{ICON_PLAY}</div>;

            const timelineProps = {};
            if (viewerHidden) {
                timelineProps['onClick'] = this.evtHandlers.showViewer;
            }

            const isMuted = muted ? ICON_MUTED : ICON_VOLUME_UP;
            const noVolume = muted ? 'noVolume' : '';
            const fastRenderIcon = fastRender ? ICON_RABBIT_SOLID_RUNNING : ICON_RABBIT_RUNNING;

            const copied = activeTimelineLayer
                ? `${fields.compositions.copyLayer}`
                : activeMultiSelectLayers.length > 0
                ? `${fields.compositions.copyLayers}`
                : '';
            const create = activeTimelineLayer
                ? `${fields.compositions.createCompFromLayer}`
                : activeMultiSelectLayers.length > 0
                ? `${fields.compositions.createCompFromLayers}`
                : '';
            const del = activeTimelineLayer
                ? `${fields.compositions.deleteLayer}`
                : activeMultiSelectLayers.length > 0
                ? `${fields.compositions.deleteLayers}`
                : '';
            const duplicate = activeTimelineLayer
                ? `${fields.compositions.duplicateLayer}`
                : activeMultiSelectLayers.length > 0
                ? `${fields.compositions.duplicateLayers}`
                : '';

            const dragClass = draggingLayer
                ? 'draggingLayer'
                : draggingHandle
                ? 'draggingHandle'
                : draggingPlayhead
                ? 'draggingPlayhead'
                : '';

            const contextMenu = (activeTimelineLayer || activeMultiSelectLayers.length > 0) && (
                <ContextMenu
                    id='my-context-menu-1'
                    appendTo='#portal-root'>
                    <ContextMenuItem onClick={() => this.setState({ showCreateCompModal: true })}>
                        {ICON_LAYER_GROUP} {create}
                    </ContextMenuItem>
                    <ContextMenuItem onClick={() => this.copyLayer()}>
                        {ICON_COPY} {copied}
                    </ContextMenuItem>
                    <ContextMenuItem onClick={this.evtHandlers.hotkeys.delete}>
                        {ICON_TRASH} {del}
                    </ContextMenuItem>
                    <ContextMenuItem onClick={(e) => this.duplicateLayer(e)}>
                        {ICON_COPY} {duplicate}
                    </ContextMenuItem>
                </ContextMenu>
            );

            const scale = scaleModifier[compositionId] ? scaleModifier[compositionId] : 1;
            const offset = positionOffset[compositionId] ? positionOffset[compositionId] : 0;
            const actFrame = activeFrame[compositionId] ? activeFrame[compositionId] : 0;
            return (
                <div
                    className={`layered-timeline ${dragClass}`}
                    {...timelineProps}
                    onMouseOver={(e) => this.setState({ mouseOver: e.type === 'mouseover' })}
                    onMouseOut={(e) => this.setState({ mouseOver: e.type === 'mouseover' })}>
                    {showCreateCompModal && (
                        <CompNameModal
                            compName={compName}
                            onChange={(c) => this.setState({ compName: c })}
                            onClose={() => this.setState({ showCreateCompModal: false })}
                            onContinue={this.evtHandlers.createCompFromLayers}
                        />
                    )}

                    <AddLayerButton
                        onAddLayer={this.evtHandlers.addEmptyLayer}
                        {...this.props}
                    />

                    <div className='timeline-controls'>
                        <PositionDisplay
                            activeFrame={actFrame}
                            frameRate={rate}
                        />
                    </div>

                    <div className='fast-render'>
                        <Button
                            key='btn-fastRender'
                            tooltip='Toggle Quick Render'
                            onClick={() =>
                                this.props.updateEditorConfig({ fastRender: !fastRender })
                            }
                            color={fastRender ? `primary` : 'default'}
                            size='large'>
                            {fastRenderIcon}
                        </Button>
                    </div>

                    <div className='timeline-buttons'>
                        <Button
                            style='subtle'
                            color='secondary'
                            onClick={this.evtHandlers.hotkeys.toStart}>
                            {ICON_ARROW_TO_LEFT}
                        </Button>
                        <Button
                            size='xlarge'
                            color='secondary'
                            customStyles={{
                                borderRadius: '50%',
                                width: '40px',
                                height: '40px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                            onClick={this.evtHandlers.hotkeys.toggleTimelinePlay}>
                            {playIcon}
                        </Button>
                        <Button
                            style='subtle'
                            color='secondary'
                            onClick={this.evtHandlers.hotkeys.toEnd}>
                            {ICON_ARROW_TO_RIGHT}
                        </Button>
                        <Button
                            color='default'
                            size='large'
                            tooltip={header.tooltipPreview}
                            onClick={this.evtHandlers.buttons.preview}>
                            {header.btnPreview}
                        </Button>
                    </div>

                    <div className={`volume-control ${noVolume}`}>
                        <Button
                            style='subtle'
                            color='secondary'
                            customStyles={{
                                width: '22px'
                            }}
                            onClick={this.evtHandlers.hotkeys.toggleMute}>
                            {isMuted}
                        </Button>
                        <SliderField
                            value={volume}
                            defaultValue={1}
                            width='50%'
                            min={0}
                            max={1}
                            step={0.01}
                            onChange={this.evtHandlers.hotkeys.toggleVolume}
                        />
                    </div>

                    <ScaleBar
                        frames={totalFrames}
                        activeFrame={actFrame}
                        scale={scale}
                        width={this.state.width}
                        offset={offset}
                        onUpdate={this.evtHandlers.updateTimelineScale}
                        minScale={0.1}
                    />

                    <div className='left-cover' />
                    <div className='right-cover' />
                    <div className='does-scroll'>
                        <LayerDropTarget
                            totalLayers={layers.length}
                            expandedLayers={expandedLayers}
                            onLayerDrop={this.props.moveLayer}
                            onAssetDrop={this.evtHandlers.addLayerFromAsset}
                            height={timelineHeight}
                            layers={layers}>
                            <div
                                className='inner-timeline'
                                ref={this.timelineWrapper}
                                style={timelineStyle}>
                                <div
                                    className='scaled-timeline'
                                    style={innerStyle}
                                    ref={this.scaledTimeline}
                                    onClick={this.evtHandlers.clearSelected}>
                                    <div
                                        className='timeline-end-cover'
                                        style={{ left: `${w}px` }}
                                    />
                                    <div className='timeline-bg' />
                                    <ContextMenuTrigger id='my-context-menu-1'>
                                        {layers.map((layer, i) => {
                                            const isMultiSelected =
                                                activeMultiSelectLayers.indexOf(layer.id) !== -1;
                                            const active =
                                                layer.id === activeTimelineLayer || isMultiSelected;
                                            const rowY = getRowY(layers, expandedLayers, layer.id);
                                            const expanded =
                                                expandedLayers.indexOf(layer.id) !== -1;
                                            const matte =
                                                layers.findIndex((l) => {
                                                    return l.matte_layer_id === layer.id;
                                                }) !== -1;
                                            const height = expanded
                                                ? LAYER_EXPANDED_ROW_HEIGHT
                                                : LAYER_ROW_HEIGHT;
                                            return (
                                                <React.Fragment key={i}>
                                                    <CompositionLayer
                                                        sourceKey={getSourceKey(
                                                            this.props.compositionId,
                                                            layer.id
                                                        )}
                                                        anchorMode={this.state.anchorMode}
                                                        active={active}
                                                        isMultiSelected={isMultiSelected}
                                                        config={layer}
                                                        expanded={expanded}
                                                        timelineWidth={w}
                                                        y={rowY}
                                                        key={layer.id}
                                                        matte={matte}
                                                        update={this.evtHandlers.layer.update}
                                                        updateAnchor={
                                                            this.evtHandlers.layer.updateAnchor
                                                        }
                                                        deleteAnchor={
                                                            this.evtHandlers.layer.deleteAnchor
                                                        }
                                                        onDrag={this.evtHandlers.layer.onDrag}
                                                        shift={this.evtHandlers.layer.shift}
                                                        clearOffset={
                                                            this.evtHandlers.layer.clearOffset
                                                        }
                                                        select={this.evtHandlers.layer.select}
                                                        multiSelect={
                                                            this.evtHandlers.layer.multiSelect
                                                        }
                                                        scale={timelineScale}
                                                        setLeftDragOffset={
                                                            this.evtHandlers.layer.leftDragOffset
                                                        }
                                                        setLayerOffsetFrames={
                                                            this.evtHandlers.layer.layerOffsetFrames
                                                        }
                                                        tempOffsetFrames={
                                                            active ? tempOffsetFrames : null
                                                        }
                                                        setRightDragOffset={
                                                            this.evtHandlers.layer.rightDragOffset
                                                        }
                                                        leftDragFrameOffset={
                                                            active ? leftDragFrameOffset : null
                                                        }
                                                        layerDragOffset={
                                                            active ? layerDragOffset : null
                                                        }
                                                        rightDragFrameOffset={
                                                            active ? rightDragFrameOffset : null
                                                        }
                                                        setDragState={
                                                            this.evtHandlers.layer.setDragState
                                                        }
                                                        startAnchorIds={getAnchorsAnchoredTo(
                                                            layer.id,
                                                            layers,
                                                            true
                                                        )}
                                                        endAnchorIds={getAnchorsAnchoredTo(
                                                            layer.id,
                                                            layers,
                                                            false
                                                        )}
                                                        activeLayer={activeTimelineLayer}
                                                    />
                                                    <div
                                                        key={`rule-${i}`}
                                                        className='timeline-h-rule'
                                                        style={{
                                                            top: `${rowY + height - 1}px`
                                                        }}
                                                    />
                                                </React.Fragment>
                                            );
                                        })}
                                    </ContextMenuTrigger>
                                    {contextMenu}
                                    <LayerAnchorDisplay
                                        anchorMode={this.state.anchorMode}
                                        activeMultiSelectLayers={activeMultiSelectLayers}
                                        activeLayer={activeTimelineLayer}
                                        tempLayerOffset={layerDragOffset}
                                        leftDragFrameOffset={leftDragFrameOffset}
                                        rightDragFrameOffset={rightDragFrameOffset}
                                        width={w}
                                        height={h}
                                        scale={timelineScale}
                                        expandedLayers={expandedLayers}
                                        layers={layers}
                                    />
                                </div>
                            </div>

                            <LayerInfoPanel
                                compositionId={this.props.compositionId}
                                onNotification={this.evtHandlers.onNotification}
                                onError={this.evtHandlers.onError}
                                onLayerMove={this.props.moveLayer}
                                onLayerChange={this.props.updateLayer}
                                onSelect={this.evtHandlers.layer.select}
                                onSelectKeyframe={this.evtHandlers.layer.selectKeyframe}
                                onSetLayerMatte={(l, m) =>
                                    this.props.setLayerMatte(this.props.compositionId, l, m)
                                }
                                onMultiSelect={this.evtHandlers.layer.multiSelect}
                                onExpand={this.evtHandlers.layer.expand}
                                layers={layers}
                            />
                        </LayerDropTarget>
                    </div>

                    <div className='labels-wrapper'>
                        <Labels
                            jumpToFrame={this.evtHandlers.jumpToFrame}
                            frames={totalFrames}
                            rate={rate}
                            scale={timelineScale}
                            width={w}
                            height={h}
                            x={x}
                        />
                    </div>

                    <div className='playhead-wrapper'>
                        <div
                            className='inner-playhead-wrapper'
                            style={{
                                width: `${w}px`,
                                left: `${x}px`
                            }}>
                            <Playhead
                                frames={totalFrames}
                                activeFrame={actFrame}
                                onMove={this.evtHandlers.playheadMove}
                                width={width}
                                height={h}
                                positionOffset={offset}
                                scale={timelineScale}
                            />
                        </div>
                    </div>

                    <LayerInfoPanelHeader />

                    <CustomDragLayer />
                </div>
            );
        } else {
            return null;
        }
    }
}

const mapDispatchToProps = (dispatch): any => {
    return bindActionCreators(
        {
            updateEditorConfig,
            setActiveViewer,
            updateTimelineState,
            createNewHTMLAsset,
            addViewer,
            saveStory,
            copyLayer,
            setLayerMatte,
            addLayer,
            createComposition,
            doAssetTableHydration,
            replaceViewer
        },
        dispatch
    );
};

const mapStateToProps = (state): any => {
    const { layerDragOffset, rightDragFrameOffset, leftDragFrameOffset, tempOffsetFrames } =
        state.timeline;
    return {
        editor: state.editor,
        timeline: state.timeline,
        project: state.project,
        layerDragOffset,
        rightDragFrameOffset,
        leftDragFrameOffset,
        tempOffsetFrames,
        story: state.story,
        changes: state.changes,
        layerCopy: state.layerCopy,
        compositions: state.compositions.present
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(LayeredTimeline);
