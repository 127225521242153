import { FieldWrapper, SelectField, ControlledList } from '@imposium-hub/components';
import { useEffect, useState } from 'react';
import { fields as copy } from '../constants/copy';
import _ from 'lodash';
import { ICON_EQUALS } from '../constants/icons';

export const VariableMappingConfig = ({ variables, layerOptions, onChange, layerVars }) => {
    const [varMapKey, setVarMapKey] = useState('');
    const [varMapVal, setVarMapVal] = useState('');
    const [srcVariabes, setSrcVariabes] = useState([]);

    const { variable_map } = layerOptions;

    useEffect(() => {
        setVarMapKey('');
        setVarMapVal('');

        let compVars = layerVars;

        for (const variable in variable_map) {
            if (variable) {
                const index = layerVars.indexOf(variable);
                if (index > -1) {
                    compVars = compVars.filter((item) => item !== variable);
                }
            }
        }

        setSrcVariabes(compVars);
    }, [layerOptions]);

    const disable = varMapKey !== '' ? false : true;
    const varOptions = getVarsOptions(variables, variable_map);

    let srcVarOptions = varOptions;

    if (layerVars.length > 0) {
        srcVarOptions = getVarsOptions(variables, variable_map, layerVars);
    }

    let updateVarOptions = srcVarOptions;

    if (!disable && varOptions.length !== 1) {
        updateVarOptions = varOptionsUpdater(varMapKey, varMapKey, srcVarOptions, true);
    }

    updateVarOptions = sortVarOptions(updateVarOptions);
    const sortedVarOptions = sortVarOptions(srcVarOptions);

    const setValue = (mapVal) => {
        setVarMapVal(mapVal);
        updateVariablesMap(mapVal);
    };

    const updateVariablesMap = (mapVal) => {
        if (!varMapKey) return;

        onChangeHandler(varMapKey, mapVal, layerOptions, variable_map, onChange);
    };

    const mapComponents = renderMapping(
        variable_map,
        variables,
        srcVarOptions,
        layerOptions,
        onChange
    );

    const removeMapping = (index) => {
        const mapKey = mapComponents[index].key;

        const updateVarMap = { ...variable_map };

        delete updateVarMap[mapKey];
        const updateOptions = { ...layerOptions, variable_map: updateVarMap };

        setVarMapKey('');
        setVarMapVal('');

        onChange(updateOptions);
    };

    const sourceCompField =
        srcVariabes.length > 0 &&
        srcVariabes.map((mapKey) => {
            const filteredOptions = filterOptions(varOptions, srcVarOptions, mapKey);
            const newVarOptions = [
                { label: '--- Select a Variable ---', value: '', type: '' }
            ].concat(filteredOptions);
            const sortedOptions = sortVarOptions(newVarOptions);
            return (
                <div
                    key={mapKey}
                    className='item'>
                    <div className='mapping'>
                        <div className='var_one'>{mapKey}</div>
                        <div className='equal'>{ICON_EQUALS}</div>
                        <SelectField
                            value={varMapVal}
                            options={sortedOptions}
                            width='50%'
                            onChange={(mapVal) =>
                                onChangeHandler(
                                    mapKey,
                                    mapVal,
                                    layerOptions,
                                    variable_map,
                                    onChange
                                )
                            }
                        />
                    </div>
                </div>
            );
        });

    const varSelectField = (
        <div className='controlled-list'>
            {sourceCompField}
            <div className='item'>
                <div className='mapping'>
                    <SelectField
                        value={varMapKey}
                        options={sortedVarOptions}
                        width='50%'
                        onChange={setVarMapKey}
                    />
                    <div className='equal'>{ICON_EQUALS}</div>
                    <SelectField
                        value={varMapVal}
                        options={updateVarOptions}
                        disable={disable}
                        width='50%'
                        onChange={setValue}
                    />
                </div>
            </div>
        </div>
    );

    return (
        <>
            <FieldWrapper
                label={copy.layerConfig.newVarProxy}
                customClass='variable_map'>
                {varSelectField}
            </FieldWrapper>

            <FieldWrapper
                label={copy.layerConfig.varProxies}
                customClass='variables_mapped'>
                <ControlledList
                    items={mapComponents}
                    removable={true}
                    onRemove={removeMapping}
                />
            </FieldWrapper>
        </>
    );
};

const onChangeHandler = (mapKey, mapVal, layerOptions, variable_map, onChange) => {
    let updateOptions = layerOptions;

    if (!variable_map) {
        const addVariableMap = { ...layerOptions, variable_map: { [mapKey]: mapVal } };
        updateOptions = addVariableMap;
    } else {
        const updateVarMap = { ...variable_map, ...{ [mapKey]: mapVal } };
        updateOptions = { ...layerOptions, variable_map: updateVarMap };
    }

    onChange(updateOptions);
};

const getVarsOptions = (variables, variable_map, layerVars = []) => {
    let isEmpty = true;

    if (variable_map) {
        isEmpty = Object.keys(variable_map).length === 0;
    }

    let varOptions = [{ label: '--- Select a Variable ---', value: '', type: '' }];

    for (const variable in variables) {
        if (variables.hasOwnProperty(variable)) {
            const type = variables[variable].type;
            const option = {
                value: variable,
                label: variable,
                type
            };

            varOptions.push(option);
        }
    }

    if (layerVars.length > 0) {
        for (const vars of layerVars) {
            const index = varOptions.findIndex((object) => {
                return object.value === vars;
            });
            varOptions.splice(index, 1);
        }
    }

    if (!isEmpty) {
        for (const variable in variable_map) {
            if (variable) {
                varOptions = varOptionsUpdater(variable, variable_map[variable], varOptions);
            }
        }
    }

    return varOptions;
};

const sortVarOptions = (options) => {
    return _.orderBy(options, ['label'], ['asc']);
};

const varOptionsUpdater = (key, value, varOptions, filter = false) => {
    let newVarOptions = varOptions;

    if (filter) {
        const filteredOptions = filterOptions(newVarOptions, newVarOptions, key);
        newVarOptions = [{ label: '--- Select a Variable ---', value: '', type: '' }].concat(
            filteredOptions
        );
    }

    return _.reject(
        _.reject(newVarOptions, (el) => el.value === value),
        (el) => el.value === key
    );
};

const filterOptions = (firstOptions, secondOptions, key) => {
    const type = firstOptions.find((f) => f.value === key)?.type;
    return secondOptions.filter(
        (f) =>
            f.type === type ||
            (type === 'text' && f.type === 'enum') ||
            (type === 'enum' && f.type === 'text')
    );
};

const renderMapping = (variable_map, variables, srcVarOptions, layerOptions, onChange) => {
    const mapArray = [];

    if (variable_map) {
        for (const mapKey in variable_map) {
            if (mapKey) {
                const mapVal = variable_map[mapKey];
                const { name, type } = variables[mapVal];
                const option = {
                    value: name,
                    label: name,
                    type
                };

                let mappedOptions = srcVarOptions.filter((f) => f.type === type);
                mappedOptions = mappedOptions.concat(option);

                const sortedMappedOptions = sortVarOptions(mappedOptions);
                mapArray.push(
                    <div
                        key={`${mapKey}`}
                        className='mapping'>
                        <div className='var_one'>{mapKey}</div>
                        <div className='equal'>{ICON_EQUALS}</div>
                        <SelectField
                            value={mapVal}
                            options={sortedMappedOptions}
                            width='50%'
                            onChange={(i) =>
                                onChangeHandler(mapKey, i, layerOptions, variable_map, onChange)
                            }
                        />
                    </div>
                );
            }
        }

        return mapArray;
    }
};
