import * as React from 'react';
import { ROW_HEIGHT } from '../../constants/timeline';

interface IErrorLayerProps {
    scale: number;
}

const iconIn = (
    <svg
        version='1.1'
        id='Layer_1'
        x='0px'
        y='0px'
        viewBox='0 0 100 100'>
        <polygon points='100,100 0,0 100,0 ' />
    </svg>
);

class ErrorLayer extends React.PureComponent<IErrorLayerProps> {
    constructor(props) {
        super(props);
    }

    public render() {
        const style = {
            height: `${ROW_HEIGHT}px`
        };

        return (
            <>
                <div
                    className='error'
                    style={style}>
                    {iconIn}
                </div>
            </>
        );
    }
}

export default ErrorLayer;
