import * as React from 'react';
import { ICON_EXCLIMATION_TRIANGLE } from '../../constants/icons';
import { errors as copy } from '../../constants/copy';

interface IPaneErrorBoundryState {
    hasError: boolean;
}

interface IPaneErrorBoundryProps {
    children?: any;
}

class PaneErrorBoundry extends React.Component<IPaneErrorBoundryProps, IPaneErrorBoundryState> {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    public static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    public render() {
        if (this.state.hasError) {
            return (
                <div className='pane-error'>
                    <h1>
                        {ICON_EXCLIMATION_TRIANGLE}&nbsp;{copy.pane}
                    </h1>
                </div>
            );
        }

        return this.props.children;
    }
}

export default PaneErrorBoundry;
