import {
    AudioPreview,
    ImagePreview,
    VideoPreview,
    getMediaPreviewStyle
} from '@imposium-hub/components';
import { VARIABLE_TYPES } from '../../constants/story';

export const DatasetMediaPreview = ({ config, onClose }) => {
    const { type, data, cell } = config;
    const playbackSettings = {
        autoPlay: true,
        loop: false
    };

    switch (type) {
        case VARIABLE_TYPES.VIDEO:
            const videoStyle = getMediaPreviewStyle(100, 100, cell, 5);
            return (
                <VideoPreview
                    disableOutsideClick={true}
                    playbackSettings={playbackSettings}
                    style={videoStyle}
                    url={data}
                    onRequestClose={onClose}
                />
            );
        case VARIABLE_TYPES.IMAGE:
            const imageStyle = getMediaPreviewStyle(100, 100, cell, 5);
            imageStyle['objectFit'] = 'contain';
            return (
                <ImagePreview
                    disableOutsideClick={true}
                    style={imageStyle}
                    url={data}
                    onRequestClose={onClose}
                />
            );
        case VARIABLE_TYPES.AUDIO:
            const audioStyle = getMediaPreviewStyle(5, 1, cell, 5);
            return (
                <AudioPreview
                    disableOutsideClick={true}
                    playbackSettings={playbackSettings}
                    style={audioStyle}
                    url={data}
                    onRequestClose={onClose}
                />
            );
        default:
            return null;
    }
};
