import { dataEditor as copy } from '../../constants/copy';
import { Button, Modal, Section, TextField } from '@imposium-hub/components';
import { useHotkeys } from 'react-hotkeys-hook';

export const DatasetNameModal = ({
    show,
    datasetName,
    onClose,
    onChange,
    onContinue,
    renaming
}) => {
    const prompt = renaming ? copy.renamePrompt : copy.newPrompt;
    const cta = renaming ? copy.btnUpdateName : copy.btnContinue;

    useHotkeys('enter', onContinue, { enableOnFormTags: true });

    return (
        <Modal
            onRequestClose={onClose}
            style={{
                width: '400px',
                height: '70%',
                top: '10%',
                left: 'calc((100% - 400px) / 2)'
            }}
            isOpen={true}>
            <Section title={'New Dataset'}>
                <TextField
                    label={prompt}
                    labelPosition={'top'}
                    focusOnMount={true}
                    onChange={onChange}
                    value={datasetName}
                    maxLength={100}
                />
                <Button
                    disabled={!datasetName}
                    onClick={onContinue}
                    style='bold'
                    color='primary'>
                    {cta}
                </Button>
            </Section>
        </Modal>
    );
};
