import { Button } from '@imposium-hub/components';
import {
    ICON_CHECK,
    ICON_CIRCLE_NOTCH,
    ICON_ERROR,
    ICON_EYE,
    ICON_PLAY
} from '../../constants/icons';
import { dataEditor as copy } from '../../constants/copy';

export const DatasetRowActions = (props) => {
    const { errors, checkingForErrors } = props;
    const rowErrors = errors[props.row];
    const hasErrors = rowErrors ? (rowErrors.length > 0 ? true : false) : null;
    const indicator =
        checkingForErrors && hasErrors === null ? (
            <DatasetLoadingIndicator />
        ) : hasErrors === null ? null : hasErrors === true ? (
            <DatasetErrorIndicator
                onClick={() => {
                    return null;
                }}
            />
        ) : (
            <DatasetCheckIndicator />
        );

    return (
        <div className='dataset-row-actions'>
            <div className='dataset-row-actions-buttons'>
                <Button
                    customClass='btn-render-row'
                    onClick={() => {
                        return null;
                    }}
                    style='bold'
                    tooltip={copy.btnRenderTooltip}
                    color='primary'>
                    {ICON_PLAY} {copy.btnRender}
                </Button>
                <Button
                    customClass='btn-preview-row'
                    onClick={() => {
                        return null;
                    }}
                    style='bold'
                    tooltip={copy.btnPreviewTooltip}
                    color='primary'>
                    {ICON_EYE} {copy.btnPreview}
                </Button>
            </div>
            {indicator}
        </div>
    );
};

export const DatasetErrorIndicator = ({ onClick }) => {
    return (
        <div className='dataset-error-indicator dataset-action-icon'>
            <Button
                customClass='btn-error-indicator'
                style='subtle'
                size='large'
                onClick={onClick}>
                {ICON_ERROR}
            </Button>
        </div>
    );
};

export const DatasetCheckIndicator = () => {
    return <div className='dataset-check-indicator dataset-action-icon'>{ICON_CHECK}</div>;
};

export const DatasetLoadingIndicator = () => {
    return (
        <div className='dataset-loading-indicator dataset-action-icon icon-spin-infinite'>
            {ICON_CIRCLE_NOTCH}
        </div>
    );
};
