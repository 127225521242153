import * as React from 'react';
import { Card, ColorField } from '@imposium-hub/components';
import { fields as copy } from '../constants/copy';
import { colorPresets } from '../constants/snippets';
import { rgbaToHexa } from '../util/general';

interface ISolidLayerOptionsProps {
    options: any;
    onChange(options: any): void;
}

class SolidLayerOptions extends React.PureComponent<ISolidLayerOptionsProps> {
    private evtHandlers = {
        color: (v) => this.updateSouceField(rgbaToHexa(v.rgb))
    };

    constructor(props) {
        super(props);
    }

    private updateSouceField(source) {
        const merged = { ...this.props.options.source, ...{ color: source } };
        const newOptions = { ...this.props.options, source: { ...merged } };
        this.props.onChange(newOptions);
    }

    public render() {
        const {
            options: { source }
        } = this.props;

        const color = source.color
            ? source.color === '#00000000'
                ? 'transparent'
                : source.color
            : '#ff0000ff';

        return (
            <Card
                title={copy.layerConfig.options}
                open={true}
                collapsable={true}
                style={'options'}>
                <ColorField
                    key='color-field'
                    width='50%'
                    enableAlpha={true}
                    presetColors={colorPresets}
                    label={copy.global.color}
                    onChange={this.evtHandlers.color}
                    value={color}
                />
            </Card>
        );
    }
}

export default SolidLayerOptions;
