import * as React from 'react';
import {
    FRAME_WIDTH,
    LAYER_ROW_HEIGHT,
    CUT_HANDLE_WIDTH,
    CUT_HANDLE_BREAKPOINT,
    LAYER_PADDING,
    LAYER_SELECT_BOX_PADDING
} from '../../constants/timeline';
import {
    getXPosFromFrame,
    moveKeyframe,
    removeKeyframe,
    replaceKeyframe
} from '../../util/timeline';
// import Draggable from 'react-draggable';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { addViewer } from '../../redux/actions/story';
import { IComposition, ICompositionLayer } from '../../constants/snippets';
import { updateEditorConfig } from '../../redux/actions/editor';
import classNames from 'classnames';
import { ASSET_TYPES, COMPOSITION_LAYER_TYPES, SOURCE_TYPES } from '../../constants/story';
import { LayerAnchorDragHandle } from './LayerAnchorDragHandle';
import { AnchorOverTarget } from './AnchorOverTarget';
import { LayerKeyframeDisplay } from './LayerKeyframeDisplay';
import { LayerAnchorRemove } from './LayerAnchorRemove';
import { LOWER_PANELS } from '../../constants/editor';
import { setComposition } from '../../redux/actions/compositions';
import { ICON_IMAGE, ICON_IMAGE_SEQUENCE, ICON_WARNING } from '../../constants/icons';
import { updateTimelineState } from '../../redux/actions/timeline';
import { Draggable } from './Draggable';
import { getSourceKey } from '../viewer/composition-preview/LayeredCompositionPreview';

interface ICompositionLayerProps {
    layerDragOffset: number;
    leftDragFrameOffset: number;
    rightDragFrameOffset: number;
    setLeftDragOffset(o: number): void;
    setRightDragOffset(o: number): void;
    setLayerOffsetFrames(o: number): void;
    tempOffsetFrames: number;
    anchorMode: boolean;
    active: boolean;
    activeLayer: string;
    config: ICompositionLayer;
    scale: number;
    y: number;
    timelineWidth: number;
    select(cutId: string, overlayId?: string): void;
    multiSelect(layerId: string): void;
    isMultiSelected: boolean;
    shift(frames: number): void;
    onDrag(frame: number, offset?: number): void;
    clearOffset(): void;
    addViewer(c: any): void;
    update(config): void;
    updateEditorConfig(c): void;
    updateTimelineState(s): void;
    updateAnchor(layerId: string, targetLayerId: string, start: boolean, targetStart: boolean);
    errors: any;
    expanded: boolean;
    activeKeyframe: string;
    startAnchorIds: string[];
    endAnchorIds: string[];
    deleteAnchor(layerId: string, anchorLayerId: string, start: boolean): void;
    compositions: any;
    compositionId: any;
    layerSources: any;
    sourceKey: string;
    layerErrors: any;
    matte: boolean;
    setDragState(s: any): void;
    setComposition: (id: string, comp: IComposition) => void;
    openFilteredList: boolean;
}

class CompositionLayer extends React.Component<ICompositionLayerProps, undefined> {
    private evtHandlers = {
        leftHandleStart: (e, i) => this.onLeftHandleDragStart(e, i),
        leftHandleStop: (e, i) => this.onLeftHandleDragStop(e, i),
        leftHandleDrag: (e, i) => this.onLeftHandleDrag(e, i),
        rightHandleStart: (e, i) => this.onRightHandleDragStart(e, i),
        rightHandleStop: (e, i) => this.onRightHandleDragStop(e, i),
        rightHandleDrag: (e, i) => this.onRightHandleDrag(e, i),
        baseStop: (e, i) => this.onBaseDragStop(e, i),
        baseStart: (e, i) => this.onBaseDragStart(e, i),
        baseDrag: (e, i) => this.onBaseDrag(e, i)
    };

    constructor(props) {
        super(props);
    }

    public layerClicked(e) {
        if (!this.props.config.locked && !this.props.anchorMode) {
            e.preventDefault();
            e.stopPropagation();

            // multi select
            if (e.shiftKey) {
                this.props.multiSelect(this.props.config.id);
            } else if (!this.props.isMultiSelected) {
                this.props.select(this.props.config.id);
            }
        }
    }

    private getStartFrame() {
        const s: any = this.props.config.start_frame;
        return parseInt(s, 10);
    }

    private getEndFrame() {
        const e: any = this.props.config.end_frame;
        return parseInt(e, 10);
    }

    public getLayerWidth(applyOffset = false): number {
        const { scale, leftDragFrameOffset, rightDragFrameOffset } = this.props;
        const startFrame = this.getStartFrame();
        const endFrame = this.getEndFrame();
        if (applyOffset) {
            const frames = endFrame + rightDragFrameOffset - (startFrame + leftDragFrameOffset);
            return FRAME_WIDTH * frames * scale;
        } else {
            const frames = endFrame - startFrame;
            return FRAME_WIDTH * frames * scale;
        }
    }

    public getLayerX(): number {
        const { scale } = this.props;
        return getXPosFromFrame(this.getStartFrame(), scale);
    }

    private onBaseDragStart(e, dragInfo) {
        const { setDragState } = this.props;
        this.layerClicked(e);
        setDragState({
            draggingHandle: false,
            draggingPlayhead: false,
            draggingLayer: true
        });
        this.props.setLeftDragOffset(0);
    }

    public onBaseDrag(e, dragInfo) {
        const { onDrag, scale } = this.props;

        const startFrame = this.getStartFrame();
        const offsetFrames = Math.round(dragInfo.offsetX / (FRAME_WIDTH * scale));

        if (offsetFrames !== this.props.layerDragOffset) {
            onDrag(startFrame + offsetFrames, offsetFrames);
        }
    }

    public onBaseDragStop(e, dragInfo) {
        const { shift, clearOffset } = this.props;
        const startFrame = this.getStartFrame();
        const newStartFrame = startFrame + this.props.layerDragOffset;
        if (startFrame !== newStartFrame) {
            // kills the main click event if the layer was dragged
            e.preventDefault();
            e.stopPropagation();

            const frames = newStartFrame - startFrame;
            clearOffset();
            shift(frames);
        }

        this.props.setDragState({
            draggingHandle: false,
            draggingPlayhead: false,
            draggingLayer: false
        });
        this.props.setLeftDragOffset(0);
    }

    public onRightHandleDragStart(e, dragInfo) {
        const {
            onDrag,
            select,
            config: { id }
        } = this.props;
        select(id);
        this.props.setDragState({
            draggingHandle: true,
            draggingPlayhead: false,
            draggingLayer: false
        });
        onDrag(this.getEndFrame() - 1);
    }

    public onRightHandleDrag(e, dragInfo) {
        const { onDrag, scale } = this.props;
        const endFrame = this.getEndFrame();
        const offsetFrames = Math.round(dragInfo.offsetX / (FRAME_WIDTH * scale));
        if (offsetFrames !== this.props.rightDragFrameOffset) {
            this.props.setRightDragOffset(offsetFrames);
            onDrag(endFrame + offsetFrames - 1);
        }
    }

    public onRightHandleDragStop(e, dragInfo) {
        const { config, update } = this.props;
        const endFrame = this.getEndFrame();
        const newEndFrame = endFrame + this.props.rightDragFrameOffset;

        this.props.setRightDragOffset(0);
        this.props.setDragState({
            draggingHandle: false,
            draggingPlayhead: false,
            draggingLayer: false
        });

        if (newEndFrame !== endFrame) {
            const newConfig = { ...config };
            newConfig.end_frame = newEndFrame;
            update(newConfig);
        }
    }

    public onLeftHandleDragStart(e, ui) {
        const {
            onDrag,
            select,
            setDragState,
            config: { id }
        } = this.props;
        select(id);
        setDragState({
            draggingHandle: true,
            draggingPlayhead: false,
            draggingLayer: false
        });
        onDrag(this.getStartFrame());
    }

    public onLeftHandleDrag(e, dragInfo) {
        const {
            onDrag,
            scale,
            config: { offset_frames }
        } = this.props;

        const isOffset = e.shiftKey;
        const startFrame = this.getStartFrame();
        const offsetFrames = Math.round(dragInfo.offsetX / (FRAME_WIDTH * scale));
        if (offsetFrames !== this.props.leftDragFrameOffset) {
            this.props.setLeftDragOffset(offsetFrames);
            if (isOffset) {
                const newOffset = Math.max(0, offset_frames + offsetFrames);
                this.props.setLayerOffsetFrames(newOffset);
            }
            onDrag(startFrame + offsetFrames);
        }
    }

    public onLeftHandleDragStop(e, dragInfo) {
        const isOffset = e.shiftKey;
        const { update, config } = this.props;
        const startFrame = this.getStartFrame();
        const newStartFrame = startFrame + this.props.leftDragFrameOffset;
        if (isOffset) {
            const newConfig = { ...config };
            const leftDragFrameOffset = newStartFrame - startFrame;
            const newOffset = Math.max(0, newConfig.offset_frames + leftDragFrameOffset);
            newConfig.offset_frames = newOffset;
            newConfig.start_frame = newConfig.start_frame + leftDragFrameOffset;
            update(newConfig);
        } else {
            if (newStartFrame !== startFrame) {
                const newConfig = { ...config };
                newConfig.start_frame = newStartFrame;
                update(newConfig);
            }
        }
        this.props.setLeftDragOffset(0);
        this.props.setLayerOffsetFrames(null);
        this.props.setDragState({
            draggingHandle: false,
            draggingPlayhead: false,
            draggingLayer: false
        });
    }

    public renderCutHandles(w, x, y): any[] {
        const { active, anchorMode } = this.props;
        if (active && !anchorMode) {
            const { timelineWidth, scale } = this.props;

            const handleStyle = {
                width: `${CUT_HANDLE_WIDTH}px`,
                top: `${LAYER_SELECT_BOX_PADDING}px`,
                height: `${LAYER_ROW_HEIGHT - LAYER_SELECT_BOX_PADDING * 2 - 1}px`
            };

            const offsetX = this.getBaseOffsetX();

            const lPos = {
                x: x + -CUT_HANDLE_WIDTH + offsetX,
                y
            };

            const lBounds = {
                left: -CUT_HANDLE_WIDTH,
                right: x + this.getLayerWidth() - CUT_HANDLE_WIDTH - FRAME_WIDTH * scale,
                top: 0,
                bottom: 0
            };

            const right = timelineWidth;

            const rBounds = {
                left: x + getXPosFromFrame(1, scale),
                right,
                top: 0,
                bottom: 0
            };

            const rPos = {
                x: x + w + offsetX,
                y
            };

            const snapGrid = {
                x: FRAME_WIDTH * scale
            };

            return [
                <Draggable
                    key='left-handle'
                    axis='x'
                    bounds={lBounds}
                    position={lPos}
                    grid={snapGrid}
                    onStart={this.evtHandlers.leftHandleStart}
                    onStop={this.evtHandlers.leftHandleStop}
                    onDrag={this.evtHandlers.leftHandleDrag}>
                    <div
                        className='layer-handle left'
                        style={handleStyle}></div>
                </Draggable>,
                <Draggable
                    key='right-handle'
                    axis='x'
                    bounds={rBounds}
                    position={rPos}
                    grid={snapGrid}
                    onStart={this.evtHandlers.rightHandleStart}
                    onStop={this.evtHandlers.rightHandleStop}
                    onDrag={this.evtHandlers.rightHandleDrag}>
                    <div
                        className='layer-handle right'
                        style={handleStyle}></div>
                </Draggable>
            ];
        }
    }

    public renderDragHandle(w, x, y): any {
        const {
            anchorMode,
            timelineWidth,
            config: { type }
        } = this.props;

        if (w >= CUT_HANDLE_BREAKPOINT && !anchorMode) {
            const handleStyle = {
                height: `${LAYER_ROW_HEIGHT - LAYER_PADDING * 2}px`,
                width: `${w}px`,
                top: `${LAYER_PADDING}px`
            };

            const leftOffsetX = this.getLeftOffsetX();

            const bPos = {
                x: x + leftOffsetX,
                y
            };

            const hBounds = {
                left: 0,
                right: timelineWidth - this.getLayerWidth(),
                top: 0,
                bottom: 0
            };

            const snapGrid = {
                x: FRAME_WIDTH * this.props.scale
            };

            return (
                <Draggable
                    axis='x'
                    bounds={hBounds}
                    position={bPos}
                    grid={snapGrid}
                    onStop={this.evtHandlers.baseStop}
                    onStart={this.evtHandlers.baseStart}
                    onDrag={this.evtHandlers.baseDrag}>
                    <div
                        className='layer-drag-handle'
                        onDoubleClick={() => this.onDoubleClicked(type)}
                        style={handleStyle}
                    />
                </Draggable>
            );
        }
    }

    private renderAnchors() {
        const { anchorMode, active, config } = this.props;
        if (anchorMode && !active) {
            return (
                <AnchorOverTarget
                    removeButtons={[this.getRemoveButtons()]}
                    layerId={config.id}
                    onAnchorDrop={this.props.updateAnchor}
                />
            );
        } else if (active && anchorMode) {
            return [
                <LayerAnchorDragHandle
                    key={'start-anchor-drag-handle'}
                    id={config.id}
                    start={true}
                />,
                <LayerAnchorDragHandle
                    key={'end-anchor-drag-handle'}
                    id={config.id}
                    start={false}
                />
            ];
        } else {
            return null;
        }
    }

    private getRemoveButtons() {
        const { startAnchorIds, endAnchorIds, activeLayer } = this.props;
        const removeButtons = [];
        if (startAnchorIds.indexOf(activeLayer) !== -1) {
            removeButtons.push(
                <LayerAnchorRemove
                    key={'remove-start-anchor'}
                    onClick={() => this.props.deleteAnchor(activeLayer, this.props.config.id, true)}
                    id={activeLayer}
                    start={true}
                />
            );
        }

        if (endAnchorIds.indexOf(activeLayer) !== -1) {
            removeButtons.push(
                <LayerAnchorRemove
                    key={'remove-end-anchor'}
                    onClick={() =>
                        this.props.deleteAnchor(activeLayer, this.props.config.id, false)
                    }
                    id={activeLayer}
                    start={false}
                />
            );
        }

        return removeButtons;
    }

    private renderOffsetIndicator() {
        const {
            config: { offset_frames },
            tempOffsetFrames,
            scale
        } = this.props;

        const dragOffset = this.getLeftOffsetX();
        const offset = tempOffsetFrames !== null ? tempOffsetFrames : offset_frames || 0;
        const offsetWidth = FRAME_WIDTH * offset * scale;
        const offsetStyle = {
            width: `${offsetWidth}px`,
            height: `${LAYER_ROW_HEIGHT - 5}px`,
            left: `${-offsetWidth + dragOffset}px`
        };

        return (
            <div
                className='layer-offset-indicator'
                style={offsetStyle}></div>
        );
    }

    private getLeftOffsetX() {
        const { scale, leftDragFrameOffset, layerDragOffset } = this.props;
        return getXPosFromFrame(leftDragFrameOffset + layerDragOffset, scale);
    }

    private getBaseOffsetX() {
        const { scale, layerDragOffset } = this.props;
        return getXPosFromFrame(layerDragOffset, scale);
    }

    private renderBorders() {
        const offsetX = this.getLeftOffsetX();

        const topStyle = {
            width: `${this.getLayerWidth(true)}px`,
            top: `${LAYER_SELECT_BOX_PADDING}px`,
            left: `${offsetX}px`
        };

        const bottomStyle = {
            width: `${this.getLayerWidth(true)}px`,
            bottom: `${LAYER_SELECT_BOX_PADDING}px`,
            left: `${offsetX}px`
        };

        if (this.props.active) {
            return (
                <>
                    <div
                        className='layer-border top'
                        style={topStyle}
                    />
                    <div
                        className='layer-border bottom'
                        style={bottomStyle}
                    />
                </>
            );
        } else {
            return null;
        }
    }

    private getDisplayClass = () => {
        const {
            config: { type, options }
        } = this.props;

        if (type !== COMPOSITION_LAYER_TYPES.TEXT) {
            const { source } = options;
            if (source.from === SOURCE_TYPES.INVENTORY) {
                return 'variable';
            } else if (source.from === SOURCE_TYPES.ASSET_TAGS) {
                return 'swap';
            }
        }
        return null;
    };

    private onSelectKeyframe = (keyframe) => {
        this.props.updateTimelineState({
            activeKeyframe: keyframe.id
        });
    };

    private onDeselectKeyframe = () => {
        this.props.updateTimelineState({
            activeKeyframe: null
        });
    };

    private onChangeKeyframe = (field, keyframe) => {
        const { config } = this.props;
        const updated = replaceKeyframe(config.keyframes, field, keyframe);
        if (updated) {
            const newConfig = { ...config };
            newConfig.keyframes = updated;
            this.props.update(newConfig);
        }
    };

    private onMoveKeyframe = (field, index, frames) => {
        const { config } = this.props;
        const updated = moveKeyframe(config.keyframes, field, index, frames);
        if (updated) {
            const newConfig = { ...config };
            newConfig.keyframes = updated;
            this.props.update(newConfig);
        }
    };

    private onDeleteKeyframe = (field, index) => {
        const { config } = this.props;
        const updated = removeKeyframe(config.keyframes, field, index);
        if (updated) {
            const newConfig = { ...config };
            newConfig.keyframes = updated;
            this.props.update(newConfig);
        }
    };

    private onJumpToKeyframe = (keyframe) => {
        const frame = keyframe.relativeFrame + this.props.config.start_frame;
        this.props.updateTimelineState({
            activeFrame: frame
        });
    };

    private renderKeyframes = (config, w) => {
        const {
            expanded,
            scale,
            activeKeyframe,
            compositionId,
            compositions,
            layerSources,
            sourceKey,
            timelineWidth
        } = this.props;
        if (expanded) {
            return (
                <LayerKeyframeDisplay
                    onMove={this.onMoveKeyframe}
                    onSelect={this.onSelectKeyframe}
                    onDeselect={this.onDeselectKeyframe}
                    onDelete={this.onDeleteKeyframe}
                    onJump={this.onJumpToKeyframe}
                    onChange={this.onChangeKeyframe}
                    activeKeyframe={activeKeyframe}
                    config={config}
                    scale={scale}
                    compositions={compositions}
                    compositionId={compositionId}
                    layerSources={layerSources}
                    sourceKey={sourceKey}
                    layerWidth={w}
                    timelineWidth={timelineWidth}
                />
            );
        } else {
            return null;
        }
    };

    private renderDynamicDurationIndicator() {
        const { options } = this.props.config;

        if (options.use_swap_duration) {
            return <div className='dynamic-length-indicator' />;
        }
    }

    private onDoubleClicked(type) {
        const {
            compositions,
            compositionId,
            layerSources,
            config: {
                id: configId,
                name,
                options: {
                    source: { asset_id: id }
                }
            }
        } = this.props;

        if (id) {
            const tabLabel =
                type === ASSET_TYPES.VIDEO_COMPOSITION
                    ? `Composition: ${name || id}`
                    : `Asset: ${name || id}`;

            const asset = layerSources[getSourceKey(compositionId, configId)];

            if (type === ASSET_TYPES.VIDEO_COMPOSITION) {
                const compData = compositions[id] ? compositions[id] : asset.data;
                this.props.updateEditorConfig({
                    activeLowerPanel: LOWER_PANELS.TIMELINE
                });
                this.props.setComposition(id, compData);
                this.props.addViewer({ id, label: tabLabel, asset, type });
            }
            this.props.addViewer({ id, label: tabLabel, asset, type });
        }
    }

    private openFilteredListHandler() {
        const { openFilteredList } = this.props;

        this.props.updateEditorConfig({
            openFilteredList: !openFilteredList
        });
    }

    public render() {
        const {
            active,
            y,
            config,
            config: {
                id,
                type,
                locked,
                options: { use_swap_duration, source, animated }
            },
            matte,
            layerErrors
        } = this.props;

        const from = source ? source.from : null;
        const asset_tags = source ? source.asset_tags : null;
        const filteredList = layerErrors[id]?.filteredList;
        let warning = false;

        if (
            filteredList &&
            from === SOURCE_TYPES.ASSET_TAGS &&
            asset_tags &&
            asset_tags.length > 0 &&
            filteredList.length > 0 &&
            type === ASSET_TYPES.VIDEO
        ) {
            warning = true;
        }

        const errors = warning && !use_swap_duration && (
            <div
                className='errors'
                onClick={() => (warning ? this.openFilteredListHandler() : null)}>
                <span className='message'>{warning ? ICON_WARNING : ''}</span>
            </div>
        );

        const animatedIcon = animated ? ICON_IMAGE_SEQUENCE : ICON_IMAGE;

        let isAnimated;

        if (type === ASSET_TYPES.HTML || type === ASSET_TYPES.TEMPLATE) {
            isAnimated = (
                <div className='errors'>
                    <span className='message'>{animatedIcon}</span>
                </div>
            );
        }
        const x = this.getLayerX();
        const mainOffset = this.getLeftOffsetX();
        const displayClass = this.getDisplayClass();

        const cutStyle = {
            left: `${x}px`,
            height: `${LAYER_ROW_HEIGHT - 1}px`,
            width: `${this.getLayerWidth()}px`,
            top: `${y}px`
        };

        const baseStyle = {
            left: `${mainOffset}px`,
            height: `${LAYER_ROW_HEIGHT - 1 - LAYER_PADDING * 2}px`,
            top: `${LAYER_PADDING}px`,
            width: `${this.getLayerWidth(true)}px`
        };
        const layerClassName = classNames('layer', type.toLowerCase(), {
            active,
            locked,
            matte
        });

        const baseClassName = classNames('layer-base', displayClass, {
            selected: active
        });

        return (
            <>
                <div
                    className={layerClassName}
                    style={cutStyle}>
                    <div
                        className={baseClassName}
                        style={baseStyle}
                        onMouseUp={(e) => this.layerClicked(e)}>
                        <div className='iconDisplay'>
                            {errors}
                            {isAnimated}
                        </div>
                    </div>

                    {this.renderOffsetIndicator()}

                    {this.renderDynamicDurationIndicator()}

                    {this.renderBorders()}

                    {this.renderKeyframes(config, this.getLayerWidth())}

                    {this.renderAnchors()}
                </div>
                {this.renderDragHandle(this.getLayerWidth(), x, y)}
                {this.renderCutHandles(this.getLayerWidth(), x, y)}
            </>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        { addViewer, updateEditorConfig, updateTimelineState, setComposition },
        dispatch
    );
};

const mapStateToProps = (state): any => {
    return {
        activeKeyframe: state.timeline.activeKeyframe,
        errors: state.errors,
        compositions: state.compositions.present,
        compositionId: state.project.compositionId,
        layerSources: state.layerSources,
        layerErrors: state.layerErrors,
        openFilteredList: state.editor.openFilteredList
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompositionLayer);
