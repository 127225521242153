import * as React from 'react';
import { SliderField, Card, NumberField } from '@imposium-hub/components';
import { IOverlayEffects } from '../constants/snippets';
import { fields as copy } from '../constants/copy';

interface IAudioLayerEffectConfigProps {
    storyId: string;
    config: IOverlayEffects;
    onChange(e: any): void;
}

class AudioLayerEffectConfig extends React.PureComponent<IAudioLayerEffectConfigProps, undefined> {
    private evtHandlers: any;

    constructor(props) {
        super(props);

        this.evtHandlers = {
            inputs: {
                volume: (v) => this.update('volume', v),
                fadeIn: (v) => this.update('fade_in', v),
                fadeOut: (v) => this.update('fade_out', v)
            }
        };
    }

    private update(key, value) {
        const newConfig = { ...this.props.config };
        newConfig[key] = value;

        if (value === '' || value === null) {
            delete newConfig[key];
        }

        this.props.onChange(newConfig);
    }

    public render() {
        const { config } = this.props;

        return (
            <Card
                title={copy.layerConfig.effects}
                open={false}
                collapsable={true}
                style={'effects'}>
                <SliderField
                    label={copy.overlayConfig.volumeMultiplier}
                    tooltip={copy.overlayConfig.tooltipVolumeMultiplier}
                    value={config['volume']}
                    defaultValue={1}
                    width='33%'
                    min={0}
                    max={5}
                    step={0.1}
                    onChange={this.evtHandlers.inputs.volume}
                />
                <NumberField
                    label={copy.overlayConfig.fadeIn}
                    tooltip={copy.overlayConfig.tooltipFadeIn}
                    value={config['fade_in']}
                    width='33%'
                    min={0}
                    onChange={this.evtHandlers.inputs.fadeIn}
                />
                <NumberField
                    label={copy.overlayConfig.fadeOut}
                    tooltip={copy.overlayConfig.tooltipFadeOut}
                    value={config['fade_out']}
                    width='34%'
                    onChange={this.evtHandlers.inputs.fadeOut}
                />
            </Card>
        );
    }
}

export default AudioLayerEffectConfig;
