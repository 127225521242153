import * as React from 'react';
import VideoStoryAudioOutputSetting from './VideoStoryAudioOutputSetting';
import { connect } from 'react-redux';
import { getStoryType } from '../util/story';
import {
    IVideoStoryAudioOutputSetting,
    NEW_VIDEO_AUDIO_ENCODING_SETTING
} from '../constants/snippets';
import { fields as copy } from '../constants/copy';
import { IStory } from '../constants/snippets';
import type { IProject } from '../redux/reducers/project';
import { logError } from '../util/notifications';
import {
    ENCODING_SETTING_PRESET_AUDIO,
    ENCODING_SETTING_PRESET_AUDIO_OPTIONS
} from '../constants/story';
import { SelectField } from '@imposium-hub/components';

interface IVideoStoryAudioOutputSettingsProps {
    story: IStory;
    project: IProject;
    compositions: any;
    config: IVideoStoryAudioOutputSetting[];
    onChange(s): void;
    isNameDup?(b): void;
}

interface IVideoStoryAudioOutputSettingState {
    addingAdditional: boolean;
    additional: IVideoStoryAudioOutputSetting;
    activePreset: string;
}

class VideoStoryAudioOutputSettings extends React.PureComponent<
    IVideoStoryAudioOutputSettingsProps,
    IVideoStoryAudioOutputSettingState
> {
    constructor(props) {
        super(props);

        this.state = {
            addingAdditional: false,
            additional: null,
            activePreset: ENCODING_SETTING_PRESET_AUDIO_OPTIONS[0].value
        };
    }

    public updateSetting(index, config) {
        const settings = [...this.props.config];
        let isNameDup = false;
        for (let i = 0; i < settings.length; i++) {
            if (i !== index) {
                if (settings[i].name === config.name) {
                    isNameDup = true;
                }
            }
        }
        this.props.isNameDup(isNameDup);
        settings[index] = config;
        this.props.onChange(settings);
    }

    private checkNameHandler(setting, settings, num = 0) {
        let newName = `${setting.name}`;
        if (num > 0) {
            newName = `${setting.name} ${num}`;
        }
        const checkNewName = settings.findIndex((s) => s.name === newName);
        if (checkNewName === -1) {
            setting.name = newName;
            settings.push(setting);
            this.props.onChange(settings);
        } else {
            const numOfRep = num + 1;
            this.checkNameHandler(setting, settings, numOfRep);
        }
    }

    private presetChanged(v) {
        if (v === 'New') {
            this.newAdditionalAudio();
        } else {
            const setting = { ...ENCODING_SETTING_PRESET_AUDIO[v] };
            const settings = this.props.config ? [...this.props.config] : [];
            this.checkNameHandler(setting, settings);
        }
    }

    public updateAdditionalSetting(config) {
        this.setState({
            additional: config
        });
    }

    private deleteSetting(index) {
        const settings = [...this.props.config];
        settings.splice(index, 1);
        this.props.onChange(settings);
    }

    private duplicateSetting(index) {
        const { config } = this.props;

        const setting = { ...config[index] };
        setting.name = '';

        this.setState({
            addingAdditional: true,
            additional: setting
        });
    }

    private newAdditionalAudio() {
        const setting = { ...NEW_VIDEO_AUDIO_ENCODING_SETTING };
        this.setState({
            addingAdditional: true,
            additional: setting
        });
    }

    private idExists(id) {
        const { config } = this.props;
        if (config) {
            for (const setting of config) {
                if (setting.name === id) {
                    return true;
                }
            }
        }
        return false;
    }

    private confirmAdditional(config) {
        const setting = { ...config };

        if (!config.name) {
            logError(copy.encoding.errorNoId);
            return;
        }

        if (this.idExists(config.name)) {
            logError(copy.encoding.errorIdExists.replace('[ID]', config.name));
            return;
        }

        this.setState(
            {
                addingAdditional: false,
                additional: null
            },
            () => {
                const settings = this.props.config ? [...this.props.config] : [];
                settings.push(setting);
                this.props.onChange(settings);
            }
        );
    }

    private cancelAdditional() {
        this.setState({
            addingAdditional: false,
            additional: null
        });
    }

    public render() {
        const { config, story } = this.props;
        const { additional, addingAdditional } = this.state;
        const settings = config || [];
        const type = getStoryType(story);

        return (
            <div>
                <SelectField
                    label={copy.encoding.addAudioOutput}
                    tooltip={copy.encoding.tooltipAddAudioOutput}
                    width='100%'
                    disableFirst={true}
                    onChange={(v) => this.presetChanged(v)}
                    value={this.state.activePreset}
                    options={ENCODING_SETTING_PRESET_AUDIO_OPTIONS}
                />

                {settings.map((setting: IVideoStoryAudioOutputSetting, i) => {
                    return (
                        <VideoStoryAudioOutputSetting
                            type={type}
                            config={setting}
                            settings={settings}
                            onUpdate={(c) => this.updateSetting(i, c)}
                            onDelete={() => this.deleteSetting(i)}
                            onDuplicate={() => this.duplicateSetting(i)}
                            key={i}
                        />
                    );
                })}

                {addingAdditional && (
                    <VideoStoryAudioOutputSetting
                        type={type}
                        isNew={true}
                        config={additional}
                        settings={settings}
                        onUpdate={(c) => this.updateAdditionalSetting(c)}
                        onConfirm={(i) => this.confirmAdditional(i)}
                        onCancel={() => this.cancelAdditional()}
                        key={'new'}
                    />
                )}
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {};
};

const mapStateToProps = (state): any => {
    return { compositions: state.compositions.present };
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoStoryAudioOutputSettings);
