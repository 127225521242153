import { NumberField } from '@imposium-hub/components';
export const KeyframeNumberControls = ({
    onChange,
    value,
    labelOverrides,
    min = null,
    max = null
}) => {
    return (
        <div className={`keyframe-controls`}>
            <NumberField
                min={min}
                max={max}
                label={labelOverrides ? labelOverrides : ' '}
                onChange={onChange}
                value={value}
            />
        </div>
    );
};
