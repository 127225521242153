import * as React from 'react';
import { Button } from '@imposium-hub/components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeMute } from '@fortawesome/free-solid-svg-icons/faVolumeMute';
import { faVolumeUp } from '@fortawesome/free-solid-svg-icons/faVolumeUp';

interface IVolumeControlProps {
    volume: number;
    onChange(volume: number): void;
}

export class VolumeControl extends React.Component<IVolumeControlProps, undefined> {
    private toggleMute() {
        const { volume } = this.props;
        const newVolume = volume === 0 ? 1 : 0;

        this.props.onChange(newVolume);
    }

    private updateVolume(e) {
        const vol = e.target.value;
        this.props.onChange(parseFloat(vol));
    }

    public render() {
        const { volume } = this.props;
        const muteIcon =
            volume === 0 ? (
                <FontAwesomeIcon icon={faVolumeMute} />
            ) : (
                <FontAwesomeIcon icon={faVolumeUp} />
            );
        const btnMute = (
            <Button
                onClick={() => this.toggleMute()}
                style='subtle'>
                {muteIcon}
            </Button>
        );
        return (
            <div className='volume-controls'>
                {btnMute}
                <input
                    className='volume-slider'
                    value={this.props.volume}
                    type='range'
                    min={0}
                    max={1}
                    step={0.01}
                    onChange={(e) => this.updateVolume(e)}
                />
            </div>
        );
    }
}

export default VolumeControl;
