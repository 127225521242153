import { NumberField, Button } from '@imposium-hub/components';
import {
    ICON_ARROW_DOWN_FROM_LINE,
    ICON_ARROW_LEFT_FROM_LINE,
    ICON_ARROW_RIGHT_FROM_LINE,
    ICON_ARROW_UP_FROM_LINE
} from '../../constants/icons';
import { LAYOUT_UNITS, ORIGINS } from '../../constants/story';
import { fields } from '../../constants/copy';
import { toggleKeyframeUnit } from '../../util/timeline';
import { trimToFourDecimalPlaces } from '../../util/general';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsMaximize } from '@fortawesome/pro-light-svg-icons';

export const KeyframeCompoundControls = ({
    onChange,
    onDefaultValueChange,
    onUnitChange,
    value,
    keys,
    units,
    label,
    labelOverrides,
    config,
    compWidth,
    compHeight,
    showFitToComp,
    composition
}) => {
    const onFieldChange = (field, unit, v) => {
        const newVal = { ...value };
        const val = unit === LAYOUT_UNITS.PERCENT ? v / 100 : v;
        newVal[field] = val;
        onChange(newVal);
    };

    const { position_inputs } = config;

    const originX = position_inputs.originX || ORIGINS.LEFT;
    const originY = position_inputs.originY || ORIGINS.TOP;
    const originStyles = {
        fontSize: `12px`,
        marginRight: `-4px`
    };

    const toggleOriginX = () => {
        const newConfig = { ...config.position_inputs };
        const currOriginX = newConfig.originX || ORIGINS.LEFT;
        const newOriginX = currOriginX === ORIGINS.LEFT ? ORIGINS.RIGHT : ORIGINS.LEFT;
        newConfig.originX = newOriginX;
        onDefaultValueChange('position_inputs', newConfig);
    };

    const toggleOriginY = () => {
        const newConfig = { ...config.position_inputs };
        const currOriginY = newConfig.originY || ORIGINS.TOP;
        const newOriginY = currOriginY === ORIGINS.TOP ? ORIGINS.BOTTOM : ORIGINS.TOP;
        newConfig.originY = newOriginY;

        onDefaultValueChange('position_inputs', newConfig);
    };

    const unitStyles = {
        fontSize: '11px',
        lineHeight: '12px',
        padding: '1px',
        minWidth: '18px'
    };

    const getUnitButton = (field) => {
        const key = `${field}Unit`;
        const unit = position_inputs[key] || LAYOUT_UNITS.PIXELS;
        const icon = unit === LAYOUT_UNITS.PIXELS ? 'px' : '%';

        const evtHandlers = {
            inputs: {
                xUnit: () => toggleUnit('x'),
                yUnit: () => toggleUnit('y'),
                widthUnit: () => toggleUnit('width'),
                heightUnit: () => toggleUnit('height'),
                anchorXUnit: () => toggleUnit('anchorX'),
                anchorYUnit: () => toggleUnit('anchorY')
            }
        };

        return (
            <Button
                key={`btn-toggle-${field}`}
                customStyles={unitStyles}
                onClick={evtHandlers.inputs[key]}>
                {icon}
            </Button>
        );
    };

    const toggleUnit = (field) => {
        const { keyframes } = config;

        const newConfig = { ...position_inputs };
        const unitKey = `${field}Unit`;
        const currValue = newConfig[field];
        const unit = newConfig[unitKey];
        const newUnit = unit === LAYOUT_UNITS.PERCENT ? LAYOUT_UNITS.PIXELS : LAYOUT_UNITS.PERCENT;

        let newValue;
        let updatedKeyframes = { ...keyframes };

        // Anchor toggle is relative to width / height
        if (field === 'anchorX' || field === 'anchorY') {
            const widthUnit = position_inputs['widthUnit'] || LAYOUT_UNITS.PIXELS;
            const heightUnit = position_inputs['heightUnit'] || LAYOUT_UNITS.PIXELS;
            const realWidth =
                widthUnit === LAYOUT_UNITS.PIXELS ? newConfig.width : newConfig.width * compWidth;
            const realHeight =
                heightUnit === LAYOUT_UNITS.PIXELS
                    ? newConfig.height
                    : newConfig.height * compHeight;

            // If we're changing to percent
            if (newUnit === LAYOUT_UNITS.PERCENT) {
                newValue = field === 'anchorX' ? currValue / realWidth : currValue / realHeight;

                // If we're changing to pixels
            } else {
                newValue = field === 'anchorX' ? currValue * realWidth : currValue * realHeight;
            }

            const scalar = field === 'anchorX' ? realWidth : realHeight;

            // Modify the anchor keyframes, if they exist
            updatedKeyframes = toggleKeyframeUnit(newUnit, keyframes, 'anchor', field, scalar);

            // X, Y, Width, and Height are relative to compWidth and compHeight
        } else {
            if (newUnit === LAYOUT_UNITS.PERCENT) {
                newValue =
                    field === 'x' || field === 'width'
                        ? currValue / compWidth
                        : currValue / compHeight;
            } else {
                newValue =
                    field === 'x' || field === 'width'
                        ? currValue * compWidth
                        : currValue * compHeight;
            }

            const scalar = field === 'x' ? compWidth : compHeight;
            // If the x or y changed, modify the position keyframes, if they exist
            if (field === 'x' || field === 'y') {
                updatedKeyframes = toggleKeyframeUnit(
                    newUnit,
                    keyframes,
                    'position',
                    field,
                    scalar
                );
            }
        }

        // TODO: Update any keyframes to the new transformed value, if need be
        newConfig[`${field}Unit`] = newUnit;
        newConfig[field] = newValue;

        onUnitChange(newConfig, updatedKeyframes);
    };

    const btnToggleX: any = (
        <Button
            key={`btn-toggle-originX`}
            customStyles={originStyles}
            style='subtle'
            onClick={() => toggleOriginX()}>
            {originX === ORIGINS.LEFT ? (
                <>
                    {ICON_ARROW_RIGHT_FROM_LINE}&nbsp;&nbsp;
                    {fields.global.left}
                </>
            ) : (
                <>
                    {ICON_ARROW_LEFT_FROM_LINE}&nbsp;&nbsp;
                    {fields.global.right}
                </>
            )}
        </Button>
    );

    const btnToggleY: any = (
        <Button
            key={`btn-toggle-originY`}
            customStyles={originStyles}
            style='subtle'
            onClick={() => toggleOriginY()}>
            {originY === ORIGINS.TOP ? (
                <>
                    {ICON_ARROW_DOWN_FROM_LINE}&nbsp;&nbsp;
                    {fields.global.top}
                </>
            ) : (
                <>
                    {ICON_ARROW_UP_FROM_LINE}&nbsp;&nbsp;
                    {fields.global.bottom}
                </>
            )}
        </Button>
    );

    const getPlaceholderButton = (type) => {
        return (
            <Button
                key={`btn-toggle-placeholder`}
                disabled={true}
                customStyles={unitStyles}>
                {type}
            </Button>
        );
    };

    const fitToComp = () => {
        const newConfig = { ...position_inputs };
        const { height, width } = composition;

        if (newConfig.heightUnit === LAYOUT_UNITS.PERCENT) {
            newConfig.height = 1;
        } else {
            newConfig.height = height;
        }

        if (newConfig.widthUnit === LAYOUT_UNITS.PERCENT) {
            newConfig.width = 1;
        } else {
            newConfig.width = width;
        }

        if (newConfig.anchorXUnit === LAYOUT_UNITS.PERCENT) {
            newConfig.anchorX = 0.5;
        } else {
            newConfig.anchorX = width / 2;
        }

        if (newConfig.anchorYUnit === LAYOUT_UNITS.PERCENT) {
            newConfig.anchorY = 0.5;
        } else {
            newConfig.anchorY = height / 2;
        }

        if (newConfig.xUnit === LAYOUT_UNITS.PERCENT) {
            newConfig.x = 0.5;
        } else {
            newConfig.x = width / 2;
        }

        if (newConfig.yUnit === LAYOUT_UNITS.PERCENT) {
            newConfig.y = 0.5;
        } else {
            newConfig.y = height / 2;
        }

        newConfig.scaleX = 1;
        newConfig.scaleY = 1;

        onDefaultValueChange('position_inputs', newConfig);
    };

    const fitToCompBtn =
        label === 'Dimensions' && !showFitToComp ? (
            <Button
                color='default'
                tooltip='Fit To Comp'
                onClick={() => fitToComp()}
                customStyles={{
                    position: 'relative',
                    bottom: '1px',
                    left: '8px'
                }}>
                <FontAwesomeIcon icon={faArrowsMaximize} />
            </Button>
        ) : null;
    return (
        <div className={`keyframe-controls quad`}>
            {keys.map((key, i) => {
                const val = value !== undefined ? value[key] : null;
                const modifiedValue =
                    units[i] === LAYOUT_UNITS.PERCENT ? trimToFourDecimalPlaces(val * 100) : val;
                let btn;
                let labelBtn = null;

                if (key === 'x') {
                    labelBtn = btnToggleX;
                }

                if (key === 'y') {
                    labelBtn = btnToggleY;
                }

                switch (key) {
                    case 'anchorX':
                    case 'scaleX':
                        labelBtn = 'X';
                        break;
                    case 'anchorY':
                    case 'scaleY':
                        labelBtn = 'Y';
                        break;
                    case 'width':
                        labelBtn = 'Width';
                        break;
                    case 'height':
                        labelBtn = 'Height';
                        break;
                }

                switch (label) {
                    case 'Position':
                    case 'Anchor Point':
                        btn = [[getUnitButton(key)]];
                        break;
                    case 'Scale':
                        btn = [getPlaceholderButton('%')];
                        break;
                    case 'Dimensions':
                        btn = [[getUnitButton(key)]];
                        break;
                    default:
                        btn = null;
                        break;
                }

                if (labelOverrides && labelOverrides[key]) {
                    labelBtn = labelOverrides[key];
                }

                return (
                    <NumberField
                        key={key}
                        onChange={(v) => onFieldChange(key, units[i], v)}
                        value={modifiedValue}
                        buttons={btn}
                        label={labelBtn}
                        width={`135px`}
                    />
                );
            })}
            {fitToCompBtn}
        </div>
    );
};
