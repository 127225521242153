import { DeterminateLoader, Spinner } from '@imposium-hub/components';
import * as React from 'react';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { fields as copy } from '../constants/copy';
import { ICON_PLUS } from '../constants/icons';
import { IStory } from '../constants/snippets';
import { updateScene } from '../redux/actions/story';
import type { IProject } from '../redux/reducers/project';
import BaseVideoUploader from '../services/BaseVideoUploader';

interface IBaseVideoPromptProps {
    story: IStory;
    project: IProject;
    updateScene(config: any): void;
}

interface IBaseVideoPromptState {
    uploading: boolean;
    processing: boolean;
    uploadProgress: number;
}

class BaseVideoPrompt extends React.PureComponent<IBaseVideoPromptProps, IBaseVideoPromptState> {
    private input: any;

    private uploader: BaseVideoUploader;

    constructor(props) {
        super(props);

        this.state = {
            uploading: false,
            processing: false,
            uploadProgress: 0
        };

        this.uploader = new BaseVideoUploader({
            onUploadStart: () => {
                this.setState({ uploading: true, uploadProgress: 0 });
            },
            onProgress: (p) => {
                this.setState({ uploadProgress: p });
            },
            onProcessStart: () => {
                this.setState({ uploading: false, processing: true });
            },
            onComplete: (output) => {
                this.setState({ processing: false, uploading: false });
                this.baseVideoUploaded(output);
            },
            onError: () => {
                this.setState({ processing: false, uploading: false, uploadProgress: 0 });
            }
        });

        this.input = React.createRef();
    }

    private baseVideoUploaded(v) {
        const {
            story,
            project: { actId, sceneId }
        } = this.props;
        const scene = { ...story.acts[actId].scenes[sceneId] };
        const sceneData = { ...scene.sceneData };
        sceneData.videoFile = v;
        scene.sceneData = sceneData;

        this.props.updateScene({
            actId,
            sceneId,
            scene
        });
    }

    private fileUploaded(files) {
        this.uploader.uploadBaseVideo(files[0]);
    }

    public onClick() {
        const input = this.input.current;
        const { uploading, processing } = this.state;

        if (input && !uploading && !processing) {
            input.click();
        }
    }

    public renderPrompt() {
        const { processing, uploading, uploadProgress } = this.state;

        if (processing) {
            return (
                <>
                    <div className='spinner'>
                        <Spinner />
                    </div>
                    <br />
                    <h2>{copy.base.processing}</h2>
                </>
            );
        } else if (uploading) {
            return (
                <>
                    <h2>{copy.base.uploading}</h2>
                    <div className='loader'>
                        <DeterminateLoader progress={uploadProgress} />
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <h2>{copy.base.uploadPrompt}</h2>
                    <div className='icon'>{ICON_PLUS}</div>
                </>
            );
        }
    }

    public render() {
        const { processing, uploading } = this.state;

        return (
            <Dropzone
                accept='video/*'
                onDrop={(f) => this.fileUploaded(f)}
                disabled={processing || uploading}>
                {({ getRootProps, getInputProps }) => (
                    <div
                        className='base-video-prompt'
                        onClick={() => this.onClick()}>
                        <div {...getRootProps()}>
                            <input
                                {...getInputProps()}
                                style={{ display: 'none' }}
                                ref={this.input}
                            />
                            {this.renderPrompt()}
                        </div>
                    </div>
                )}
            </Dropzone>
        );
    }
}

const mapDispatchToProps = (dispatch): any => {
    return bindActionCreators({ updateScene }, dispatch);
};

const mapStateToProps = (state): any => {
    return { story: state.story, project: state.project };
};

export default connect(mapStateToProps, mapDispatchToProps)(BaseVideoPrompt);
