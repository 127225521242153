import * as React from 'react';
import { ICON_EYE, ICON_LOCK, ICON_VOLUME_UP } from '../../constants/icons';
import { generateUUID } from '../../util/story';
import { renderTooltip, renderTooltipProps } from '@imposium-hub/components';
import { fields as copy } from '../../constants/copy';
export const LayerInfoPanelHeader: React.FC = () => {
    const motionBlurTooltipId = `motionBlurTooltipId-${generateUUID()}`;
    const motionBlurIconUrl = 'https://imposium-cdn.s3.amazonaws.com/motionblur/icon.png';

    return (
        <div className='layer-info-header'>
            <div className='col icon'>{ICON_EYE}</div>
            <div className='col icon'>{ICON_VOLUME_UP}</div>
            <div className='col icon'>{ICON_LOCK}</div>
            <div className='col icon'></div>
            <div className='col icon'></div>
            <div className='col name'>Layer Name</div>
            <div className='col mode'>Mode</div>
            <div className='col type'>Position</div>
            <div className='col fill'>Fill</div>
            <div className='col matte'>Matte</div>
            <div
                className='col icon'
                {...renderTooltipProps(motionBlurTooltipId, copy.effectConfig.motionBlur)}>
                <img src={motionBlurIconUrl} />
                {renderTooltip(motionBlurTooltipId, copy.effectConfig.motionBlur)}
            </div>
        </div>
    );
};
