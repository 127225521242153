import { Button, Section, Spinner } from '@imposium-hub/components';
import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    ICON_ARROW_DOWN_AZ,
    ICON_ARROW_UP_DOWN,
    ICON_FILE_CSV,
    ICON_TIMES
} from '../constants/icons';
import { getVariableType, getPreviewItem, uploadFile } from '../util/variables';
import { fields as copy } from '../constants/copy';
import * as Papa from 'papaparse';
import { getFirstAct } from '../util/story';
import { sections as sectionCopy } from '../constants/copy';
import VariableList from './VariableList';
import { IStory } from '../constants/snippets';
import { IProject } from '../redux/reducers/project';
import { IEditor } from '../redux/reducers/editor';
import { updateEditorConfig } from '../redux/actions/editor';
import { updateInventory } from '../redux/actions/story';

interface IVariablePanelProps {
    story: IStory;
    editor: IEditor;
    project: IProject;
    updateEditorConfig(c): void;
    updateInventory(config): void;
}

class VariablePanel extends React.PureComponent<IVariablePanelProps, null> {
    private evtHandlers: any;

    private readonly hiddenFileRef: any;

    constructor(props) {
        super(props);

        this.evtHandlers = {
            toggleVars: () => this.toggleVars(),
            sortVars: () => this.sortVars()
        };

        this.hiddenFileRef = React.createRef();
    }

    private toggleVars() {
        this.props.updateEditorConfig({
            varsOpen: !this.props.editor.varsOpen
        });
    }

    private sortVars() {
        this.props.updateEditorConfig({
            sortVars: !this.props.editor.sortVars
        });
    }

    private doUpload = (e: any) => {
        const file = e.target.files[0];
        if (!file) return;
        const conf = confirm(copy.global.varExistPrompt);
        if (conf) {
            this.props.updateEditorConfig({
                varsLoading: true
            });
            Papa.parse(file, {
                complete: async (c) => {
                    const headers = c.data[0];
                    const firstRow = c.data[1];
                    for (let i = 0; i < headers.length; i++) {
                        const type = getVariableType(firstRow[i]);
                        let previewItem: any = getPreviewItem(type, firstRow[i]);
                        if (type === 'image' || type === 'audio' || type === 'video') {
                            previewItem = await uploadFile(firstRow[i], this.props.project.storyId);
                        }
                        this.updateInventory(headers[i], type, previewItem);
                    }
                    this.props.updateEditorConfig({
                        varsLoading: false
                    });
                    this.hiddenFileRef.current.value = '';
                }
            });
        }
    };

    private updateInventory(header, type, previewItem) {
        const {
            project: { actId }
        } = this.props;

        const defaultItem = { type, src: null, options: null };

        if (isFinite(header)) {
            header = `-${header}-`;
        }

        this.props.updateInventory({
            actId,
            inventoryId: header,
            inventoryItem: {
                name: header,
                type,
                optional: false,
                hidden: false,
                id: header,
                defaultItem,
                previewItem
            }
        });
    }

    public render() {
        const {
            story,
            editor: { varsOpen, varsLoading, sortVars },
            project: { actId }
        } = this.props;

        const act = actId ? story.acts[actId] : getFirstAct(story);

        const btnCloseVars = (
            <Button
                style='subtle'
                key='close-vars'
                onClick={this.evtHandlers.toggleVars}>
                {ICON_TIMES}
            </Button>
        );

        const btnLoadCSV = varsLoading ? (
            <Spinner key='loading-csv' />
        ) : (
            <Button
                onClick={() => {
                    this.hiddenFileRef.current.click();
                }}
                style='subtle'
                key='load-csv'
                tooltip='import variables from CSV'>
                <input
                    id='csv-upload'
                    className='file-hidden'
                    ref={this.hiddenFileRef}
                    type='file'
                    accept='.csv'
                    onChange={this.doUpload}
                />
                {ICON_FILE_CSV}
            </Button>
        );

        const btnSortVars = sortVars ? (
            <Button
                style='subtle'
                key='sort-vars'
                onClick={this.evtHandlers.sortVars}>
                {ICON_ARROW_DOWN_AZ}
            </Button>
        ) : (
            <Button
                style='subtle'
                key='sort-vars'
                onClick={this.evtHandlers.sortVars}>
                {ICON_ARROW_UP_DOWN}
            </Button>
        );

        return (
            <Section
                title={sectionCopy.variables}
                buttons={varsOpen ? [btnLoadCSV, btnSortVars, btnCloseVars] : null}>
                <VariableList variables={act.inventory} />
            </Section>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            updateEditorConfig,
            updateInventory
        },
        dispatch
    );
};

const mapStateToProps = (state): any => {
    return {
        story: state.story,
        editor: state.editor,
        project: state.project
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(VariablePanel);
