import { browserHistory } from 'react-router';
import store from '../redux/store';

export const pushRoute = (route, noOrg = false): void => {
    const state = store.getState();
    const routing: any = state.routing;
    const org = routing.locationBeforeTransitions.query.organization_id;
    if (!noOrg && org) {
        browserHistory.push(`${route}?organization_id=${org}`);
    } else {
        browserHistory.push(`${route}`);
    }
};

export const replaceRoute = (route, noOrg = false): void => {
    const state = store.getState();
    const routing: any = state.routing;
    const org = routing.locationBeforeTransitions.query.organization_id;
    if (!noOrg && org) {
        browserHistory.replace(`${route}?organization_id=${org}`);
    } else {
        browserHistory.replace(`${route}`);
    }
};

export const getServiceURL = (serviceName: string): string => {
    const state = store.getState();
    const services = state.access.services;
    const storyId = state.story.id;
    const routing: any = state.routing;
    const org = routing.locationBeforeTransitions.query.organization_id;

    for (const service of services) {
        if (service.name === serviceName) {
            return `${service.url}/${storyId}?organization_id=${org}`;
        }
    }

    return null;
};

export const getDemoURL = (): string => {
    const {
        location: { href }
    } = window;

    if (href.indexOf('localhost') !== -1) {
        return 'http://localhost:3044';
    } else if (href.indexOf('staging') !== -1) {
        return 'https://demo.staging.imposium.com';
    } else {
        return 'https://demo.imposium.com';
    }
};
