import { FieldWrapper, NumberField, SelectField } from '@imposium-hub/components';
import * as React from 'react';
import { fields as copy } from '../constants/copy';
import { IAnimation } from '../constants/snippets';
import { ANIMATION_EASE_OPTIONS, ANIMATION_TYPE_OPTIONS } from '../constants/story';

interface IAnimationFieldsProps {
    config?: IAnimation;
    title: string;
    onChange(c): void;
}

class AnimationFields extends React.PureComponent<IAnimationFieldsProps, undefined> {
    private evtHandlers: any;

    constructor(props) {
        super(props);

        this.evtHandlers = {
            inputs: {
                type: (v) => this.inputChanged('type', v),
                duration: (v) => this.inputChanged('duration', v),
                delay: (v) => this.inputChanged('delay', v),
                ease: (v) => this.inputChanged('ease', v)
            }
        };
    }

    private inputChanged(key, value) {
        const newConfig = { ...this.props.config };
        newConfig[key] = value;

        // if we're changing the type, set some defaults
        if (key === 'type') {
            if (value) {
                newConfig['duration'] = 1;
                newConfig['delay'] = 0;
            }
        }

        this.props.onChange(newConfig);
    }

    public render() {
        const { config, title } = this.props;
        const configFields = (
            <>
                <NumberField
                    label={copy.global.duration}
                    value={config['duration']}
                    width='50%'
                    onChange={this.evtHandlers.inputs.duration}
                />

                <NumberField
                    label={copy.global.delay}
                    value={config['delay']}
                    width='50%'
                    onChange={this.evtHandlers.inputs.delay}
                />

                <SelectField
                    label={copy.global.ease}
                    value={config['ease']}
                    width='50%'
                    options={ANIMATION_EASE_OPTIONS}
                    onChange={this.evtHandlers.inputs.ease}
                />
            </>
        );

        return (
            <>
                <FieldWrapper
                    width='100%'
                    label={title}>
                    <SelectField
                        label={copy.global.type}
                        value={config['type']}
                        width='50%'
                        options={ANIMATION_TYPE_OPTIONS}
                        onChange={this.evtHandlers.inputs.type}
                    />

                    {config['type'] && configFields}
                </FieldWrapper>
            </>
        );
    }
}

export default AnimationFields;
