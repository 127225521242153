import { useState } from 'react';
import { dataEditor as copy } from '../../constants/copy';
import { Button, Modal, Section, TextField } from '@imposium-hub/components';
import { useHotkeys } from 'react-hotkeys-hook';

export const DatasetColumnModal = ({ onClose, headers, onContinue }) => {
    const [colName, setColName] = useState('');
    const isUnique = () => {
        return headers.indexOf(colName) === -1;
    };

    const contHandler = () => {
        if (colName.length > 0) {
            onContinue(colName);
        }
    };

    useHotkeys('enter', contHandler, { enableOnFormTags: true });

    return (
        <Modal
            onRequestClose={onClose}
            style={{
                width: '400px',
                height: '70%',
                top: '10%',
                left: 'calc((100% - 400px) / 2)'
            }}
            isOpen={true}>
            <Section title={copy.newColTitle}>
                <p>{copy.newColPrompt}</p>
                <TextField
                    focusOnMount={true}
                    onChange={setColName}
                    value={colName}
                />
                <Button
                    disabled={!isUnique() || colName.length === 0}
                    onClick={contHandler}
                    style='bold'
                    color='primary'>
                    {copy.cont}
                </Button>
            </Section>
        </Modal>
    );
};
