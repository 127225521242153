import {
    AssetsTableDateCell,
    DataTable,
    ImposiumDropdown,
    Spinner
} from '@imposium-hub/components';
import { useRef, useState } from 'react';
import { dataEditor as copy } from '../../constants/copy';
import _ from 'lodash';

export const DatasetSelector = ({ datasetList, onSelect, activeId }) => {
    const [showDatasetDropdown, setShowDatasetDropdown] = useState(false);
    const [order] = useState('date_modified');
    const [orderDirection] = useState('desc');
    const datasetToggleRef = useRef();

    let datasetToggle: JSX.Element = <Spinner />;
    let datasetMenuInner: JSX.Element = (
        <div className='no-datasets-dialog'>{copy.noDatasetsFound}</div>
    );

    const COLUMNS: any[] = [
        {
            accessor: 'name',
            Header: 'Name',
            width: 75,
            minWidth: 75,
            maxWidth: 75,
            disableSortBy: false
        },
        {
            accessor: 'date_modified',
            Header: 'Last Modified',
            width: 45,
            minWidth: 45,
            maxWidth: 45,
            disableSortBy: false,
            Cell: (cell: any) => (
                <AssetsTableDateCell
                    cell={cell}
                    date_created={false}
                />
            )
        },
        {
            accessor: 'date_created',
            Header: 'Created Date',
            width: 45,
            minWidth: 45,
            maxWidth: 45,
            disableSortBy: false,
            Cell: (cell: any) => (
                <AssetsTableDateCell
                    cell={cell}
                    date_created={true}
                />
            )
        },
        {
            accessor: 'last_modified_by',
            Header: 'Last Modified By',
            width: 50,
            minWidth: 50,
            maxWidth: 50,
            disableSortBy: true
        }
    ];

    const changeDataset = (dataset: any): void => {
        if (typeof onSelect === 'function') {
            onSelect(dataset.id);
            setShowDatasetDropdown(false);
        }
    };
    if (datasetList && datasetList.length > 0) {
        let datasetName = copy.noDatasetDefault;

        if (activeId) {
            const currentDataset = datasetList.filter((obj) => obj.id === activeId);
            datasetName = currentDataset[0].name;
        }

        datasetToggle = (
            <h1
                className={`dataset-name`}
                ref={datasetToggleRef}
                onClick={() => setShowDatasetDropdown(!showDatasetDropdown)}>
                {datasetName}
            </h1>
        );

        const sortBy =
            order && orderDirection ? [{ id: order, desc: orderDirection === 'desc' }] : undefined;

        const sortedDatasets = _.orderBy(datasetList, ['date_modified'], ['desc']);

        datasetMenuInner = (
            <DataTable
                columns={COLUMNS}
                data={sortedDatasets}
                sortBy={sortBy}
                noDataText={copy.noDatasetsFound}
                itemsPerPage={datasetList.length}
                hidePaginator={true}
                onRowClick={changeDataset}
            />
        );

        return (
            <>
                {datasetToggle}
                <ImposiumDropdown
                    key='datasets-dropdown'
                    position='bottomleft'
                    show={showDatasetDropdown}
                    toggleRef={datasetToggleRef}
                    onOutsideClick={() => setShowDatasetDropdown(false)}>
                    <div className='datasets-menu'>{datasetMenuInner}</div>
                </ImposiumDropdown>
            </>
        );
    }
    return null;
};
