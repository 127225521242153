import * as React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import SplitPane from 'react-split-pane';
import { IEditor } from '../../redux/reducers/editor';
import { Button, ITab, Modal, Spinner, Tabs, updateAssetData } from '@imposium-hub/components';
import { fields as copy, header, storyErrors } from '../../constants/copy';
import { getAssetMapHandler, updateEditorConfig } from '../../redux/actions/editor';
import { ICON_SAVE, ICON_TABLE_COLUMNS, ICON_TABLE_ROWS } from '../../constants/icons';
import { IFramePreview } from './code-editor/IFramePreview';
import { IStory } from '../../constants/snippets';
import { api } from '../../constants/app';
import { CodePanels } from './code-editor/CodePanels';
import { parseInventoryFromVariables } from '../../util/story';
import _ from 'lodash';
import {
    CODE_EDITOR_TABS,
    FORCE_UPDATE_HTML_ASSET,
    FORCE_UPDATE_HTML_EDITOR,
    HEADER_HEIGHT
} from '../../constants/editor';
import DevConfigOption from './code-editor/DevConfigOption';

interface IHTMLAssetEditorProps {
    assets: any;
    assetId: string;
    variables: any;
    width: number;
    height: number;
    active?: boolean;
    editor: IEditor;
    story: IStory;
    updateEditorConfig(c): void;
    updateAssetData(apiInstance: any, id: string, newName: string);
}

interface IHTMLAssetEditorState {
    date: number;
    asset: any;
    openDevConfig: boolean;
}

class HTMLAssetEditor extends React.PureComponent<IHTMLAssetEditorProps, IHTMLAssetEditorState> {
    private evtHandlers: any;

    private splitPaneResizeHandler: any;

    private iFramePreviewRef: any;

    private autoSaveInterval: any;

    private updateAssetHandler = () => this.getAsset();

    constructor(props) {
        super(props);

        this.evtHandlers = {
            tabs: () => this.tabModeHandler(),
            save: () => this.saveBtnHandler(),
            config: (c) => this.configChange(c),
            code: (f, v) => this.codeChange(f, v),
            modal: () => this.openDevConfigHandler(),
            refresh: () => this.refreshPreviewHandler()
        };

        this.state = {
            date: Date.now(),
            asset: null,
            openDevConfig: false
        };

        this.iFramePreviewRef = React.createRef();

        this.splitPaneResizeHandler = () => {
            const iFramePreview = this.iFramePreviewRef.current;
            if (iFramePreview) {
                iFramePreview.setPreviewDimensions();
            }
        };
    }

    public componentDidMount(): void {
        this.getAsset();
        document.addEventListener(FORCE_UPDATE_HTML_ASSET, this.updateAssetHandler);
    }

    public componentWillUnmount(): void {
        document.removeEventListener(FORCE_UPDATE_HTML_ASSET, this.updateAssetHandler);
    }

    private updateDevConfigVariables() {
        if (this.state.asset) {
            const {
                asset: {
                    data: { devConfig }
                }
            } = this.state;

            const { variables } = this.props;
            const inventory = parseInventoryFromVariables(variables);
            const newConfig = { ...devConfig };
            newConfig['inventory'] = inventory;
            this.configChange(newConfig);
        } else {
            this.getAsset();
        }
    }

    private getAsset() {
        const { assetId, variables } = this.props;

        api.getAssetItem(assetId)
            .then((asset) => {
                const type = typeof asset.data;
                if (type === 'string') {
                    asset.data = JSON.parse(asset.data);
                }
                this.setState({ asset }, () => {
                    const inventory = parseInventoryFromVariables(variables);
                    const {
                        data: { devConfig }
                    } = asset;
                    const isEqual = _.isEqual(inventory, devConfig.inventory);

                    if (!isEqual) {
                        this.updateDevConfigVariables();
                    }

                    this.refreshPreviewHandler();
                    // Trigger the code panels to update
                    const event = new CustomEvent(FORCE_UPDATE_HTML_EDITOR, {});
                    document.dispatchEvent(event);
                });
            })
            .catch((e) => {
                console.error(e);
            });
    }

    public componentDidUpdate(prevProps): void {
        const {
            variables,
            story: { id }
        } = this.props;

        if (this.state.asset && prevProps.assets !== this.props.assets) {
            const {
                asset: {
                    data: { devConfig }
                }
            } = this.state;
            void getAssetMapHandler(id).then((map) => {
                const assets = JSON.stringify(map.assets);
                if (!_.isEqual(devConfig.assets, assets)) {
                    const newConfig = { ...devConfig };
                    newConfig['assets'] = assets;
                    this.configChange(newConfig);
                }
            });
        }

        if (prevProps.variables !== variables) {
            this.updateDevConfigVariables();
        }

        clearInterval(this.autoSaveInterval);
    }

    private tabModeHandler() {
        const {
            editor: { tabMode }
        } = this.props;

        this.props.updateEditorConfig({ tabMode: !tabMode });
    }

    private saveBtnHandler(asset?) {
        let update;

        if (asset === undefined) {
            update = this.state.asset;
        } else {
            update = asset;
        }

        const { id, data } = update;

        this.props.updateAssetData(api, id, data).then(() => {
            this.setState({ date: Date.now() });
            this.props.updateEditorConfig({ reHydrateAssetTable: true });
        });
    }

    private tabChange(i) {
        this.props.updateEditorConfig({ activeCodeEditorTab: i });
    }

    private configChange(newConfig) {
        const {
            asset,
            asset: {
                data,
                data: { devConfig }
            }
        } = this.state;

        const newData = { ...data, devConfig: { ...devConfig, ...newConfig } };
        const updateAsset = { ...asset, data: { ...newData } };

        this.setState({ asset: updateAsset }, () => this.autoSaveTimer(updateAsset));
    }

    private codeChange(field, value) {
        const {
            asset,
            asset: { data }
        } = this.state;

        const newData = { ...data };
        newData[field] = value;
        const updateAsset = { ...asset, data: { ...newData } };

        if (!_.isEqual(updateAsset, asset)) {
            this.setState({ asset: updateAsset }, () => this.autoSaveTimer(updateAsset));
        }
    }

    private openDevConfigHandler() {
        this.setState({ openDevConfig: true });
    }

    private refreshPreviewHandler() {
        this.setState({ date: Date.now() });
    }

    private renderIFramePreview(date, width, height, asset) {
        return (
            <IFramePreview
                ref={this.iFramePreviewRef}
                date={date}
                width={width}
                height={height}
                asset={asset}
                openDevConfig={this.evtHandlers.modal}
                refresh={this.evtHandlers.refresh}
                key='iFrame'
            />
        );
    }

    private renderCodePanels(width, asset) {
        return (
            <CodePanels
                asset={asset}
                paneWidth={width / 3}
                codeChange={this.evtHandlers.code}
                key='code-panels'
            />
        );
    }

    private renderTabs(asset, width) {
        const {
            editor: { activeCodeEditorTab },
            height
        } = this.props;

        const { date } = this.state;

        const codeConfig: ITab = {
            label: 'Code Editor',
            key: CODE_EDITOR_TABS.CODE,
            resize: true,
            content: this.renderCodePanels(width, asset)
        };

        const previewConfig: ITab = {
            label: 'HTML Preview',
            key: CODE_EDITOR_TABS.PREVIEW,
            resize: true,
            content: this.renderIFramePreview(date, width, height, asset)
        };

        const tabs = [codeConfig, previewConfig];

        return (
            <Tabs
                activeTab={activeCodeEditorTab}
                onChange={(i) => this.tabChange(i)}
                options={tabs}
            />
        );
    }

    private autoSaveTimer(asset) {
        this.autoSaveInterval = setInterval(() => {
            this.saveBtnHandler(asset);
        }, 2500);
    }

    public render() {
        const {
            active,
            editor: { tabMode },
            width,
            height
        } = this.props;

        const { asset, date, openDevConfig } = this.state;

        if (!asset) {
            return (
                <div className='html-loader'>
                    <div>{storyErrors.assetNotFound}</div>
                    <Spinner />
                </div>
            );
        }

        const btnTab = (
            <Button
                size='small'
                color='primary'
                style='bold'
                onClick={this.evtHandlers.tabs}>
                {!tabMode ? copy.codeEditor.tab : copy.codeEditor.split}{' '}
                {!tabMode ? ICON_TABLE_COLUMNS : ICON_TABLE_ROWS}
            </Button>
        );

        const btnSave = (
            <Button
                size='small'
                color='primary'
                style='bold'
                onClick={this.evtHandlers.save}>
                {header.btnSave} {ICON_SAVE}
            </Button>
        );

        const codeEditor = !tabMode ? (
            <SplitPane
                defaultSize={(height - 20) / 2}
                split='horizontal'
                allowResize={true}
                primary='first'
                onChange={this.splitPaneResizeHandler}
                className='code-editor'>
                {this.renderCodePanels(width, asset)}
                {this.renderIFramePreview(date, width, height, asset)}
            </SplitPane>
        ) : (
            this.renderTabs(asset, width)
        );

        const devConfig = (
            <Modal
                onRequestClose={(e) => this.setState({ openDevConfig: false })}
                wrapperStyle={{
                    top: HEADER_HEIGHT,
                    left: '0px',
                    width: '100%',
                    height: `calc(100% - ${HEADER_HEIGHT}px)`
                }}
                style={{
                    width: '250px',
                    height: '70%',
                    top: '10%',
                    left: 'calc((100% - 250px) / 2)'
                }}
                isOpen={openDevConfig}>
                <DevConfigOption
                    asset={asset}
                    configChange={this.evtHandlers.config}
                    saveChanges={() =>
                        this.setState({ openDevConfig: false }, this.evtHandlers.save)
                    }
                />
            </Modal>
        );

        if (active) {
            return (
                <div id='html-editor'>
                    <div className='btnMenu'>
                        {btnTab}
                        {btnSave}
                    </div>
                    {codeEditor}
                    {devConfig}
                </div>
            );
        } else {
            return null;
        }
    }
}

const mapDispatchToProps = (dispatch): any => {
    return bindActionCreators({ updateEditorConfig, updateAssetData }, dispatch);
};

const mapStateToProps = (state): any => {
    return {
        assets: state.assetList.assets,
        editor: state.editor,
        story: state.story
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(HTMLAssetEditor);
