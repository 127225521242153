import * as React from 'react';
import { useDrop } from 'react-dnd';
import { ASSET_DND_TYPES, LAYER_DND_TYPES } from '../../constants/timeline';
import { dropableAssetTypeCheck } from '../../util/general';

interface ILayerDropIndicatorProps {
    top: number;
    index: number;
    modifier: string;
    onDrop(item: any, index: any): void;
}

export const LayerDropIndicator: React.FC<ILayerDropIndicatorProps> = (
    p: ILayerDropIndicatorProps
) => {
    const { top, index, onDrop, modifier } = p;
    const [{ isOver, canDrop }, drop] = useDrop({
        accept: [LAYER_DND_TYPES.LAYER_NAME, ASSET_DND_TYPES.ASSET_NAME],
        canDrop: (item: any) => {
            return item.type === LAYER_DND_TYPES.LAYER_NAME
                ? true
                : dropableAssetTypeCheck(item.rowData.type);
        },
        drop: (item: any) => {
            onDrop(item, index);
        },
        collect: (monitor) => ({
            canDrop: monitor.canDrop(),
            isOver: monitor.isOver()
        })
    });

    const isModifier = canDrop ? modifier : '';
    const indicator =
        canDrop && isOver ? <div className={`layer-position-indicator ${isModifier}`} /> : null;

    return (
        <div
            className={`layer-drop-indicator ${isModifier}`}
            style={{ top: `${top}px` }}
            ref={drop}>
            {indicator}
        </div>
    );
};
