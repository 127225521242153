import { Button } from '@imposium-hub/components';
import { ICON_TRASH, ICON_CROSSHAIRS } from '../../constants/icons';
import { KEYFRAME_EASE_OPTIONS } from '../../constants/story';
import { timeline as copy } from '../../constants/copy';

export const KeyframeContextMenu = ({ config, onChange, onDelete, onJump, disableEase }) => {
    const onEaseChange = (e) => {
        const val = e.target.value;
        const newConfig = { ...config };
        newConfig.ease = val;
        onChange(newConfig);
    };

    return (
        <div className='keyframe-context-menu'>
            <div className='caret'></div>
            {!disableEase && (
                <select
                    className='keyframe-ease-selector'
                    value={config.ease}
                    onChange={onEaseChange}>
                    {KEYFRAME_EASE_OPTIONS.map((ease: string) => {
                        return (
                            <option
                                key={ease}
                                value={ease}>
                                {ease}
                            </option>
                        );
                    })}
                </select>
            )}
            <Button
                style='subtle'
                tooltip={copy.tooltipJumpToKeyframe}
                onClick={() => onJump(config)}>
                {ICON_CROSSHAIRS}
            </Button>
            <Button
                style='subtle'
                tooltip={copy.tooltipDeleteKeyframe}
                onClick={onDelete}>
                {ICON_TRASH}
            </Button>
        </div>
    );
};
