import { Button, ButtonMenu, ButtonMenuItem } from '@imposium-hub/components';
import {
    ICON_COPY,
    ICON_DOWNLOAD,
    ICON_ELLIPSIS_H,
    ICON_ERROR,
    ICON_FILES,
    ICON_FILE_CSV,
    ICON_FILE_IMPORT,
    ICON_PEN_TO_SQUARE,
    ICON_PUBLISH,
    ICON_TRASH
} from '../../constants/icons';
import { fields, dataEditor } from '../../constants/copy';
import { useRef } from 'react';
import { saveAs } from 'file-saver';
import { formatDatasetToCSVFromArray } from '../../util/dataset';

export const DatasetContextMenu = ({
    activeDataset,
    onDelete,
    onRename,
    onDuplicate,
    onPublish,
    onCheckForErrors,
    onImport,
    onGetList,
    onGenerate
}) => {
    if (!activeDataset) {
        return null;
    }

    const fileInput: any = useRef();

    const trigger = (
        <Button
            size='small'
            style='subtle'>
            {ICON_ELLIPSIS_H}
        </Button>
    );

    const onMenuDelete = () => {
        const c = confirm(dataEditor.confirmDelete.replace('[name]', activeDataset.name));
        if (c) {
            onDelete(activeDataset.id);
        }
    };

    const onMenuImport = () => {
        if (fileInput.current) {
            fileInput.current.click();
        }
    };

    const onExport = () => {
        const csv = formatDatasetToCSVFromArray(activeDataset);
        saveAs(csv, `${activeDataset.name}.csv`);
    };

    const onFileUpload = (e) => {
        const file = e.target.files[0];
        onImport(activeDataset.id, file);
        e.target.value = null;
    };

    return (
        <>
            <input
                type='file'
                accept={'.csv'}
                style={{ display: 'none' }}
                ref={fileInput}
                onChange={onFileUpload}
            />
            <ButtonMenu
                items={[
                    <ButtonMenuItem
                        key='duplicate'
                        onClick={onDuplicate}
                        label={
                            <div className='dataset-context-label'>
                                {ICON_COPY} {fields.global.duplicate}
                            </div>
                        }
                    />,
                    <ButtonMenuItem
                        key='rename'
                        onClick={onRename}
                        label={
                            <div className='dataset-context-label'>
                                {ICON_PEN_TO_SQUARE} {fields.global.rename}
                            </div>
                        }
                    />,
                    <ButtonMenuItem
                        key='import'
                        onClick={onMenuImport}
                        label={
                            <div className='dataset-context-label'>
                                {ICON_FILE_IMPORT} {dataEditor.import}
                            </div>
                        }
                    />,
                    <ButtonMenuItem
                        key='export'
                        onClick={onExport}
                        label={
                            <div className='dataset-context-label'>
                                {ICON_DOWNLOAD} {dataEditor.export}
                            </div>
                        }
                    />,
                    <ButtonMenuItem
                        key='publish'
                        onClick={onPublish}
                        label={
                            <div className='dataset-context-label'>
                                {ICON_PUBLISH} {dataEditor.publish}
                            </div>
                        }
                    />,
                    <ButtonMenuItem
                        key='errors'
                        onClick={onCheckForErrors}
                        label={
                            <div className='dataset-context-label'>
                                {ICON_ERROR} {dataEditor.checkForErrors}
                            </div>
                        }
                    />,
                    <ButtonMenuItem
                        key='sample'
                        onClick={onGenerate}
                        label={
                            <div className='dataset-context-label'>
                                {ICON_FILE_CSV} {dataEditor.generateSampleData}
                            </div>
                        }
                    />,
                    <ButtonMenuItem
                        key='assets'
                        onClick={onGetList}
                        label={
                            <div className='dataset-context-label'>
                                {ICON_FILES} {dataEditor.getAssets}
                            </div>
                        }
                    />,
                    <ButtonMenuItem
                        key='delete'
                        onClick={onMenuDelete}
                        label={
                            <div className='dataset-context-label'>
                                {ICON_TRASH} {fields.global.delete}
                            </div>
                        }
                    />
                ]}
                position='left'
                button={trigger}
            />
        </>
    );
};
