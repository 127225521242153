import * as React from 'react';
import { useDrop } from 'react-dnd';
import { ASSET_DND_TYPES } from '../../../constants/timeline';
import { dropableAssetTypeCheck } from '../../../util/general';

interface ICompPreviewDropProps {
    children: any;
    onAssetDrop(asset);
}

export const CompPreviewDrop: React.FC<ICompPreviewDropProps> = (p: ICompPreviewDropProps) => {
    const [{ isOver, canDrop }, drop] = useDrop({
        accept: [ASSET_DND_TYPES.ASSET_NAME],
        canDrop: (item: any) => {
            return dropableAssetTypeCheck(item.rowData.type);
        },
        drop: (item: any) => {
            if (item.type === ASSET_DND_TYPES.ASSET_NAME) {
                p.onAssetDrop(item.rowData);
            }
        },
        collect: (monitor) => ({
            canDrop: monitor.canDrop(),
            isOver: monitor.isOver()
        })
    });

    return (
        <div
            ref={drop}
            className={canDrop && isOver ? 'drop' : null}>
            {p.children}
        </div>
    );
};

export default CompPreviewDrop;
