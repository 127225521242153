import * as React from 'react';
import { FRAME_WIDTH, MIN_LABEL_WIDTH } from '../../constants/timeline';
import { getFrameFromXPos } from '../../util/timeline';

interface ILabelsProps {
    frames: number;
    rate: number;
    scale: number;
    width: number;
    height: number;
    x: number;
    jumpToFrame(f: number): void;
}

interface ILabelsState {
    dragging: boolean;
}

class Labels extends React.PureComponent<ILabelsProps, ILabelsState> {
    private mouseUpHandler: any;

    private mouseMoveHandler: any;

    private frameTimeout: any;

    constructor(props) {
        super(props);

        this.state = {
            dragging: false
        };

        this.mouseUpHandler = (e) => this.killDrag(e);
    }

    public componentDidMount() {
        document.addEventListener('mouseup', this.mouseUpHandler);
    }

    public componentWillUnmount() {
        clearTimeout(this.frameTimeout);
        document.removeEventListener('mouseup', this.mouseUpHandler);
    }

    private killDrag(e) {
        this.setState({
            dragging: false
        });
    }

    public onMouseDown(e) {
        const x = e.nativeEvent.offsetX;
        this.setState(
            {
                dragging: true
            },
            () => {
                this.setFrame(x);
            }
        );
    }

    public onMouseMove(e) {
        const x = e.nativeEvent.offsetX;

        if (this.state.dragging) {
            this.setFrame(x);
        }
    }

    private setFrame(x) {
        const { scale, frames } = this.props;
        const frame = Math.min(getFrameFromXPos(x - 10, scale), frames - 1);
        clearTimeout(this.frameTimeout);
        this.frameTimeout = setTimeout(() => {
            this.props.jumpToFrame(frame);
        }, 10);
    }

    public getTicks() {
        const { frames, rate, scale } = this.props;
        const ticks = [];
        const fps = Math.round(rate);
        let step = fps;
        let space = FRAME_WIDTH * scale * step;

        while (space < MIN_LABEL_WIDTH) {
            step += fps;
            space = FRAME_WIDTH * scale * step;
        }

        for (let i = 0; i <= frames; i += step) {
            const time = Math.floor(i / rate);
            const label = `${time}`;
            const x = FRAME_WIDTH * scale * i;
            const style = {
                left: `${x}px`
            };

            ticks.push(
                <div
                    key={i}
                    className='tick'
                    style={style}>
                    <div className='line' />
                    <div className='label'>{label}</div>
                </div>
            );
        }
        return ticks;
    }

    public render() {
        const { x, width } = this.props;
        const style = {
            left: `${x}px`,
            width: `${width}px`
        };

        // const barH = {
        //     height: `calc(100% + ${height}px)`
        // };

        return (
            <div
                className='labels'
                style={style}>
                <div
                    className='bar'
                    onMouseDown={(e) => this.onMouseDown(e)}
                    onMouseMove={(e) => this.onMouseMove(e)}
                    onMouseUp={this.mouseUpHandler}
                />
                {this.getTicks()}
            </div>
        );
    }
}

export default Labels;
