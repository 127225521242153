import * as React from 'react';
import { ICON_TIMES } from '../../constants/icons';
interface ILayerAnchorRemoveProps {
    id: string;
    start: boolean;
    onClick: any;
}

export const LayerAnchorRemove: React.FC<ILayerAnchorRemoveProps> = (
    props: ILayerAnchorRemoveProps
) => {
    const { start, onClick } = props;
    const modifier = start ? 'start' : 'end';
    return (
        <div
            onClick={onClick}
            className={`layer-anchor-remove ${modifier}`}>
            {ICON_TIMES}
        </div>
    );
};
