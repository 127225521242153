import { useRef, useEffect } from 'react';
import { ICompositionLayer } from '../../constants/snippets';
import { LAYER_DND_TYPES, LAYER_ROW_HEIGHT } from '../../constants/timeline';
import { getDevicePixelRatio } from '../../util/general';
import { getRowY, getXPosFromFrame } from '../../util/timeline';
import { useDragLayer } from 'react-dnd';

interface ILayerAnchorDisplayProps {
    layers: ICompositionLayer[];
    expandedLayers: string[];
    activeLayer: string;
    scale: number;
    width: number;
    height: number;
    anchorMode: boolean;
    tempLayerOffset: number;
    leftDragFrameOffset: number;
    rightDragFrameOffset: number;
    activeMultiSelectLayers: string[];
}

export const LayerAnchorDisplay = ({
    layers,
    activeMultiSelectLayers,
    tempLayerOffset,
    leftDragFrameOffset,
    rightDragFrameOffset,
    anchorMode,
    expandedLayers,
    activeLayer,
    scale,
    width,
    height
}: ILayerAnchorDisplayProps) => {
    const mainCanvas = useRef();
    const ratio = getDevicePixelRatio();

    const { item, itemType, isDragging, initialOffset, currentOffset } = useDragLayer(
        (monitor) => ({
            item: monitor.getItem(),
            itemType: monitor.getItemType(),
            initialOffset: monitor.getInitialSourceClientOffset(),
            currentOffset: monitor.getSourceClientOffset(),
            isDragging: monitor.isDragging()
        })
    );

    useEffect(() => {
        draw();
    }, [
        layers,
        expandedLayers,
        scale,
        activeLayer,
        width,
        height,
        anchorMode,
        tempLayerOffset,
        leftDragFrameOffset,
        activeMultiSelectLayers,
        rightDragFrameOffset,
        currentOffset
    ]);

    const draw = () => {
        // Get the canvas & context
        const canvas: HTMLCanvasElement = mainCanvas.current;
        const ctx = canvas.getContext('2d');

        // Reset the transform and set the scale to 2
        ctx.setTransform(1, 0, 0, 1, 0, 0);
        ctx.scale(ratio, ratio);

        // Clear the context
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

        // Loop through the layers until you find one which is anchored to another one
        for (const layer of layers) {
            const { anchor_start, anchor_end } = layer;
            if (anchor_start) {
                const anchoredTo = layers.find((l) => {
                    return l.id === anchor_start.id;
                });
                drawLineBetweenLayers(
                    ctx,
                    layer,
                    true,
                    anchoredTo,
                    anchor_start.attachment_point !== 'end_frame'
                );
            }
            if (anchor_end) {
                const anchoredTo = layers.find((l) => {
                    return l.id === anchor_end.id;
                });
                drawLineBetweenLayers(
                    ctx,
                    layer,
                    false,
                    anchoredTo,
                    anchor_end.attachment_point !== 'end_frame'
                );
            }
        }

        // Draw the line from the active layer, to the anchor handle, if need be
        if (isDragging && itemType === LAYER_DND_TYPES.ANCHOR) {
            drawLineFromLayerToAnchorHandle(ctx);
        }
    };

    const drawLine = (ctx, originX, originY, destX, destY, color, dashed = false) => {
        const offsetY = destY - originY;
        const halfwayY = originY + offsetY / 2;
        const offset = 4;
        const offsetYStart = offsetY >= 0 ? offset : -offset;
        const offsetYEnd = offsetY >= 0 ? -offset : offset;
        if (dashed) {
            ctx.setLineDash([2, 2]);
        } else {
            ctx.setLineDash([0, 0]);
        }

        ctx.beginPath();

        // Draw the orthagonal white line between the layers
        ctx.moveTo(originX, originY + offsetYStart);
        ctx.lineTo(originX, halfwayY);
        ctx.lineTo(destX, halfwayY);
        ctx.lineTo(destX, destY + offsetYEnd);
        ctx.strokeStyle = color;
        ctx.stroke();
        ctx.closePath();
    };

    const drawCircle = (ctx, x, y, r, color) => {
        // Draw a circle at the beginning of the line
        ctx.moveTo(x, y);
        ctx.beginPath();
        ctx.arc(x, y, r, 0, Math.PI * 2, true);
        ctx.fillStyle = color;
        ctx.fill();
        ctx.closePath();
    };

    const drawLineBetweenLayers = (ctx, originLayer, originStart, destLayer, destStart) => {
        if (!destLayer || !originLayer) {
            return;
        }

        const originActive =
            originLayer.id === activeLayer ||
            activeMultiSelectLayers.indexOf(originLayer.id) !== -1;
        const destActive =
            destLayer.id === activeLayer || activeMultiSelectLayers.indexOf(destLayer.id) !== -1;
        const [originX, originY] = getXYFromLayer(
            originLayer,
            originStart,
            originActive,
            originActive
        );
        const [destX, destY] = getXYFromLayer(destLayer, destStart, destActive, destActive);

        drawLine(ctx, originX, originY, destX, destY, 'white', !originActive);

        // If this layer is selected, and we're not in anchor mode, draw the end caps on the line
        if (originActive && !anchorMode) {
            drawCircle(ctx, originX, originY, 4, 'white');

            // Draw a triangle at the end of the line
            const pointX = destStart ? destX + 6 : destX - 6;
            const sideX = destStart ? destX - 0.5 : destX + 0.5;

            ctx.beginPath();
            ctx.moveTo(sideX, destY - 4);
            ctx.lineTo(sideX, destY + 4);
            ctx.lineTo(pointX, destY);
            ctx.lineTo(sideX, destY - 4);
            ctx.fillStyle = 'white';
            ctx.fill();
            ctx.closePath();
        }
    };

    const drawLineFromLayerToAnchorHandle = (ctx) => {
        if (currentOffset && initialOffset) {
            const xOffset = currentOffset.x - initialOffset.x - 2;
            const yOffset = currentOffset.y - initialOffset.y;

            const destLayer = layers.find((l) => {
                return l.id === activeLayer;
            });

            const [originX, originY] = getXYFromLayer(destLayer, item.start, false, false);

            drawLine(ctx, originX, originY, originX + xOffset, originY + yOffset, 'white', false);
        }
    };

    const getXYFromLayer = (layer, startOfLayer, applyDragOffset, applyHandleOffset) => {
        const { start_frame, end_frame, id } = layer;
        const tempOffset = tempLayerOffset !== null && applyDragOffset ? tempLayerOffset : 0;
        const leftOffset = applyHandleOffset ? leftDragFrameOffset : 0;
        const rightOffset = applyHandleOffset ? rightDragFrameOffset : 0;

        const start = start_frame + tempOffset + leftOffset;
        const end = end_frame + tempOffset + rightOffset;

        const x = getXPosFromFrame(startOfLayer ? start : end, scale);
        const y = getRowY(layers, expandedLayers, id) + LAYER_ROW_HEIGHT / 2;
        return [x, y];
    };

    const canvasStyle = {
        width: `${width}px`,
        height: `${height}px`
    };

    return (
        <canvas
            style={canvasStyle}
            width={`${width * ratio}px`}
            height={`${height * ratio}px`}
            ref={mainCanvas}
            id='layer-anchor-display'
        />
    );
};
