import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLayerGroup } from '@fortawesome/free-solid-svg-icons/faLayerGroup';
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons/faEllipsisV';
import { faCopy } from '@fortawesome/free-solid-svg-icons/faCopy';
import { faPlay } from '@fortawesome/free-solid-svg-icons/faPlay';
import { faFilter } from '@fortawesome/free-solid-svg-icons/faFilter';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faCut } from '@fortawesome/free-solid-svg-icons/faCut';
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus';
import { faHeadphones } from '@fortawesome/free-solid-svg-icons/faHeadphones';
import { faFont } from '@fortawesome/free-solid-svg-icons/faFont';
import { faHashtag } from '@fortawesome/free-solid-svg-icons/faHashtag';
import { faToggleOn } from '@fortawesome/free-solid-svg-icons/faToggleOn';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { faImage } from '@fortawesome/free-solid-svg-icons/faImage';
import { faVideo } from '@fortawesome/free-solid-svg-icons/faVideo';
import { faVolumeUp } from '@fortawesome/free-solid-svg-icons/faVolumeUp';
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons/faFileDownload';
import { faFileUpload } from '@fortawesome/free-solid-svg-icons/faFileUpload';
import { faSave } from '@fortawesome/free-solid-svg-icons/faSave';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faLock } from '@fortawesome/free-solid-svg-icons/faLock';
import { faSync } from '@fortawesome/free-solid-svg-icons/faSync';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons/faExternalLinkAlt';
import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import { faCompress } from '@fortawesome/free-solid-svg-icons/faCompress';
import { faStickyNote } from '@fortawesome/free-solid-svg-icons/faStickyNote';
import { faJs } from '@fortawesome/free-brands-svg-icons/faJs';
import { faProjectDiagram } from '@fortawesome/free-solid-svg-icons/faProjectDiagram';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import { faComment } from '@fortawesome/free-solid-svg-icons/faComment';
import { faDownload } from '@fortawesome/free-solid-svg-icons/faDownload';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons/faInfoCircle';
import { faKeyboard } from '@fortawesome/free-solid-svg-icons/faKeyboard';
import { faList } from '@fortawesome/free-solid-svg-icons/faList';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons/faExclamationCircle';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons/faArrowRight';
import { faFileCode } from '@fortawesome/free-solid-svg-icons/faFileCode';
import { faUpload } from '@fortawesome/free-solid-svg-icons/faUpload';
import { faText } from '@fortawesome/pro-solid-svg-icons/faText';
import { faEye } from '@fortawesome/pro-light-svg-icons/faEye';
import { faEye as faEyeSolid } from '@fortawesome/pro-solid-svg-icons/faEye';
import { faEyeSlash } from '@fortawesome/pro-solid-svg-icons/faEyeSlash';
import { faImages } from '@fortawesome/free-solid-svg-icons/faImages';
import { faFileArchive } from '@fortawesome/pro-light-svg-icons/faFileArchive';
import { faAlignLeft } from '@fortawesome/free-solid-svg-icons/faAlignLeft';
import { faAlignRight } from '@fortawesome/free-solid-svg-icons/faAlignRight';
import { faAlignCenter } from '@fortawesome/free-solid-svg-icons/faAlignCenter';
import { faArrowToTop } from '@fortawesome/pro-light-svg-icons/faArrowToTop';
import { faArrowToBottom } from '@fortawesome/pro-light-svg-icons/faArrowToBottom';
import { faArrowsV } from '@fortawesome/pro-light-svg-icons/faArrowsV';
import { faArrowToLeft } from '@fortawesome/pro-light-svg-icons/faArrowToLeft';
import { faArrowToRight } from '@fortawesome/pro-light-svg-icons/faArrowToRight';
import { faPause } from '@fortawesome/pro-light-svg-icons/faPause';
import { faAnchor } from '@fortawesome/pro-light-svg-icons/faAnchor';
import { faBullseye } from '@fortawesome/pro-light-svg-icons/faBullseye';
import { faBracketsCurly } from '@fortawesome/pro-solid-svg-icons/faBracketsCurly';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons/faEllipsisH';
import { faClipboard } from '@fortawesome/free-solid-svg-icons/faClipboard';
import { faHubspot } from '@fortawesome/free-brands-svg-icons/faHubspot';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope';
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons/faUpRightFromSquare';
import { faVideoPlus } from '@fortawesome/pro-solid-svg-icons/faVideoPlus';
import { faAsterisk } from '@fortawesome/pro-solid-svg-icons/faAsterisk';
import { faDiamond } from '@fortawesome/pro-solid-svg-icons/faDiamond';
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons/faCaretRight';
import { faCaretLeft } from '@fortawesome/pro-solid-svg-icons/faCaretLeft';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons/faCaretDown';
import { faCaretUp } from '@fortawesome/pro-solid-svg-icons/faCaretUp';
import { faStopwatch } from '@fortawesome/pro-solid-svg-icons/faStopwatch';
import { faStopwatch as faStopWatchLight } from '@fortawesome/pro-light-svg-icons/faStopwatch';
import { faCrosshairs } from '@fortawesome/pro-solid-svg-icons/faCrosshairs';
import { faClock } from '@fortawesome/pro-solid-svg-icons/faClock';
import { faFileCsv } from '@fortawesome/pro-solid-svg-icons/faFileCsv';
import { faShare } from '@fortawesome/pro-solid-svg-icons/faShare';
import { faArrowDownFromLine } from '@fortawesome/pro-solid-svg-icons/faArrowDownFromLine';
import { faArrowUpFromLine } from '@fortawesome/pro-solid-svg-icons/faArrowUpFromLine';
import { faArrowLeftFromLine } from '@fortawesome/pro-solid-svg-icons/faArrowLeftFromLine';
import { faArrowRightFromLine } from '@fortawesome/pro-solid-svg-icons/faArrowRightFromLine';
import { faUnlock } from '@fortawesome/pro-solid-svg-icons/faUnlock';
import { faArrowDownAZ } from '@fortawesome/pro-solid-svg-icons/faArrowDownAZ';
import { faArrowUpArrowDown } from '@fortawesome/pro-solid-svg-icons/faArrowUpArrowDown';
import { faCode } from '@fortawesome/pro-solid-svg-icons/faCode';
import { faLink } from '@fortawesome/pro-solid-svg-icons/faLink';
import { faTableRows } from '@fortawesome/pro-solid-svg-icons/faTableRows';
import { faTableColumns } from '@fortawesome/pro-solid-svg-icons/faTableColumns';
import { faPenPaintbrush } from '@fortawesome/pro-light-svg-icons/faPenPaintbrush';
import { faEquals, faMaximize, faMinimize, faFilePen } from '@fortawesome/free-solid-svg-icons';
import { faVolumeXmark } from '@fortawesome/free-solid-svg-icons';
import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import { faPaintRoller } from '@fortawesome/free-solid-svg-icons';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { faGear } from '@fortawesome/free-solid-svg-icons';
import {
    faArrowsToCircle,
    faGlobe,
    faRabbitRunning,
    faWaveformLines
} from '@fortawesome/pro-solid-svg-icons';
import { faPalette } from '@fortawesome/pro-solid-svg-icons';
import { faStepForward } from '@fortawesome/free-solid-svg-icons/faStepForward';
import { faStepBackward } from '@fortawesome/free-solid-svg-icons/faStepBackward';
import { faBarsStaggered } from '@fortawesome/pro-solid-svg-icons/faBarsStaggered';
import { faTable } from '@fortawesome/pro-solid-svg-icons/faTable';
import { faPenToSquare } from '@fortawesome/pro-solid-svg-icons/faPenToSquare';
import { faFileImport } from '@fortawesome/pro-solid-svg-icons/faFileImport';
import { faFiles } from '@fortawesome/pro-solid-svg-icons/faFiles';
import { faCircleNotch } from '@fortawesome/pro-solid-svg-icons/faCircleNotch';
import { faCircle as faCircleLight } from '@fortawesome/pro-light-svg-icons';
import { faRabbitRunning as faRabbitOutlineRunning } from '@fortawesome/pro-regular-svg-icons';
import { COMPOSITION_LAYER_TYPES, VARIABLE_TYPES } from './story';

export const ICON_IMAGE_SEQUENCE = <FontAwesomeIcon icon={faImages} />;

export const ICON_COMPRESS = <FontAwesomeIcon icon={faCompress} />;

export const ICON_PROJECT_DIAGRAM = <FontAwesomeIcon icon={faProjectDiagram} />;

export const ICON_JS = <FontAwesomeIcon icon={faJs} />;

export const ICON_STICKY_NOTE = <FontAwesomeIcon icon={faStickyNote} />;

export const ICON_EDIT = <FontAwesomeIcon icon={faEdit} />;

export const ICON_CIRCLE = <FontAwesomeIcon icon={faCircle} />;

export const ICON_TIMES_CIRCLE = <FontAwesomeIcon icon={faTimesCircle} />;

export const ICON_LAYER_GROUP = <FontAwesomeIcon icon={faLayerGroup} />;

export const ICON_TRASH = <FontAwesomeIcon icon={faTrash} />;

export const ICON_ELLIPSIS_V = <FontAwesomeIcon icon={faEllipsisV} />;

export const ICON_COPY = <FontAwesomeIcon icon={faCopy} />;

export const ICON_PLAY = <FontAwesomeIcon icon={faPlay} />;

export const ICON_FILTER = <FontAwesomeIcon icon={faFilter} />;

export const ICON_TIMES = <FontAwesomeIcon icon={faTimes} />;

export const ICON_CUT = <FontAwesomeIcon icon={faCut} />;

export const ICON_PLUS = <FontAwesomeIcon icon={faPlus} />;

export const ICON_VIDEOPLUS = <FontAwesomeIcon icon={faVideoPlus} />;

export const ICON_HEADPHONES = <FontAwesomeIcon icon={faHeadphones} />;

export const ICON_FONT = <FontAwesomeIcon icon={faFont} />;

export const ICON_HASHTAG = <FontAwesomeIcon icon={faHashtag} />;

export const ICON_TOGGLE_ON = <FontAwesomeIcon icon={faToggleOn} />;

export const ICON_BARS = <FontAwesomeIcon icon={faBars} />;

export const ICON_IMAGE = <FontAwesomeIcon icon={faImage} />;

export const ICON_VIDEO = <FontAwesomeIcon icon={faVideo} />;

export const ICON_VOLUME_UP = <FontAwesomeIcon icon={faVolumeUp} />;

export const ICON_COG = <FontAwesomeIcon icon={faCog} />;

export const ICON_FILE_DOWNLOAD = <FontAwesomeIcon icon={faFileDownload} />;

export const ICON_FILE_UPLOAD = <FontAwesomeIcon icon={faFileUpload} />;

export const ICON_SAVE = <FontAwesomeIcon icon={faSave} />;

export const ICON_CHECK = <FontAwesomeIcon icon={faCheck} />;

export const ICON_LOCK = <FontAwesomeIcon icon={faLock} />;

export const ICON_SYNC = <FontAwesomeIcon icon={faSync} />;

export const ICON_EXTERNAL_LINK_ALT = <FontAwesomeIcon icon={faExternalLinkAlt} />;

export const ICON_EXCLIMATION_TRIANGLE = <FontAwesomeIcon icon={faExclamationTriangle} />;

export const ICON_COMMENT = <FontAwesomeIcon icon={faComment} />;

export const ICON_DOWNLOAD = <FontAwesomeIcon icon={faDownload} />;

export const ICON_INFO_CIRCLE = <FontAwesomeIcon icon={faInfoCircle} />;

export const ICON_HOTKEYS = <FontAwesomeIcon icon={faKeyboard} />;

export const ICON_GLOBE = <FontAwesomeIcon icon={faGlobe} />;

export const ICON_LIST = <FontAwesomeIcon icon={faList} />;

export const ICON_ERROR = <FontAwesomeIcon icon={faExclamationCircle} />;

export const ICON_ARROW_RIGHT = <FontAwesomeIcon icon={faArrowRight} />;

export const ICON_FILE_CODE = <FontAwesomeIcon icon={faFileCode} />;

export const ICON_UPLOAD = <FontAwesomeIcon icon={faUpload} />;

export const ICON_TEXT = <FontAwesomeIcon icon={faText} />;

export const ICON_EYE = <FontAwesomeIcon icon={faEye} />;

export const ICON_EYE_SOLID = <FontAwesomeIcon icon={faEyeSolid} />;

export const ICON_EYE_SLASH = <FontAwesomeIcon icon={faEyeSlash} />;

export const ICON_FILE_ARCHIVE = <FontAwesomeIcon icon={faFileArchive} />;

export const ICON_ALIGN_LEFT = <FontAwesomeIcon icon={faAlignLeft} />;

export const ICON_ALIGN_RIGHT = <FontAwesomeIcon icon={faAlignRight} />;

export const ICON_ALIGN_CENTER = <FontAwesomeIcon icon={faAlignCenter} />;

export const ICON_ARROW_UP = <FontAwesomeIcon icon={faArrowToTop} />;

export const ICON_ARROW_CENTER_V = <FontAwesomeIcon icon={faArrowsV} />;

export const ICON_ARROW_DOWN = <FontAwesomeIcon icon={faArrowToBottom} />;

export const ICON_ARROW_TO_LEFT = <FontAwesomeIcon icon={faArrowToLeft} />;

export const ICON_ARROW_TO_RIGHT = <FontAwesomeIcon icon={faArrowToRight} />;

export const ICON_PAUSE = <FontAwesomeIcon icon={faPause} />;

export const ICON_ANCHOR = <FontAwesomeIcon icon={faAnchor} />;

export const ICON_BULLSEYE = <FontAwesomeIcon icon={faBullseye} />;

export const ICON_BRACKET_CURLY = <FontAwesomeIcon icon={faBracketsCurly} />;

export const ICON_ELLIPSIS_H = <FontAwesomeIcon icon={faEllipsisH} />;

export const ICON_CLIPBOARD = <FontAwesomeIcon icon={faClipboard} />;

export const ICON_HUBSPOT = <FontAwesomeIcon icon={faHubspot} />;

export const ICON_EMAIL = <FontAwesomeIcon icon={faEnvelope} />;

export const ICON_DIAMOND = <FontAwesomeIcon icon={faDiamond} />;

export const ICON_CARET_RIGHT = <FontAwesomeIcon icon={faCaretRight} />;

export const ICON_CARET_LEFT = <FontAwesomeIcon icon={faCaretLeft} />;

export const ICON_CARET_DOWN = <FontAwesomeIcon icon={faCaretDown} />;

export const ICON_CARET_UP = <FontAwesomeIcon icon={faCaretUp} />;

export const ICON_ASTERISK = <FontAwesomeIcon icon={faAsterisk} />;

export const ICON_STOPWATCH = <FontAwesomeIcon icon={faStopwatch} />;

export const ICON_STOPWATCH_LIGHT = <FontAwesomeIcon icon={faStopWatchLight} />;

export const ICON_CROSSHAIRS = <FontAwesomeIcon icon={faCrosshairs} />;

export const ICON_CLOCK = <FontAwesomeIcon icon={faClock} />;

export const ICON_FILE_CSV = <FontAwesomeIcon icon={faFileCsv} />;

export const ICON_PUBLISH = <FontAwesomeIcon icon={faShare} />;

export const ICON_ARROW_DOWN_FROM_LINE = <FontAwesomeIcon icon={faArrowDownFromLine} />;

export const ICON_ARROW_UP_FROM_LINE = <FontAwesomeIcon icon={faArrowUpFromLine} />;

export const ICON_ARROW_LEFT_FROM_LINE = <FontAwesomeIcon icon={faArrowLeftFromLine} />;

export const ICON_ARROW_RIGHT_FROM_LINE = <FontAwesomeIcon icon={faArrowRightFromLine} />;

export const ICON_UNLOCK = <FontAwesomeIcon icon={faUnlock} />;

export const ICON_ARROW_DOWN_AZ = <FontAwesomeIcon icon={faArrowDownAZ} />;

export const ICON_ARROW_UP_DOWN = <FontAwesomeIcon icon={faArrowUpArrowDown} />;

export const ICON_VIDEO_BUILDER = <FontAwesomeIcon icon={faPenPaintbrush} />;

export const ICON_ADSTUDIO = <FontAwesomeIcon icon={faPalette} />;

export const ICON_LINK = <FontAwesomeIcon icon={faLink} />;

export const ICON_TABLE_ROWS = <FontAwesomeIcon icon={faTableRows} />;

export const ICON_TABLE_COLUMNS = <FontAwesomeIcon icon={faTableColumns} />;

export const ICON_GEAR = <FontAwesomeIcon icon={faGear} />;

export const ICON_ARROW_ROTATE = <FontAwesomeIcon icon={faArrowsRotate} />;

export const ICON_CODE = <FontAwesomeIcon icon={faCode} />;

export const ICON_BAN = <FontAwesomeIcon icon={faBan} />;

export const ICON_WARNING = <FontAwesomeIcon icon={faTriangleExclamation} />;

export const ICON_EQUALS = <FontAwesomeIcon icon={faEquals} />;

export const ICON_PAINT = <FontAwesomeIcon icon={faPaintRoller} />;

export const ICON_STEP_FORWARD = <FontAwesomeIcon icon={faStepForward} />;

export const ICON_STEP_BACKWARD = <FontAwesomeIcon icon={faStepBackward} />;

export const ICON_MUTED = <FontAwesomeIcon icon={faVolumeXmark} />;

export const ICON_BARS_STAGGERED = <FontAwesomeIcon icon={faBarsStaggered} />;

export const ICON_TABLE = <FontAwesomeIcon icon={faTable} />;

export const ICON_PEN_TO_SQUARE = <FontAwesomeIcon icon={faPenToSquare} />;

export const ICON_FILE_IMPORT = <FontAwesomeIcon icon={faFileImport} />;

export const ICON_FILES = <FontAwesomeIcon icon={faFiles} />;

export const ICON_CIRCLE_NOTCH = <FontAwesomeIcon icon={faCircleNotch} />;

export const ICON_WAVEFORM = <FontAwesomeIcon icon={faWaveformLines} />;

export const ICON_RABBIT_SOLID_RUNNING = <FontAwesomeIcon icon={faRabbitRunning} />;

export const ICON_RABBIT_RUNNING = <FontAwesomeIcon icon={faRabbitOutlineRunning} />;

export const ICON_FILE_PEN = <FontAwesomeIcon icon={faFilePen} />;

export const ICON_MAXIMIZE = <FontAwesomeIcon icon={faMaximize} />;

export const ICON_MINIMIZE = <FontAwesomeIcon icon={faMinimize} />;

export const ICON_UP_RIGHT_FROM_SQUARE = <FontAwesomeIcon icon={faUpRightFromSquare} />;

export const ICON_CENTER = <FontAwesomeIcon icon={faArrowsToCircle} />;

export const layerTypeIcon = (type: string) => {
    switch (type) {
        case COMPOSITION_LAYER_TYPES.IMAGE:
            return ICON_IMAGE;

        case COMPOSITION_LAYER_TYPES.VIDEO:
            return ICON_VIDEO;

        case COMPOSITION_LAYER_TYPES.VIDEO_COMPOSITION:
            return ICON_LAYER_GROUP;

        case COMPOSITION_LAYER_TYPES.AUDIO:
            return ICON_HEADPHONES;

        case COMPOSITION_LAYER_TYPES.TEXT:
            return ICON_TEXT;

        case COMPOSITION_LAYER_TYPES.IMAGE_SEQUENCE:
            return ICON_IMAGE_SEQUENCE;

        case COMPOSITION_LAYER_TYPES.TEMPLATE:
            return ICON_FILE_ARCHIVE;

        case COMPOSITION_LAYER_TYPES.HTML:
            return ICON_CODE;

        case COMPOSITION_LAYER_TYPES.SOLID:
            return ICON_PAINT;
    }
};

export const getVariableIcon = (type) => {
    switch (type.toLowerCase()) {
        case VARIABLE_TYPES.TEXT:
            return ICON_FONT;
        case VARIABLE_TYPES.NUMBER:
            return ICON_HASHTAG;
        case VARIABLE_TYPES.BOOLEAN:
            return ICON_TOGGLE_ON;
        case VARIABLE_TYPES.ENUM:
            return ICON_BARS;
        case VARIABLE_TYPES.IMAGE:
            return ICON_IMAGE;
        case VARIABLE_TYPES.VIDEO:
            return ICON_VIDEO;
        case VARIABLE_TYPES.AUDIO:
            return ICON_VOLUME_UP;
    }
};

export const MOTION_BLUR = (
    <>
        <FontAwesomeIcon
            className='circle0'
            icon={faCircle}
        />
        <FontAwesomeIcon
            className='circle1'
            icon={faCircleLight}
        />
        <FontAwesomeIcon
            className='circle2'
            icon={faCircleLight}
        />
    </>
);
