import { SelectField } from '@imposium-hub/components';
import { ITTSConfig } from '../constants/snippets';
import { TTS_SERVICE_DEFAULTS, TTS_SERVICE_OPTIONS, TTS_VOICES } from '../constants/story';
import { fields as copy } from '../constants/copy';

interface ITTSOptionsProps {
    config: ITTSConfig;
    onChange(c: ITTSConfig): void;
}

export const TextToSpeechOptions = ({ config, onChange }: ITTSOptionsProps) => {
    const onServiceChange = (service) => {
        const newConfig = { ...config };
        newConfig['service'] = service;
        newConfig['voice'] = TTS_SERVICE_DEFAULTS[service];
        onChange(newConfig);
    };

    const onVoiceChange = (voice) => {
        const newConfig = { ...config };
        newConfig['voice'] = voice;
        onChange(newConfig);
    };

    return (
        <>
            <SelectField
                options={TTS_SERVICE_OPTIONS}
                value={config.service}
                width={'50%'}
                onChange={(v) => onServiceChange(v)}
                label={copy.overlayConfig.ttsService}
            />
            <SelectField
                options={TTS_VOICES[config.service] || []}
                value={config.voice}
                width={'50%'}
                onChange={(v) => onVoiceChange(v)}
                label={copy.overlayConfig.ttsVoice}
            />
        </>
    );
};
