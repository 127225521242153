import * as React from 'react';
import { NEW_CUSTOM_VAR, ICustomVar } from '../constants/snippets';
import { TextField, ControlledList, FieldWrapper } from '@imposium-hub/components';
import { logError } from '../util/notifications';
import { fields as copy } from '../constants/copy';

interface ICustomVarFieldsProps {
    vars?: ICustomVar[];
    onChange(e): void;
}

interface ICustomVarFieldsState {
    varName: string;
    varValue: string;
}

class CustomVarFields extends React.PureComponent<ICustomVarFieldsProps, ICustomVarFieldsState> {
    constructor(props) {
        super(props);

        this.state = {
            varName: '',
            varValue: ''
        };
    }

    public addVar(e) {
        const { vars } = this.props;
        const { varName } = this.state;
        const varValue = e;

        if (varName === '') {
            logError(copy.customVar.nameEmptyError);
            return;
        } else if (varValue === '') {
            logError(copy.customVar.valueEmptyError);
            return;
        } else {
            this.setState(
                {
                    varName: ''
                },
                () => {
                    const v: ICustomVar = { ...NEW_CUSTOM_VAR };

                    v.key = varName;
                    v.value = varValue;

                    const newVars = [...vars];
                    newVars.push(v);

                    this.props.onChange(newVars);
                }
            );
        }
    }

    private removeVar(index) {
        const newVars = [...this.props.vars];
        newVars.splice(index, 1);

        this.props.onChange(newVars);
    }

    public renderVars() {
        const { vars } = this.props;
        const tagArray = [];

        for (let i = 0; i < vars.length; i++) {
            const v = vars[i];
            tagArray.push(
                <CustomVar
                    onKeyChange={(val) => this.updateVar('key', i, val)}
                    onValueChange={(val) => this.updateVar('value', i, val)}
                    name={v.key}
                    value={v.value}
                />
            );
        }

        return tagArray;
    }

    private updateVar(field, index, value) {
        const newVars = [...this.props.vars];
        const newVar = { ...newVars[index] };
        newVar[field] = value;
        newVars[index] = newVar;

        this.props.onChange(newVars);
    }

    public render() {
        const { varName, varValue } = this.state;
        const tagComponents = this.renderVars();

        return (
            <>
                <FieldWrapper
                    label={copy.customVar.add}
                    tooltip={copy.customVar.tooltipAdd}>
                    <TextField
                        value={varName}
                        width={140}
                        placeholder={copy.customVar.placeholderKey}
                        tooltip={copy.customVar.tooltipKey}
                        onChange={(v) => this.updateVarState('varName', v)}
                    />

                    <TextField
                        value={varValue}
                        width={'calc(100% - 140px)'}
                        submittable={true}
                        placeholder={copy.customVar.placeholderValue}
                        tooltip={copy.customVar.tooltipValue}
                        submittableType='add'
                        doSubmit={(v) => this.addVar(v)}
                    />
                </FieldWrapper>

                <FieldWrapper label={copy.customVar.title}>
                    <ControlledList
                        items={tagComponents}
                        removable={true}
                        onRemove={(t) => this.removeVar(t)}
                    />
                </FieldWrapper>
            </>
        );
    }

    public updateVarState(key, value) {
        const state: any = {
            [key]: value
        };

        this.setState(state);
    }
}

export const CustomVar = (props) => {
    const { lockKey, lockValue } = props;
    return (
        <div className={`custom-var`}>
            <div className='key'>
                <TextField
                    disabled={lockKey}
                    onChange={(e) => props.onKeyChange(e)}
                    value={props.name}
                />
            </div>
            <div className='value'>
                <TextField
                    disabled={lockValue}
                    onChange={(e) => props.onValueChange(e)}
                    value={props.value}
                />
            </div>
        </div>
    );
};

export default CustomVarFields;
