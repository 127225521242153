import * as React from 'react';
import { VARIABLE_RESIZE_OPTIONS } from '../constants/story';
import {
    Button,
    TextField,
    SelectField,
    NumberField,
    FieldWrapper
} from '@imposium-hub/components';
import { IVideoVariableVersion, NEW_VIDEO_VARIABLE_VERSION } from '../constants/snippets';
import { fields as copy } from '../constants/copy';
import { ICON_PLUS, ICON_TIMES } from '../constants/icons';
import { generateUUID } from '../util/story';

interface IVideoVariableVersionsProps {
    versions?: IVideoVariableVersion[];
    onChange(e): void;
}

class VideoVariableVersions extends React.PureComponent<IVideoVariableVersionsProps, undefined> {
    constructor(props) {
        super(props);
    }

    private addVersion() {
        const newVersions = [...this.props.versions];
        const version = { ...NEW_VIDEO_VARIABLE_VERSION };
        version.id = generateUUID();
        newVersions.push(version);
        this.props.onChange(newVersions);
    }

    private removeVersion(index) {
        const newVersions = [...this.props.versions];
        newVersions.splice(index, 1);

        this.props.onChange(newVersions);
    }

    private updateVersion(field, index, value) {
        const newVersions = [...this.props.versions];
        const newVersion = { ...newVersions[index] };
        newVersion[field] = value;
        newVersions[index] = newVersion;

        this.props.onChange(newVersions);
    }

    public render() {
        const { versions } = this.props;

        const btnAdd = (
            <Button
                style='subtle'
                onClick={() => this.addVersion()}
                color='primary'>
                {ICON_PLUS}
            </Button>
        );

        const label = (
            <span>
                {copy.variable.addVersion}&nbsp;{btnAdd}
            </span>
        );

        return (
            <>
                <FieldWrapper
                    label={label}
                    tooltip={copy.variable.tooltipVideoVersion}>
                    {versions.map((version, i) => {
                        return (
                            <VideoVariableVersion
                                onRemove={() => this.removeVersion(i)}
                                label={`Version ${i + 1}`}
                                key={version.id}
                                onFieldChange={(k, v) => this.updateVersion(k, i, v)}
                                {...version}
                            />
                        );
                    })}
                </FieldWrapper>
            </>
        );
    }
}

export const VideoVariableVersion = (props) => {
    const btnRemove = (
        <Button
            style='subtle'
            key='btn-remove'
            customStyles={{
                position: 'absolute',
                top: '0px',
                right: '0px'
            }}
            onClick={() => props.onRemove()}>
            {ICON_TIMES}
        </Button>
    );

    return (
        <div className={`variable-version`}>
            <TextField
                buttons={[btnRemove]}
                onChange={(e) => props.onFieldChange('name', e)}
                value={props.name}
            />
            <NumberField
                label={copy.global.width}
                onChange={(e) => props.onFieldChange('width', e)}
                value={props.width}
            />
            <NumberField
                label={copy.global.height}
                onChange={(e) => props.onFieldChange('height', e)}
                value={props.height}
            />
            <SelectField
                label={copy.encoding.resize}
                tooltip={copy.variable.tooltipResizeType}
                options={VARIABLE_RESIZE_OPTIONS}
                onChange={(e) => props.onFieldChange('resize', e)}
                value={props.resize}
            />
        </div>
    );
};

export default VideoVariableVersions;
