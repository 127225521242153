import { LAYER_SOURCE_STATUSES } from '../../../constants/editor';
import MediaLayerSource from './MediaLayerSource';

export default class SolidLayerSource extends MediaLayerSource {
    public async loadLayerSource(): Promise<void | any> {
        this.setState({
            status: LAYER_SOURCE_STATUSES.READY
        });

        // Overwrite this
        return Promise.resolve();
    }
}
