import { Modal, Section, TextAreaField } from '@imposium-hub/components';

export const DatasetAssetsModal = ({ show, onClose, assets }) => {
    let assetString = '';
    for (const asset of assets) {
        assetString += `${asset}\n`;
    }

    return (
        <Modal
            onRequestClose={onClose}
            style={{
                width: '500px',
                height: '70%',
                top: '10%',
                left: 'calc((100% - 600px) / 2)'
            }}
            isOpen={show}>
            <Section title={`Required Assets`}>
                <div className='dataset-assets-modal'>
                    <TextAreaField
                        value={assetString}
                        readOnly
                        showCopy={true}
                    />
                </div>
            </Section>
        </Modal>
    );
};
