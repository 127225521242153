import * as React from 'react';
import { toFixed, padStart, SMPTEField } from '@imposium-hub/components';
import Timecode from 'smpte-timecode';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { updateTimelineState } from '../../redux/actions/timeline';

interface IPositionDisplayProps {
    activeFrame: number;
    frameRate: number;
    updateTimelineState(c): void;
}

class PositionDisplay extends React.PureComponent<IPositionDisplayProps, undefined> {
    private evtHandlers: any;

    constructor(props) {
        super(props);

        this.evtHandlers = {
            duration: (v) => this.updateDuration(v)
        };
    }

    private updateDuration(v) {
        const { frameRate } = this.props;
        const rate = parseFloat(toFixed(frameRate, 3));
        const duration = Timecode(v, parseFloat(toFixed(rate, 3)));
        this.props.updateTimelineState({ activeFrame: duration.frameCount });
    }

    public render() {
        const { activeFrame, frameRate } = this.props;
        const rate = parseFloat(toFixed(frameRate, 3));
        const timecode = new Timecode(activeFrame, rate).toString();

        return (
            <div className='position-display'>
                <div className='timecode'>
                    <SMPTEField
                        value={timecode}
                        frameRate={rate}
                        onChange={this.evtHandlers.duration}
                    />
                </div>
                <div className='frames'>
                    {padStart(activeFrame, 5)}&nbsp;{`(${rate} fps)`}
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ updateTimelineState }, dispatch);
};

export default connect(null, mapDispatchToProps)(PositionDisplay);
