import * as React from 'react';
import { Spinner } from '@imposium-hub/components';
import { fields as copy } from '../constants/copy';

const BlockingJobOverlay = () => {
    return (
        <div className='blocking-job-overlay'>
            <div className='content'>
                <Spinner />
                <br />
                <br />
                <h2>{copy.global.blockingJob}</h2>
            </div>
        </div>
    );
};

export default BlockingJobOverlay;
