import * as React from 'react';
import SplitPane from 'react-split-pane';
import LeftHeader from './LeftHeader';
import AssetsSection from './assets/AssetsSection';
import CompositionLayerConfig from './CompositionLayerConfig';
import RightHeader from './RightHeader';
import Viewer from './viewer/Viewer';
import { IComposition, IStory, IViewer, NEW_EMPTY_DEFAULT_VIEWER } from '../constants/snippets';
import type { IEditor } from '../redux/reducers/editor';
import type { IProject } from '../redux/reducers/project';
import { sections as sectionCopy } from '../constants/copy';
import { Button, ButtonGroupField } from '@imposium-hub/components';
import { updateEditorConfig } from '../redux/actions/editor';
import { connect } from 'react-redux';
import { ICON_BARS_STAGGERED, ICON_BRACKET_CURLY, ICON_TABLE } from '../constants/icons';
import {
    HEADER_HEIGHT,
    LEFT_PANEL_DEFAULT,
    VAR_PANEL_DEFAULT,
    TIMELINE_MAX,
    TIMELINE_MIN,
    LAYERED_TIMELINE_DEFAULT,
    LOWER_PANELS,
    LEFT_PANEL_EXPANDED,
    ASSET_TABLE_EXPANDED,
    ASSET_TABLE_DEFAULT
} from '../constants/editor';
import PaneErrorBoundry from './errors/PaneErrorBoundry';
import { getFirstAct } from '../util/story';
import {
    updateAnchor,
    deleteAnchor,
    loadComposition,
    addLayer,
    deleteLayer,
    deleteLayers,
    duplicateLayer,
    moveLayer,
    updateLayer,
    shiftLayers,
    duplicateLayers
} from '../redux/actions/compositions';
import {
    createDataset,
    getDataset,
    updateDatasetData,
    updateDatasetName,
    deleteDataset,
    duplicateDataset,
    addDatasetRow,
    duplicateDatasetRow,
    addDatasetColumn,
    deleteDatasetColumns,
    deleteDatasetRows,
    importDataset,
    updateActiveDataset,
    updateDatasetColumnWidths
} from '../redux/actions/datasets';
import { bindActionCreators } from 'redux';
import { ASSET_TYPES } from '../constants/story';
import LayeredTimeline from './timeline/LayeredTimeline';
import EmptyLayeredTimeline from './timeline/EmptyLayeredTimeline';
import hotkeys from 'hotkeys-js';
import VariablePanel from './VariablePanel';
import { DatasetEditor } from './data/DatasetEditor';
import { updateMultipleInventory } from '../redux/actions/story';

interface ICompositionStoryInterfaceProps {
    organizationId: string;
    compositions: IComposition;
    story: IStory;
    editor: IEditor;
    project: IProject;
    datasets: any;
    viewer: IViewer;
    activeTimelineLayer: string;
    loadComposition(id): void;
    editComposition(id, c): void;
    updateEditorConfig(c): void;
    addLayer(id, l, i?): void;
    deleteLayer(id, l): void;
    deleteLayers(id, lIds): void;
    duplicateLayer(id, l): void;
    duplicateLayers(id, ls): void;
    moveLayer(id, l, i): void;
    updateLayer(id, l): void;
    updateAnchor(id, lId, tId, lS, tS): void;
    deleteAnchor(id, lId, start, aLid): void;
    shiftLayers(id, l, f): void;
    onStoryChange?: (story: any) => any;
    createDataset(sId, data, name): void;
    getDataset(sId, id): void;
    updateDatasetData(sId, id, data, name): void;
    updateDatasetName(sId, id, name): void;
    deleteDataset(sId, id): void;
    duplicateDataset(sId, dataset, name): void;
    addDatasetRow(sId, id, index, rows): void;
    duplicateDatasetRow(sId, id, index): void;
    addDatasetColumn(sId, id, header): void;
    deleteDatasetRows(sId, id, range): void;
    deleteDatasetColumns(sId, id, range): void;
    importDataset(sId, id, file): void;
    updateMultipleInventory(actId, items): void;
    updateActiveDataset(sId, id, data): void;
    updateDatasetColumnWidths(sId, id, data): void;
}

class CompositionStoryInterface extends React.PureComponent<ICompositionStoryInterfaceProps, null> {
    private evtHandlers: any;

    private btnToggleStyle = {
        position: 'absolute',
        top: '2px',
        right: '2px'
    };

    constructor(props) {
        super(props);

        this.evtHandlers = {
            toggleVars: () => this.toggleVars(),
            toggleLowerPanel: (v) => this.toggleLowerPanel(v)
        };
    }

    public componentDidMount() {
        this.checkCompLoad();
        hotkeys('v', this.evtHandlers.toggleVars);
    }

    public componentDidUpdate(prevProps) {
        if (prevProps.viewer.activeViewer !== this.props.viewer.activeViewer) {
            this.checkCompLoad();
        }
    }

    public componentWillUnmount() {
        hotkeys.unbind('v', this.evtHandlers.toggleVars);
    }

    private checkCompLoad() {
        const {
            viewer: { tabs },
            compositions
        } = this.props;

        // go through the tabs, if there is a comp tab, and the comp isn't loaded, load that comp
        for (const tab of tabs) {
            if (tab.type === ASSET_TYPES.VIDEO_COMPOSITION && !compositions[tab.id]) {
                this.props.loadComposition(tab.id);
            }
        }
    }

    private toggleVars() {
        this.props.updateEditorConfig({
            varsOpen: !this.props.editor.varsOpen
        });
    }

    private toggleLowerPanel(v) {
        this.props.updateEditorConfig({
            activeLowerPanel: v
        });
    }

    public render() {
        const {
            story,
            datasets: {
                present: { datasetList, activeDataset, loadingDataset }
            },
            activeTimelineLayer,
            editor: { varsOpen, expandPanel, varsDisabled, activeLowerPanel, activeDatasetId },
            project: { actId, compositionId },
            compositions,
            viewer: { activeViewer },
            organizationId
        } = this.props;

        const composition: any = compositions[compositionId];
        const baseVideo: any = composition?.videoFile;
        const act = actId ? story.acts[actId] : getFirstAct(story);
        const activeLayerIndex =
            activeTimelineLayer && composition
                ? composition.layers.findIndex((l) => l.id === activeTimelineLayer)
                : null;
        const activeLayer =
            activeLayerIndex !== -1 && activeLayerIndex !== null
                ? composition.layers[activeLayerIndex]
                : null;

        const btnOpenVars = (
            <Button
                style='subtle'
                tooltip={sectionCopy.variablesTooltip}
                onClick={this.evtHandlers.toggleVars}
                customStyles={this.btnToggleStyle}>
                {ICON_BRACKET_CURLY}
            </Button>
        );

        const frames = baseVideo ? baseVideo.totalFrames : composition ? composition.frames : null;
        const rate = baseVideo ? baseVideo.rate : composition ? composition.rate : null;
        const width = baseVideo ? baseVideo.width : composition ? composition.width : null;
        const height = baseVideo ? baseVideo.height : composition ? composition.height : null;

        const lowerPanelToggle = compositionId && (
            <div className='lower-panel-toggle'>
                <ButtonGroupField
                    value={activeLowerPanel}
                    width='60px'
                    options={[
                        {
                            value: LOWER_PANELS.TIMELINE,
                            label: ICON_BARS_STAGGERED
                        },
                        {
                            value: LOWER_PANELS.DATA,
                            label: ICON_TABLE
                        }
                    ]}
                    onChange={this.evtHandlers.toggleLowerPanel}
                />
            </div>
        );

        const paneContent =
            composition &&
            frames &&
            width &&
            height &&
            composition.layers &&
            activeLowerPanel === LOWER_PANELS.TIMELINE ? (
                <PaneErrorBoundry>
                    <LayeredTimeline
                        compositionId={compositionId}
                        viewerHidden={activeViewer !== compositionId}
                        updateLayer={(layer) => this.props.updateLayer(compositionId, layer)}
                        updateAnchor={(lId, tId, lS, tS) =>
                            this.props.updateAnchor(compositionId, lId, tId, lS, tS)
                        }
                        moveLayer={(index, shift) =>
                            this.props.moveLayer(compositionId, index, shift)
                        }
                        addLayer={(layer, index) =>
                            this.props.addLayer(compositionId, layer, index)
                        }
                        shiftLayers={(layers, f) =>
                            this.props.shiftLayers(compositionId, layers, f)
                        }
                        deleteLayer={(layerId) => this.props.deleteLayer(compositionId, layerId)}
                        deleteLayers={(layerIds) =>
                            this.props.deleteLayers(compositionId, layerIds)
                        }
                        deleteAnchor={(lId, aLId, start) => {
                            this.props.deleteAnchor(compositionId, lId, start, aLId);
                        }}
                        duplicateLayer={(layerId) =>
                            this.props.duplicateLayer(compositionId, layerId)
                        }
                        duplicateLayers={(layerIds) =>
                            this.props.duplicateLayers(compositionId, layerIds)
                        }
                        layers={composition.layers}
                        totalFrames={frames}
                        rate={rate}
                        width={width}
                        height={height}
                    />
                </PaneErrorBoundry>
            ) : composition && activeLowerPanel === LOWER_PANELS.DATA ? (
                <DatasetEditor
                    loading={loadingDataset}
                    activeDatasetId={activeDatasetId}
                    activeStoryId={story.id}
                    datasetList={datasetList}
                    activeDataset={activeDataset}
                    variables={act.inventory}
                    activeComposition={composition}
                    onDelete={(id) => {
                        this.props.deleteDataset(story.id, id);
                    }}
                    onDuplicate={(newName) => {
                        this.props.duplicateDataset(story.id, activeDataset, newName);
                    }}
                    onSelect={(id) => {
                        this.props.updateEditorConfig({ activeDatasetId: id });
                        this.props.getDataset(story.id, id);
                    }}
                    onChangeData={(id, data, name) => {
                        this.props.updateDatasetData(story.id, id, data, name);
                    }}
                    onSetSampleData={(id, data) => {
                        this.props.updateActiveDataset(story.id, id, data);
                    }}
                    onAddRow={(id, index, rowsToAdd) => {
                        this.props.addDatasetRow(story.id, id, index, rowsToAdd);
                    }}
                    onDuplicateRow={(id, index) => {
                        this.props.duplicateDatasetRow(story.id, id, index);
                    }}
                    onAddColumn={(id, header) => {
                        this.props.addDatasetColumn(story.id, id, header);
                    }}
                    onRemoveRows={(id, range) => {
                        this.props.deleteDatasetRows(story.id, id, range);
                    }}
                    onRemoveColumns={(id, range) => {
                        this.props.deleteDatasetColumns(story.id, id, range);
                    }}
                    onChangeName={(id, name) => {
                        this.props.updateDatasetName(story.id, id, name);
                    }}
                    onMoveColumns={(id, data) => {
                        this.props.updateActiveDataset(story.id, id, data);
                    }}
                    onMoveRows={(id, data) => {
                        this.props.updateActiveDataset(story.id, id, data);
                    }}
                    onColumnResize={(id, data) => {
                        this.props.updateDatasetColumnWidths(story.id, id, data);
                    }}
                    onImport={(id, file) => {
                        this.props.importDataset(story.id, id, file);
                    }}
                    onCreate={(file, name) => this.props.createDataset(story.id, file, name)}
                    onPreview={(items) => this.props.updateMultipleInventory(actId, items)}
                />
            ) : (
                <EmptyLayeredTimeline />
            );

        const verticalPanelProps = expandPanel ? { size: LEFT_PANEL_EXPANDED } : {};
        const assetTableProps = expandPanel ? { size: ASSET_TABLE_EXPANDED } : {};

        // TODO: remove everything that supports the old composition format
        return (
            <SplitPane
                split='horizontal'
                minSize={TIMELINE_MIN}
                maxSize={TIMELINE_MAX}
                defaultSize={LAYERED_TIMELINE_DEFAULT}
                primary='second'>
                <SplitPane
                    split='vertical'
                    allowResize={false}
                    maxSize={LEFT_PANEL_EXPANDED}
                    {...verticalPanelProps}
                    defaultSize={LEFT_PANEL_DEFAULT}
                    primary='first'>
                    <SplitPane
                        split='horizontal'
                        minSize={HEADER_HEIGHT}
                        maxSize={HEADER_HEIGHT}
                        defaultSize={HEADER_HEIGHT}
                        allowResize={false}
                        primary='first'>
                        <PaneErrorBoundry>
                            <LeftHeader
                                organizationId={organizationId}
                                onStoryChange={this.props.onStoryChange}
                            />
                        </PaneErrorBoundry>

                        <SplitPane
                            split='horizontal'
                            defaultSize={ASSET_TABLE_DEFAULT}
                            {...assetTableProps}
                            primary='first'>
                            <div className='panel'>
                                <PaneErrorBoundry>
                                    <AssetsSection />
                                </PaneErrorBoundry>
                            </div>

                            <div className='panel'>
                                {composition && activeLayer && (
                                    <PaneErrorBoundry>
                                        <CompositionLayerConfig
                                            rate={rate}
                                            width={width}
                                            height={height}
                                            layer={activeLayer}
                                            allLayers={composition.layers}
                                            compositionId={compositionId}
                                            updateLayer={(layer) =>
                                                this.props.updateLayer(compositionId, layer)
                                            }
                                            updateAnchor={(lId, tId, lS, tS) =>
                                                this.props.updateAnchor(
                                                    compositionId,
                                                    lId,
                                                    tId,
                                                    lS,
                                                    tS
                                                )
                                            }
                                            deleteAnchor={(lId, s) =>
                                                this.props.deleteAnchor(compositionId, lId, s, null)
                                            }
                                            deleteLayer={(layerId) =>
                                                this.props.deleteLayer(compositionId, layerId)
                                            }
                                            duplicateLayer={(layerId) =>
                                                this.props.duplicateLayer(compositionId, layerId)
                                            }
                                            variables={act.inventory}
                                        />
                                    </PaneErrorBoundry>
                                )}
                            </div>
                        </SplitPane>
                    </SplitPane>

                    <SplitPane
                        split='horizontal'
                        minSize={HEADER_HEIGHT}
                        maxSize={HEADER_HEIGHT}
                        defaultSize={HEADER_HEIGHT}
                        allowResize={false}
                        primary='first'>
                        <PaneErrorBoundry>
                            <RightHeader />
                        </PaneErrorBoundry>

                        <SplitPane
                            split='vertical'
                            allowResize={false}
                            defaultSize={varsOpen ? VAR_PANEL_DEFAULT : 0}
                            primary='second'>
                            <div className='panel'>
                                <PaneErrorBoundry>
                                    <Viewer
                                        videoFile={baseVideo}
                                        variables={act.inventory}
                                    />
                                </PaneErrorBoundry>
                                {!varsOpen && !varsDisabled ? btnOpenVars : null}
                            </div>

                            <div className='panel'>
                                <PaneErrorBoundry>
                                    <VariablePanel />
                                </PaneErrorBoundry>
                            </div>
                        </SplitPane>
                    </SplitPane>
                </SplitPane>

                <>
                    {paneContent}
                    {lowerPanelToggle}
                </>

                <div />
            </SplitPane>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            updateEditorConfig,
            loadComposition,
            updateAnchor,
            deleteAnchor,
            addLayer,
            deleteLayer,
            deleteLayers,
            moveLayer,
            updateLayer,
            duplicateLayer,
            duplicateLayers,
            shiftLayers,
            createDataset,
            getDataset,
            updateDatasetData,
            updateDatasetName,
            deleteDataset,
            duplicateDataset,
            duplicateDatasetRow,
            addDatasetRow,
            addDatasetColumn,
            deleteDatasetRows,
            deleteDatasetColumns,
            importDataset,
            updateMultipleInventory,
            updateActiveDataset,
            updateDatasetColumnWidths
        },
        dispatch
    );
};

const mapStateToProps = (state): any => {
    return {
        activeTimelineLayer: state.timeline.activeTimelineLayer,
        story: state.story,
        datasets: state.datasets,
        viewer: state.story.viewer || { ...NEW_EMPTY_DEFAULT_VIEWER },
        compositions: state.compositions.present,
        editor: state.editor,
        project: state.project
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompositionStoryInterface);
