import * as React from 'react';
import { connect } from 'react-redux';
import { createStory } from '../redux/actions/story';
import { IStory } from '../constants/snippets';
import type { IEditor } from '../redux/reducers/editor';
import { fields as copy } from '../constants/copy';
import { Button } from '@imposium-hub/components';
import { ICON_PLUS } from '../constants/icons';
import { updateEditorConfig } from '../redux/actions/editor';

interface IStoryPromptProps {
    editor: IEditor;
    story: IStory;
    createStory(i): IStory;
    updateEditorConfig(c): void;
}

class StoryPrompt extends React.PureComponent<IStoryPromptProps> {
    constructor(props) {
        super(props);
    }

    private createStory() {
        this.props.updateEditorConfig({
            newStoryModalOpen: true
        });
    }

    public render() {
        return (
            <div
                className='story-prompt'
                onClick={(e) => this.createStory()}>
                <div>
                    <h2>{copy.story.uploadPrompt}</h2>
                    <br />
                    <br />
                    <br />
                    <Button
                        style='subtle'
                        size='large'>
                        {ICON_PLUS}
                    </Button>
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createStory: (d) => dispatch(createStory(d)),
        updateEditorConfig: (c) => dispatch(updateEditorConfig(c))
    };
};

const mapStateToProps = (state): any => {
    return { editor: state.editor, story: state.story };
};

export default connect(mapStateToProps, mapDispatchToProps)(StoryPrompt);
