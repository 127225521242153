const ErrorStoryPrompt = () => {
    return (
        <div className='error-story-prompt'>
            <div>
                <h2>Project Not Found</h2>
            </div>
        </div>
    );
};

export default ErrorStoryPrompt;
