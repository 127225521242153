import * as React from 'react';
import { logError } from '../util/notifications';
import { SliderField, Card } from '@imposium-hub/components';
import { ASSET_TYPES, COMPOSITION_LAYER_TYPES } from '../constants/story';
import { IOverlayEffects } from '../constants/snippets';
import { fields as copy } from '../constants/copy';
import { api } from '../constants/app';
import AnimationFields from './AnimationFields';
import AssetField from './assets/AssetField';

interface ILayerEffectConfigProps {
    storyId: string;
    config: IOverlayEffects;
    layerType: string;
    onChange(e: any): void;
}

class LayerEffectConfig extends React.PureComponent<ILayerEffectConfigProps, undefined> {
    private evtHandlers: any;

    constructor(props) {
        super(props);

        this.evtHandlers = {
            inputs: {
                mask: (a) => this.maskAssetChanged(a),
                volume: (v) => this.update('volume', v),
                saturation: (v) => this.update('saturation', v),
                hue: (v) => this.update('hue', v),
                brightness: (v) => this.update('brightness', v),
                blur: (v) => this.update('motion_blur', v),
                lut: (v) => this.update('lut_asset_id', v ? v.id : null),
                assetError: (m) => {
                    logError(m);
                }
            }
        };
    }

    private maskAssetChanged(asset) {
        const newConfig = { ...this.props.config };

        if (!asset) {
            delete newConfig['mask_asset_id'];
        } else {
            newConfig['mask_asset_id'] = asset.id;
        }

        this.props.onChange(newConfig);
    }

    private update(key, value) {
        const newConfig = { ...this.props.config };
        newConfig[key] = value;

        if (key === 'motion_blur') {
            newConfig[key] = value ? 0.2 : 0;
        } else {
            newConfig[key] = value;
        }

        if (value === '' || value === null) {
            delete newConfig[key];
        }

        this.props.onChange(newConfig);
    }

    public render() {
        const { config, storyId, layerType } = this.props;

        const volume =
            layerType === COMPOSITION_LAYER_TYPES.VIDEO ? (
                <SliderField
                    label={copy.overlayConfig.volumeMultiplier}
                    tooltip={copy.overlayConfig.tooltipVolumeMultiplier}
                    value={config['volume']}
                    defaultValue={1}
                    width='50%'
                    min={0}
                    max={5}
                    step={0.1}
                    onChange={this.evtHandlers.inputs.volume}
                />
            ) : null;

        const animOn =
            layerType !== COMPOSITION_LAYER_TYPES.TEXT && config['animate_on'] ? (
                <AnimationFields
                    config={config['animate_on']}
                    title={copy.effectConfig.animOn}
                    onChange={this.evtHandlers.inputs.animOn}
                />
            ) : null;

        const animOff =
            layerType !== COMPOSITION_LAYER_TYPES.TEXT && config['animate_off'] ? (
                <AnimationFields
                    config={config['animate_off']}
                    title={copy.effectConfig.animOff}
                    onChange={this.evtHandlers.inputs.animOff}
                />
            ) : null;

        const maskField = (
            <AssetField
                api={api}
                storyId={storyId}
                label={copy.effectConfig.mask}
                width='50%'
                tooltip={copy.effectConfig.tooltipMask}
                onChange={this.evtHandlers.inputs.mask}
                accepts={[ASSET_TYPES.IMAGE, ASSET_TYPES.IMAGE_SEQUENCE]}
                onError={this.evtHandlers.inputs.assetError}
                assetId={config['mask_asset_id']}
            />
        );

        return (
            <Card
                title={copy.layerConfig.effects}
                open={false}
                collapsable={true}
                style={'effects'}>
                {config['mask_asset_id'] ? maskField : null}
                <SliderField
                    label={copy.effectConfig.saturation}
                    width='50%'
                    min={0}
                    max={1}
                    step={0.01}
                    value={config['saturation']}
                    defaultValue={1}
                    onChange={this.evtHandlers.inputs.saturation}
                />
                <SliderField
                    label={copy.effectConfig.hue}
                    width='50%'
                    min={0}
                    max={360}
                    step={1}
                    value={config['hue']}
                    defaultValue={0}
                    onChange={this.evtHandlers.inputs.hue}
                />
                <SliderField
                    label={copy.effectConfig.brightness}
                    width='50%'
                    min={-10}
                    max={10}
                    step={0.05}
                    value={config['brightness']}
                    defaultValue={0}
                    onChange={this.evtHandlers.inputs.brightness}
                />
                <AssetField
                    api={api}
                    storyId={storyId}
                    label={copy.overlayConfig.lut}
                    width='50%'
                    tooltip={copy.overlayConfig.tooltipLut}
                    onChange={this.evtHandlers.inputs.lut}
                    onError={this.evtHandlers.inputs.assetError}
                    accepts={ASSET_TYPES.LUT}
                    assetId={config['lut_asset_id']}
                />
                {volume}
                {animOn}
                {animOff}
            </Card>
        );
    }
}

export default LayerEffectConfig;
