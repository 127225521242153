import * as React from 'react';
import {
    Button,
    TextField,
    FieldWrapper,
    HRule,
    Spinner,
    SelectField
} from '@imposium-hub/components';
import { ICON_EDIT } from '../constants/icons';
import { fields as copy } from '../constants/copy';
import { api } from '../constants/app';
import { logError, logNotification } from '../util/notifications';
import { AWS_S3_CLOUD_REGIONS } from '../constants/story';

interface IS3BucketFieldsProps {
    value: string;
    label: string;
    bucketReadOnly: boolean;
    createCloudFront: boolean;
    tooltip: any;
    onChange: (val: string) => any;
}

interface IS3BucketFieldsState {
    value: string;
    editing: boolean;
    queryRunning: boolean;
    region: string;
    exists: boolean;
}

class S3BucketFields extends React.PureComponent<IS3BucketFieldsProps, IS3BucketFieldsState> {
    constructor(props) {
        super(props);

        this.state = {
            value: props.value,
            editing: false,
            region: AWS_S3_CLOUD_REGIONS[0],
            queryRunning: false,
            exists: null
        };
    }

    private editFields() {
        const { editing } = this.state;
        if (editing) {
            this.setState({ editing: false, value: this.props.value });
        } else {
            this.setState({ editing: true });
        }
    }

    private checkBucketExists() {
        const bucket = this.state.value;

        logNotification(copy.project.checkBucket.replace('[bucket]', bucket));

        this.setState(
            {
                queryRunning: true
            },
            () => {
                api.checkS3Bucket(bucket)
                    .then((d) => {
                        this.setState({
                            queryRunning: false,
                            exists: d.exists
                        });
                    })
                    .catch((e) => {
                        this.setState(
                            {
                                queryRunning: false
                            },
                            () => {
                                logError(copy.project.checkError);
                            }
                        );
                    });
            }
        );
    }

    private createBucket() {
        const { value, region } = this.state;
        const { bucketReadOnly, createCloudFront } = this.props;

        logNotification(copy.project.creatingBucket.replace('[bucket]', value));
        this.setState(
            {
                queryRunning: true
            },
            () => {
                api.createS3Bucket(value, region, bucketReadOnly, createCloudFront)
                    .then((d) => {
                        logNotification(copy.project.creatingSuccess.replace('[bucket]', value));
                        this.setState({
                            queryRunning: false,
                            exists: true
                        });
                    })
                    .catch((e) => {
                        this.setState(
                            {
                                queryRunning: false
                            },
                            () => {
                                logError(copy.project.creatingError.replace('[bucket]', value));
                            }
                        );
                    });
            }
        );
    }

    private updateNewBucket(v) {
        this.setState({
            value: v,
            exists: null
        });
    }

    private updateRegion(v) {
        this.setState({
            region: v
        });
    }

    private useBucket() {
        this.setState(
            {
                editing: false,
                exists: null
            },
            () => {
                this.props.onChange(this.state.value);
            }
        );
    }

    private renderFields() {
        const { editing, exists, queryRunning, region } = this.state;

        const btnCheck = (
            <Button
                style='subtle'
                color='secondary'
                key='btn-check'
                tooltip={copy.project.tooltipS3Check}
                onClick={() => this.checkBucketExists()}>
                {copy.global.check}
            </Button>
        );

        const btnUse = (
            <Button
                style='subtle'
                color='primary'
                key='btn-use'
                tooltip={copy.project.tooltipS3Use}
                onClick={() => this.useBucket()}>
                {copy.global.use}
            </Button>
        );

        const btnCreate = (
            <Button
                style='subtle'
                color='primary'
                key='btn-use'
                tooltip={copy.project.tooltipS3Create}
                onClick={() => this.createBucket()}>
                {copy.global.create}
            </Button>
        );

        const regionSelect = (
            <SelectField
                options={AWS_S3_CLOUD_REGIONS}
                buttons={[btnCreate]}
                onChange={(v) => this.updateRegion(v)}
                value={region}
            />
        );

        let prompt;
        let select;

        if (queryRunning) {
            prompt = <Spinner />;
        } else {
            if (exists === null) {
                prompt = copy.project.bucketCheckPrompt;
            } else if (exists === true) {
                prompt = copy.project.bucketUsePrompt;
            } else if (exists === false) {
                prompt = copy.project.bucketCreatePrompt;
                select = regionSelect;
            }
        }

        if (editing) {
            return (
                <>
                    <div style={{ textAlign: 'right', height: '20px' }}>{prompt}</div>
                    <HRule style='subtle' />

                    <FieldWrapper label={copy.global.bucket}>
                        <TextField
                            buttons={[exists === true ? btnUse : btnCheck]}
                            onChange={(v) => this.updateNewBucket(v)}
                            value={this.state.value}
                        />
                        {select}
                    </FieldWrapper>

                    <HRule style='subtle' />
                </>
            );
        } else {
            return null;
        }
    }

    public render() {
        const { label, value, tooltip } = this.props;
        const btnEdit = (
            <Button
                style='subtle'
                color='secondary'
                key='btn-edit'
                tooltip={copy.global.edit}
                onClick={() => this.editFields()}>
                {ICON_EDIT}
            </Button>
        );

        return (
            <FieldWrapper
                label={label}
                tooltip={tooltip}>
                <TextField
                    readOnly={true}
                    buttons={[btnEdit]}
                    value={value}
                />
                {this.renderFields()}
            </FieldWrapper>
        );
    }
}

export default S3BucketFields;
