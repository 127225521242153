import { Button, TextField, TextAreaField } from '@imposium-hub/components';
import { useState } from 'react';
import { ICON_COMPRESS, ICON_CHECK } from '../constants/icons';
import { logError, logNotification } from '../util/notifications';
import { scaleCoords, convertMocha, convertAE, countCoordsFrames } from '../util/story';

export const MTQuadPositionConfig = ({ config, onChange }) => {
    const [scale, setScale] = useState('');
    const {
        position_inputs,
        position_inputs: { coords }
    } = config;

    const evtHandlers = {
        inputs: {
            updateScale: (s) => updateScale(s),
            updateCoords: (v) => updateCoords(v)
        }
    };

    const updateCoords = (value) => {
        const newConfig = { ...position_inputs };
        newConfig['coords'] = value;
        onChange('position_inputs', newConfig);
    };

    const updateScale = (s) => {
        setScale(s);
    };

    const transformScale = () => {
        const newScale: number = parseFloat(scale);

        if (!isNaN(newScale)) {
            const newCoords = scaleCoords(coords, newScale);
            setScale('');
            updateCoords(newCoords);
        } else {
            logError('No number found in scale input.');
        }
    };

    const convertCornerPinData = () => {
        let newCoords;

        if (coords === undefined || coords.trim() === '') {
            logError('Invalid Corner Pin Data');
            return;
        }

        if (coords.indexOf('ADBE Corner Pin') !== -1) {
            newCoords = convertMocha(coords);
        } else if (
            coords.indexOf('Adobe After Effects') !== -1 &&
            coords.indexOf('Keyframe Data') !== -1
        ) {
            newCoords = convertAE(coords);
        } else {
            logError('Valid Corner pin data not found - please update the input and try again.');
            return;
        }

        updateCoords(newCoords);
    };

    const renderTransform = () => {
        if (
            (coords.indexOf('Adobe After Effects') !== -1 &&
                coords.indexOf('Keyframe Data') !== -1) ||
            coords.indexOf('ADBE Corner Pin') !== -1
        ) {
            return (
                <Button
                    onClick={() => convertCornerPinData()}
                    key='btn-transform-tracking'
                    style='subtle'
                    color='primary'>
                    {ICON_COMPRESS}
                </Button>
            );
        } else {
            const btnScale = (
                <Button
                    key='btn-scale'
                    style='subtle'
                    onClick={() => transformScale()}>
                    {ICON_CHECK}
                </Button>
            );

            return (
                <TextField
                    width='80px'
                    key='scale-input'
                    placeholder='Scale'
                    buttons={[btnScale]}
                    value={scale}
                    onChange={evtHandlers.inputs.updateScale}
                />
            );
        }
    };

    return (
        <>
            <div
                className='keyframe-controller'
                style={{ height: '66px' }}>
                <div className='col-left'></div>
                <div className='col-right'>
                    <div className='keyframe-controller-label'>Coordinates</div>
                </div>
                <div className={`keyframe-controls`}>
                    <TextAreaField
                        label=' '
                        showCopy={true}
                        width={`306px`}
                        value={coords}
                        onNotification={(n) => logNotification(n)}
                        onError={(n) => logError(n)}
                        onChange={evtHandlers.inputs.updateCoords}
                    />
                </div>
            </div>
            <div className='keyframe-controller'>
                <div className='col-left'></div>
                <div className='col-right'>
                    <div className='keyframe-controller-label'></div>
                </div>
                <div className={`keyframe-controls`}>
                    <div className='coord-transform'>
                        <div className='frames'>Frames:&nbsp;{countCoordsFrames(coords)}</div>
                        <div className='transform'>{renderTransform()}</div>
                    </div>
                </div>
            </div>
        </>
    );
};
