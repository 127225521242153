import * as React from 'react';
import { ROW_HEIGHT } from '../../constants/timeline';

interface IBaseProps {
    width: number;
}

class Base extends React.PureComponent<IBaseProps> {
    constructor(props) {
        super(props);

        this.state = {};
    }

    public render() {
        const { width } = this.props;

        const style = {
            height: `${ROW_HEIGHT}px`,
            width: `${width}px`
        };

        return (
            <div
                className='base'
                style={style}>
                <div className='border-top' />
                <div className='border-right' />
                <div className='border-left' />
                <div className='border-bottom' />
            </div>
        );
    }
}

export default Base;
