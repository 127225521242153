import * as React from 'react';
import { TextField, SelectField, Card } from '@imposium-hub/components';
import { fields as copy } from '../constants/copy';
import { IMetadata } from '../constants/snippets';
import { LOCALE_OPTIONS } from '../constants/story';

interface IMetadataConfig {
    config: IMetadata;
    onChange(c: IMetadata): void;
}

export const MetadataConfig = ({ config, onChange }: IMetadataConfig) => {
    const update = (key, value) => {
        const c = { ...config };
        c[key] = value;

        onChange(c);
    };

    return (
        <div
            className='settings-tab'
            key='player'>
            <Card
                title={copy.metadata.header}
                collapsable={false}
                open={true}>
                <TextField
                    label={copy.metadata.title}
                    value={config['title']}
                    tooltip={copy.metadata.tooltipTitle}
                    onChange={(v) => update('title', v)}
                />
                <TextField
                    label={copy.metadata.description}
                    value={config['description']}
                    tooltip={copy.metadata.tooltipDescription}
                    onChange={(v) => update('description', v)}
                />
                <TextField
                    label={copy.metadata.twitter}
                    value={config['twitterHandle']}
                    tooltip={copy.metadata.tooltipTwitter}
                    onChange={(v) => update('twitterHandle', v)}
                    pattern={/(^(?![\s\S]))|(^@[\w\d]{0,15}$)/}
                />
                <TextField
                    label={copy.metadata.facebook}
                    value={config['fbAppId']}
                    tooltip={copy.metadata.tooltipFacebook}
                    onChange={(v) => update('fbAppId', v)}
                />
                <SelectField
                    label={copy.metadata.locale}
                    value={config['locale']}
                    options={LOCALE_OPTIONS}
                    tooltip={copy.metadata.tooltipLocale}
                    onChange={(v) => update('locale', v)}
                />
            </Card>
        </div>
    );
};
