import * as React from 'react';
import { LAYER_DND_TYPES } from '../../constants/timeline';
import { useDrag } from 'react-dnd';
import { ICON_TIMES } from '../../constants/icons';
import { getEmptyImage } from 'react-dnd-html5-backend';
interface ILayerAnchorDragHandleProps {
    id: string;
    start: boolean;
}

export const LayerAnchorDragHandle: React.FC<ILayerAnchorDragHandleProps> = (
    props: ILayerAnchorDragHandleProps
) => {
    const { id, start } = props;

    const [, drag, preview] = useDrag({
        type: LAYER_DND_TYPES.ANCHOR,
        item: {
            id,
            start
        }
    });

    React.useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true });
    }, []);

    const modifier = start ? 'start' : 'end';

    return (
        <div
            className={`layer-anchor-drag-handle ${modifier}`}
            ref={drag}>
            {ICON_TIMES}
        </div>
    );
};
