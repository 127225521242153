import * as React from 'react';
import { Button, CheckboxField, ColorField, NumberField } from '@imposium-hub/components';
import { codeTemplate as copy } from '../../../constants/copy';
import { colorPresets } from '../../../constants/snippets';
import { ICON_SAVE } from '../../../constants/icons';

interface IDevConfigOptionProps {
    asset: any;
    configChange(config: any);
    saveChanges(): void;
}

export default class DevConfigOption extends React.PureComponent<IDevConfigOptionProps> {
    private evtHandlers: any;

    constructor(props) {
        super(props);

        this.evtHandlers = {
            fps: (v) => this.updateConfig('fps', v),
            frames: (v) => this.updateConfig('frames', v),
            frame: (v) => this.updateConfig('frame', v),
            width: (v) => this.updateConfig('width', v),
            height: (v) => this.updateConfig('height', v),
            type: (v) => this.updateConfig('type', v),
            color: (v) => this.updateConfig('background_color', v.hex)
        };
    }

    private updateConfig(field, value) {
        const {
            asset: {
                data: { devConfig }
            }
        } = this.props;
        const newConfig = { ...devConfig };

        if (field === 'type') {
            if (value) {
                newConfig[field] = 'image_sequence';
            } else {
                newConfig[field] = 'image';
            }
        } else if (field === 'width' || field === 'height') {
            newConfig[field] = parseInt(value, 10);
        } else {
            newConfig[field] = value;
        }

        this.props.configChange(newConfig);
    }

    public render() {
        const {
            asset: {
                data: {
                    devConfig: { type, fps, frames, frame, height, width, background_color }
                }
            }
        } = this.props;

        const isAnimated = type === 'image_sequence' ? true : false;

        const {
            config: { label, tooltips }
        } = copy;

        return (
            <>
                <div className='title'>{copy.configuration}</div>
                <div className='config-field'>
                    <ColorField
                        labelPosition='top'
                        tooltip={tooltips.background}
                        enableAlpha={true}
                        presetColors={colorPresets}
                        label={label.background}
                        onChange={this.evtHandlers.color}
                        value={background_color}
                    />
                    <CheckboxField
                        label={label.type}
                        tooltip={tooltips.type}
                        value={isAnimated}
                        labelPosition='top'
                        onChange={this.evtHandlers.type}
                    />
                    <NumberField
                        label={label.fps}
                        tooltip={tooltips.fps}
                        value={fps}
                        labelPosition='top'
                        onChange={this.evtHandlers.fps}
                    />
                    <NumberField
                        label={label.frames}
                        tooltip={tooltips.frames}
                        value={frames}
                        labelPosition='top'
                        onChange={this.evtHandlers.frames}
                    />
                    <NumberField
                        label={label.frame}
                        tooltip={tooltips.frame}
                        value={frame}
                        labelPosition='top'
                        onChange={this.evtHandlers.frame}
                    />
                    <NumberField
                        label={label.width}
                        tooltip={tooltips.width}
                        value={width}
                        labelPosition='top'
                        onChange={this.evtHandlers.width}
                    />
                    <NumberField
                        label={label.height}
                        tooltip={tooltips.height}
                        value={height}
                        labelPosition='top'
                        onChange={this.evtHandlers.height}
                    />
                    <Button
                        customStyles={{
                            position: 'absolute',
                            bottom: '3px',
                            left: '50%',
                            marginLeft: '-47px'
                        }}
                        onClick={() => this.props.saveChanges()}
                        color='primary'>
                        {ICON_SAVE} Save and Close
                    </Button>
                </div>
            </>
        );
    }
}
