import React from 'react';
import SplitPane from 'react-split-pane';
import { Button, fitToContainer } from '@imposium-hub/components';
import { IPreviewScale } from '../../../constants/snippets';
import { ICON_ARROW_ROTATE, ICON_GEAR } from '../../../constants/icons';
import { TEMPLATE_PREVIEW_EVENTS } from '../../../constants/story';

interface IIFramePreviewProps {
    date: number;
    width: number;
    height: number;
    asset: any;
    openDevConfig(): void;
    refresh(): void;
}

interface IIFramePreviewState {
    wrapHeight: number;
    wrapWidth: number;
    openModal: boolean;
}

export class IFramePreview extends React.PureComponent<IIFramePreviewProps, IIFramePreviewState> {
    private previewWrapperRef: any;

    private resizeHandler: any;

    private onLoadMessageHandler: any;

    private iframeRef: any;

    constructor(props) {
        super(props);
        this.state = {
            wrapHeight: null,
            wrapWidth: null,
            openModal: false
        };

        this.resizeHandler = () => this.setPreviewDimensions();
        this.onLoadMessageHandler = (e) => this.onLoadMessage(e);

        this.previewWrapperRef = React.createRef();

        this.iframeRef = React.createRef();
    }

    public componentDidMount(): void {
        this.setPreviewDimensions();

        window.addEventListener('resize', this.resizeHandler);
        window.addEventListener('message', this.onLoadMessageHandler);
    }

    public componentWillUnmount(): void {
        window.removeEventListener('resize', this.resizeHandler);
        window.removeEventListener('message', this.onLoadMessageHandler);
    }

    private onLoadMessage(e) {
        if (e && e.data && e.data.type) {
            if (e.data.type === TEMPLATE_PREVIEW_EVENTS.LOADED) {
                this.iframeRef.current.contentWindow.postMessage(
                    {
                        type: 'TRIGGER_RENDER'
                    },
                    '*'
                );
            }
        }
    }

    public setPreviewDimensions() {
        const element = this.previewWrapperRef.current;
        if (element) {
            this.setState({
                wrapWidth: element.offsetWidth,
                wrapHeight: element.offsetHeight
            });
        }
    }

    private getPreviewDimensions(): IPreviewScale {
        const {
            data: {
                devConfig: { width, height }
            }
        } = this.props.asset;

        const { wrapHeight, wrapWidth } = this.state;

        const dims: any = fitToContainer(
            {
                width: wrapWidth,
                height: wrapHeight - 30
            },
            {
                width,
                height
            },
            {
                scaleMode: 'proportionalInside',
                padding: 10,
                hAlign: 'center',
                vAlign: 'center',
                maxWidth: width,
                maxHeight: height
            }
        );

        dims.scale = dims.width / width;

        return dims;
    }

    public render() {
        const {
            date,
            asset: {
                data: {
                    devConfig: { width, height }
                }
            }
        } = this.props;

        const dims = this.getPreviewDimensions();

        const iFrameStyle: any = {
            border: `1px solid #ddd`,
            width: `${width}px`,
            height: `${height}px`,
            transformOrigin: 'top left',
            transform: `scale(${dims.scale})`,
            position: 'relative',
            top: `${Math.round(dims.top)}px`,
            left: `${Math.round(dims.left)}px`
        };

        const btnDevConfig = (
            <Button
                style='subtle'
                onClick={() => this.props.openDevConfig()}>
                {ICON_GEAR}
            </Button>
        );

        const btnRefresh = (
            <Button
                style='subtle'
                onClick={() => this.props.refresh()}>
                {ICON_ARROW_ROTATE}
            </Button>
        );

        return (
            <SplitPane
                defaultSize={this.props.width}
                allowResize={true}
                primary='first'
                className='html-preview'
                key='preview'
                onChange={this.resizeHandler}>
                <div
                    className='preview'
                    ref={this.previewWrapperRef}>
                    <div className='preview-title'>
                        <p>Preview</p>
                        <div className='preview-replay'>{btnRefresh}</div>
                        <div className='preview-options'>{btnDevConfig}</div>
                    </div>
                    <div
                        className='iframe-wrapper'
                        style={iFrameStyle}>
                        <iframe
                            ref={this.iframeRef}
                            className='preview-frame'
                            width={width}
                            height={height}
                            src={`${this.props.asset.preview_url}?date=${date}`}
                        />
                    </div>
                </div>
                <div />
            </SplitPane>
        );
    }
}
