import { IMAGE_EXTENSIONS } from '@imposium-hub/components';
import { api } from '../constants/app';
import { AUDIO_EXTENSIONS, VIDEO_EXTENSIONS } from '../constants/story';

export const getVariableType = (value) => {
    if (value) {
        const ext = value.split('.').pop().toLowerCase();
        if (isBoolean(ext)) {
            return 'boolean';
        } else if (isFinite(ext)) {
            return 'number';
        } else if (isUrl(value) && IMAGE_EXTENSIONS.includes(ext)) {
            return 'image';
        } else if (isUrl(value) && VIDEO_EXTENSIONS.includes(ext)) {
            return 'video';
        } else if (isUrl(value) && AUDIO_EXTENSIONS.includes(ext)) {
            return 'audio';
        } else {
            return 'text';
        }
    } else {
        return 'text';
    }
};

export const getPreviewItem = (type, value) => {
    let src: any;

    if (type === 'boolean') {
        src = toBoolean(value);
        return { type, src, option: null };
    } else {
        src = value;
        return { type, src, option: null };
    }
};

export const isUrl = (v) => {
    let url;

    try {
        url = new URL(v);
    } catch (e) {
        return false;
    }

    return url.protocol === 'http:' || url.protocol === 'https:';
};

export const uploadFile = async (url, storyId) => {
    return new Promise((resolve) => {
        api.processStoryFile(storyId, null, url).then((resFile) => {
            resolve(resFile);
        });
    });
};

export const isBoolean = (string) => {
    return string.toUpperCase() === 'TRUE' || string.toUpperCase() === 'FALSE';
};

export const toBoolean = (string) => {
    if (string.toUpperCase() === 'TRUE') {
        return true;
    }

    if (string.toUpperCase() === 'FALSE') {
        return false;
    }
};
