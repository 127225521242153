import { Spinner } from '@imposium-hub/components';
import { dataEditor as copy } from '../../constants/copy';

export const DatasetAsyncIndicator = ({ checkingForErrors, gettingAssets }) => {
    const getCopy = () => {
        if (checkingForErrors) {
            return copy.errorCheckIndicator;
        } else if (gettingAssets) {
            return copy.assetListIndicator;
        } else {
            return null;
        }
    };
    const asyncCopy = getCopy();

    if (asyncCopy) {
        return (
            <div className='dataset-async-indicator'>
                <Spinner />
                {asyncCopy}
            </div>
        );
    } else {
        return null;
    }
};
