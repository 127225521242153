import * as React from 'react';
import {
    FieldWrapper,
    Spinner,
    AssetsTableDropzone,
    DroppableAssetRenderer,
    Button
} from '@imposium-hub/components';
import { fields as copy } from '../constants/copy';
import { api } from '../constants/app';
import { logNotification, logError } from '../util/notifications';
import { VARIABLE_TYPES } from '../constants/story';
import store from '../redux/store';
import { ICON_TIMES } from '../constants/icons';
import { getInputFilter } from '../util/general';

interface IMediaVariableDefaultProps {
    config: any;
    tooltip?: any;
    label?: string;
    onChange(c): void;
    onClear(e): void;
    disableClear?: boolean;
}

interface IMediaVariableDefaultState {
    uploading: boolean;
}

class MediaVariableDefault extends React.Component<
    IMediaVariableDefaultProps,
    IMediaVariableDefaultState
> {
    private input: any;

    constructor(props) {
        super(props);

        this.input = React.createRef();

        this.state = {
            uploading: false
        };
    }

    private onFileDrop(i: any, monitor: any) {
        const { files } = monitor.getItem();
        const file = files[0];

        if (file) {
            this.uploadFile(file);
        }
    }

    private onFileUpload(e) {
        const files = e.target.files;
        const file = files[0];

        if (file) {
            this.uploadFile(file);
        }
    }

    private uploadFile(file) {
        const storyId = store.getState().project.storyId;

        logNotification(copy.variable.uploadingDefault);
        this.setState({ uploading: true }, () => {
            api.getSignedUrl(storyId, file)
                .then((resSignedUrl) => {
                    api.uploadToSignedUrl(resSignedUrl.signed_url, file)
                        .then((resFileUploaded) => {
                            api.processStoryFile(storyId, resSignedUrl.file_key)
                                .then((resFile) => {
                                    this.setState(
                                        {
                                            uploading: false
                                        },
                                        () => {
                                            this.props.onChange(resFile);
                                        }
                                    );
                                })
                                .catch((fileError) => {
                                    logError(copy.variable.uploadError);
                                    this.setState({ uploading: false });
                                });
                        })
                        .catch((e) => {
                            logError(copy.variable.uploadError);
                            this.setState({ uploading: false });
                        });
                })
                .catch((errorSignedUrl) => {
                    logError(copy.variable.uploadError);
                    this.setState({ uploading: false });
                });
        });
    }

    private onAssetDrop(data, m) {
        const {
            rowData: { type }
        } = data;
        const fileType = this.props.config.type;

        if (type === fileType) {
            const storyId = store.getState().project.storyId;
            this.setState(
                {
                    uploading: true
                },
                () => {
                    api.processStoryFile(storyId, null, data.rowData.url)
                        .then((resFile) => {
                            this.setState(
                                {
                                    uploading: false
                                },
                                () => {
                                    this.props.onChange(resFile);
                                }
                            );
                        })
                        .catch((fileError) => {
                            logError(copy.variable.uploadError);
                            this.setState({ uploading: false });
                        });
                }
            );
        } else {
            logError(copy.asset.fieldMismatchError.replace('[accepts]', fileType));
        }
    }

    private renderFields() {
        const { uploading } = this.state;
        const {
            config: { type, url }
        } = this.props;

        if (uploading) {
            return <Spinner />;
        } else {
            if (url) {
                switch (type.toLowerCase()) {
                    case VARIABLE_TYPES.IMAGE:
                        return (
                            <img
                                className='default-preview'
                                src={url}
                            />
                        );
                    case VARIABLE_TYPES.VIDEO:
                        return (
                            <video
                                playsInline={true}
                                className='default-preview'
                                src={url}
                                controls
                            />
                        );
                    case VARIABLE_TYPES.AUDIO:
                        return (
                            <audio
                                className='default-preview'
                                src={url}
                                controls
                            />
                        );
                }
            }
        }
    }

    public onClick() {
        const input = this.input.current;
        const { uploading } = this.state;

        if (input && !uploading) {
            input.click();
        }
    }

    private renderClearButton() {
        const {
            config: { url },
            disableClear
        } = this.props;
        if (url && !disableClear) {
            return <Button onClick={(e) => this.props.onClear(e)}>{ICON_TIMES}</Button>;
        }
    }

    public render() {
        const { uploading } = this.state;
        const {
            tooltip,
            label,
            config: { type }
        } = this.props;

        const accept: string = getInputFilter(type);

        return (
            <>
                <input
                    type='file'
                    accept={accept}
                    style={{ display: 'none' }}
                    ref={this.input}
                    onChange={(e) => this.onFileUpload(e)}
                />
                <FieldWrapper
                    tooltip={tooltip}
                    label={label}>
                    <AssetsTableDropzone
                        onDrop={(i, m) => this.onFileDrop(i, m)}
                        disable={uploading}>
                        <div
                            className='variable-default-upload'
                            onClick={() => this.onClick()}>
                            <DroppableAssetRenderer onDrop={(i, m) => this.onAssetDrop(i, m)}>
                                {this.renderFields()}
                                <div className='btn-wrapper'>{this.renderClearButton()}</div>
                            </DroppableAssetRenderer>
                        </div>
                    </AssetsTableDropzone>
                </FieldWrapper>
            </>
        );
    }
}

export default MediaVariableDefault;
