import {
    ICON_CARET_LEFT,
    ICON_CARET_RIGHT,
    ICON_DIAMOND,
    ICON_STOPWATCH,
    ICON_STOPWATCH_LIGHT
} from '../../constants/icons';
import { ICompositionLayer, IKeyframe } from '../../constants/snippets';
import { KEYFRAME_CONTROLLER_TYPES } from '../../constants/timeline';
import { getInterpolatedConfigValue } from '../../util/timeline';
import { KeyframeCompoundControls } from './KeyframeCompoundControls';
import { KeyframeNumberControls } from './KeyframeNumberControls';
import { Button } from '@imposium-hub/components';

interface IKeyframeControllerProps {
    min?: number;
    max?: number;
    field: string;
    label: string;
    type: string;
    defaultValue: any;
    relativeFrame: number;
    keyframes: IKeyframe[];
    keys?: string[];
    units?: string[];
    disabled?: boolean;
    labelOverrides?: any;
    compWidth: number;
    compHeight: number;
    disableKeyframes?: boolean;
    config?: ICompositionLayer;
    onKeyframeValueChange?(index: number, val: any): void;
    onAddKeyframe?(val: any): void;
    onSelectKeyframe?(keyframe): any;
    onClearKeyframes?(): void;
    onDeleteKeyframe?(index): void;
    onDefaultValueChange(key, value): void;
    onUnitChange?(pos, keyframes): void;
    showFitToComp?: boolean;
    composition?: any;
}

export const KeyframeController = ({
    min,
    max,
    label,
    labelOverrides,
    type,
    disabled,
    disableKeyframes,
    defaultValue,
    compWidth,
    compHeight,
    keyframes,
    relativeFrame,
    keys,
    units,
    config,
    onKeyframeValueChange,
    onSelectKeyframe,
    onClearKeyframes,
    onAddKeyframe,
    onDeleteKeyframe,
    onDefaultValueChange,
    onUnitChange,
    showFitToComp,
    composition
}: IKeyframeControllerProps) => {
    const hasKeyframes = keyframes && keyframes.length > 0;
    const activeKeyframeIndex = keyframes.findIndex((k) => {
        return k.relativeFrame === relativeFrame;
    });

    const activeKeyframe = activeKeyframeIndex !== -1 ? keyframes[activeKeyframeIndex] : null;
    const activeClass = activeKeyframe ? 'on-keyframe' : '';
    const disabledClass = disabled ? 'disabled' : '';
    const getPrevAndNext = () => {
        let prev = null;
        let next = null;
        for (const keyframe of keyframes) {
            if (keyframe.relativeFrame < relativeFrame) {
                prev = keyframe;
            }
            if (keyframe.relativeFrame > relativeFrame && !next) {
                next = keyframe;
            }
        }
        return {
            prevKeyframe: prev,
            nextKeyframe: next
        };
    };

    const { prevKeyframe, nextKeyframe } = getPrevAndNext();

    const getFrameValue = () => {
        if (keyframes && keyframes.length > 0) {
            if (activeKeyframe) {
                return activeKeyframe.value;
            } else {
                return getInterpolatedConfigValue(keyframes, relativeFrame, keys);
            }
        } else {
            return defaultValue;
        }
    };

    const valueChanged = (v) => {
        if (keyframes.length > 0) {
            if (activeKeyframeIndex !== -1) {
                onKeyframeValueChange(activeKeyframeIndex, v);
            } else {
                initKeyframe(v);
            }
        } else {
            onDefaultValueChange('position_inputs', v);
        }
    };

    const renderControls = () => {
        switch (type) {
            case KEYFRAME_CONTROLLER_TYPES.COMPOUND:
                return (
                    <KeyframeCompoundControls
                        compWidth={compWidth}
                        compHeight={compHeight}
                        keys={keys}
                        units={units}
                        label={label}
                        labelOverrides={labelOverrides}
                        config={config}
                        showFitToComp={showFitToComp}
                        composition={composition}
                        onChange={valueChanged}
                        onDefaultValueChange={onDefaultValueChange}
                        onUnitChange={onUnitChange}
                        value={getFrameValue()}
                    />
                );
            case KEYFRAME_CONTROLLER_TYPES.NUMBER:
                return (
                    <KeyframeNumberControls
                        min={min}
                        max={max}
                        labelOverrides={labelOverrides}
                        onChange={valueChanged}
                        value={getFrameValue()}
                    />
                );
        }
    };

    const initKeyframe = (val) => {
        if (!activeKeyframe) {
            const value = val !== undefined && val !== null ? val : getFrameValue();
            onAddKeyframe({
                type,
                value
            });
        }
    };

    const btnAddKeyframes = (
        <Button
            customStyles={{ alignSelf: 'flex-end' }}
            style='subtle'
            color={hasKeyframes ? 'primary' : 'default'}
            onClick={!hasKeyframes ? () => initKeyframe(null) : onClearKeyframes}>
            {hasKeyframes ? ICON_STOPWATCH : ICON_STOPWATCH_LIGHT}
        </Button>
    );
    const disablePrevKeyframe = !prevKeyframe ? 'disabled' : '';
    const disableNextKeyframe = !nextKeyframe ? 'disabled' : '';
    const invertAddKeyframe = activeKeyframe ? 'inverted' : '';
    const onKeyframeClick = invertAddKeyframe
        ? () => onDeleteKeyframe(activeKeyframeIndex)
        : () => initKeyframe(null);
    const keyframeNav = (
        <div className='keyframe-nav'>
            <div
                className={`btn-keyframe-nav btn-prev-keyframe ${disablePrevKeyframe}`}
                onClick={() => onSelectKeyframe(prevKeyframe)}>
                {ICON_CARET_LEFT}
            </div>
            <div
                className={`btn-keyframe-nav btn-add-keyframe ${invertAddKeyframe}`}
                onClick={onKeyframeClick}>
                {ICON_DIAMOND}
            </div>
            <div
                className={`btn-keyframe-nav btn-next-keyframe ${disableNextKeyframe}`}
                onClick={() => onSelectKeyframe(nextKeyframe)}>
                {ICON_CARET_RIGHT}
            </div>
        </div>
    );

    return (
        <div className={`keyframe-controller ${activeClass} ${disabledClass}`}>
            <div className='col-left'>
                {!disableKeyframes && btnAddKeyframes}
                {hasKeyframes && keyframeNav}
            </div>
            <div className='col-right'>
                <div className='keyframe-controller-label'>{label}</div>
            </div>
            {renderControls()}
        </div>
    );
};
