import { Button, Modal, Section, TextField } from '@imposium-hub/components';
import { timeline as copy } from '../../constants/copy';
import { useHotkeys } from 'react-hotkeys-hook';

export const CompNameModal = ({ compName, onClose, onChange, onContinue }) => {
    useHotkeys('enter', onContinue, { enableOnFormTags: true });

    return (
        <Modal
            onRequestClose={onClose}
            style={{
                width: '400px',
                height: '70%',
                top: '10%',
                left: 'calc((100% - 400px) / 2)'
            }}
            isOpen={true}>
            <Section title={copy.compModal.title}>
                <TextField
                    label={copy.compModal.label}
                    labelPosition={'top'}
                    focusOnMount={true}
                    onChange={onChange}
                    value={compName}
                    maxLength={100}
                />
                <Button
                    disabled={!compName}
                    onClick={onContinue}
                    style='bold'
                    color='primary'>
                    {copy.compModal.continue}
                </Button>
            </Section>
        </Modal>
    );
};
