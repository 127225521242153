import * as React from 'react';
import { logError } from '../util/notifications';
import { SelectField, SliderField } from '@imposium-hub/components';
import { EFFECT_BLEND_MODE_OPTIONS, ASSET_TYPES } from '../constants/story';
import { IOverlayEffects } from '../constants/snippets';
import { fields as copy } from '../constants/copy';
import { SCENE_TYPES } from '../constants/story';
import { api } from '../constants/app';
import { matchType } from '../util/story';
import AssetField from './assets/AssetField';

interface IEffectConfigProps {
    storyId: string;
    config: IOverlayEffects;
    overlayType: string;
    onChange(e: any): void;
}

class EffectConfig extends React.PureComponent<IEffectConfigProps, undefined> {
    private evtHandlers: any;

    constructor(props) {
        super(props);

        this.evtHandlers = {
            inputs: {
                blend: (v) => this.update('blend_mode', v),
                mask: (a) => this.maskAssetChanged(a),
                saturation: (v) => this.update('saturation', v),
                hue: (v) => this.update('hue', v),
                brightness: (v) => this.update('brightness', v),
                blur: (v) => this.update('motion_blur', v),
                lut: (v) => this.update('lut_asset_id', v ? v.id : null),
                assetError: (m) => {
                    logError(m);
                }
            }
        };
    }

    private maskAssetChanged(asset) {
        const newConfig = { ...this.props.config };

        if (!asset) {
            delete newConfig['mask_asset_id'];
        } else {
            newConfig['mask_asset_id'] = asset.id;
        }

        this.props.onChange(newConfig);
    }

    private update(key, value) {
        const newConfig = { ...this.props.config };
        newConfig[key] = value;

        if (value === '' || value === null) {
            delete newConfig[key];
        }

        this.props.onChange(newConfig);
    }

    public render() {
        const { config, overlayType, storyId } = this.props;

        const maskAccepts =
            overlayType === SCENE_TYPES.IMAGE ? ASSET_TYPES.IMAGE : ASSET_TYPES.IMAGE_SEQUENCE;

        return (
            <>
                <SelectField
                    label={copy.effectConfig.blendMode}
                    width='50%'
                    options={EFFECT_BLEND_MODE_OPTIONS}
                    value={config['blend_mode']}
                    onChange={this.evtHandlers.inputs.blend}
                />
                <AssetField
                    api={api}
                    storyId={storyId}
                    label={copy.effectConfig.mask}
                    width='50%'
                    tooltip={copy.effectConfig.tooltipMask}
                    onChange={this.evtHandlers.inputs.mask}
                    accepts={maskAccepts}
                    onError={this.evtHandlers.inputs.assetError}
                    assetId={config['mask_asset_id']}
                />
                <SliderField
                    label={copy.effectConfig.saturation}
                    width='50%'
                    min={0}
                    max={1}
                    step={0.01}
                    value={config['saturation']}
                    defaultValue={1}
                    onChange={this.evtHandlers.inputs.saturation}
                />
                <SliderField
                    label={copy.effectConfig.hue}
                    width='50%'
                    min={0}
                    max={360}
                    step={1}
                    value={config['hue']}
                    defaultValue={0}
                    onChange={this.evtHandlers.inputs.hue}
                />
                <SliderField
                    label={copy.effectConfig.brightness}
                    width='50%'
                    min={-10}
                    max={10}
                    step={0.05}
                    value={config['brightness']}
                    defaultValue={0}
                    onChange={this.evtHandlers.inputs.brightness}
                />
                <AssetField
                    api={api}
                    storyId={storyId}
                    label={copy.overlayConfig.lut}
                    width='50%'
                    tooltip={copy.overlayConfig.tooltipLut}
                    onChange={this.evtHandlers.inputs.lut}
                    onError={this.evtHandlers.inputs.assetError}
                    accepts={ASSET_TYPES.LUT}
                    assetId={config['lut_asset_id']}
                />
                {matchType(
                    <SliderField
                        label={copy.effectConfig.motionBlur}
                        width='50%'
                        min={0}
                        max={1}
                        step={0.01}
                        value={config['motion_blur']}
                        defaultValue={0}
                        onChange={this.evtHandlers.inputs.blur}
                    />,
                    overlayType,
                    SCENE_TYPES.VIDEO
                )}
            </>
        );
    }
}

export default EffectConfig;
